import { format, getYear, subDays } from "date-fns";
import _, { capitalize } from "lodash";

import {
  formatDate,
  formatDateString,
  formatMoney,
  formatMoneyString,
  stringToCents,
  upCase,
} from "../../../utility/utilityFunctions";

// Obtained from order type and defmacro order_type_display_fragment in the backend
const orderTypeMap = {
  "pre-order": "Pre-Order",
  "on-demand": "On-Demand",
  inventory: "Inventory",
  "re-stock": "Re-Stock",
};

const mapSummaryItem = (item) => {
  return {
    id: item.id,
    supplier: item.supplier_name,
    programType: item.program_type_description
      ? item.program_type_description
      : "---",
    brands: [...new Set(item.brand_names.split(","))].join(", "),
    bus: [...new Set(item.business_unit_names.split(","))].join(", "),
    program: item.program_name ? item.program_name : "---",
    itemNumber: item.item_number,
    itemTypeCode: item.item_type_external_id,
    itemType: item.item_type_description,
    itemDesc: item.comment ? item.comment : "---",
    totalQty: item.total_order_variants_quantity,
    totalOrderVariants: item.total_order_variants,
    estCost: formatMoney(stringToCents(item.estimated_cost), false),
    totalEstCost: formatMoney(
      item.total_order_variants_quantity * stringToCents(item.estimated_cost),
      false
    ),
    standardSpecCode: item.standard_specification_code ?? "---",
    standardSpecQtyTier: item.standard_specification_quantity_tier ?? "---",
    priceBasedOnQty: item.price_based_on_qty
      ? formatMoney(stringToCents(item.price_based_on_qty))
      : "---",
  };
};

export const mapOrderWindowSummary = (repItems) => {
  let qtyItems = repItems.filter((i) => i.total_order_variants_quantity > 0);
  let zeroQtyItems = repItems.filter(
    (i) => i.total_order_variants_quantity === 0
  );

  const qtySummary = qtyItems.map((item) => mapSummaryItem(item));

  const zeroQtySummary =
    zeroQtyItems.length > 0
      ? zeroQtyItems.map((item) => mapSummaryItem(item))
      : null;

  const blankRow = [
    {
      id: "blank-row",
      supplier: "Items that were not ordered:",
      programType: "",
      brands: "",
      bus: "",
      program: "",
      itemNumber: "",
      itemTypeCode: "",
      itemType: "",
      itemDesc: "",
      totalQty: "",
      totalOrderVariants: "",
      estCost: "",
      totalEstCost: "",
    },
  ];

  const focusSummary = zeroQtySummary
    ? qtySummary.concat(blankRow).concat(zeroQtySummary)
    : qtySummary;

  return focusSummary;
};

export const mapRFPSummary = (repItems) => {
  const rfpSummary = repItems.map((item) => {
    const mapAndJoin = (arr, prop) => arr.map((item) => item[prop]).join(", ");

    const bidObjectArr = item.bids
      ? item.bids.map((bid) => ({
          price: bid.price ? formatMoney(stringToCents(bid.price)) : "No Bid",
          priceWithMarkup: bid.price_with_markup
            ? formatMoney(stringToCents(bid.price_with_markup), true)
            : "No Bid",
          status: bid.status ? capitalize(bid.status) : "No Status",
        }))
      : [];

    return {
      id: item.id,
      itemNumber: item.item_number,
      estCost: formatMoney(stringToCents(item.estimated_cost), true),
      supplier: item.supplier_name
        ? item.supplier_name.replace(/_/g, " ")
        : "---",
      programType: item.program_type_description
        ? item.program_type_description
        : "---",
      brands: [...new Set(item.brand_names.split(","))].join(", "),
      bus: item.business_unit_names
        ? [...new Set(item.business_unit_names.split(","))].join(", ")
        : "---",
      program: item.program_name ? item.program_name : "---",
      itemTypeCode: item.item_type_external_id,
      itemType: item.item_type_description,
      itemDesc: item.comment ? item.comment : "---",
      totalQty: item.total_order_variants_quantity,
      totalOrderVariants: item.total_order_variants,
      bids: mapAndJoin(bidObjectArr, "price"),
      bidsWithMarkup: mapAndJoin(bidObjectArr, "priceWithMarkup"),
      bidStatuses: mapAndJoin(bidObjectArr, "status"),
    };
  });
  const rfpSummarySorted = _.sortBy(rfpSummary, "supplier");
  return rfpSummarySorted;
};

export const mapFocusPresentation = (repItems, geographyType) => {
  let focusPresentation = repItems.map((item) => ({
    program: item.program_name,
    territory: item.territory_name,
    orderTerritory: item.order_territory_name,
    brand: item.primary_brand ? item.primary_brand : "---",
    businessUnitNames: item.business_unit_names
      ? item.business_unit_names
      : "---",
    image: item.images.length !== 0 ? item.images[0] : "/images/NotFound.png",
    itemName: item.item_name,
    itemNumber: item.item_number,
    itemDesc: item.item_comment ? item.item_comment : "---",
    totalQty: item.total_order_variants_quantity,
    addressCity: item.address_city,
    addressName: item.address_name,
    inMarketDate: item.purchase_order_in_market_date
      ? format(formatDate(item.purchase_order_in_market_date), "MM/dd/yyyy")
      : "---",
  }));
  focusPresentation = groupByProg(focusPresentation, geographyType);
  return focusPresentation;
};

const groupByProg = (array, groupBy) => {
  let progGroupObj = array.reduce((group, item) => {
    let groupName = item.program;
    group[groupName] = group[groupName] || [];
    group[groupName].push(item);
    return group;
  }, {});

  for (const key in progGroupObj) {
    progGroupObj[key] = groupByItemNum(progGroupObj[key], groupBy);
  }
  return progGroupObj;
};

const groupByItemNum = (array, groupBy) => {
  let itemNumObj = array.reduce((group, item) => {
    let groupName = item.itemNumber;
    group[groupName] = group[groupName] || [];
    group[groupName].push(item);
    return group;
  }, {});

  for (const key in itemNumObj) {
    itemNumObj[key] = groupByTerOrDist(itemNumObj[key], groupBy);
  }
  return itemNumObj;
};

const groupByTerOrDist = (array, groupBy) => {
  let groupType = groupBy === "national" ? "territory" : "addressCity";
  let progGroupObj = array.reduce((group, item) => {
    let groupName = item[groupType];
    group[groupName] = group[groupName] || [];
    group[groupName].push(item);
    return group;
  }, {});
  const sortedArray = Object.values(progGroupObj)
    .sort((a, b) => {
      return a[0].brand < b[0].brand ? -1 : a[0].brand > b[0].brand ? 1 : 0;
    })
    .reduce((a, b) => a.concat(b));
  const totalQty = sortedArray
    .map((item) => item.totalQty)
    .reduce((a, b) => a + b);
  const sortedWithTotals = sortedArray.map((item) => ({
    ...item,
    grandTotal: totalQty,
  }));
  return sortedWithTotals;
};

export const mapOrderWindowDetail = (repItems) => {
  const focusDetail = repItems.map((item) => ({
    id: item.id,
    itemNumber: item.item_number,
    program: item.program_name,
    bus: [...new Set(item.business_unit_names.split(","))].join(", "),
    brands: [...new Set(item.brand_names.split(","))].join(", "),
    territory: item.territory_name,
    distributorAbn: item.address_external_id ?? "---",
    addressABN: item.address_abn ?? "---",
    addressName: item.address_name?.replace(/,/g, ""),
    addressOne: item.address_street_one,
    addressTwo: item.address_street_two ? item.address_street_two : "---",
    city: item.address_city,
    state: item.state_code,
    zip: item.address_zip,
    isCoupon: item.isCoupon ? "Coupon" : "POS",
    itemTypeCode: item.item_type_external_id,
    itemType: item.item_type_description,
    itemDesc: item.comment ? item.comment : "---",
    supplier: item.supplier_name,
    totalQty: item.quantity,
    estCost: formatMoney(stringToCents(item.estimated_cost), false),
    totalEstCost: formatMoney(
      item.quantity * stringToCents(item.estimated_cost),
      false
    ),
    totalEstWithTax: formatMoney(
      item.quantity * stringToCents(item.estimated_cost) +
        stringToCents(item.total_estimated_tax),
      false
    ),
    user: item.user_name,
    status: upCase(item.order_status, "-"),
    standardSpecCode: item.standard_specification_code ?? "---",
  }));
  return focusDetail;
};

export const mapOrderVariantSummary = (repItems) => {
  const itemArray = [];
  repItems.forEach((item) => {
    if (item.brands.length === 1) {
      itemArray.push({
        id: item.id,
        user: item["order-user-name"],
        itemNumber: item["item-number"],
        territory: item["territory-name"],
        state: item.state,
        brand: item.brands[0].name,
        brandCode: item.brands[0]["external-id"],
        unit: item.brands[0]["business-unit"].name,
        itemType: item["item-type-description"],
        inMarketMonth: item["month-in-market"]
          ? format(formatDate(item["month-in-market"]), "MM/dd/yyyy")
          : "---",
        estCost: formatMoney(stringToCents(item["item-estimated-cost"]), true),
        actCost: item["po-item-actual-cost"]
          ? formatMoney(stringToCents(item["po-item-actual-cost"]), true)
          : "---",
        totalQty: item.qty,
        program: item["program-name"],
        orderType: orderTypeMap[item["order-type"]],
        // couponValue:
        //   item["item-coupon-face-value"] &&
        //   item["item-coupon-face-value"] !== "0.0"
        //     ? stringToCents(item["item-coupon-face-value"])
        //     : "---",
        couponValue: "---",
        brandFunded: item["program-is-brand-funded"] ? "Yes" : "No",
        dropCost: "---",
        blankA: "",
        blankB: "",
        blankC: "",
      });
    } else {
      item.brands.forEach((brand) => {
        itemArray.push({
          id: item.id,
          user: item["order-user-name"],
          itemNumber: item["item-number"],
          territory: item["territory-name"],
          state: item.state,
          brand: brand.name,
          brandCode: brand["external-id"],
          unit: brand["business-unit"].name,
          itemType: item["item-type-description"],
          inMarketMonth: item["month-in-market"]
            ? format(formatDate(item["month-in-market"]), "MM/dd/yyyy")
            : "---",
          estCost: stringToCents(item["item-estimated-cost"]),
          actCost: item["po-item-actual-cost"]
            ? stringToCents(item["po-item-actual-cost"])
            : "---",
          totalQty: parseFloat((item.qty / item.brands.length).toFixed(1)),
          program: item["program-name"],
          orderType: item["order-type"],
          // couponValue:
          //   item["item-coupon-face-value"] &&
          //   item["item-coupon-face-value"] !== "0.0"
          //     ? stringToCents(item["item-coupon-face-value"])
          //     : "---",
          couponValue: "---",
          brandFunded: item["program-is-brand-funded"] ? "Yes" : "No",
          dropCost: "---",
        });
      });
    }
  });
  return itemArray;
};

export const mapPOComplianceItems = (items) => {
  const typeMap = {
    "prior-approval": "Prior Approval",
    "coupon-prior-approval": "Coupon Prior Approval",
    "internal-approval": "Internal Approval",
    price: "Price",
    "item-type": "Item Type",
    material: "Material",
    "metal-wood": "Metal / Wood",
    "coupon-offer-type": "Coupon Offer Type",
    "coupon-item-type": "Coupon Item Type",
    "coupon-face-value": "Coupon Face Value",
  };
  const statusMap = {
    "prior-approval-pending": "Pending",
    "in-violation": "In Violation",
    approved: "Approved",
    denied: "Denied",
  };
  let mappedItems = items.map((item) => {
    return {
      id: item.id,
      poId: item.purchase_order_id,
      purchaser: item.purchaser_name,
      supplier: item.supplier_name,
      itemNumber: item.item_number,
      program: item.program_names,
      brand: item.brand_names,
      brandCode: item.brand_codes,
      itemType: item.item_type_description,
      itemTypeCode: item.item_type_external_id,
      ruleType: item.rule_type
        .split(",")
        .map((r) => typeMap[r])
        .join(", "),
      status: item.rule_status
        .split(",")
        .map((s) => statusMap[s])
        .join(", "),
      ruleDesc: item.rule_description.split(",").join(", "),
      state: item.state_code,
      territory: item.territory_name,
      orderedBy: item.ordered_by,
      emailSent: item.approval_email_sent ? "Yes" : "No",
      emailSentDate: item.email_sent_at
        ? format(formatDate(item.email_sent_at), "MM/dd/yyyy")
        : "---",
      inMarketDate: item.in_market_date
        ? format(formatDate(item.in_market_date), "MM/dd/yyyy")
        : "---",
      orderType: item.order_type ? orderTypeMap[item.order_type] : "---",
    };
  });
  return mappedItems;
};
const mapInventoryItem = (row, allocation) => {
  let dataObject = {
    id: row.id,
    itemNumber: row.item_number,
    itemDesc: row.comment,
    estCost: formatMoneyString(row.estimated_cost),
    warehouse: row.warehouse ? upCase(row.warehouse) : "Not Assigned",
    program: row.program_names,
    brand: row.brand_names,
    brandCode: row.brand_codes,
    bu: row.business_unit_names,
    itemType: row.item_type_description,
    itemTypeCode: row.item_type_external_id,
    ninetyDayQty: allocation.ninety_day_order_qty,
    yearQty: allocation.year_order_qty,

    allocationName: allocation.name,
    allocationType: allocation.type,
    region: allocation.region,
    availableToOrderQty: allocation.available_to_order_qty,

    mostRecentReceivedQty: allocation.most_recent_received_inventory?.qty,
    mostRecentReceivedDate: allocation.most_recent_received_inventory?.date,
    pendingOrdered: allocation.ordered,
    firstShip: allocation.first_ship,
    lastShip: allocation.last_ship,
  };
  return dataObject;
};

export const mapInventoryItems = (data) => {
  return data.flatMap((row) =>
    row.allocations.map((allocation) => mapInventoryItem(row, allocation))
  );
};

const mapAvailableInventoryItem = (row, allocation) => ({
  id: row.id,
  itemNumber: row.item_number,
  itemDesc: row.comment,
  brand: row.brand_names,
  brandCode: row.brand_codes,
  bu: row.business_unit_names,
  itemType: row.item_type_description,
  allocationName: allocation.name,
  allocationType: allocation.type,
  availableToOrderQty: allocation.available_to_order_qty,
  estCost: formatMoneyString(row.estimated_cost),
});

export const mapAvailableInventoryItems = (data) => {
  return data.flatMap((row) =>
    row.allocations.map((allocation) =>
      mapAvailableInventoryItem(row, allocation)
    )
  );
};

export const mapRoadmapForecastItems = (items) => {
  const mappedItems = items.map((item) => ({
    id: item.id,
    itemNumber: item.item_number,
    itemDesc: item.comment ? item.comment : "---",
    isCoupon: item.is_coupon ? "Coupon" : "POS",
    estCost: formatMoney(stringToCents(item.estimated_cost), true),
    actCost: item.max_actual_cost
      ? formatMoney(stringToCents(item.max_actual_cost), true)
      : "---",
    totalCost: item.max_actual_cost
      ? formatMoney(item.qty * stringToCents(item.max_actual_cost), true)
      : formatMoney(item.qty * stringToCents(item.estimated_cost), true),
    qty: item.qty,
    shipDate: item.ship_date
      ? format(formatDate(item.ship_date), "MM/dd/yyyy")
      : "---",
    trackingNumber: item.tracking_data ? item.tracking_data.number : "---",
    carrier: item.tracking_data ? item.tracking_data.carrier : "---",
    orderDate: format(formatDate(item.order_date), "MM/dd/yyyy"),
    orderType: orderTypeMap[item.order_type],
    orderNumber: item.order_number,
    orderStatus: upCase(item.order_status, "-"),
    name: item.address_name?.replace(/,/g, ""),
    state: item.state_code,
    inMarketDate:
      item.program_in_market_date && item.order_type === "pre-order"
        ? format(formatDate(item.program_in_market_date), "MM/dd/yyyy")
        : item.requested_in_market_date
        ? format(formatDate(item.requested_in_market_date), "MM/dd/yyyy")
        : "---",
    program: item.order_program_name
      ? item.order_program_name
      : item.item_program_names.split(",").join(", "),
    brand: item.brand_names.split(",").join(", "),
    brandCode: item.brand_codes.split(",").join(", "),
    itemType: item.item_type_description,
    itemTypeCode: item.item_type_external_id,
    territory: item.territory_name,
    user: item.user_name,
    blank_1: "",
    blank_2: "",
    anaplanCode: item.codes,
  }));

  return mappedItems;
};

export const mapCustomCouponReport = (items) => {
  let totalsObject = items.reduce((seqGroup, item) => {
    let group = item.item_number;
    seqGroup[group] = (seqGroup[group] || 0) + +item.qty;
    return seqGroup;
  }, {});
  let stateTerrTotalsObject = items.reduce((stateTerrGroup, item) => {
    let group = `${item.item_number}-${item.state_code}-${item.territory_name}`;
    stateTerrGroup[group] = (stateTerrGroup[group] || 0) + +item.qty;
    return stateTerrGroup;
  }, {});

  const mappedOrderVariants = items
    .map((item) => {
      // Redemptions and Fees are duplicates of "Coupon Production" rows, avoid sending a quantity for those rows.
      const isCouponFeesOrRedemption = Boolean(
        item.tactic === "Coupon Fees" || item.tactic === "Coupon Redemptions"
      );
      return {
        createdAt: format(formatDate(item.created_at), "MM/dd/yyyy"),
        createdBy: item.created_by,
        userName: item.ordered_by,
        userEmail: item.ordered_by_email,
        territoryType: item.territory_type,
        territoryName: item.territory_name,
        brandCode: item.brand_codes
          ? item.brand_codes.split(",").join(", ")
          : "",
        brand: item.brand_names ? item.brand_names.split(",").join(", ") : "",
        itemNumber: item.item_number,
        couponOfferTypeCode: item.offer_type,
        couponTypeCode: item.version,
        itemTypeCode: item.item_type_external_id,
        itemType: item.item_type_description,
        couponStartDate: item.start_date
          ? format(formatDate(item.start_date), "MM/dd/yyyy")
          : null,
        couponExpirationDate: item.expiration_date
          ? format(formatDate(item.expiration_date), "MM/dd/yyyy")
          : null,
        couponFaceValue: formatMoneyString(item.face_value, false),
        isNABItem: item.version === "NAB" ? "Yes" : "No",
        andType: "and",
        couponOfferDetail: item.offer_detail,
        totalQty: isCouponFeesOrRedemption ? 0 : totalsObject[item.item_number],
        stateTerrQty: isCouponFeesOrRedemption
          ? 0
          : stateTerrTotalsObject[
              `${item.item_number}-${item.state_code}-${item.territory_name}`
            ],
        state: item.state_code,
        brandFunded: "No",
        channel: upCase(item.channel, "-"),
        barcodeId: item.barcode_id,
        programType: item.program_type,
        tactic: item.tactic ?? "", // For backwards compatibility
        orderId: item.order_id ?? "",
      };
    })
    .sort((a, b) => {
      return parseInt(a.itemNumber) < parseInt(b.itemNumber)
        ? -1
        : parseInt(a.itemNumber) > parseInt(b.itemNumber)
        ? 1
        : 0;
    });

  return mappedOrderVariants;
};

export const mapCDCSummaryReport = (items) => {
  const mappedItems = items.map((item) => ({
    id: item.id,
    supplier: item.supplier_name,
    itemNumber: item.item_number,
    itemType: item.item_type_description,
    brand: item.brand_names.split(",").join(", "),
    totalQty: item.total_order_variants_quantity,
    comment: "",
  }));
  return mappedItems;
};

export const mapCDCShipListReport = (items) => {
  let currentYear = getYear(new Date()).toString().slice(2);
  const mappedItems = items.map((item) => ({
    id: item.id,
    purchaseOrderNum: item.purchase_order_id,
    supplierReference: item.supplier_reference ?? "---",
    isKeyAccount: item.territory_type === "Customer" ? "Yes" : "No",
    territory: item.territory_type === "Customer" ? item.territory_name : "---",
    inMarketDate: format(formatDate(item.in_market_date), "MM/dd/yyyy"),
    orderNotes: item.order_notes ? item.order_notes : "---",
    distributorAbn: item.address_external_id ? item.address_external_id : "---",
    addressName: item.address_name?.replace(/,/g, ""),
    addressOne: item.address_street_one,
    addressTwo: item.address_street_two ? item.address_street_two : "---",
    attn: item.attn ? item.attn : "---",
    city: item.address_city,
    state: item.state_code,
    zip: item.address_zip,
    phoneNumber: item.phone_number ?? "---",
    itemNumber: item.item_number,
    shippingLabel: `${item["shipping_label"].title} - ${item["shipping_label"].desc} - ${currentYear}-${item["shipping_label"].code}`,
    totalQty: item.qty,
    complianceStatus: upCase(item.compliance_status, "-"),
    carrier: item.tracking_data?.carrier || "",
    method: item.method || "",
    actShipDate: item.actual_ship_date
      ? formatDateString(item.actual_ship_date)
      : "",
    shippedQty: item.shipped_qty || "",
    packageCount: item.package_count || "",
    tracking: item.tracking_data?.number || "",
    tax:
      item.tax && item.tax !== "0"
        ? formatMoney(stringToCents(item.tax), true)
        : "",
  }));
  return mappedItems;
};

export const mapCDCTransferAdviceReport = (items) => {
  const mappedItems = items.map((item) => ({
    id: item.id,
    purchaseOrderNum: item.purchase_order_id,
    inMarketDate: format(
      subDays(new Date(item.in_market_date), 21),
      "MM/dd/yyyy"
    ),
    supplier: item.supplier_name,
    totalQty: item.qty,
    packageCount: Math.floor(item.qty / item.qty_per_pack),
    itemNumber: item.item_number,
    itemType: item.item_type_description,
    unitOfMeasure: item.qty_per_pack === 1 ? "Eaches" : "Packs",
    packSize: item.qty_per_pack,
    brandCode: item.brand_codes.split(",").join(", "),
    brand: item.brand_names.split(",").join(", "),
    payloadId: "",
    timeStamp: "",
    nowDate: format(formatDate(new Date()), "MM/dd/yyyy"),
    orderType: "",
    totalMoney: "",
    abn: "",
    shipName: "Rapid Global Logistics",
    shipDeliverTo: "",
    shipStreetOne: "4100 W. 76th Street Suite F",
    shipStreetTwo: "Attn: Loretta Robinson",
    shipCity: "Chicago",
    shipState: "IL",
    shipZip: "60652",
    shipCountry: "US",
    billName: "",
    billDeliverTo: "",
    billStreetOne: "",
    billStreetTwo: "",
    billCity: "",
    billState: "",
    billZip: "",
    billCountry: "",
    shipMoney: "",
    shipDesc: "Not Specific",
    shopperName: "",
    costCenter: "",
    reqType: "TransferAdvice",
    arrivalDate: "",
    vendorNum: "TransferAdvice",
    payloadId2: "",
    timeStamp2: "",
    purchaseOrderNum2: item.purchase_order_id,
    lineNum: "",
    unitPrice: "",
    classification: "",
    packageType: "Cartons",
    tracking: "",
    rapidNum2: "",
    dateTime2: "",
  }));
  return mappedItems;
};

export const mapSupplierSpendSummaryReport = (items) => {
  let mappedItems = [];
  items.forEach((item) => {
    let retailSpend = stringToCents(item.retail_total_po_product_spend);
    let retailFreight = item.retail_total_freight
      ? stringToCents(item.retail_total_freight)
      : 0;
    let retailTax = item.retail_total_tax
      ? stringToCents(item.retail_total_tax)
      : 0;

    let onPremiseSpend = stringToCents(item.on_premise_total_po_product_spend);
    let onPremiseFreight = item.on_premise_total_freight
      ? stringToCents(item.on_premise_total_freight)
      : 0;
    let onPremiseTax = item.on_premise_total_tax
      ? stringToCents(item.on_premise_total_tax)
      : 0;

    let inventorySpend = stringToCents(item.inventory_total_po_product_spend);
    let inventoryFreight = item.inventory_total_freight
      ? stringToCents(item.inventory_total_freight)
      : 0;
    let inventoryTax = item.inventory_total_tax
      ? stringToCents(item.inventory_total_tax)
      : 0;

    item.retail_purchase_order_count > 0 &&
      mappedItems.push({
        id: item.id,
        channel: "Retail",
        supplier: item.supplier_name,
        totalPurchaseOrders: item.retail_purchase_order_count,
        totalPOProductSpend: formatMoney(retailSpend, true),
        totalFreight: formatMoney(retailFreight, true),
        totalTax: formatMoney(retailTax, true),
        totalPOAmount: formatMoney(
          retailSpend + retailFreight + retailTax,
          true
        ),
      });

    item.on_premise_purchase_order_count > 0 &&
      mappedItems.push({
        id: item.id,
        channel: "On Premise",
        supplier: item.supplier_name,
        totalPurchaseOrders: item.on_premise_purchase_order_count,
        totalPOProductSpend: formatMoney(onPremiseSpend, true),
        totalFreight: formatMoney(onPremiseFreight, true),
        totalTax: formatMoney(onPremiseTax, true),
        totalPOAmount: formatMoney(
          onPremiseSpend + onPremiseFreight + onPremiseTax,
          true
        ),
      });

    item.inventory_purchase_order_count > 0 &&
      mappedItems.push({
        id: item.id,
        channel: "NA (Inventory POs)",
        supplier: item.supplier_name,
        totalPurchaseOrders: item.inventory_purchase_order_count,
        totalPOProductSpend: formatMoney(inventorySpend, true),
        totalFreight: formatMoney(inventoryFreight, true),
        totalTax: formatMoney(inventoryTax, true),
        totalPOAmount: formatMoney(
          inventorySpend + inventoryFreight + inventoryTax,
          true
        ),
      });
  });
  return mappedItems;
};

export const mapSupplierSpendDetailReport = (items) => {
  const directCostItems = items.filter((i) => i.is_direct_cost);
  const nonDirectCostItems = items.filter((i) => !i.is_direct_cost);

  let mappedItems = nonDirectCostItems.map((item) => ({
    id: item.id,
    supplier: item.supplier_name,
    poId: item.purchase_order_id,
    itemNumber: item.item_number,
    brand: item.brand_names.split(",").join(", "),
    itemType: item.item_type_description,
    qty: item.qty,
    carrier: item.carrier,
    method: item.method,
    actCost: formatMoney(stringToCents(item.actual_cost), true),
    totalCost: formatMoney(item.qty * stringToCents(item.actual_cost), true),
    shippingCost:
      stringToCents(item.freight_cost) > 0
        ? formatMoney(stringToCents(item.freight_cost))
        : "---",
    tax:
      item.tax && stringToCents(item.tax) > 0
        ? formatMoney(stringToCents(item.tax), true)
        : "---",
    orderType: orderTypeMap[item.order_type],
    program: item.program_name,
    channel: upCase(item.channel, "_"),
    setUpFee: "---",
    poRequestedDate: formatDateString(item.in_market_date),
  }));

  if (directCostItems.length > 0) {
    directCostItems.forEach((item) => {
      if (mappedItems.find((i) => i.poId === item.purchase_order_id)) {
        mappedItems = mappedItems.map((it) => {
          if (it.poId === item.purchase_order_id) {
            return {
              ...it,
              setUpFee: formatMoney(stringToCents(item.actual_cost), true),
            };
          } else return { ...it };
        });
      }
    });
  }

  return mappedItems;
};

export const mapFinanceBillingReport = (items) => {
  let totalQty = 0;
  let totalCost = 0;
  let mappedItems = items.map((item) => {
    totalQty += item.quantity;
    totalCost += stringToCents(item.total);

    return {
      id: item.id,
      glAccount: item.account,
      internalOrder: item.internal_order_code,
      itemNumber: item.sequence_number,
      itemType: item.description,
      state: item.ship_to_state,
      invoiceNumber:
        item.invoice_number !== "EMPTY" ? item.invoice_number : "---",
      qty: item.quantity,
      total: formatMoney(stringToCents(item.total), true),
    };
  });

  mappedItems = mappedItems.concat([
    {
      id: "blank-row",
      glAccount: "",
      internalOrder: "",
      itemNumber: "",
      itemType: "",
      state: "",
      invoiceNumber: "",
      qty: "",
      total: "",
    },
    {
      id: "total-row",
      glAccount: "Grand Total",
      internalOrder: "",
      itemNumber: "",
      itemType: "",
      state: "",
      invoiceNumber: "",
      qty: totalQty,
      total: formatMoney(totalCost, true),
    },
  ]);

  return mappedItems;
};

class FinanceJournalRow {
  constructor(
    id,
    account,
    cost,
    internalOrder,
    state,
    itemText,
    qty,
    supplier,
    invoiceNumber
  ) {
    this.id = id;
    this.glAccount = account;
    this.total = formatMoney(stringToCents(cost), true);
    this.blankA = "";
    this.blankB = "";
    this.blankC = "";
    this.blankD = "";
    this.internalOrder = internalOrder;
    this.blankE = "";
    this.state = state;
    this.itemText = itemText;
    this.blankF = "";
    this.qty = qty;
    this.baseUnit = "EA";
    this.supplier = supplier;
    this.invoiceNumber = invoiceNumber;
  }
}

export const mapFinanceJournalReport = (items) => {
  let mappedItems = [];

  items.forEach((item) => {
    mappedItems.push(
      new FinanceJournalRow(
        item.id,
        item.account,
        item.cost,
        item.internal_order_code,
        item.ship_to_state,
        `Seq#${item.sequence_number} ${item.description}`,
        item.qty,
        item.supplier,
        item.invoice_number !== "EMPTY" ? item.invoice_number : "Not Provided"
      )
    );
    mappedItems.push(
      new FinanceJournalRow(
        item.id,
        item.freight_code,
        item.shipping_cost,
        item.internal_order_code,
        item.ship_to_state,
        `Seq#${item.sequence_number} ${item.description}`,
        item.qty,
        item.supplier,
        item.invoice_number !== "EMPTY" ? item.invoice_number : "Not Provided"
      )
    );
  });

  return mappedItems;
};

export const mapOrderedItemsReport = (items) => {
  const mappedItems = items.map((item) => ({
    id: item.id,
    itemNumber: item.item_number,
    brand: item.brand_names.split(",").join(", "),
    itemType: item.item_type_description,
    itemDesc: item.comment ? item.comment : "---",
    orderType: item.order_type,
    estCost: formatMoney(stringToCents(item.estimated_cost), true),
    totalQty: item.total_qty ? item.total_qty : 0,
    totalEstCost: item.total_qty
      ? formatMoney(item.total_qty * stringToCents(item.estimated_cost), true)
      : "$0.0000",
    canceledQty: item.total_canceled_qty ? item.total_canceled_qty : 0,
  }));

  return mappedItems;
};

export const mapInvoicingReport = (items) => {
  const mappedItems = items.map((item) => ({
    id: item.id,
    poId: item.po_id,
    itemNumber: item.item_number,
    supplierName: item.supplier_name,
    brand: item.brand_names.split(",").join(", "),
    qty: item.qty,
    actualCost: formatMoney(stringToCents(item.actual_cost), true),
    submittedDate: format(new Date(item.submitted_at_date), "MM/dd/yyy"),
    shipDate: item.actual_ship_date
      ? formatDateString(item.actual_ship_date)
      : "---",
    allocation: item.is_direct_ship ? "Direct Ship" : "CDC",
    orderType: orderTypeMap[item.po_type],
  }));

  return mappedItems;
};

export const mapInvoiceDoubleCheckReport = (items) => {
  const totalCount = [...new Set(items.map((i) => i.id))].length;
  const totals = items.reduce(
    (acc, i) => {
      acc.qty = acc.qty + i.qty;
      acc.extendedProductCost =
        acc.extendedProductCost + stringToCents(i.extended_product_cost);
      acc.totalFreight = acc.totalFreight + stringToCents(i.freight_total);
      acc.totalTax = acc.totalTax + stringToCents(i.taxes_total);
      acc.total = acc.total + stringToCents(i.total);
      return acc;
    },
    {
      qty: 0,
      extendedProductCost: 0,
      totalFreight: 0,
      totalTax: 0,
      total: 0,
    }
  );
  const mappedItems = items.map((item) => ({
    id: item.id,
    poId: item.id,
    supplier: item.supplier_name,
    itemNumber: item.sequence_number,
    brand: item.brand_name.split(",").join(", "),
    itemType: item.item_type.split(",").join(", "),
    actualCost: formatMoney(stringToCents(item.actual_unit_cost), true),
    qty: item.qty,
    extendedProductCost: formatMoney(
      stringToCents(item.extended_product_cost),
      true
    ),
    totalFreight: item.freight_total
      ? formatMoney(stringToCents(item.freight_total), true)
      : "---",
    totalTax: item.taxes_total
      ? formatMoney(stringToCents(item.taxes_total), true)
      : "---",
    totalCost: formatMoney(stringToCents(item.total), true),
  }));

  const totalRows = [
    {
      id: "total-row",
      poId: "Totals:",
      supplier: "",
      itemNumber: `PO Count: ${totalCount}`,
      brand: "",
      itemType: "",
      actualCost: "",
      qty: totals.qty,
      extendedProductCost: formatMoney(totals.extendedProductCost, true),
      totalFreight: formatMoney(totals.totalFreight, true),
      totalTax: formatMoney(totals.totalTax, true),
      totalCost: formatMoney(totals.total, true),
    },
    {
      id: "empty-row",
      poId: "",
      supplier: "",
      itemNumber: "",
      brand: "",
      itemType: "",
      actualCost: "",
      qty: "",
      extendedProductCost: "",
      totalFreight: "",
      totalTax: "",
      totalCost: "",
    },
  ];

  return totalRows.concat(mappedItems);
};

export const mapInvoiceDetailReport = (items) => {
  const rawTotalRow = items.shift();
  const totalRows = [
    {
      id: "total-row",
      invoiceNumber: "Totals:",
      internalOrder: "",
      itemNumber: "",
      actualCost: "",
      qty: rawTotalRow.qty,
      totalCost: formatMoney(stringToCents(rawTotalRow.cost), true),
      stateAssignment: `Total Freight: ${formatMoney(
        stringToCents(rawTotalRow.shipping_cost),
        true
      )}`,
    },
    {
      id: "empty-row",
      invoiceNumber: "",
      internalOrder: "",
      itemNumber: "",
      actualCost: "",
      qty: "",
      totalCost: "",
      stateAssignment: "",
    },
  ];

  const mappedItems = items.map((item, i) => ({
    id: i,
    invoiceNumber: item.invoice_number,
    internalOrder: item.internal_order_code,
    itemNumber: item.sequence_number,
    actualCost: formatMoney(stringToCents(item.unit_cost), true),
    qty: item.qty,
    totalCost: formatMoney(stringToCents(item.cost), true),
    stateAssignment: item.ship_to_state,
  }));

  return totalRows.concat(mappedItems);
};

class PrintInvoiceRow {
  constructor(
    title,
    supplier,
    invoiceNumber,
    postingKey,
    glAccount,
    cost,
    internalOrder,
    state,
    itemNumber,
    qty,
    profitCenter
  ) {
    this.postingDate = title;
    this.documentDate = format(new Date(), "MM/dd/yyyy");
    this.companyCode = "1010";
    this.documentType = "QN";
    this.currency = "USD";
    this.headerText = supplier;
    this.reference = invoiceNumber;
    this.ledgerGroup = "";
    this.reversalDate = "";
    this.reversalReason = "";
    this.exchangeRates = "";
    this.translationDate = "";
    this.postingKey = postingKey;
    this.glAccount = glAccount;
    this.cost = cost;
    this.localCurrencyAmt = "";
    this.globalCurrencyAmt = "";
    this.costCenter = "";
    this.profitCenter = profitCenter;
    this.internalOrder = internalOrder;
    this.tradingPartner = "";
    this.state = state;
    this.itemNumber = itemNumber;
    this.coCode = "";
    this.qty = qty;
    this.unitOfMeasuer = "";
    this.taxOnSales = "";
    this.taxJurisdiction = "";
    this.billToParty = "";
    this.shipToParty = "";
    this.materialNumber = "";
    this.plant = "";
    this.profitCtr = "";
    this.channel = "";
    this.longText = "";
    this.brand = "";
    this.territoryName = "";
    this.isGallo = "";
    this.divisionVIP = "";
    this.referenceKey = "";
    this.lineMaterial = "";
  }
}

export const mapPrintInvoiceReport = (items) => {
  let mappedItems = [];
  let totalRow = items.shift();
  let freightRow = items.shift();
  let taxRow = items.shift();
  let setUpFeeRow = items.shift();
  let plugRow = items.shift();

  const displayCost = items.reduce(
    (acc, i) =>
      acc +
      stringToCents(
        formatMoney(stringToCents(i.cost), false)
          .split("$")
          .join("")
          .split(",")
          .join("")
      ),
    0
  );

  const displayFreight = items.reduce(
    (acc, i) =>
      acc +
      stringToCents(
        formatMoney(stringToCents(i.shipping_cost), false)
          .split("$")
          .join("")
          .split(",")
          .join("")
      ),
    0
  );

  const totalDisplayCost = displayFreight + displayCost;

  const plugCost = formatMoney(
    stringToCents(totalRow.cost) - totalDisplayCost,
    false
  );

  mappedItems.push(
    new PrintInvoiceRow(
      "Total:",
      items[0]?.supplier,
      items[0]?.invoice_number,
      totalRow.posting_key,
      "Total",
      formatMoney(stringToCents(totalRow.cost), false),
      "",
      "",
      "",
      totalRow.qty,
      "400000"
    )
  );
  mappedItems.push(
    new PrintInvoiceRow(
      "Freight Total:",
      items[0]?.supplier,
      items[0]?.invoice_number,
      freightRow.posting_key,
      freightRow.account,
      formatMoney(stringToCents(freightRow.cost), false),
      "",
      "",
      "Freight",
      "",
      ""
    )
  );
  mappedItems.push(
    new PrintInvoiceRow(
      "Tax Total:",
      items[0]?.supplier,
      items[0]?.invoice_number,
      taxRow.posting_key,
      taxRow.account,
      formatMoney(stringToCents(taxRow.cost), false),
      taxRow.internal_order_code,
      taxRow.ship_to_state,
      taxRow.description,
      "",
      ""
    )
  );
  mappedItems.push(
    new PrintInvoiceRow(
      "Set Up Fees:",
      items[0]?.supplier,
      items[0]?.invoice_number,
      "40",
      setUpFeeRow.account,
      formatMoney(stringToCents(setUpFeeRow.cost), false),
      setUpFeeRow.internal_order_code,
      setUpFeeRow.ship_to_state,
      setUpFeeRow.description,
      "",
      ""
    )
  );
  mappedItems.push(
    new PrintInvoiceRow(
      "Plug:",
      items[0]?.supplier,
      items[0]?.invoice_number,
      "40",
      plugRow.account,
      plugCost,
      plugRow.internal_order_code,
      plugRow.ship_to_state,
      plugRow.description,
      "",
      ""
    )
  );

  items.forEach((item) => {
    if (!item.is_additional_freight) {
      mappedItems.push(
        new PrintInvoiceRow(
          "",
          item.supplier,
          item.invoice_number,
          "40",
          item.account,
          formatMoney(stringToCents(item.cost), false),
          item.internal_order_code,
          item.ship_to_state,
          item.sequence_number,
          item.qty,
          ""
        )
      );
    }
    mappedItems.push(
      new PrintInvoiceRow(
        "Freight per unit x QTY on Shipping Param",
        item.supplier,
        item.is_additional_freight
          ? item.additional_freight_invoice_number
          : item.invoice_number,
        "40",
        item.freight_code,
        formatMoney(stringToCents(item.shipping_cost, false)),
        item.internal_order_code,
        item.ship_to_state,
        item.sequence_number,
        item.qty,
        ""
      )
    );
  });
  return mappedItems;
};

export const mapOrderWindowItems = (report) => {
  const mappedData = report
    .map((row) => ({
      id: row.id,
      itemNumber: row.sequence_number,
      program: row.program,
      brand: row.brands,
      itemType: row.item_type,
      itemDescription: row.comment,
      estCost: formatMoneyString(row.estimated_cost || 0),
      supplier: row.supplier,
      orderWindow: row.order_window,
    }))
    .sort((a, b) =>
      a.program > b.program ? 1 : a.program < b.program ? -1 : 0
    );

  return mappedData;
};

export const mapOrderDetailReport = (report) => {
  const data = report.map((r) => ({
    itemNumber: r.sequence_number,
    orderWindowName: r.order_window_name ?? "---",
    inMarketMonth: r.month_in_market ?? "---",
    quarter: r.quarter ?? "---",
    orderType: orderTypeMap[r.order_type],
    orderedBy: r.ordered_by,
    channel: r.channel ? upCase(r.channel, "_") : "---",
    territoryNames: r.territory_names ?? "---",
    brands:
      r.brand && r.brand.length > 0 ? r.brand.split(",").join(", ") : "---",
    businessUnits:
      r.business_unit && r.business_unit.length > 0
        ? r.business_unit.split(",").join(", ")
        : "---",
    itemType: r.item_type,
    itemDescription: r.item_description ?? "---",
    abn: r.abn ?? "---",
    addressName: r.address_name?.replace(/,/g, ""),
    city: r.city,
    state: r.ship_to_state,
    zip: r.zip,
    qty: r.qty,
    estimatedCost: formatMoney(stringToCents(r.estimated_cost), false),
    estimatedTax: formatMoney(stringToCents(r.estimated_tax), false),
    estimatedFreight: formatMoney(stringToCents(r.estimated_freight), false),
    estimatedExtendedCost: formatMoney(
      stringToCents(r.estimated_extended_cost),
      false
    ),
    actualCost: r.actual_cost
      ? formatMoney(stringToCents(r.actual_cost), false)
      : "---",
    actualTax: r.actual_tax
      ? formatMoney(stringToCents(r.actual_tax), false)
      : "---",
    actualFreight: r.actual_shipping_cost
      ? formatMoney(stringToCents(r.actual_shipping_cost), false)
      : "---",
    actualExtenededCost: r.actual_extended_cost
      ? formatMoney(stringToCents(r.actual_extended_cost), false)
      : "---",
    orderDate: format(new Date(r.order_date), "MM/dd/yyyy"),
    inMarketDate: r.in_market_date ? formatDateString(r.in_market_date) : "---",
    estShipDate: r.estimated_ship_date
      ? formatDateString(r.estimated_ship_date)
      : "---",
    shipDate: r.ship_date ? formatDateString(r.ship_date) : "---",
    status:
      r.status === "ok" && r.order_status
        ? upCase(r.order_status, "-")
        : upCase(r.status, "-"),
    complianceStatus: r.compliance_status ? upCase(r.compliance_status) : "---",
    suppler: r.supplier,
    orderNumber: r.order_number,
    internalOrder: r.internal_order_code ?? "---",
    invoiceNumber: r.invoice_number ?? "---",
    totalBeaconCost: formatMoneyString(r.total_beacon_cost),
    posType: r.pos_type,
    program: r.program,
    anaplanSubProgramCodes:
      _(r.anaplan_sub_programs)
        .map((str) => str.split(" ").at(-1))
        .join(", ") || "",
    poNumber: r.po_number,
    programType: r.program_type,
  }));

  return data;
};

export const mapOpenPurchaseOrderReport = (report) => {
  const data = report.map((r) => ({
    itemNumber: r.seq_number,
    brand: r.brand.split(",").join(", "),
    itemType: r.item_type,
    inMarketDate: formatDateString(r.in_market_date),
    status: r.status === "Shipping Hold" ? r.status : upCase(r.status, "-"),
    poId: r.po_number,
    supplier: r.supplier,
    qty: r.qty,
    actCost: formatMoney(stringToCents(r.actual_cost), true),
    submittedAt: format(new Date(r.submitted_at), "MM/dd/yyyy"),
  }));

  return data;
};

export const mapVariantAllocationReport = (report) => {
  const data = report.map((r) => ({
    itemNumber: r.variant_sku,
    brands: r.brands,
    description: r.description,
    itemType: r.item_type,
    territory: r.territory_name,
    allocatedQty: r.allocated,
    orderedQty: r.ordered,
    pendingQty: r.pending,
    available: r.balance,
    expectedDate: formatDateString(r.expected_date),
  }));

  return data;
};

export const mapPurchaseOrderReport = (report) => {
  const data = report.map((r) => ({
    id: r.id,
    poId: r.po_id,
    purchaser: r.purchaser,
    supplier: r.supplier,
    itemNumber: r.sequence_number,
    brands: r.brands,
    itemCode: r.item_code,
    itemType: r.description,
    qty: r.qty,
    actualCost: formatMoney(stringToCents(r.actual_unit_cost), true),
    extendedCost: formatMoney(stringToCents(r.extended_cost), true),
    submittedAt: r.submitted_date
      ? format(new Date(r.submitted_date), "MM/dd/yyyy")
      : "---",
    inMarketDate: formatDateString(r.in_market_date),
    allocation: r.is_direct_ship ? "Allocated" : "CDC",
    status:
      r.status === "Shipping Hold" ? "Shipping Hold" : upCase(r.status, "-"),
    invoiceNumber: r.invoice_number ?? "---",
    freightCost: r.freight_cost
      ? formatMoney(stringToCents(r.freight_cost), true)
      : "---",
    additionalFreightInvoiceNumber:
      r.additional_freight_invoice_number ?? "---",
    additionalFreightCosts: r.additional_freight_costs
      ? formatMoney(stringToCents(r.additional_freight_costs), true)
      : "---",
    brokerFreightInvoiceNumber: r.broker_freight_invoice_number ?? "---",
    brokerFreightCosts: r.broker_freight_costs
      ? formatMoneyString(r.broker_freight_costs)
      : "---",
    includeBeacon: r.include_beacon ? "Y" : "N",
  }));

  return data;
};
