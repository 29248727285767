import { useSelector } from "react-redux";

import { useOrderSetQuery } from "@features/orders/queries";

export const useDraftOrderSetQuery = (
  orderType: "from-inventory" | "on-demand"
) => {
  const currentOrderId = useSelector(
    (state: any) => state.app.draftOrderIds[orderType]
  );

  return useOrderSetQuery(currentOrderId);
};

export default useDraftOrderSetQuery;
