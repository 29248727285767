export const buildOrderSet = (type, territoryId, channel, programId) => {
  let formattedType =
    type === "inventory"
      ? "from-inventory"
      : type === "onDemand"
      ? "on-demand"
      : "pre-order";

  let requestData = {
    data: {
      type: "order-set",
      attributes: {
        type: formattedType,
        channel: channel,
        "all-regions": !territoryId,
      },
    },
  };
  let relationships = {};
  if (programId) {
    relationships.program = {
      data: programId
        ? {
            type: "program",
            id: programId,
          }
        : null,
    };
  }
  if (territoryId) {
    relationships.territory = {
      data: territoryId
        ? {
            type: "territory",
            id: territoryId,
          }
        : null,
    };
  }
  if (Object.keys(relationships).length > 0) {
    requestData.data.relationships = relationships;
  }
  return requestData;
};

export const buildOrderSetVariantPost = (id, variant) => {
  return {
    data: {
      type: "order-set-variant",
      relationships: {
        "order-set": {
          data: id
            ? {
                type: "order-set",
                id: id,
              }
            : null,
        },
        variant: {
          data: variant
            ? {
                type: "variant",
                id: variant,
              }
            : null,
        },
      },
    },
  };
};

export const buildOrder = (id, addressId, type) => {
  return {
    data: {
      type: "order",
      attributes: {
        type: type,
      },
      relationships: {
        address: {
          data: addressId
            ? {
                type: "address",
                id: addressId,
              }
            : null,
        },
        "order-set": {
          data: id
            ? {
                type: "order-set",
                id: id,
              }
            : null,
        },
      },
    },
  };
};

export const buildOrderVariantPatch = (id, qty) => {
  return {
    data: {
      type: "order-variant",
      id: id,
      attributes: {
        qty: qty,
      },
    },
  };
};
