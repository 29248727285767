/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Card } from "@mui/material";

import { useMutateError } from "@features/errors";
import { ItemCatalogView } from "@features/items";
import {
  useCreateOrderSetVariantsMutation,
  useDraftPreOrderOrderSetQuery,
} from "@features/orders";
import AddToCartButton from "@features/orders/ordering/AddToCartButton";
import VariantSelectionModal from "@features/orders/ordering/VariantSelectionModal";
import { Item } from "@models/Item";
import { Program } from "@models/Program";

import { useItemProgramListQuery } from "../data";

const PreOrderItemPrograms = ({
  programId,
  orderWindowId,
  program,
}: {
  programId: string;
  orderWindowId: string;
  program?: Program;
}) => {
  const setError = useMutateError();
  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );
  const { data: orderSet } = useDraftPreOrderOrderSetQuery(
    programId,
    orderWindowId
  );

  const createOrderSetVariantsMutation = useCreateOrderSetVariantsMutation();

  const addVariantsToOrderSet = async (variantIds: string[]) => {
    await createOrderSetVariantsMutation
      .mutateAsync({
        orderSetId: orderSet?.id,
        variantIds,
        orderType: "pre-order",
      })
      .catch((err) => setError(err, "Add Order Set Variants"));
  };
  const addItemToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants!.find(
      (v) => v.selectedVariantOptions.length === 0
    );

    if (defaultVariant?.isActive) {
      await addVariantsToOrderSet([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  const { data = [], ...tableProps } = useItemProgramListQuery({
    filter: {
      programIds: [programId],
      orderCalendarMonthIds: [orderWindowId],
      isActive: true,
      rtaDeployment: true,
    },
  });

  const items: Item[] = data.map((itemProgram) => ({
    ...itemProgram.item,
    mostRecentEstimatedCost: itemProgram.estimatedCost,
    mostRecentMoq: itemProgram.moq,
  }));

  if (!program) return null;

  return (
    <>
      {orderSet && variantSelectionItem && (
        <VariantSelectionModal
          items={[variantSelectionItem]}
          orderType={"pre-order"}
          orderSetVariants={orderSet.orderSetVariants}
          createOrUpdateOrderSet={addVariantsToOrderSet}
          onClose={() => setVariantSelectionItem(null)}
        />
      )}
      <div tw="flex-1 flex flex-col h-full overflow-hidden">
        <Card>
          <ItemCatalogView
            {...tableProps}
            rows={items}
            hideBrands={program?.brandAssignmentLevel !== "item"}
            orderView="pre-order"
            {...(orderSet && {
              rowActions: (item) => (
                <AddToCartButton
                  item={item}
                  orderSetVariants={orderSet.orderSetVariants}
                  addToOrderSet={addItemToOrderSet}
                  orderType={"pre-order"}
                />
              ),
            })}
          />
        </Card>
      </div>
    </>
  );
};

export default PreOrderItemPrograms;
