/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { LinearProgress } from "@mui/material";

import { setFiltersOpen } from "src/redux/slices/filterSlice.js";

import ChangeLog from "../components/PlanningTool/Changelog/ChangeLog.js";
import PlanningToolNav from "../components/PlanningTool/PlanningToolNav";
import {
  clearChanges,
  fetchFilteredChangelog,
  fetchNextChanges,
} from "../redux/slices/planningTool/changelogSlice";

const PlanningToolChangelog = () => {
  const { pid } = useParams();
  const dispatch = useDispatch();

  const { changes, isLoading, nextLink, isNextLoading } = useSelector(
    (state) => state.changelog
  );

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pid !== undefined) {
      dispatch(fetchFilteredChangelog({ programId: pid }));
      return () => {
        dispatch(clearChanges());
      };
    }
  }, [pid, dispatch]);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextChanges(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  return (
    <div
      tw="h-[calc(100vh - var(--header-height))]"
      css={{ "& p": tw`text-slate-700` }}
    >
      <Helmet>
        <title>Planning Tool | Program Changelog</title>
      </Helmet>
      <PlanningToolNav
        title="Program Changelog"
        programId={pid}
        view="changelog"
      />
      <div ref={scrollRef} tw="h-full overflow-y-auto pt-4 pb-16 flex-1">
        <ChangeLog changes={changes} />
      </div>
      <div tw="h-2">{(isLoading || isNextLoading) && <LinearProgress />}</div>
    </div>
  );
};

PlanningToolChangelog.propTypes = {};

export default PlanningToolChangelog;
