/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { IconButton, Tooltip } from "@mui/material";

import { LS_ITEM_VIEW } from "src/constants/localStorage";
import { setProductView } from "src/redux/slices/appSlice";

import { FaIcon } from "@features/ui";

const ItemViewButtons = () => {
  const dispatch = useDispatch();
  const { productView } = useSelector((state: any) => state.app);

  const handleChange = (view: "table" | "grid") => {
    dispatch(setProductView(view));
    localStorage.setItem(LS_ITEM_VIEW, view);
  };
  return (
    <div tw="flex items-center">
      <Tooltip title="View List">
        <IconButton onClick={() => handleChange("table")}>
          <FaIcon
            icon="list"
            tw="text-lg"
            css={
              productView === "table"
                ? tw`text-primary-500`
                : tw`text-neutral-500`
            }
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Grid">
        <IconButton onClick={() => handleChange("grid")}>
          <FaIcon
            icon="table-cells-large"
            tw="text-lg"
            css={
              productView === "grid"
                ? tw`text-primary-500`
                : tw`text-neutral-500`
            }
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ItemViewButtons;
