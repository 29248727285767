import _ from "lodash";

import { useProgramQuery } from "@features/programs";
import { ResourceAutocomplete } from "@utils/forms";

const AutocompleteProgram = ({ programIdState }) => {
  const { data: selectedProgram } = useProgramQuery(programIdState[0]);
  const [programId, setProgramId] = programIdState;

  return (
    <ResourceAutocomplete
      size="medium"
      label="Program"
      value={programId}
      tapOptions={(options) =>
        _.uniqBy(
          [selectedProgram, ...options].filter((p) => p),
          "id"
        )
      }
      resource="programs"
      onChange={(_e, v) => setProgramId(v?.id || null)}
      getOptionText={(p) =>
        `${p.name} | ${p.brands?.map((b) => b.name).join(", ")}`
      }
      minimumCharacters={5}
    />
  );
};

export default AutocompleteProgram;
