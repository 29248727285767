import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { kebabCaseKeys } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { setIsUpdateLoading } from "../suppliers/supplierSlice";
import {
  mapCost,
  mapDetailedQuote,
  mapPricingTierQuote,
  mapRFQItems,
  mapSingleRFQ,
} from "./maps";

const unsetCurrentRFQ = {
  id: null,
  itemId: null,
  itemName: null,
  itemNumber: null,
  itemType: null,
  programId: null,
  programName: null,
  round: null,
  status: null,
  userName: null,

  brands: [],
  quotes: [],
  pricingTiers: [],
};

const unsetCurrentQuote = {
  id: null,
  pricingTierQuotes: [],
};

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isSingleLoading: false,
  isUpdateLoading: false,
  nextPage: null,
  nextLink: null,
  refreshLink: null,
  rfqItems: [],
  quoteItems: [],
  error: null,
  RFQModalOpen: false,
  selectedId: null,
  currentRFQ: { ...unsetCurrentRFQ },
  currentQuote: { ...unsetCurrentQuote },
};

const rfqSlice = createSlice({
  name: "rfq",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setSingleLoading(state) {
      state.isSingleLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getRFQsSuccess(state, action) {
      const { rfqItems, nextLink, refreshLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.refreshLink = refreshLink;
      state.rfqItems = [...rfqItems];
      state.isLoading = false;
      state.error = null;
    },
    getQuotesSuccess(state, action) {
      const { quoteItems, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.quoteItems = [...quoteItems];
      state.isLoading = false;
      state.error = null;
    },
    getNextRFQsSuccess(state, action) {
      const { rfqItems, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rfqItems = state.rfqItems.concat(rfqItems);
      state.isNextLoading = false;
      state.error = null;
    },
    getSingleRFQSuccess(state, action) {
      const { rfq } = action.payload;
      state.currentRFQ = { ...rfq };

      state.isSingleLoading = false;
      state.isUpdateLoading = false;
      state.error = null;

      // If this rfq exists in state.rfqItems, replace it
      const inArrayIndex = state.rfqItems.findIndex((r) => r.id === rfq.id);
      if (inArrayIndex !== -1) {
        state.rfqItems = state.rfqItems.map((item) => {
          return item.id === rfq.id ? { ...rfq } : item;
        });
      }
    },
    clearSingleQuote(state) {
      state.currentQuote = { ...unsetCurrentQuote };
    },
    fetchSingleQuoteSuccess(state, action) {
      const { quote } = action.payload;
      state.currentQuote = { ...quote };
      state.isLoading = false;
    },
    createTierSuccess(state, action) {
      const { newTier } = action.payload;
      state.currentRFQ.pricingTiers = [
        ...state.currentRFQ.pricingTiers,
        newTier,
      ];
      state.isUpdateLoading = false;
    },
    deleteTierSuccess(state, action) {
      const { idRemoved } = action.payload;
      state.currentRFQ.pricingTiers = state.currentRFQ.pricingTiers.filter(
        (t) => t.id !== idRemoved
      );
      state.isUpdateLoading = false;
    },
    updateTierQuoteSuccess(state, action) {
      const updatedQuote = action.payload;
      state.currentQuote.pricingTierQuotes =
        state.currentQuote.pricingTierQuotes.map((qt) =>
          qt.id === updatedQuote.id ? updatedQuote : qt
        );
    },
    openRFQModal(state, { payload }) {
      const { loadRFQ, init } = payload || {};
      state.RFQModalOpen = true;
      state.selectedId = loadRFQ || null;
      state.RFQModalInit = init || {};
    },
    closeRFQModal(state) {
      state.RFQModalOpen = false;
      state.selectedId = null;
      state.currentRFQ = { ...unsetCurrentRFQ };
      state.RFQModalInit = null;
    },
    clearNextLink(state) {
      state.nextLink = null;
    },
    setFailure: (state, action) => {
      const { error } = action.payload;
      state.isLoading = false;
      state.error = error;
    },
    createCostEntrySuccess(state, action) {
      state.currentQuote.costs = [...state.currentQuote.costs, action.payload];
    },
    updateCostEntrySuccess(state, action) {
      const newCost = action.payload;
      state.currentQuote.costs = state.currentQuote.costs.map((cost) =>
        cost.id === newCost.id ? newCost : cost
      );
    },
    deleteCostEntrySuccess(state, action) {
      const deletedId = action.payload;
      state.currentQuote.costs = state.currentQuote.costs.filter(
        (cost) => cost.id !== deletedId
      );
    },
    deleteCloudinaryFileSuccess(state) {
      state.isUpdateLoading = false;
    },
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  setSingleLoading,
  setUpdateLoading,
  getRFQsSuccess,
  getQuotesSuccess,
  getNextRFQsSuccess,
  getSingleRFQSuccess,
  createTierSuccess,
  deleteTierSuccess,
  updateTierQuoteSuccess,
  openRFQModal,
  closeRFQModal,
  clearNextLink,
  setFailure,
  fetchSingleQuoteSuccess,
  clearSingleQuote,
  createCostEntrySuccess,
  updateCostEntrySuccess,
  deleteCostEntrySuccess,
  deleteCloudinaryFileSuccess,
} = rfqSlice.actions;

export default rfqSlice.reducer;

/* ---------- Rfq fetches ---------- */
export const fetchRFQs = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());

    const queryString = `/api/request-for-quotes?${query({
      filter: filterObject,
    })}`;

    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapRFQItems(response.data.data);
    dispatch(
      getRFQsSuccess({
        rfqItems: mappedData,
        nextLink: response.data.nextLink,
        refreshLink: queryString,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Fetch RFQ" }));
    dispatch(stopGlobalLoad());
  }
};
export const refreshRFQs = () => async (dispatch, getState) => {
  // console.log({ getState: getState() });
  try {
    const url = getState().rfq.refreshLink;
    if (!url) return;

    dispatch(setIsLoading());

    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;

    const mappedData = mapRFQItems(response.data.data);
    dispatch(
      getRFQsSuccess({
        rfqItems: mappedData,
        nextLink: response.data.nextLink,
        refreshLink: url,
      })
    );
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Refresh RFQ" }));
  }
};

export const fetchNextRFQs = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(clearNextLink());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapRFQItems(response.data.data);
    dispatch(
      getNextRFQsSuccess({
        rfqItems: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Fetch next RFQ" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchSingleRFQ = (id) => async (dispatch) => {
  try {
    dispatch(setSingleLoading());
    const rqfRes = await axiosGet(`/api/request-for-quotes/${id}`);
    if (rqfRes.error) throw rqfRes.error;
    const cloudinaryFileRes = await axiosGet(
      `/api/cloudinary-files?filter[request_for_quote_id]=${id}`
    );
    if (cloudinaryFileRes.error) throw cloudinaryFileRes.error;
    const mappedRFQ = mapSingleRFQ(rqfRes.data, cloudinaryFileRes.data);
    dispatch(getSingleRFQSuccess({ rfq: mappedRFQ }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Fetch single RFQ" }));
  }
};

export const createRFQ =
  (program_id, item_id, callback) => async (dispatch) => {
    try {
      dispatch(setSingleLoading());
      const body = {
        data: {
          attributes: {
            program_id,
            item_id,
          },
        },
      };

      const res = await axiosPost(`/api/request-for-quotes/`, body);
      const mappedRFQ = mapSingleRFQ(res.data);
      dispatch(getSingleRFQSuccess({ rfq: mappedRFQ }));
      callback && callback();
    } catch (error) {
      dispatch(setFailure({ error: error.toString() }));
      dispatch(setError({ error: error.toString(), source: "Create rfq" }));
    }
  };

export const updateRFQ = (id, attributes) => async (dispatch) => {
  /*
  attributes: {
    note,
    program_id,
    item_id,
  }
  */
  const body = {
    data: {
      attributes,
    },
  };

  try {
    dispatch(setUpdateLoading());
    const rqfRes = await axiosPut(`/api/request-for-quotes/${id}`, body);
    if (rqfRes.error) throw rqfRes.error;
    const cloudinaryFileRes = await axiosGet(
      `/api/cloudinary-files?filter[request_for_quote_id]=${id}`
    );
    if (cloudinaryFileRes.error) throw cloudinaryFileRes.error;
    const mappedRFQ = mapSingleRFQ(rqfRes.data, cloudinaryFileRes.data);
    dispatch(getSingleRFQSuccess({ rfq: mappedRFQ }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Update rfq" }));
  }
};

export const sendRFQ = (rfqId, supplier_ids) => async (dispatch) => {
  const body = {
    data: {
      supplier_ids,
    },
  };
  try {
    dispatch(setUpdateLoading());
    const res = await axiosPost(`/api/request-for-quotes/${rfqId}/send`, body);
    if (res.error) throw res.error;
    const mappedRFQ = mapSingleRFQ(res.data);
    dispatch(getSingleRFQSuccess({ rfq: mappedRFQ }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Send RFQ" }));
  }
};

export const cancelRFQ = (id) => async (dispatch) => {
  try {
    dispatch(setUpdateLoading());
    const res = await axiosPost(`/api/request-for-quotes/${id}/cancel`, {});
    if (res.error) throw res.error;
    const mappedRFQ = mapSingleRFQ(res.data);
    dispatch(getSingleRFQSuccess({ rfq: mappedRFQ }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "Send RFQ" }));
  }
};

export const createPricingTier = (rfqId, qty) => async (dispatch) => {
  try {
    const body = {
      data: {
        type: "pricing-tiers",
        attributes: {
          request_for_quote_id: rfqId,
          qty,
        },
      },
    };
    dispatch(setUpdateLoading());
    const res = await axiosPost("/api/pricing-tiers", body);
    if (res.error) throw res.error;

    const newTier = { id: res.data.id, qty: res.data.qty };
    dispatch(createTierSuccess({ newTier }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Create pricing tier" })
    );
  }
};
export const deletePricingTier = (tierId) => async (dispatch) => {
  try {
    dispatch(setUpdateLoading());
    const res = await axiosDelete(`/api/pricing-tiers/${tierId}`, {});
    if (res.error) throw res.error;
    dispatch(deleteTierSuccess({ idRemoved: tierId }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Delete pricing tier" })
    );
  }
};

export const fetchSingleQuote = (quoteId) => async (dispatch) => {
  try {
    dispatch(startGlobalLoad());
    const res = await axiosGet(`/api/quotes/${quoteId}`);
    if (res.error) throw res.error;
    let mappedData = {};
    if (res.data["request-for-quote"]?.id) {
      const cloudinaryFileRes = await axiosGet(
        `/api/cloudinary-files?filter[request_for_quote_id]=${res.data["request-for-quote"]?.id}`
      );
      if (cloudinaryFileRes.error) throw cloudinaryFileRes.error;
      mappedData = mapDetailedQuote(res.data, cloudinaryFileRes.data);
    } else {
      mappedData = mapDetailedQuote(res.data);
    }

    dispatch(fetchSingleQuoteSuccess({ quote: mappedData }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Fetch Single Quote" })
    );
  }
  dispatch(stopGlobalLoad());
};
// quotes then need to be accepted by the supplier before they can be modified.
export const sendQuoteAction = (action, quoteId) => async (dispatch) => {
  try {
    if (!["accept", "decline", "complete"].includes(action))
      throw new Error("Invalid action passed to sendQuoteAction");

    dispatch(setUpdateLoading());
    const res = await axiosPost(`/api/quotes/${quoteId}/${action}`, {});
    if (res.error) throw res.error;
    const cloudinaryFileRes = await axiosGet(
      `/api/cloudinary-files?filter[request_for_quote_id]=${res.data.id}`
    );
    if (cloudinaryFileRes.error) throw cloudinaryFileRes.error;
    const mappedData = mapDetailedQuote(res.data, cloudinaryFileRes.data);
    dispatch(fetchSingleQuoteSuccess({ quote: mappedData }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Send quote action" })
    );
  }
};

export const updateQuote = (quoteId, data) => async (dispatch) => {
  const body = {
    data: {
      attributes: kebabCaseKeys(data),
    },
  };
  try {
    dispatch(setIsLoading());
    const res = await axiosPut(`/api/quotes/${quoteId}`, body);
    if (res.error) throw res.error;
    const cloudinaryFileRes = await axiosGet(
      `/api/cloudinary-files?filter[request_for_quote_id]=${res.data.id}`
    );
    if (cloudinaryFileRes.error) throw cloudinaryFileRes.error;
    const mappedData = mapDetailedQuote(res.data, cloudinaryFileRes.data);
    dispatch(fetchSingleQuoteSuccess({ quote: mappedData }));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(setError({ error: error.toString(), source: "update quote" }));
  }
};

export const updatePricingTierQuote = (id, data) => async (dispatch) => {
  const body = {
    data: {
      attributes: {
        cost: data.price,
        "lead-time-in-days": data.leadTimeInDays,
      },
    },
  };
  try {
    dispatch(setIsUpdateLoading());
    const res = await axiosPut(`/api/pricing-tier-quotes/${id}`, body);
    if (res.error) throw res.error;
    const mappedData = mapPricingTierQuote(res.data);
    dispatch(updateTierQuoteSuccess(mappedData));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Update pricing tier quote" })
    );
  }
};

export const createCostEntry =
  (quoteId, costType, data) => async (dispatch) => {
    try {
      if (!["per-unit", "set-up"].includes(costType))
        throw new Error("Invalid costType passed to createCostEntry");

      const body = {
        data: {
          attributes: {
            "quote-id": quoteId,
            "cost-type": costType,
            name: data.name,
            amount: data.amount,
          },
        },
      };
      const res = await axiosPost("/api/costs", body);
      if (res.error) throw res.error;
      const mappedData = mapCost(res.data);
      dispatch(createCostEntrySuccess(mappedData));
    } catch (error) {
      dispatch(setFailure({ error: error.toString() }));
      dispatch(
        setError({ error: error.toString(), source: "Create costs entry" })
      );
    }
  };
export const updateCostEntry = (quoteId, data) => async (dispatch) => {
  try {
    const body = {
      data: {
        attributes: {
          name: data.name,
          amount: data.amount,
        },
      },
    };
    const res = await axiosPut(`/api/costs/${quoteId}`, body);
    if (res.error) throw res.error;
    const mappedData = mapCost(res.data);
    dispatch(updateCostEntrySuccess(mappedData));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Create costs entry" })
    );
  }
};
export const deleteCostEntry = (quoteId) => async (dispatch) => {
  try {
    const res = await axiosDelete(`/api/costs/${quoteId}`, {});
    if (res.error) throw res.error;
    dispatch(deleteCostEntrySuccess(quoteId));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Delete costs entry" })
    );
  }
};

export const createCloudinaryFile =
  (rfqId, cloudinaryId, fileName) => async (dispatch) => {
    try {
      // if (fileName.split(".").pop() === "undefined") {
      //   fileName = fileName.split(".")[0];
      // }
      const body = {
        data: {
          type: "cloudinary-file",
          attributes: {
            cloudinary_id: cloudinaryId,
            file_name: fileName,
          },
          relationships: {
            request_for_quote: {
              data: { type: "request-for-quote", id: rfqId },
            },
          },
        },
      };
      const res = await axiosPost(`/api/cloudinary-files/`, body);
      if (res.error) throw res.error;
      dispatch(fetchSingleRFQ(rfqId));
    } catch (error) {
      dispatch(setFailure({ error: error.toString() }));
      dispatch(
        setError({ error: error.toString(), source: "Create cloudinary file" })
      );
    }
  };

export const deleteCloudinaryFile = (id, rfqId) => async (dispatch) => {
  try {
    const res = await axiosDelete(`/api/cloudinary-files/${id}`);
    if (res.error) throw res.error;
    dispatch(fetchSingleRFQ(rfqId));
  } catch (error) {
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Create cloudinary file" })
    );
  }
};
