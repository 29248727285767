import { useSelector } from "react-redux";

import { useItemTypesQuery } from "@features/itemTypes";

import LocalErrorBoundary from "../../../Utility/LocalErrorBoundary";
import CollapsibleComponent from "../../CollapsibleComponent";
import VariablePricingInput from "../VariablePricingInput";
import FormSupplier from "./FormSupplier";
import ImageUploadAccordion from "./ImageUploadAccordion";
import SpecAccordion from "./SpecAccordion";
import VariantAccordion from "./VariantAccordion";

const useSpecType = (itemTypeId) => {
  const { data: itemTypes = [] } = useItemTypesQuery();
  const itemType = itemTypes.find(({ id }) => id === itemTypeId);
  return itemType?.specType?.toLowerCase();
};

const useSupplierInfo = (supplierId) => {
  return useSelector((state) =>
    state.suppliers.supplierList.find(({ id }) => id === supplierId)
  );
};

const ItemSections = ({ item, type }) => {
  const ids = {
    itemId: item.itemId,
    itemProgramId: item.id,
  };

  const specType = useSpecType(item.itemTypeId);
  const supplier = useSupplierInfo(item.supplierId);

  const { large, thumbnail } = item.images.reduce(
    (acc, img) => {
      acc[img.type].push(img);
      return acc;
    },
    {
      large: [],
      thumbnail: [],
    }
  );

  return (
    <>
      {specType !== "spec" && <VariablePricingInput itemProgramId={item?.id} />}

      <CollapsibleComponent title="Variants">
        <LocalErrorBoundary>
          <VariantAccordion
            itemProgramId={item.id}
            itemId={item.itemId}
            programId={item.programId}
            itemProgramVariants={item.variants}
          />
        </LocalErrorBoundary>
      </CollapsibleComponent>

      {type !== "templated-coupon" && specType && (
        <CollapsibleComponent
          title="Item Specification"
          subtitle={
            specType === "spec"
              ? `Paper ${item.specification?.["Standard Spec Code"] ?? ""}`
              : "Structure"
          }
        >
          <LocalErrorBoundary>
            <SpecAccordion
              {...ids}
              specType={specType}
              specs={item.specification}
              itemTypeId={item.itemTypeId}
              couponSpecCode={item.standardSpecificationCode}
              isCoupon={Boolean(item.itemProgramType === "coupon")}
            />
          </LocalErrorBoundary>
        </CollapsibleComponent>
      )}

      <CollapsibleComponent
        title="Image upload"
        subtitle={`${large.length || "no"} image${
          large.length !== 1 ? "s" : ""
        }`}
      >
        <LocalErrorBoundary>
          <ImageUploadAccordion
            {...ids}
            editable={type !== "templated-coupon"}
            images={large}
            thumbnails={thumbnail}
          />
        </LocalErrorBoundary>
      </CollapsibleComponent>
      {type !== "templated-coupon" && (
        <CollapsibleComponent
          title="Vendor assign"
          subtitle={
            !item.allowSupplierUpdate
              ? `Awarded to ${supplier?.name}`
              : supplier
              ? supplier.name
              : "No supplier selected"
          }
        >
          <LocalErrorBoundary>
            <FormSupplier
              {...ids}
              supplier={supplier}
              locked={!item.allowSupplierUpdate}
            />
          </LocalErrorBoundary>
        </CollapsibleComponent>
      )}
    </>
  );
};

export default ItemSections;
