/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Menu, MenuItem } from "@mui/material";

import { saveAs } from "file-saver";
import cloudinary from "src/services/cloudinary";

import { Button, FaIcon } from "@features/ui";

type ImageDownloadMenuButtonProps = {
  index: number;
  cloudinaryIdsOrImageUrls: string[];
};

type fileTypeOptions = "jpg" | "pdf";

const ImageDownloadMenuButton: React.FC<ImageDownloadMenuButtonProps> = ({
  index,
  cloudinaryIdsOrImageUrls,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createCloudinaryDownloadLink = (format, index) => {
    // Check if id is already a URL
    if (cloudinaryIdsOrImageUrls[index].startsWith("http")) {
      return `${cloudinaryIdsOrImageUrls[index]
        .split(".")
        .slice(0, -1)
        .join(".")}.${format}`;
    }

    // Otherwise, use the image id to create a cloudinary link
    return cloudinary.url(`${cloudinaryIdsOrImageUrls[index]}.${format}`);
  };

  const downloadImage = async (index: number, format: fileTypeOptions) => {
    const url = createCloudinaryDownloadLink(format, index);
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, `image${index}.${format}`);
  };

  const downloadAllImages = async (format) => {
    const downloadPromises = cloudinaryIdsOrImageUrls.map((_, i) => {
      return downloadImage(i, format);
    });

    await Promise.all(downloadPromises);
  };

  return (
    <>
      <Button
        size="small"
        color="secondary"
        onClick={handleClick}
        startIcon={<FaIcon icon="arrow-down-to-line" />}
      >
        Download Image{cloudinaryIdsOrImageUrls.length > 1 && "s"}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => downloadImage(index, "jpg")}>
          Download as JPG
        </MenuItem>
        <MenuItem onClick={() => downloadImage(index, "pdf")}>
          Download as PDF
        </MenuItem>
        {cloudinaryIdsOrImageUrls.length > 1 && [
          <MenuItem key="all-jpg" onClick={() => downloadAllImages("jpg")}>
            Download all as JPG
          </MenuItem>,
          <MenuItem key="all-pdf" onClick={() => downloadAllImages("pdf")}>
            Download all as PDF
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default ImageDownloadMenuButton;
