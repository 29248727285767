/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import axios from "axios";
import _ from "lodash";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { PageTitle } from "@features/ui";

import { setError } from "../redux/slices/errorSlice";

const formatDate = (date) => {
  const dObj = new Date(date);
  return dObj.toLocaleString();
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const arrowStyle = {
  ".MuiTableSortLabel-icon": {
    opacity: 0.5,
  },
  "&.Mui-active .MuiTableSortLabel-icon": {
    color: "#2196f3",
  },
};

const AdminAnaplanTroubleshooting = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [sortByFilename, setSortByFilename] = useState(false);
  const [sortByOccurredAt, setSortByOccurredAt] = useState(true);
  const [sortByError, setSortByError] = useState(false);
  const [sortByCSVRow, setSortByCSVRow] = useState(false);
  const [sortSelected, setSortSelected] = useState("occurred-at");

  const sortFilenameCol = () => {
    setSortByFilename(!sortByFilename);
    setSortSelected("filename");
    const sorted = _.sortBy(errors, (err) =>
      err.attributes.filename.split("/").at(-1).toLowerCase()
    );
    if (!sortByFilename) {
      sorted.reverse();
    }
    setErrors(sorted);
  };

  const sortOccurredAtCol = () => {
    setSortByOccurredAt(!sortByOccurredAt);
    setSortSelected("occurred-at");
    let sortedErrors = _.sortBy(
      errors,
      (e) => new Date(_.get(e, "attributes.updated-at"))
    );
    if (!sortByOccurredAt) {
      sortedErrors.reverse();
    }
    setErrors(sortedErrors);
  };

  const sortErrorCol = () => {
    setSortByError(!sortByError);
    setSortSelected("error");
    const sortedErrors = _.sortBy(errors, (error) =>
      error.attributes.error.toLowerCase()
    );
    if (!sortByError) {
      sortedErrors.reverse();
    }
    setErrors(sortedErrors);
  };

  const sortCSVRowCol = () => {
    setSortByCSVRow(!sortByCSVRow);
    setSortSelected("csv-row");
    const sorted = _.sortBy(errors, (err) =>
      _.get(err, "attributes.csv-row-map.Program ID").toLowerCase()
    );
    if (!sortByCSVRow) {
      sorted.reverse();
    }
    setErrors(sorted);
  };

  useEffect(() => {
    axios
      .get(`/api/anaplan-errors`)
      .then((response) => {
        const data = _.sortBy(response.data.data, [
          (error) => new Date(_.get(error, "attributes.updated-at")),
          (error) => error.attributes.error.toLowerCase(),
        ]).reverse();
        setErrors(data);
        return data;
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  return (
    <FixedHeightScrollLastChild>
      <PageTitle title="Anaplan Troubleshooting" />
      <TableContainer style={{ fontSize: 8 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByFilename}
              >
                <TableSortLabel
                  direction={!sortByFilename ? "asc" : "desc"}
                  onClick={sortFilenameCol}
                  active={sortSelected === "filename"}
                  sx={arrowStyle}
                >
                  Filename
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByOccurredAt ? "asc" : "desc"}
                  onClick={sortOccurredAtCol}
                  active={sortSelected === "occurred-at"}
                  sx={arrowStyle}
                >
                  Occurred At
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByError ? "asc" : "desc"}
                  onClick={sortErrorCol}
                  active={sortSelected === "error"}
                  sx={arrowStyle}
                >
                  Error
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByCSVRow ? "asc" : "desc"}
                  onClick={sortCSVRowCol}
                  active={sortSelected === "csv-row"}
                  sx={arrowStyle}
                >
                  CSV Row
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.attributes.filename.split("/").at(-1)}
                </TableCell>
                <TableCell>
                  {formatDate(row.attributes["updated-at"])}
                </TableCell>
                <TableCell>{row.attributes.error}</TableCell>
                <TableCell>
                  <Stack>
                    <Typography
                      variant="p"
                      key={row.attributes["csv-row-map"]["Program ID"]}
                    >
                      <b>Program ID:</b>{" "}
                      {row.attributes["csv-row-map"]["Program ID"]}
                    </Typography>
                    {Object.entries(row.attributes["csv-row-map"])
                      .filter((attr) => attr[0] !== "Program ID")
                      .map(([key, value]) => {
                        return (
                          <Typography variant="p" key={key}>
                            <b>{key}:</b> {value}
                          </Typography>
                        );
                      })}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FixedHeightScrollLastChild>
  );
};

export default AdminAnaplanTroubleshooting;
