/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { FixedHeightScrollLastChild } from "src/components/StyledComponents";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { BudgetInfo, useBudgetQuery } from "@features/budgets";
import BudgetDetailOrderHistorySummary from "@features/budgets/BudgetDetail/BudgetDetailOrderHistorySummary";
import { ResourceError } from "@features/errors";

const BudgetDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams() as Record<string, string>;
  const { error } = useBudgetQuery(id);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);

  if (error) return <ResourceError error={error} />;

  return (
    <FixedHeightScrollLastChild tw="max-w-screen-md py-px space-y-3">
      <BudgetInfo budgetId={id} />
      <Link
        tw="underline text-neutral-600 text-sm px-3"
        to={`/orders/history/order?budgets[]=${id}`}
        target="_blank"
      >
        View on Order History
      </Link>
      <BudgetDetailOrderHistorySummary budgetId={id} />
    </FixedHeightScrollLastChild>
  );
};

export default BudgetDetail;
