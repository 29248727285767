/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";

import { setSelection } from "src/redux/slices/compliance/triggeredRulesSlice";

import { useSendTriggeredRulesActionMutation } from "@features/compliance";
import { useMutateError } from "@features/errors";
import { Button } from "@features/ui";
import { TriggeredRule } from "@models/TriggeredRule";

const LabelValuePair = ({ label, value, ...props }) => (
  <div tw="text-neutral-800 mb-2" {...props}>
    <div tw="text-primary-500 font-medium text-sm tracking-wide">{label}</div>
    <div tw="text-lg">{value}</div>
  </div>
);

const RuleApproveDenyNotesModal = ({
  triggeredRules,
  closeModal,
  action,
}: {
  triggeredRules: TriggeredRule[];
  closeModal: () => void;
  action: "approve" | "deny";
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [notes, setNotes] = useState(triggeredRules.map(() => ""));

  const setError = useMutateError();

  const sendActionMutation = useSendTriggeredRulesActionMutation();

  const dispatch = useDispatch();

  const handleNext = () => {
    if (currentStep < triggeredRules.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNoteChange = (event) => {
    const newNotes = [...notes];
    newNotes[currentStep] = event.target.value;
    setNotes(newNotes);
  };

  const currentItem = triggeredRules[currentStep];

  const isLastStep = currentStep === triggeredRules.length - 1;

  const handleFinishClick = () => {
    sendActionMutation.mutate(
      {
        action,
        values: triggeredRules.map((item, index) => ({
          id: item.id,
          note: notes[index],
        })),
      },
      {
        onError: (e) => setError(e, `${action} triggered rules`),
        onSuccess: () => {
          dispatch(setSelection([]));
          closeModal();
        },
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={closeModal}>
      <DialogTitle tw="flex items-center justify-between">
        <span>
          {action === "approve" ? "Approving" : "Denying"} Rule{" "}
          {currentStep + 1}
        </span>
        <div tw="flex items-center space-x-2">
          <IconButton onClick={closeModal} edge="end">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent tw="overflow-visible ">
        <div tw="grid gap-3 grid-cols-2">
          <LabelValuePair label="Brand" value={currentItem.brandNames} />
          <LabelValuePair
            label="Item Type"
            value={currentItem.itemTypeDescription}
          />
          <LabelValuePair label="State" value={currentItem.state.code} />
          <LabelValuePair label="Rule Type" value={currentItem.rule.type} />
          <LabelValuePair
            tw="col-span-2"
            label="Rule Description"
            value={currentItem.rule.description}
          />
        </div>
        <TextField
          value={notes[currentStep] || ""}
          onChange={handleNoteChange}
          placeholder="Add Note (required)"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <div tw="flex gap-3">
          <Button
            onClick={handlePrevious}
            disabled={currentStep === 0 || sendActionMutation.isPending}
            variant="outlined"
            color="primary"
          >
            Previous
          </Button>
          {isLastStep ? (
            <Button
              onClick={handleFinishClick}
              variant="contained"
              color="primary"
              disabled={!notes[currentStep]}
              loading={sendActionMutation.isPending}
            >
              Finish
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              disabled={!notes[currentStep]}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RuleApproveDenyNotesModal;
