import React from "react";
import { useSelector } from "react-redux";

import { SmartTableProps } from "@components/SmartTable/SmartTable";
import { Item } from "@models";

import ItemCatalogGrid from "./ItemCatalogGrid";
import ItemCatalogTable from "./ItemCatalogTable";

export type ItemCatalogProps = Omit<SmartTableProps<Item>, "columns"> & {
  query?: string;
  showCheckbox?: boolean;
  isRowDisabled?: (row: Item) => boolean;
  getCheckboxText?: (row: Item) => string;
  rowActions?: (row: Item) => React.ReactNode | null;
  orderView?: "from-inventory" | "on-demand" | "pre-order";
  hideBrands?: boolean;
};

const ItemCatalogView = (props: ItemCatalogProps) => {
  const productView: "table" | "grid" = useSelector(
    (state: any) => state.app.productView
  );

  if (productView === "table") {
    return <ItemCatalogTable {...props} />;
  }

  return <ItemCatalogGrid {...props} />;
};

export default ItemCatalogView;
