import { createSlice } from "@reduxjs/toolkit";

import { axiosPatch } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { buildFavoriteItemsPatch } from "./helpers";

let initialState = {
  loginIsLoading: false,
  isUpdateLoading: false,
  isLoading: false,
  id: null,
  supplierId: null,
  firstName: null,
  lastName: null,
  name: null,
  initials: null,
  email: null,
  role: null,
  status: null,
  isOnPremise: null,
  isRetail: null,
  currentChannel: null,
  sessionExpire: null,
  timeOutSet: false,
  territories: [],
  states: [],
  currentTerritory: null,
  favoriteItemIds: [],
  logInError: null,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.loginIsLoading = false;
  state.error = error;
};

const logInFailed = (state, action) => {
  const { error } = action.payload;
  state.loginIsLoading = false;
  state.logInError = error;
};

const updateFailed = (state, action) => {
  const { error } = action.payload;
  state.isUpdateLoading = false;
  state.error = error;
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getUserSuccess(state, action) {
      const { user } = action.payload;
      state.id = user.id;
      state.supplierId = user.supplierId;
      state.name = user.name;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.initials = user.initials;
      state.email = user.email;
      state.role = user.role;
      state.status = user.status;
      state.isOnPremise = user.isOnPremise;
      state.isRetail = user.isRetail;
      state.currentChannel = state.currentChannel
        ? state.currentChannel
        : user.currentChannel;
      state.territories = [...user.territories];
      state.states = user.states.length > 0 ? [...user.states] : [];
      state.currentTerritory = state.currentTerritory
        ? state.currentTerritory
        : user.currentTerritory;
      state.favoriteItemIds = user.favoriteItemIds;
      state.isLoading = false;
      state.logInError = null;
      state.error = null;
    },
    updateFavoriteItems(state, action) {
      const { items } = action.payload;
      state.favoriteItemIds = [...items];
      state.isUpdateLoading = false;
    },
    updateCurrentTerritory(state, action) {
      const { territory } = action.payload;
      state.currentTerritory = territory;
    },
    updateCurrentChannel(state, action) {
      const { channel } = action.payload;
      state.currentChannel = channel;
    },
    setExpires(state, action) {
      const { expires } = action.payload;
      state.sessionExpire = expires;
    },
    setTimeoutSet(state) {
      state.timeOutSet = !state.timeOutSet;
    },
    removeUser: (state) => {
      Object.assign(state, initialState);
    },
    setLogInFailure: logInFailed,
    setUpdateFailure: updateFailed,
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setUpdateLoading,
  getUserSuccess,
  updateFavoriteItems,
  setExpires,
  setTimeoutSet,
  updateCurrentTerritory,
  updateCurrentChannel,
  removeUser,
  setLogInFailure,
  setUpdateFailure,
  setFailure,
} = userSlice.actions;

export default userSlice.reducer;

export const addToFavoriteItems = (idArray) => async (dispatch) => {
  try {
    dispatch(setUpdateLoading());
    const patchData = buildFavoriteItemsPatch(idArray);
    const response = await axiosPatch("/api/current-user", patchData);
    if (response.error) throw response.error;
    dispatch(updateFavoriteItems({ items: response.data.favoriteItemIds }));
  } catch (err) {
    dispatch(setUpdateFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Users" }));
  }
};
