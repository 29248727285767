import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { PageTitle } from "@features/ui";
import { CSVLink } from "@utils/csv";

import MaintenanceMenu from "../components/OrderHistory/MaintenanceMenu";
import SingleOrderDetailTable from "../components/OrderHistory/SingleOrderDetailTable";
import Loading from "../components/Utility/Loading";
import ConfirmCancelOrder from "../components/Utility/Modals/ConfirmCancelOrder";
import EditOrderAddressModal from "../components/Utility/Modals/EditOrderAddressModal";
import EditOrderVariantModal from "../components/Utility/Modals/EditOrderVariantModal";
import TrackingModal from "../components/Utility/Modals/TrackingModal";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { setFiltersOpen } from "../redux/slices/filterSlice";
import {
  fetchOrder,
  setOrderHasUpdated,
} from "../redux/slices/ordering/orderHistorySlice";
import {
  cancelOrderById,
  cancelOrderVariantById,
} from "../redux/slices/patchOrderSlice";
import { getTracking } from "../redux/slices/trackingSlice";
import { formatMoney, formatMoneyString } from "../utility/utilityFunctions";

/*
The SingleOrder view handles displaying a single order in order history. There is only functionality
in this view if the user is an admin, and the order hasn't shipped yet. If that is the case, admin users
(super, and purchasers) can edit quantities or addresses on the order.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SingleOrder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const typeMap = {
    "pre-order": "Pre-Order",
    "on-demand": "On-Demand",
    inventory: "Inventory",
  };

  const [isTrackingOpen, setTrackingOpen] = useState(false);
  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });
  const [isEditing, setIsEditing] = useState(false);
  const [isCancelOpen, setCancelOpen] = useState(false);
  const [cancelType, setCancelType] = useState(null);
  const [cancelItemId, setCancelItemId] = useState(null);
  const [isItemEditOpen, setIsItemEditOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isAddressEditOpen, setIsAddressEditOpen] = useState(false);

  const currentOrder = useSelector((state) => state.orderHistory.singleOrder);
  const currentUserRole = useSelector((state) => state.user.role);
  const currentSuppliers = useSelector((state) => state.suppliers.supplierList);
  const isLoading = useSelector((state) => state.orderHistory.isLoading);

  const handleTrackingClick = (id) => {
    dispatch(getTracking(id));
    setTrackingOpen(true);
  };

  const handleCancelOpen = (type, itemId) => {
    if (itemId) {
      setCancelItemId(itemId);
    }
    setCancelType(type);
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
    setCancelType(null);
    setCancelItemId(null);
  };

  const handleItemEditOpen = (item) => {
    setIsItemEditOpen(true);
    setEditItem(item);
  };

  const handleItemEditClose = (redirect) => {
    dispatch(setOrderHasUpdated({ value: false, newOrderId: null }));
    setIsItemEditOpen(false);
    setEditItem(null);
    if (redirect) {
      navigate(redirect);
    } else {
      dispatch(fetchOrder(orderId));
    }
  };

  const handleAddressEditOpen = () => setIsAddressEditOpen(true);

  const handleAddressEditClose = () => {
    setIsAddressEditOpen(false);
    dispatch(fetchOrder(orderId));
  };

  const handleCancel = (id, type, note, cancelType) => {
    if (cancelType === "order") {
      dispatch(cancelOrderById(id, type, note));
    }
    if (cancelType === "item") {
      dispatch(cancelOrderVariantById(id, type, note, orderId));
    }
    handleCancelClose();
  };

  useEffect(() => {
    if (
      (!currentOrder.id && currentUserRole.length > 0) ||
      (currentOrder.id !== orderId && currentUserRole.length > 0)
    ) {
      dispatch(fetchOrder(orderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (
      currentOrder.id &&
      currentCSV.data.length === 0 &&
      currentOrder.id === orderId &&
      currentSuppliers.length > 0 &&
      currentOrder.variants.length > 0
    ) {
      let csvHeaders = [
        { label: "Ordered By", key: "user" },
        { label: "Market", key: "state" },
        { label: "Brand", key: "brandCode" },
        { label: "BU", key: "unit" },
        { label: "Item Type", key: "itemType" },
        { label: "Month in Market", key: "inMarketDate" },
        { label: "Estimated Cost", key: "totalEstCost" },
        { label: "Qty Ordered", key: "totalQty" },
        { label: "Seq #", key: "itemNumber" },
        { label: "Program", key: "program" },
        { label: "Carrier", key: "carrier" },
        { label: "Tracking", key: "tracking" },
        { label: "Order Type", key: "orderType" },
        { label: "Beacon", key: "beacon" },
      ];
      let csvData = [];
      currentOrder.variants.forEach((item) => {
        let dataObject = {
          user: currentOrder.user,
          state: item.state.code,
          brandCode: item.brandCode,
          unit: item.unit,
          itemType: item.itemType,
          inMarketDate: item.inMarketDate,
          totalEstCost: formatMoney(item.totalEstCost),
          totalQty: item.totalQty,
          itemNumber: item.itemNumber,
          program: item.program,
          carrier: item.carrier,
          tracking: item.isTransferred
            ? "Transferred qty’s from other territories"
            : item.tracking,
          orderType: typeMap[currentOrder.type] ?? currentOrder.type,
          beacon: item.poId !== "---" ? (item.includeBeacon ? "Y" : "N") : "",
        };
        csvData.push(dataObject);
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrder.variants,
    currentOrder.type,
    currentOrder.user,
    currentOrder.id,
    currentCSV.data.length,
    currentSuppliers,
    orderId,
  ]);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !currentOrder.id) {
    return <Loading />;
  }

  return (
    <>
      {isCancelOpen && (
        <ConfirmCancelOrder
          open={isCancelOpen}
          handleClose={handleCancelClose}
          handleCancel={handleCancel}
          id={cancelType === "order" ? orderId : cancelItemId}
          type={cancelType}
        />
      )}
      {isItemEditOpen && editItem && (
        <EditOrderVariantModal
          modalOpen={isItemEditOpen}
          handleClose={handleItemEditClose}
          item={editItem}
          order={currentOrder}
        />
      )}
      {isAddressEditOpen && (
        <EditOrderAddressModal
          modalOpen={isAddressEditOpen}
          handleClose={handleAddressEditClose}
          order={currentOrder}
        />
      )}
      {isTrackingOpen && (
        <TrackingModal open={isTrackingOpen} handleClose={setTrackingOpen} />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <PageTitle fallbackUrl="/orders/history" title={`Order ${orderId}`} />

          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                style={{ marginRight: "20px" }}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  WRAP UP
                </Button>
              </CSVLink>
              {[
                "super",
                "purchaser",
                "select-purchaser",
                "compliance",
              ].includes(currentUserRole) && (
                <MaintenanceMenu
                  orderType={currentOrder.orderType}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  handleCancelOpen={handleCancelOpen}
                  handleAddressEditOpen={handleAddressEditOpen}
                />
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <Grid container spacing={5}>
          <Grid item lg={9} sm={12} xs={12}>
            <Typography className={classes.headerText}>Order Items:</Typography>
            <Divider />
            <br />
            <SingleOrderDetailTable
              items={currentOrder.variants}
              handleTrackingClick={handleTrackingClick}
              isEditing={isEditing}
              handleCancelOpen={handleCancelOpen}
              handleItemEditOpen={handleItemEditOpen}
            />
          </Grid>
          <Grid item lg={3} sm={12} xs={12}>
            <Typography className={classes.headerText}>
              Order Summary:
            </Typography>
            <Divider />
            <br />
            <Typography className={classes.headerText}>
              {`Ordered By: ${currentOrder.user}`}
            </Typography>
            <br />
            <Typography className={classes.headerText}>
              {`Status: ${
                currentOrder.status[0].toUpperCase() +
                currentOrder.status.slice(1)
              }`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Order Type / Window: ${currentOrder.type}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Order Date: ${currentOrder.orderDate}`}
            </Typography>
            {currentOrder.orderType !== "to-inventory" && (
              <>
                <Typography className={classes.headerText}>
                  {`Shipping Location: ${currentOrder.address.name} - ${
                    currentOrder.distributorAbn
                      ? currentOrder.distributorAbn
                      : currentOrder.address.id
                  }`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`Street Address One: ${currentOrder.address.streetAddress1}`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`Street Address Two: ${
                    currentOrder.address.streetAddress2
                      ? currentOrder.address.streetAddress2
                      : "---"
                  }`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`City: ${currentOrder.address.city}`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`State: ${currentOrder.address.state.code}`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`Zip: ${currentOrder.address.zip}`}
                </Typography>
                <Typography className={classes.headerText}>
                  {`Attention: ${currentOrder.attn}`}
                </Typography>
              </>
            )}
            {currentOrder.orderType === "to-inventory" && (
              <>
                <Typography className={classes.headerText}>
                  {`Restock Allocation: ${currentOrder.toInventoryAllocation}`}
                </Typography>
              </>
            )}
            <br />
            <Divider />
            <br />
            <Typography className={classes.headerText}>
              {`Total Items: ${currentOrder.totalQty}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Item Est. Cost: ${formatMoney(
                currentOrder.totalProductCost,
                false
              )}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Item Act. Cost: ${
                currentOrder.totalActCost !== "---"
                  ? formatMoney(currentOrder.totalActCost, false)
                  : "---"
              }`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Beacon Cost: ${formatMoneyString(
                currentOrder.totalBeaconCost,
                false
              )}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Est. Freight: ${formatMoney(
                currentOrder.totalEstFreight,
                false
              )}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Act. Freight: ${
                currentOrder.totalActFreight !== "---"
                  ? formatMoney(currentOrder.totalActFreight, false)
                  : "---"
              }`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Est. Tax: ${formatMoney(
                currentOrder.totalEstTax,
                false
              )}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Act. Tax: ${
                currentOrder.totalActTax !== "---"
                  ? formatMoney(currentOrder.totalActTax, false)
                  : "---"
              }`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Est. Cost: ${formatMoney(
                currentOrder.totalEstExtendedCost,
                false
              )}`}
            </Typography>
            <Typography className={classes.headerText}>
              {`Total Act. Cost: ${
                currentOrder.totalActExtendedCost
                  ? formatMoney(currentOrder.totalActExtendedCost, false)
                  : "---"
              }`}
            </Typography>
            <br />
            <Typography className={classes.headerText}>
              {`Order Notes: ${currentOrder.note ? currentOrder.note : "---"}`}
            </Typography>
            <br />
          </Grid>
        </Grid>
        <OrderPatchLoading />
      </Container>
      <br />
    </>
  );
};

SingleOrder.propTypes = {
  order: PropTypes.string,
};

export default SingleOrder;
