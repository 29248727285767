/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useMutateError } from "@features/errors";
import { useItemQuery, useUpdateItemMutation } from "@features/items";
import { Button } from "@features/ui";

import { SelectField } from "../Forms/DefaultInputs";

export type AssignWarehouseToItemModalProps = {
  handleContinue: () => void;
  handleCancel: () => void;
  itemId: string;
};

const warehouses = [
  { value: "champion", label: "Champion" },
  { value: "rapid", label: "Rapid" },
];

const AssignWarehouseToItemModal: React.FC<AssignWarehouseToItemModalProps> = ({
  handleContinue,
  handleCancel,
  itemId,
}) => {
  const mutateError = useMutateError();
  const [warehouse, setWarehouse] = useState("");

  const updateItemMutation = useUpdateItemMutation();

  const { data } = useItemQuery(itemId);

  const handleUpdateWarehouse = () => {
    if (!warehouse) return;
    updateItemMutation.mutate(
      {
        id: itemId,
        warehouse,
      },
      {
        onError: (e) => mutateError(e, "Update item warehouse"),
        onSuccess: () => handleContinue(),
      }
    );
  };

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>The item selected needs a warehouse</DialogTitle>
      <DialogContent>
        {!data && <CircularProgress />}
        {data && (
          <div>
            <p>Choose a warehouse to assign to item #{data.itemNumber}</p>
            <div>
              <SelectField
                tw="w-full mt-3"
                label="Select Warehouse"
                options={warehouses}
                value={warehouse}
                onChange={(e) => setWarehouse(e.target.value as string)}
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="danger" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleUpdateWarehouse}
          disabled={!warehouse}
          loading={updateItemMutation.isPending}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignWarehouseToItemModal;
