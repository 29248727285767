/** @jsxImportSource @emotion/react */
import "twin.macro";

import { SUPPLIER_ROLES } from "src/constants/permissions";

import useRoleIs from "@utils/useRoleIs";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const statusOptions = [
  { id: "draft", name: "Draft" },
  { id: "sent", name: "Waiting for Response" },
  { id: "ready-for-review", name: "Ready for Review" },
  { id: "awarded", name: "Awarded" },
  { id: "complete", name: "Complete" },
  { id: "canceled", name: "Canceled" },
];

const supplierStatusOptions = [
  { id: "sent", name: "New" },
  { id: "accepted", name: "In Progress" },
  { id: "awarded", name: "Awarded" },
];

const useOptions = () => {
  const roleIs = useRoleIs();
  if (roleIs(SUPPLIER_ROLES)) {
    return supplierStatusOptions;
  }
  return statusOptions;
};

const filterRfpStatus = makeRadioOptionsFilter({
  name: "rfpStatus",
  title: "Status",
  useOptionsHook: useOptions,
});

export default filterRfpStatus;
