import { query } from "@services/api";

export const buildItemQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    stock: "cached-warehouse-qty",
  };
  let sortString =
    filterObject.sortOrder && filterObject.sortOrderBy
      ? `${filterObject.sortOrder === "desc" ? "-" : ""}${
          sortMap[filterObject.sortOrderBy]
        }`
      : filterObject.sortString ?? "";
  return `/api/items?${query({ filter: filterObject, sort: sortString })}`;
};
export const buildItemProgramQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    stock: "cached-warehouse-qty",
  };
  let sortString =
    filterObject.sortOrder && filterObject.sortOrderBy
      ? `${filterObject.sortOrder === "desc" ? "-" : ""}${
          sortMap[filterObject.sortOrderBy]
        }`
      : null;
  return `/api/item-programs?${query({
    filter: filterObject,
    sort: sortString,
  })}`;
};

export const buildBrand = (productFamilyId) => {
  return {
    data: {
      type: "brand",
      relationships: {
        "product-family": {
          data: {
            type: "product-family",
            id: productFamilyId,
          },
        },
      },
    },
  };
};

export const buildItemType = ({
  description,
  itemTypeCode,
  packSize,
  leadTime,
  categoryCode,
  specType,
  posType,
  rtaDeployment,
  sendToContentHub,
}) => {
  return {
    data: {
      type: "item-type",
      attributes: {
        description,
        "external-id": itemTypeCode,
        "qty-per-pack": packSize,
        "lead-time-in-days": leadTime,
        "category-code": categoryCode,
        "spec-type": specType,
        "pos-type": posType,
        "rta-deployment": rtaDeployment,
        "send-to-content-hub": sendToContentHub,
      },
    },
  };
};

export const buildItemSubType = ({
  label,
  moq,
  estimated_cost,
  complexity_score,
}) => {
  return {
    data: {
      type: "item-type",
      attributes: {
        label,
        moq,
        "estimated-cost": estimated_cost,
        "complexity-score": complexity_score,
      },
    },
  };
};
