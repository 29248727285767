import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import fp from "lodash/fp";
import { userReportColumns } from "src/components/Settings/UserTable";
import {
  formatDateString,
  formatMoneyString,
} from "src/utility/utilityFunctions";

import OrderHistorySalesSummaryPDF from "@components/PDFs/OrderHistorySalesSummaryPDF";
import { useAuthUser } from "@features/auth";
import { budgetSummarySchema } from "@features/budgets";
import { rulesSchema, triggeredRulesSchema } from "@features/compliance";
import approvalsOrderSetSchema from "@features/orders/approvals/reportApprovalsOrderSetSchema";
import approvalsOrderVariantSchema from "@features/orders/approvals/reportApprovalsOrderVariantSchema";
import orderHistoryOrderSchema from "@features/orders/history/orderHistoryOrderReportSchema";
import orderHistoryVariantSchema from "@features/orders/history/orderHistoryVariantReportSchema";
import useGetVariantAvailableInventory from "@features/orders/ordering/hooks/useGetVariantAvailableInventory";
import {
  purchaseOrderRollupSchema,
  rfpHistorySchema,
  rfpRollupSchema,
} from "@features/purchaseOrders";
import purchaseOrderHistorySchema from "@features/purchaseOrders/purchaseOrderHistory/reportPurchaseOrderHistorySchema";
import { Order } from "@models/Order";
import { OrderVariant } from "@models/OrderVariant";
import { csvDataFromSchema, csvSchemaFromTableSchema } from "@utils/csv";

import schemaToCsvReport from "./utils/schemaToCsvReport";

const commaSeparated = (value: any[]) => value.join(", ");
const yesNo = (value: boolean) => (value ? "Yes" : "No");

const useReportDefinitions = () => {
  const user = useAuthUser();
  const { role } = user;
  const getVariantAvailableInventory = useGetVariantAvailableInventory();

  const reports = {
    "budgets-summary": schemaToCsvReport(budgetSummarySchema),
    "order-set-wrapup": {
      ...schemaToCsvReport([]),
      onComplete: (data: Order[]) =>
        csvDataFromSchema(
          [
            { id: "order.user.name", label: "Ordered By" },
            { id: "order.address.state.code", label: "Market" },
            {
              id: "variant.item.brands",
              label: "Brand",
              f: fp.compose(commaSeparated, fp.map("name")),
            },
            {
              id: "variant.item.brands",
              label: "BU",
              f: fp.compose(commaSeparated, fp.map("businessUnit.name")),
            },
            { id: "variant.item.type", label: "Item Type" },
            {
              id: "inMarketDate",
              label: "Month in Market",
              f: (d) => (d ? formatDateString(d) : "---"),
            },
            {
              id: "estimatedCost",
              label: "Estimated Cost",
              f: formatMoneyString,
            },
            { id: "qty", label: "Qty Ordered" },
            { id: "variant.variantSku", label: "Seq #" },
            { id: "order.programNames", label: "Program", f: commaSeparated },
            { id: "order.type", label: "Order Type" },
            { id: "isRush", label: "Rush Order", f: yesNo },
          ],
          _(data)
            .flatMap("orderVariants")
            .sortBy("variant.variantSku")
            .value() as OrderVariant[]
        ),
    },
    "users-summary": schemaToCsvReport(
      csvSchemaFromTableSchema(userReportColumns)
    ),
    "order-history-order": schemaToCsvReport(orderHistoryOrderSchema),
    "order-history-variant": schemaToCsvReport(orderHistoryVariantSchema(role)),
    "order-history-sales-summary": {
      fileExtension: "pdf",
      onDownload: (data) => pdf(OrderHistorySalesSummaryPDF({ data })).toBlob(),
    },
    "approvals-order-set": schemaToCsvReport(approvalsOrderSetSchema),
    "approvals-order-variant": schemaToCsvReport(approvalsOrderVariantSchema),
    "inventory-order-items": schemaToCsvReport([
      { label: "Sequence #", id: "itemNumber" },
      {
        label: "Brand",
        id: "brands",
        f: (b) => commaSeparated(b.map((b) => b.name)),
      },
      {
        label: "Program",
        id: "programs",
        f: (b) => commaSeparated(b.map((b) => b.name)),
      },
      { label: "Item Type", id: "itemTypeName" },
      { label: "Item Desc.", id: "comment" },
      { label: "Pack Size", id: "qtyPerPack" },
      {
        id: "_allInventory",
        label: "On Hand Nat.",
        f: (__, i) => _.sumBy(i.variants, "cachedWarehouseQty"),
      },
      {
        label: "On Hand Terr.",
        id: "_territoryInventory",
        f: (__, i) => _.sumBy(i.variants, getVariantAvailableInventory),
      },
      {
        label: "Est. Cost",
        id: "mostRecentEstimatedCost",
        f: formatMoneyString,
      },
    ]),
    "purchase-order-rollup": schemaToCsvReport(purchaseOrderRollupSchema(role)),
    "rfp-rollup": schemaToCsvReport(rfpRollupSchema),
    "purchase-order-history": schemaToCsvReport(
      purchaseOrderHistorySchema(role)
    ),
    "rfp-history": schemaToCsvReport(rfpHistorySchema(user)),
    "triggered-rules": schemaToCsvReport(triggeredRulesSchema()),
    rules: schemaToCsvReport(rulesSchema()),
  };

  return reports;
};

export default useReportDefinitions;
