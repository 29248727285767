/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Link } from "react-router-dom";

import { PaperTooltip } from "@features/ui";

import { Column } from "./SmartTable";

export const insertIf = <T extends any>(
  condition: any,
  ...elements: Column<T>[]
) => (condition ? elements : []);

export const showFirst = (value?: string[]) =>
  value && (
    <div>
      {value.length > 1 && (
        <PaperTooltip title={value.join(", ")}>
          <div tw="float-right text-xs py-px px-2 ml-1 mb-1 rounded bg-neutral-200 text-neutral-600 cursor-pointer hover:bg-neutral-300">
            +{value.length - 1}
          </div>
        </PaperTooltip>
      )}
      <div>{value[0]}</div>
    </div>
  );

export const TableLink = tw(Link)`
  font-medium underline tracking-wide text-base underline-offset-2 hover:text-primary-600`;
