/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { Link } from "react-router-dom";

import usePreOrderPrograms from "@features/orders/preOrder/usePreOrderPrograms";
import { Button } from "@features/ui";

const ProgramOrderSetButton = ({
  programId,
  orderWindowId,
}: {
  programId: string;
  orderWindowId: string;
}) => {
  const {
    orderSetQuery: { data: orderSets, isLoading },
  } = usePreOrderPrograms(orderWindowId);

  const orderSet = orderSets.find(
    (os) =>
      os.program?.id === programId &&
      os.orderCalendarMonth?.id === orderWindowId
  );

  const buttonText = !orderSet ? "Start Order" : "View Order";

  return (
    <Button
      variant="contained"
      disabled={isLoading}
      component={Link}
      to={`/orders/${orderWindowId}/${programId}`}
      state={{ createOrderSet: !isLoading && !orderSet }}
    >
      {buttonText}
    </Button>
  );
};

export default ProgramOrderSetButton;
