import { useMemo } from "react";

import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { utcDate } from "src/utility/utilityFunctions";

import { useApiResource } from "@services/api";
import useRoleIs from "@utils/useRoleIs";

import { getOrderableEndDateField } from "./helpers";
import { OcmDateField } from "./types";

const today = new Date();

type DateRange = {
  startDate?: OcmDateField;
  endDate?: OcmDateField;
};

export default function useActiveOrderWindows(dateRange?: DateRange) {
  const { role } = useRoleIs();

  const shouldFetch = role !== "supplier";

  const { data = [] } = useApiResource(shouldFetch && "order-calendar-months", {
    params: {
      filter: {
        hasFutureInMarketStartDate: true,
        isAdHoc: false,
      },
      sort: "order-window-open-date",
      page: { size: 100 },
    },

    staleTime: 60 * 60 * 1000,
  });

  const startDateField: OcmDateField =
    dateRange?.startDate ?? "orderWindowOpenDate";

  const endDateField: OcmDateField =
    dateRange?.endDate ?? getOrderableEndDateField(role);

  const activeOrderWindows = useMemo(
    () =>
      data.filter(
        (ocm) =>
          isBefore(startOfDay(utcDate(ocm[startDateField])), today) &&
          isAfter(endOfDay(utcDate(ocm[endDateField])), today)
      ),
    [startDateField, endDateField, data]
  );

  return activeOrderWindows;
}
