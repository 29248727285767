/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { format } from "date-fns";

import { Type } from "@features/ui";
import { OrderVariant } from "@models/OrderVariant";

import { useOrderVariantTrackingQuery } from "../queries";

const TrackingContent = ({ orderVariantId }: { orderVariantId: string }) => {
  const { data, error } = useOrderVariantTrackingQuery(orderVariantId);

  if (error) {
    return (
      <>
        <Alert severity="warning" tw="max-w-prose text-lg">
          This order was shipped by a freight carrier. To get tracking updates,
          please Google the freight carrier name and track your shipment through
          their website.
        </Alert>
        <pre tw="mt-4 text-xs text-neutral-400">{error.message}</pre>
      </>
    );
  }

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <div tw="grid gap-6 grid-cols-2 items-start">
      <Card tw="sticky top-0 p-6">
        <Type.H2>
          Tracking Status:{" "}
          <b tw="font-medium">{data.carrierStatusDescription}</b>
        </Type.H2>
        {data.trackingUrl && (
          <Type.P tw="mb-4">
            <a
              href={data.trackingUrl}
              target="_blank"
              rel="noreferrer"
              tw="text-blue-600 underline"
            >
              Track on carrier website
            </a>
          </Type.P>
        )}
        <Type.P>Tracking Number: {data.trackingNumber}</Type.P>
        <Type.P>Carrier: {data.carrierCode}</Type.P>

        <Type.P>Status: {data.carrierStatusDescription}</Type.P>
        {data.actualDeliveryDate ? (
          <Type.P>
            Delivered on {format(new Date(data.actualDeliveryDate), "PPPP p")}
          </Type.P>
        ) : data.estimatedDeliveryDate ? (
          <Type.P>
            Estimated Delivery:{" "}
            {format(new Date(data.estimatedDeliveryDate), "PPPP")}
          </Type.P>
        ) : (
          <Type.P>Has not left original location yet.</Type.P>
        )}
      </Card>
      <div>
        <div tw="divide-y">
          {data.events.map((event, index) => (
            <div key={index} tw="py-3">
              <div tw="flex gap-2 flex-wrap items-baseline justify-between">
                <Type.Small tw="uppercase text-xs text-primary-500 mb-1">
                  {event.status_description}
                </Type.Small>
                <Type.Small>
                  {format(new Date(event.occurred_at), "PP p")}
                </Type.Small>
              </div>
              <Type.P tw="text-lg">{event.description}</Type.P>
              {event.city_locality && (
                <Type.P tw="text-neutral-400 text-sm">
                  {event.city_locality}, {event.state_province}{" "}
                  {event.postal_code}
                </Type.P>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OrderVariantTrackingModal = ({
  onClose,
  orderVariant,
}: {
  onClose: () => void;
  orderVariant: OrderVariant;
}) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle tw="flex items-center justify-between">
        Tracking for #{orderVariant.variant.item.itemNumber} on order #
        {orderVariant.order.id}
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="max-h-[480px]">
        <TrackingContent orderVariantId={orderVariant.id} />
      </DialogContent>
    </Dialog>
  );
};

export default OrderVariantTrackingModal;
