/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch } from "react-redux";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { createProgram } from "src/redux/slices/planningTool/programSlice";

import CreateProgramForm from "./CreateProgramForm";

const CreateNationalProgramModal = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    dispatch(createProgram(data));
  };

  return (
    <Dialog fullWidth open onClose={handleClose} maxWidth="sm">
      <DialogTitle tw="flex items-center justify-between">
        Create National Program
        <IconButton edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CreateProgramForm
          onSubmit={handleSubmit}
          defaultValues={{}}
          submitButtonText="Create Program"
          programTypeIds={["1", "4"]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNationalProgramModal;
