/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import DocTitle from "@utils/DocTitle";

import BackButton from "./BackButton";
import { Type } from "./Type";

const PageTitle: React.FC<
  {
    fallbackUrl?: string | boolean;
    title: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ fallbackUrl, title, children, ...props }) => {
  return (
    <>
      <DocTitle title={title} />
      <div tw="flex gap-2 items-center" {...props}>
        {fallbackUrl && (
          <BackButton
            defaultUrl={typeof fallbackUrl === "string" ? fallbackUrl : "/"}
          />
        )}
        <Type.H1 tw="m-0 font-medium">{title}</Type.H1>
        {children}
      </div>
    </>
  );
};

export default PageTitle;
