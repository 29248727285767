/** @jsxImportSource @emotion/react */
import "twin.macro";

import { PaginatedResourceProps, SmartTable } from "@components/SmartTable";
import { displayDate } from "@features/reports";
import { Button } from "@features/ui";
import { AnaplanProgram } from "@models/AnaplanProgram";

type AnaplanProgramsTableProps = PaginatedResourceProps & {
  data: AnaplanProgram[];
  onActivate: (program: AnaplanProgram) => void;
};
const AnaplanProgramsTable = ({
  data,
  onActivate,
  ...props
}: AnaplanProgramsTableProps) => {
  return (
    <SmartTable
      rows={data}
      {...props}
      columns={[
        {
          id: "code",
          label: "Program Id",
        },
        { id: "name", label: "Name" },
        { id: "startDate", label: "In Market Start Date", render: displayDate },
        { id: "endDate", label: "In Market End Date", render: displayDate },
        {
          id: "_activate",
          label: "",
          render: (_, ap) => (
            <Button disableRipple onClick={() => onActivate(ap)}>
              Activate
            </Button>
          ),
        },
      ]}
    />
  );
};

export default AnaplanProgramsTable;
