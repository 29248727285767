import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { setItemPreview } from "src/redux/slices/items/itemSlice";

import { deleteOverride } from "../../redux/slices/compliance/complianceRulesSlice";
import ImageWrapper from "../Utility/ImageWrapper";

const headCells = [
  { id: "preview", label: "Preview" },
  { id: "itemNumber", label: "Sequence #" },
  { id: "brand", label: "Brand" },
  { id: "itemType", label: "Item Type" },
  { id: "ruleType", label: "Rule Type" },
  { id: "ruleDesc", label: "Rule Description" },
  { id: "state", label: "State" },
  { id: "notes", label: "Notes" },
];

const OverrideTableHead = ({
  classes,
  handleSelectAll,
  numberSelectable,
  numberSelected,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" padding="checkbox">
          <Checkbox
            indeterminate={
              numberSelected > 0 && numberSelected < numberSelectable
            }
            checked={
              numberSelectable > 0 && numberSelectable === numberSelected
            }
            onChange={handleSelectAll}
            inputProps={{ "aria-label": "select all rules" }}
          />
        </TableCell>
        {headCells.map((hc) => (
          <TableCell align="left" className={classes.headerText} key={hc.id}>
            {hc.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

OverrideTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  numberSelectable: PropTypes.number.isRequired,
  numberSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ComplianceOverrideTable = ({
  selected,
  handleSelect,
  handleSelectAll,
  isSelected,
  numberSelectable,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isUpdateLoading, isLoading, overrideRules } = useSelector(
    (state) => state.complianceRules
  );

  const handleDeleteOverride = (identifier, id) => {
    dispatch(deleteOverride(identifier, id));
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <OverrideTableHead
          classes={classes}
          handleSelectAll={handleSelectAll}
          numberSelectable={numberSelectable}
          numberSelected={selected.length}
        />
        <TableBody>
          {!isLoading && overrideRules.length === 0 && (
            <TableRow>
              <TableCell align="left" colSpan={headCells.length + 1}>
                There are no rules for the selected item ...
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            overrideRules.length > 0 &&
            overrideRules.map((row, i) => {
              const isRowSelected = isSelected(row.identifier);
              const labelId = `override-checkbox-${i}`;
              return (
                <TableRow key={i} hover>
                  {Boolean(row.overrideId) ? (
                    <TableCell padding="checkbox" align="center">
                      <Button
                        className={classes.largeButton}
                        variant="contained"
                        disabled={isUpdateLoading}
                        onClick={() =>
                          handleDeleteOverride(row.identifier, row.overrideId)
                        }
                      >
                        ACTIVATE
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        checked={isRowSelected}
                        disabled={isUpdateLoading}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(evt) => evt.stopPropagation()}
                        onChange={(evt) => {
                          handleSelect(row.identifier);
                          evt.stopPropagation();
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <ImageWrapper
                      id={row.item.id}
                      imgClass={classes.previewImageFloat}
                      alt={row.item.itemType}
                      imgUrl={row.item.imgUrlThumb}
                      handleClick={(evt) => {
                        evt.stopPropagation();
                        dispatch(setItemPreview({ id: row.item.id }));
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{row.item.itemNumber}</TableCell>
                  <TableCell align="left">{row.item.brand}</TableCell>
                  <TableCell align="left">{row.item.itemType}</TableCell>
                  <TableCell align="left">{row.ruleType}</TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">{row.stateCode}</TableCell>
                  <TableCell align="left">
                    {Boolean(row.overrideId)
                      ? `Overridden on ${row.overriddenAt} by ${row.overriddenBy}: ${row.note}`
                      : "---"}
                  </TableCell>
                </TableRow>
              );
            })}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={headCells.length + 1}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ComplianceOverrideTable.propTypes = {
  selected: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  numberSelectable: PropTypes.number.isRequired,
};

export default ComplianceOverrideTable;
