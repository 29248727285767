import { sortBy } from "lodash";
import { displayId } from "src/utility/utilityFunctions";

import { Image } from "@models/Image";
import { Item } from "@models/Item";
import { Variant } from "@models/Variant";
import { VariantAllocation } from "@models/VariantAllocation";

export function getItemPreviewImage(item: Pick<Item, "images">): Image | null {
  const sortedImages = sortBy(item.images, "position");
  const thumb = sortedImages.find((i) => i.type === "thumbnail");
  // replicating the old behavior of returning the second image if there is no thumbnail
  // image at index 1 seems to be the coupon design on templated-coupons
  return thumb ?? sortedImages.at(-2) ?? sortedImages[0] ?? null;
}

export const allocationName = (alloc: VariantAllocation): string =>
  alloc.subStateName ?? alloc.territoryName ?? "";

export const variantId = (variant: Variant) =>
  displayId(variant.variantSku, variant.runNumber);
