/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Tooltip } from "@mui/material";

import _ from "lodash";

import { Item } from "@models";

const countVariantOptions = (item: Item) =>
  _(item.variants)
    .filter("isActive")
    .sortBy("orderPosition")
    .flatMap((v) => v.selectedVariantOptions)
    .reduce<Record<string, string[]>>((acc, vo) => {
      const key = vo.variantCategory.name;
      if (!acc[key]) acc[key] = [];
      if (!acc[key].includes(vo.name)) acc[key].push(vo.name);
      return acc;
    }, {});

const ItemOptionsCount = ({ item }: { item: Item }) => {
  return (
    <div tw="flex gap-2 flex-wrap text-sm text-neutral-500 mt-2">
      {Object.entries(countVariantOptions(item)).map(([key, value]) => (
        <Tooltip
          key={key}
          title={
            <div tw="px-2 py-1">
              <h5 tw="font-medium mr-4 mb-1">
                {value.length} {key} Options
              </h5>
              <ul tw="list-disc pl-4">
                {_.sortBy(value, _.identity).map((v) => (
                  <li key={v}>{v}</li>
                ))}
              </ul>
            </div>
          }
        >
          <div tw="text-primary-500 bg-primary-50 rounded-full px-2">
            {value.length}
            <span> {key}</span>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default ItemOptionsCount;
