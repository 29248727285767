import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";
import { setDraftOrderId } from "src/redux/slices/appSlice";

import { OrderSet } from "@models/OrderSet";

import {
  orderSetsKeyFactory,
  useDraftOrderSetsQuery,
} from "../queries/orderSetQueries";

// This function fetches and pre-loads the in-progress order sets,
// and sets the ids to the global state where they can be accessed by other components.
function useUserDraftOrderSets() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { channel, territory } = useSelector((state: any) => state.app);
  const { data } = useDraftOrderSetsQuery();

  const osIdString = data.map((os) => os.id).join(",");

  useEffect(() => {
    ["from-inventory", "on-demand"].forEach((orderType) => {
      const orderSet = data.find(
        (os) =>
          os.type === orderType &&
          (os.territory?.id ?? null) === territory &&
          os.channel === channel
      );
      dispatch(setDraftOrderId({ orderType, id: orderSet?.id ?? null }));
      if (orderSet) {
        // preload the order set
        queryClient.setQueryData<OrderSet>(
          orderSetsKeyFactory.detail(orderSet.id).queryKey,
          (oldOs) => oldOs ?? orderSet // don't override existing data
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryClient, osIdString, channel, territory]);
}

const FetchUserDraftOrderSets = () => {
  useUserDraftOrderSets();

  return null;
};

export default FetchUserDraftOrderSets;
