import { OcmDateField } from "./types";

export function getOrderableEndDateField(role: string): OcmDateField {
  return (
    {
      field1: "orderWindowCloseDate" as const,
      field2: "reviewCloseDate" as const,
    }[role] || ("purchasingCloseDate" as const)
  );
}
