/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";

import { DatePicker } from "../components/Forms/DefaultInputs";
import ReduxReportGenerator from "../components/Reporting/ReduxReportGenerator";
import {
  MonthSelector,
  ReportOrderTypeSelector,
  YearSelector,
} from "../components/Reporting/ReportSelectors";
import ProgramAutoComplete from "../components/Utility/AutoCompleteFields/ProgramAutoComplete";
import TerritoryAutoComplete from "../components/Utility/AutoCompleteFields/TerritoryAutoComplete";
import { resetFilters, updateSingleFilter } from "../redux/slices/filterSlice";
import { getRoadmapForecastReport } from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportRoadmapForecast = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentMonth, setCurrentMonth] = useState("1");
  const [currentYear, setCurrentYear] = useState("2021");
  const [currentOrderType, setCurrentOrderType] = useState("all");
  const [reset, setReset] = useState(false);
  const [currentPrograms, setCurrentPrograms] = useState([]);
  const [currentTerritories, setCurrentTerritories] = useState([]);
  const [fromDate, setFromDate] = useState(
    format(new Date("02/01/2021"), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const { isLoading } = useSelector((state) => state.reports);
  const currentProgramFilter = useSelector((state) => state.filters.program);
  const currentTerritoryFilter = useSelector(
    (state) => state.filters.territory
  );
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const handleReset = () => {
    setReset(true);
    dispatch(resetFilters());
    setCurrentMonth("1");
    setCurrentYear("2021");
    setCurrentPrograms([]);
    setCurrentTerritories([]);
    setCurrentOrderType("all");
    setFromDate(format(new Date("02/01/2021"), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
  };

  const handleProgram = (value, _type, _filter) => {
    setCurrentPrograms(value);
    dispatch(updateSingleFilter({ filter: "program", value: value }));
  };

  const handleTerritory = (value, _type, _filter) => {
    setCurrentTerritories(value);
    dispatch(updateSingleFilter({ filter: "territory", value: value }));
  };

  const handleProgramChipClick = (id) => {
    let currentProgramArray = currentProgramFilter.filter(
      (prog) => prog.id !== id
    );
    setCurrentPrograms(currentProgramArray);
    dispatch(
      updateSingleFilter({ filter: "program", value: currentProgramArray })
    );
  };

  const handleTerritoryChipClick = (id) => {
    let currentTerrArray = currentTerritoryFilter.filter(
      (terr) => terr.id !== id
    );
    setCurrentTerritories(currentTerrArray);
    dispatch(
      updateSingleFilter({ filter: "territory", value: currentTerrArray })
    );
  };

  const handleGetReport = () => {
    const filterObject = {
      channel: currentChannel,
      orderType: currentOrderType,
      dateRange:
        currentOrderType !== "pre-order" ? `${fromDate} - ${toDate}` : null,
      territory: currentTerritories,
      orderProgram: currentOrderType === "pre-order" ? currentPrograms : null,
      itemProgram: currentOrderType !== "pre-order" ? currentPrograms : null,
      orderMonth: currentOrderType === "pre-order" ? currentMonth : null,
      orderYear: currentOrderType === "pre-order" ? currentYear : null,
    };
    dispatch(getRoadmapForecastReport(filterObject));
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle={"Roadmap Forecast Report"}
        fileName={"roadmap_forecast.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
        reportType={"roadmap-forecast"}
      >
        <>
          <div className={classes.searchComponents}>
            <ReportOrderTypeSelector
              currentOrderType={currentOrderType}
              setCurrentOrderType={setCurrentOrderType}
              classes={classes}
            />
            <ProgramAutoComplete
              classes={classes}
              handleChange={handleProgram}
              reset={reset}
              setReset={setReset}
              filterType="report"
            />
            <TerritoryAutoComplete
              classes={classes}
              handleChange={handleTerritory}
              reset={reset}
              setReset={setReset}
              filterType="report"
              tw="max-w-[250px]"
            />
          </div>
          <br />
          {currentOrderType === "pre-order" ? (
            <div className={classes.searchComponents}>
              <MonthSelector
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                classes={classes}
              />
              <YearSelector
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
                classes={classes}
              />
            </div>
          ) : (
            <div className={classes.searchComponents}>
              <DatePicker
                fullWidth
                color="secondary"
                className={classes.queryField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="fromDate"
                label="Order From Date"
                value={fromDate}
                onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
                disabled={isLoading}
              />

              <DatePicker
                fullWidth
                color="secondary"
                className={classes.queryField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="toDate"
                label="Order To Date"
                value={toDate}
                onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
                disabled={isLoading}
              />
            </div>
          )}
          <br />
          <br />
          <br />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Typography
              className={classes.headerText}
              style={{ marginRight: "10px" }}
            >
              Territory Filters:
            </Typography>
          </div>
          <div className={classes.chipRow}>
            {currentTerritories.length > 0 &&
              currentTerritories.map((terr) => (
                <Chip
                  style={{ margin: "auto 2.5px" }}
                  color="primary"
                  key={terr.id}
                  label={terr.name}
                  onDelete={() => handleTerritoryChipClick(terr.id)}
                />
              ))}
          </div>
          <br />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Typography
              className={classes.headerText}
              style={{ marginRight: "10px" }}
            >
              Program Filters:
            </Typography>
          </div>
          <div className={classes.chipRow}>
            {currentPrograms.length > 0 &&
              currentPrograms.map((prog) => (
                <Chip
                  style={{ margin: "auto 2.5px" }}
                  color="primary"
                  key={prog.id}
                  label={prog.name}
                  onDelete={() => handleProgramChipClick(prog.id)}
                />
              ))}
          </div>
        </>
      </ReduxReportGenerator>
    </>
  );
};

ReportRoadmapForecast.propTypes = {};

export default ReportRoadmapForecast;
