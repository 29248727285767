/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterProgramTypes = makeResourceAutocompleteFilter({
  name: "programTypes",
  resource: "program-types" as any,
  titleSingular: "Program Type",
  titlePlural: "Program Types",
  skipsPagination: true,
  getOptionText: (programType) => programType.description,
});

export default filterProgramTypes;
