import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { mapOrderHistoryVariants } from "../ordering/maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  pendingNextLink: null,
  items: [],
  pendingOrderVariants: [],
  selectedItems: [],
  compItemReport: [],
  triggerCSVDownload: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const complianceItemsSlice = createSlice({
  name: "complianceItems",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getPendingOrderVariantsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      state.pendingNextLink = nextLink;
      state.pendingOrderVariants = [...items];
      state.isLoading = false;
      state.error = null;
    },
    updateCompItemSelection(state, action) {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
    cancelCompItem(state, action) {
      const { id } = action.payload;
      const filteredItems = state.pendingOrderVariants.filter(
        (item) => item.id !== id
      );
      state.pendingOrderVariants = filteredItems;
      state.isUpdateLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  getPendingOrderVariantsSuccess,
  updateCompItemSelection,
  cancelCompItem,
  setFailure,
} = complianceItemsSlice.actions;

export default complianceItemsSlice.reducer;

export const fetchTriggeredRulesByOrders =
  (orderVariantIds) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const url = `/api/order-variants?${query({
        filter: { ids: orderVariantIds },
        page: { size: orderVariantIds.length },
      })}`;
      const ovResponse = await axiosGetWithNext(url);
      if (ovResponse.error) throw ovResponse.error;
      const mappedData = mapOrderHistoryVariants(ovResponse.data.data).filter(
        (item) =>
          item.triggeredRules.length > 0 ||
          item.triggeredPriorApprovalRules.length > 0
      );
      dispatch(
        getPendingOrderVariantsSuccess({
          items: mappedData,
          nextLink: ovResponse.data.nextLink,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Compliance Items" }));
      dispatch(stopGlobalLoad());
    }
  };
