import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { uniqBy } from "lodash";
import PropTypes from "prop-types";

import { shiftToFront } from "../../../utility/utilityFunctions";

const BUAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const [open, setOpen] = useState(false);
  const [bu, setBU] = useState("");
  const [currentBUs, setCurrentBUs] = useState([]);

  const currentFiltersBU = useSelector((state) => state.filters.bu);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);
  const units = useSelector((state) => state.businessUnits.bus);

  // Getting rid of non unique business units names and ordered in a more relevant fashion
  const uniqueUnits = uniqBy(units, "name");
  shiftToFront("name", "Luxury", uniqueUnits);
  shiftToFront("name", "Spirits", uniqueUnits);
  shiftToFront("name", "Commercial", uniqueUnits);

  const handleBUs = (value) => {
    setCurrentBUs(value);
  };

  useEffect(() => {
    if (currentFiltersBU.length !== currentBUs.length) {
      setCurrentBUs(currentFiltersBU);
    }
  }, [currentFiltersBU, currentBUs.length]);

  useEffect(() => {
    if (reset) {
      setBU("");
      setCurrentBUs([]);
      setReset(false);
    }
  }, [reset, setBU, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        id="bu-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={bu}
        onInputChange={(_evt, value) => setBU(value)}
        onChange={(_evt, value) => {
          handleChange(value, "bu", filterType);
          handleBUs(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(bu) => bu.name}
        options={uniqueUnits}
        value={currentBUs}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Business Unit"
            id="bu-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

BUAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default BUAutoComplete;
