/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { SUPPLIER_ROLES } from "src/constants/permissions";
import { formatMoneyUnlimitedDecimals } from "src/utility/utilityFunctions";

import {
  Column,
  PaginatedResourceProps,
  SmartTable,
  insertIf,
  showFirst,
} from "@components/SmartTable";
import { useAuthUser } from "@features/auth";
import { displayDate } from "@features/reports";
import { RequestForPrice } from "@models/RequestForPrice";
import useRoleIs from "@utils/useRoleIs";

type RfpHistoryTableProps = PaginatedResourceProps & {
  data: RequestForPrice[];
};

const handleStatus = (
  supplierId: string | null,
  status,
  bids: RequestForPrice["bids"]
) => {
  if (!!supplierId && status === "sent") {
    let currentBid = bids.find((bid) => bid.supplier.id === supplierId)!;
    if (currentBid.status === "sent") {
      return "New";
    } else if (currentBid.status === "accepted") {
      return "In Progress";
    } else if (currentBid.status === "declined") {
      return "Declined";
    }
  }
  if (status === "sent") {
    let bidCount = 0;
    let decCount = 0;
    bids.forEach((bid) => {
      if (bid.status === "accepted" || bid.status === "declined") {
        bidCount += 1;
        if (bid.status === "declined") {
          decCount += 1;
        }
      }
    });
    if (bidCount !== bids.length) {
      return `Waiting for Resp. ${bidCount}/${bids.length}`;
    } else {
      if (bids.length === decCount) {
        return "All Bids Declined";
      } else return "Ready for Review";
    }
  } else {
    return status[0].toUpperCase() + status.slice(1);
  }
};

export const rfpHistoryColumns = (
  isSupplier,
  supplierId
): Column<RequestForPrice>[] => {
  return [
    {
      id: "status",
      label: "Status",
      sort: "status",
      render: (v, rfp) => handleStatus(supplierId, v, rfp.bids),
    },
    {
      id: "id",
      label: "RFP #",
      sort: "id",
      render: (id) => (
        <Link
          to={`/purchasing/rfp/${id}`}
          tw="font-semibold underline tracking-wide"
        >
          {id}
        </Link>
      ),
      renderText: (v) => v,
    },
    { id: "item.itemNumber", label: "Item #", sort: "item-number" },
    ...insertIf<RequestForPrice>(
      !isSupplier,
      { id: "programName", sort: "program-name", label: "Program" },
      {
        id: "item.brands",
        label: "Brand",
        render: (brands) => showFirst(brands.map((b) => b.name)),
        renderText: (brands) => brands.map((b) => b.name).join(", "),
      }
    ),
    {
      id: "item.type",
      sort: "item-type-description",
      label: "Item Type",
    },
    { id: "item.comment", label: "Item Desc." },
    { id: "qty", label: "Qty Ordered" },
    ...insertIf<RequestForPrice>(
      !isSupplier,
      {
        id: "item.mostRecentEstimatedCost",
        label: "Est. Cost",
        align: "right",
        render: formatMoneyUnlimitedDecimals,
      },
      {
        id: "_totalEstCost",
        label: "Est. Total",
        align: "right",
        render: (_v, rfp) =>
          formatMoneyUnlimitedDecimals(
            rfp.qty * +rfp.item.mostRecentEstimatedCost
          ),
      },
      {
        id: "_actualCost",
        label: "Actual Cost",
        align: "right",
        render: (_v, rfp) => {
          const awardedBidCost = rfp.bids.find(
            (bid) => bid.status === "awarded"
          )?.price;
          return awardedBidCost
            ? formatMoneyUnlimitedDecimals(awardedBidCost)
            : "";
        },
      },
      {
        id: "_actualTotal",
        label: "Actual Total",
        align: "right",
        render: (_v, rfp) => {
          const awardedBidCost = rfp.bids.find(
            (bid) => bid.status === "awarded"
          )?.price;
          return awardedBidCost
            ? formatMoneyUnlimitedDecimals(+awardedBidCost * rfp.qty)
            : "";
        },
      }
    ),
    { id: "dueDate", label: "Due Date", sort: "due-date", render: displayDate },
    { id: "programStartDate", label: "In Market Date", render: displayDate },
    ...insertIf<RequestForPrice>(isSupplier, {
      id: "_bid",
      label: "Your Bid",
      align: "right",
      render: (v, rfp) => {
        const bid = rfp.bids.find((bid) => bid.supplier.id === supplierId);
        return bid ? formatMoneyUnlimitedDecimals(bid.price) : "";
      },
    }),
  ];
};

const RfpHistoryTable = ({ data, ...props }: RfpHistoryTableProps) => {
  const isSupplier = useRoleIs()(SUPPLIER_ROLES);
  const { supplier } = useAuthUser();
  const supplierId = supplier?.id;

  return (
    <SmartTable
      {...props}
      rows={data}
      columns={rfpHistoryColumns(isSupplier, supplierId)}
    />
  );
};

export default RfpHistoryTable;
