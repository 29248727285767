/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const orderStatusOptions = [
  { id: "in-progress", name: "In Progress" },
  { id: "submitted", name: "Submitted" },
  { id: "in-progress,submitted", name: "All Orders" },
];

const filterOrderSetStatus = makeRadioOptionsFilter({
  name: "orderSetStatus",
  title: "Status",
  options: orderStatusOptions,
});

export default filterOrderSetStatus;
