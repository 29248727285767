/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useReactToPrint } from "react-to-print";

import { Tooltip } from "@mui/material";

import { Button, FaIcon } from "@features/ui";

const PrintTableButton = ({ printRef }: { printRef: React.RefObject<any> }) => {
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Tooltip title="Print Table">
      <Button
        variant="contained"
        color="secondary"
        onClick={handlePrint}
        tw="min-w-0 py-3"
      >
        <FaIcon icon="print" tw="text-base" />
      </Button>
    </Tooltip>
  );
};

export default PrintTableButton;
