/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import _ from "lodash";

import { useApiResource } from "@services/api";

import ControlledFilterAutocomplete from "../components/ControlledFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const useBUOptions = () => {
  const { data, isLoading } = useApiResource("business-units" as any);
  const options = _(data ?? [])
    .map("name")
    .uniq()
    .sort()
    .map((name) => ({ id: name, name }))
    .value();
  return { options, isLoading };
};

const Popper = () => {
  const { control } = useFilterContext();
  const buData = useBUOptions();
  return (
    <ControlledFilterAutocomplete
      {...buData}
      name="businessUnits"
      inputPlaceholder="Filter Business Units"
      control={control}
    />
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedBusinessUnits = filterValues.businessUnits ?? [];
  const { options } = useBUOptions();
  return (
    <ResourceFilterChip
      options={options}
      ids={selectedBusinessUnits}
      prefix="Business Unit is"
      contentWhenEmpty="Select Business Unit"
      {...props}
    />
  );
};

const businessUnitFilter = {
  name: "businessUnits",
  menuText: "Business Units",
  Popper,
  Chip,
};

export default businessUnitFilter;
