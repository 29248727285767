import React from "react";

import { format } from "date-fns";
import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { PaginatedResourceProps, SmartTable } from "@components/SmartTable";
import { Rule } from "@models/Rule";

type ComplianceRulesTableProps = PaginatedResourceProps & { data: Rule[] };

export const complianceRuleColumns = [
  { id: "type", label: "Rule Type", render: _.startCase },
  { id: "description", label: "Description" },
  {
    id: "itemTypeDescriptions",
    label: "Item Type",
    render: (v) => v.join(", "),
  },
  { id: "itemTypeCategoryCode", label: "Item Type Code" },
  {
    id: "productFamilyNames",
    label: "Product Family",
    render: (v) => v.join(", "),
  },
  {
    id: "price",
    label: "Price",
    align: "right" as const,
    render: (p) => p && formatMoneyString(p),
  },
  {
    id: "states",
    label: "States",
    render: (v) => v.map((s) => s.code).join(", "),
  },
  {
    id: "insertedAt",
    label: "Created At",
    render: (d) => format(new Date(d), "PP p"),
  },
  {
    id: "updatedAt",
    label: "Updated At",
    render: (d) => format(new Date(d), "PP p"),
  },
];

const ComplianceRulesTable = ({
  data,
  ...props
}: ComplianceRulesTableProps) => {
  return <SmartTable rows={data} {...props} columns={complianceRuleColumns} />;
};

export default ComplianceRulesTable;
