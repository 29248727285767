/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const typeOptions = [
  { id: "pre-order", name: "Pre-Order" },
  { id: "on-demand", name: "On-Demand" },
  { id: "from-inventory", name: "Inventory" },
  { id: "fulfillment", name: "Fulfillment" },
];

const filterPurchaseOrderType = makeRadioOptionsFilter({
  name: "purchaseOrderType",
  title: "PO Type",
  options: typeOptions,
});

export default filterPurchaseOrderType;
