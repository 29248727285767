import _ from "lodash";
import { SUPPLIER_ROLES } from "src/constants/permissions";

import { AuthUser } from "@models/AuthUser";
import { csvSchemaFromTableSchema } from "@utils/csv";

import { rfpHistoryColumns } from "./RfpHistoryTable";

const rfpHistorySchema = (user: AuthUser) => {
  return csvSchemaFromTableSchema(
    rfpHistoryColumns(_.includes(SUPPLIER_ROLES, user.role), user.supplier?.id)
  );
};
export default rfpHistorySchema;
