import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, List, ListItem, TextField } from "@mui/material";

import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import { executeOnEnter } from "./utility";

const FiltersC2MItemHistory = ({
  classes,
  itemNumber,
  bindSequenceNum,
  c2mBrands,
  bindC2mBrands,
  c2mName,
  bindC2mName,
  c2mItemType,
  bindC2mItemType,
  c2mProgramTheme,
  bindC2mProgramTheme,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.globalLoad.isLoading);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="sequenceNumber"
            type="text"
            label="Sequence #"
            value={itemNumber}
            {...bindSequenceNum}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="productName"
            type="text"
            label="Product Name"
            value={c2mName}
            {...bindC2mName}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="itemType"
            type="text"
            label="Item Type"
            value={c2mItemType}
            {...bindC2mItemType}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="programTheme"
            type="text"
            label="Program Theme"
            value={c2mProgramTheme}
            {...bindC2mProgramTheme}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="brand"
            type="text"
            label="Brand"
            value={c2mBrands}
            {...bindC2mBrands}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersC2MItemHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string,
  bindSequenceNum: PropTypes.object.isRequired,
  c2mName: PropTypes.string,
  bindC2mName: PropTypes.object.isRequired,
  c2mItemType: PropTypes.string,
  bindC2mItemType: PropTypes.object.isRequired,
  c2mProgramTheme: PropTypes.string,
  bindC2mProgramTheme: PropTypes.object.isRequired,
  c2mBrands: PropTypes.string,
  bindC2mBrands: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersC2MItemHistory;
