import React, { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

const MaintenanceMenu = ({
  orderType,
  isEditing,
  setIsEditing,
  handleCancelOpen,
  handleAddressEditOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Order Maintenance">
        <IconButton
          aria-owns={anchorEl ? "order-maintenance" : undefined}
          aria-haspopup="true"
          onClick={(evt) => {
            handleOpen(evt);
          }}
          size="large"
        >
          <MenuIcon fontSize="large" color="inherit" />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: "10px" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setIsEditing(!isEditing);
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={isEditing ? "Turn off Edit Mode" : "Edit Item Level Info"}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleAddressEditOpen();
            handleClose();
          }}
          disabled={orderType === "to-inventory"}
        >
          <ListItemIcon>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Order Level Address" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCancelOpen("order");
            handleClose();
          }}
        >
          <ListItemIcon>
            <CancelIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Cancel Entire Order" />
        </MenuItem>
      </Menu>
    </>
  );
};

MaintenanceMenu.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  handleCancelOpen: PropTypes.func.isRequired,
  handleAddressEditOpen: PropTypes.func.isRequired,
};

export default MaintenanceMenu;
