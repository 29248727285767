/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterSubStates = makeResourceAutocompleteFilter({
  name: "subStates",
  resource: "sub-states",
  titleSingular: "State",
  titlePlural: "States",
  tapOptions: (opts) => _.sortBy(opts, "name"),
  skipsPagination: true,
});

export default filterSubStates;
