import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { AnaplanProgram } from "@models";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const anaplanProgramsKeyFactory = createQueryKeys("anaplan-programs", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<AnaplanProgram[]>("anaplan-programs", { params }),
  }),
  byProgramId: (programId: string) => ({
    queryKey: [programId],
    queryFn: () =>
      client
        .get<AnaplanProgram>(`anaplan-programs`, {
          params: {
            filter: { programId },
          },
        })
        .then((res) => res.data[0]),
  }),
});

export const usePaginatedAnaplanProgramsQuery = buildPaginatedQuery(
  anaplanProgramsKeyFactory.paginated
);

export const useAnaplanProgramByProgramId = (programId?: string | null) => {
  return useQuery({
    ...anaplanProgramsKeyFactory.byProgramId(programId!),
    enabled: !!programId,
  });
};
