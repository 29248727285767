/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Card } from "@mui/material";

import { Type } from "@features/ui";

import { upCase } from "../../../utility/utilityFunctions";

const valuify = (val) =>
  typeof val === "string" ? (
    <span tw="text-green-700">{`"${val}"`}</span>
  ) : val === null ? (
    <span tw="text-neutral-400">null</span>
  ) : typeof val === "object" ? (
    <pre tw="text-purple-600 overflow-x-auto my-0">
      {JSON.stringify(val, null, 2)}
    </pre>
  ) : typeof val !== "number" ? (
    <span tw="text-purple-600">{String(val)}</span>
  ) : (
    <span tw="text-blue-600">{val}</span>
  );

const ChangeLog = ({ changes }) => {
  return (
    <div tw="max-w-screen-md mx-auto space-y-4 text-sm">
      {changes.map((row) => (
        <Card key={row.id} tw="p-4 flex">
          <div tw="w-1/3 flex-none items-center text-neutral-500">
            <div tw=" flex gap-2 items-center">
              <div
                css={[
                  tw`w-2 h-2 rounded-full bg-neutral-300`,
                  row.action === "updated" && tw`bg-blue-500`,
                  row.action === "created" && tw`bg-green-500`,
                  row.action === "deleted" && tw`bg-red-500`,
                ]}
              ></div>
              <Type.H3>
                {row.recordType !== "program"
                  ? `#${row.itemNumber || row.entityId}`
                  : "Program"}
              </Type.H3>
            </div>
            <Type.Small tw="mt-2">{row.recordedAt}</Type.Small>
            <div tw="text-lg capitalize mt-4">{row.action}</div>
            {`By ${row.userName || "system"}`}
          </div>
          {row.changes?.length > 0 && (
            <div tw="space-y-4">
              {row.changes
                .filter((change) => change !== null)
                .map((change, i) => {
                  const changedField =
                    change.field === "order_calendar_month_id"
                      ? row.recordType === "program"
                        ? "In Market Month"
                        : "Order Window Month"
                      : upCase(change.field, "_");
                  return (
                    <div
                      key={i}
                      tw="flex justify-between items-center gap-x-4 flex-wrap"
                    >
                      <div>{changedField}</div>
                      <div tw="font-mono max-w-full">
                        {valuify(change.originalValue)}
                        <span tw="text-lg">{" ⇉ "}</span>
                        {valuify(change.updatedValue)}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </Card>
      ))}
    </div>
  );
};

export default ChangeLog;
