import { useSelector } from "react-redux";

import { usePaginatedProgramsQuery } from "./data";

const useOnDemandNationalCouponPrograms = () => {
  const currentChannel = useSelector((state: any) => state.user.currentChannel);
  const params = {
    filter: {
      programTypeIds: [4], // National Coupon
      isOrderableForOnDemand: true,
      isPreOrder: false,
      channel: currentChannel,
    },
  };
  const { data } = usePaginatedProgramsQuery(params, {
    initialPagesToLoad: 10,
  });
  return data;
};

export default useOnDemandNationalCouponPrograms;
