/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch } from "react-redux";

import _ from "lodash";
import { openRFQModal } from "src/redux/slices/planningTool/rfqSlice";

import {
  PaginatedResourceProps,
  SmartTable,
  TableLink,
} from "@components/SmartTable";
import { displayDate } from "@features/reports";
import { Button } from "@features/ui";
import { RequestForQuote } from "@models/RequestForQuote";

type PlanningRFQsTableProps = PaginatedResourceProps & {
  data: RequestForQuote[];
};
const PlanningRFQsTable = ({ data, ...props }: PlanningRFQsTableProps) => {
  const dispatch = useDispatch();

  const handleViewRfq = (id) => {
    dispatch(openRFQModal({ loadRFQ: id }));
  };

  return (
    <SmartTable
      rows={data}
      {...props}
      columns={[
        {
          id: "itemNumber",
          label: "Quote Item",
          render: (itemNumber, rfq) => (
            <TableLink
              to={`/planning/rfqs/${rfq.program.id}/item/${rfq.item.id}`}
            >
              {itemNumber}
            </TableLink>
          ),
        },
        { id: "itemName", label: "Item Name" },
        {
          id: "_view",
          label: "RFQ",
          render: (_, rfq) => (
            <Button
              color="secondary"
              tw="whitespace-nowrap"
              onClick={() => handleViewRfq(rfq.id)}
            >
              View RFQ
            </Button>
          ),
        },
        { id: "brandNames", label: "Brand" },
        { id: "programName", label: "Program" },
        { id: "itemType", label: "Item Type" },
        { id: "round", label: "Round" },
        { id: "status", label: "Status", render: _.startCase },
        { id: "updatedAt", label: "Date Submitted", render: displayDate },
      ]}
    />
  );
};

export default PlanningRFQsTable;
