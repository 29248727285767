/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export const Type = {
  H1: tw.h1`text-2xl text-neutral-700 font-semibold`,
  H2: tw.h2`text-xl text-neutral-700`,
  H3: tw.h3`text-lg text-neutral-700 font-medium tracking-wide`,
  H4: tw.h4`text-primary-500 font-semibold uppercase tracking-wider`,
  P: tw.p`text-neutral-600 max-w-prose`,
  Small: tw.small`text-neutral-500 text-sm`,
};
