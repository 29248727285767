/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { Contained } from "@components/StyledComponents";

import AddressOptions from "../components/Profile/AddressOptions";
import CustomAddresses from "../components/Profile/CustomAddresses";
import DistributorAddresses from "../components/Profile/DistributorAddresses";
import DistributorEmails from "../components/Profile/DistributorEmails";
import FavoriteItems from "../components/Profile/FavoriteItems";
import General from "../components/Profile/General";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useNoFetch } from "../hooks/UtilityHooks";

const tabs = [
  {
    hash: "#general",
    label: "General",
    icon: "user",
    component: <General />,
    nonPermittedUserRoles: [],
  },
  {
    hash: "#favoriteAddresses",
    label: "Favorite Addresses",
    icon: "map-marker-alt",
    component: <AddressOptions />,
    nonPermittedUserRoles: ["read-only", "compliance"],
  },
  {
    hash: "#customAddresses",
    label: "Custom Addresses",
    icon: "address-book",
    component: <CustomAddresses />,
    nonPermittedUserRoles: ["read-only", "compliance"],
  },
  {
    hash: "#distributorChangeRequests",
    label: "Distributor Change Requests",
    icon: "pen-to-square",
    component: <DistributorAddresses />,
    nonPermittedUserRoles: ["read-only", "compliance"],
  },
  {
    hash: "#distributorEmailUpdate",
    label: "Distributor Email Update",
    icon: "envelope",
    component: <DistributorEmails />,
    nonPermittedUserRoles: ["read-only", "compliance"],
  },
  {
    hash: "#favoriteItem",
    label: "Favorite Items",
    icon: "heart",
    component: <FavoriteItems />,
    nonPermittedUserRoles: ["read-only", "compliance"],
  },
];

const Profile = () => {
  const { hash } = useLocation();

  useNoFetch();
  const currentUserRole = useSelector((state) => state.user.role);

  if (!hash) {
    return <Navigate to={`${tabs[0].hash}`} replace />;
  }

  return (
    <Contained>
      <div tw="grid grid-cols-[auto 1fr] gap-6 items-start">
        <nav tw="w-52 mt-4 sticky top-16" aria-label="settings options">
          {tabs.map((tab) => {
            return (
              !tab.nonPermittedUserRoles.includes(currentUserRole) && (
                <li
                  key={tab.hash}
                  className="group"
                  css={[
                    tw`flex items-center justify-between transition-colors duration-300 rounded text-neutral-500 hover:bg-neutral-100 hover:text-neutral-700`,
                    hash === tab.hash && tw`text-primary-500! font-medium`,
                  ]}
                >
                  <Link
                    to={tab.hash}
                    tw="transition-transform duration-300 group-hover:translate-x-0 -translate-x-2 p-2 flex items-baseline"
                  >
                    {/* <FaIcon
                        icon={tab.icon}
                        variant="solid"
                        tw="block text-sm scale-0 transition-transform duration-300 group-hover:scale-100 w-6"
                      /> */}
                    <span>{tab.label}</span>
                  </Link>
                </li>
              )
            );
          })}
        </nav>
        <div tw="min-w-0">
          {tabs.map((tab) => (
            <div key={tab.hash}>{hash === tab.hash && tab.component}</div>
          ))}
        </div>
      </div>
      <OrderPatchLoading />
    </Contained>
  );
};

Profile.propTypes = {};

export default Profile;
