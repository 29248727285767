import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  cancelRFQ,
  closeRFQModal,
  fetchSingleRFQ,
} from "../../../../redux/slices/planningTool/rfqSlice";
import StatusChip from "../../../Utility/StatusChip";
import ViewDraft from "./ViewDraft";
import ViewNew from "./ViewNew";
import ViewSent from "./ViewSent";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  allowOverflow: {
    overflowY: "visible",
  },
}));

const RfqModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [view, setView] = useState("new"); // new, draft, sent, canceled

  const titles = {
    new: "New request for quotes",
    draft: "Send request for quotes",
    sent: "Request for quotes overview",
    canceled: "Canceled RFQ",
  };

  const programIdState = useState(null);

  const itemIdState = useState("");

  const {
    currentRFQ,
    isSingleLoading: isLoading,
    isUpdateLoading,
    RFQModalOpen,
    selectedId,
  } = useSelector((state) => state.rfq);

  // To do: once program context has been created, we can use that action to prefill
  // the program input and the item input.

  const statusChipClasses = {
    draft: "",
    sent: "info",
    canceled: "error",
  };

  const handleCancel = () => {
    dispatch(cancelRFQ(currentRFQ.id));
  };

  const handleClose = () => {
    dispatch(closeRFQModal());
  };

  const viewProps = {
    setView,
    classes,
    currentRFQ,
    isUpdateLoading,
    handleCancel,
  };

  useEffect(() => {
    if (currentRFQ.id) {
      setView(currentRFQ.status);
    }
  }, [currentRFQ]);

  useEffect(() => {
    if (!isLoading && selectedId && selectedId !== currentRFQ.id) {
      dispatch(fetchSingleRFQ(selectedId));
    }
  }, [selectedId, isLoading, dispatch, currentRFQ.id]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={RFQModalOpen}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        classes={{ paper: view === "new" ? classes.allowOverflow : null }}
        maxWidth="md"
      >
        {!isLoading && (
          <Box sx={{ my: 2, mx: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <DialogTitle>{titles[view]}</DialogTitle>
              <Box>
                {currentRFQ?.status && (
                  <StatusChip
                    label={currentRFQ.status.toUpperCase()}
                    status={statusChipClasses[currentRFQ.status]}
                    sx={{ mr: 2 }}
                  />
                )}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  disabled={isUpdateLoading}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent>
              {view === "new" && (
                <ViewNew
                  programIdState={programIdState}
                  itemIdState={itemIdState}
                  classes={classes}
                />
              )}
              {view === "draft" && <ViewDraft {...viewProps} />}
              {(view === "sent" || view === "canceled") && (
                <ViewSent {...viewProps} />
              )}
            </DialogContent>
          </Box>
        )}
        {isLoading && (
          <Box
            sx={{
              my: 8,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {isUpdateLoading && <LinearProgress />}
      </Dialog>
    </div>
  );
};

const MountedRfqModal = () => {
  // This intermediate Component will make sure RfqModal gets unmounted
  const { RFQModalOpen } = useSelector((state) => state.rfq);
  return RFQModalOpen && <RfqModal />;
};

export default memo(MountedRfqModal);
