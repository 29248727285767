import { mapAndSortTerritories } from "../territories/maps";

export const mapUsers = (users) => {
  const mappedUsers = users.map((user) => ({
    id: user.id,
    role: user.role,
    status: user.status,
    name: user.name,
    email: user.email,
    isOnPremise: user["is-on-premise"],
    isRetail: user["is-retail"],
    states: user.states,
    territories: user.territories,
    supplierId: user.supplier?.id,
    hasLimitedBrandAccess: user["has-limited-brand-access"] ?? false,
    brands: user.brands ?? [],
  }));
  return mappedUsers;
};

export const mapCurrentUser = (user) => {
  let currentUser = {
    id: user.id,
    supplierId: user.supplier ? user.supplier.id : null,
    name: user.name.includes(",")
      ? user.name.split(", ")[1] + " " + user.name.split(", ")[0]
      : user.name,
    firstName: user.name.includes(",")
      ? user.name.split(", ")[1]
      : user.name.split(" ")[0],
    lastName: user.name.includes(",")
      ? user.name.split(", ")[0]
      : user.name.split(" ")[user.name.split(" ").length - 1],
    initials: "",
    email: user.email,
    role: user.role,
    isOnPremise: user["is-on-premise"] ? true : false,
    isRetail: user["is-retail"] ? true : !user["is-on-premise"] ? true : false,
    currentChannel: user["is-retail"]
      ? "retail"
      : user["is-on-premise"]
      ? "on_premise"
      : "retail",
    territories: mapAndSortTerritories(user.territories, user.role),
    states:
      user.states.length > 0
        ? user.states.map((state) => ({
            id: state.id,
            code: state.code,
          }))
        : [],
    currentTerritory: "",
    favoriteItemIds: user["favorite-item-ids"],
    status: user.status ? user.status : "active",
  };
  currentUser.initials = `${currentUser.firstName[0]}${currentUser.lastName[0]}`;
  currentUser.currentTerritory =
    currentUser.territories.length > 0 ? currentUser.territories[0].id : null;
  return currentUser;
};
