import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { addDays } from "date-fns";

import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

import { itemRollupsKeyFactory } from "./itemRollupQueries";

export const requestForPricesKeyFactory = createQueryKeys(
  "request-for-prices",
  {
    paginated: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client.get<any[]>("request-for-prices", {
          params,
        }),
    }),
  }
);

export const usePaginatedRfpQuery = buildPaginatedQuery(
  requestForPricesKeyFactory.paginated,
  {
    placeholderData: keepPreviousData,
  }
);

type CreateRFPPayload = {
  itemId: string;
  programId: string;
  orderVariantIds: (string | number)[];
};

export const useCreateRequestForPriceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ itemId, programId, ...data }: CreateRFPPayload) =>
      client
        .post(`request-for-prices`, {
          ...data,
          dueDate: addDays(new Date(), 5),
          item: { id: itemId },
          program: { id: programId },
          relationshipNames: ["program", "item"],
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: itemRollupsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: requestForPricesKeyFactory.paginated._def,
      });
    },
  });
};
