/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { CircularProgress, LinearProgress, Paper } from "@mui/material";

import { isBefore } from "date-fns";
import _ from "lodash";
import { DatePicker } from "src/components/Forms/DefaultInputs";
import { SmartTable } from "src/components/SmartTable";
import { BeaconIcon } from "src/components/StyledComponents";
import {
  formatDateString,
  formatMoneyString,
  utcDate,
} from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";
import {
  displayToInventoryAllocation,
  getExtendedCost,
} from "@features/orders";
import Tags from "@features/ui/Tags";
import { OrderVariant } from "@models/OrderVariant";

import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";
import useUpdateOrderSetVariantMutation from "../orderSet/data/mutations/useUpdateOrderSetVariantMutation";

const InMarketDateSelector = ({ orderSetVariant, handleSaveInMarketDate }) => {
  const [saving, setSaving] = useState(false);
  const [inMarketDate, setInMarketDate] = useState<Date | null>(
    new Date(
      orderSetVariant.inMarketDate ?? orderSetVariant.standardDeliveryDate
    )
  );

  const handleSave = (date: Date | null) => {
    if (!date) return;

    const isRush = isBefore(
      date,
      utcDate(orderSetVariant.standardDeliveryDate)
    );

    setSaving(true);
    setInMarketDate(date);
    handleSaveInMarketDate(orderSetVariant.id, date, isRush).finally(() =>
      setSaving(false)
    );
  };

  return (
    <div tw="relative -my-2 rounded overflow-hidden max-w-min">
      <DatePicker
        tw="min-w-[11em] max-w-[11em] text-sm"
        disabled={saving}
        disablePast
        value={inMarketDate}
        onChange={handleSave}
      />
      {saving && <LinearProgress tw="absolute inset-0 top-auto" />}
    </div>
  );
};

const OrderSetVariantsOverview = () => {
  const setMutateError = useMutateError();

  const updateOrderSetVariant = useUpdateOrderSetVariantMutation();

  const { orderSet, orders, orderSetVariants } = useCurrentOrderSet();

  const handleSaveInMarketDate = (osvId: string, date: Date, isRush: boolean) =>
    updateOrderSetVariant
      .mutateAsync({
        id: osvId,
        inMarketDate: date.toISOString(),
        isRush,
      })
      .catch((e) => setMutateError(e, "orderSetVariant PUT inMarketDate"));

  const groupedOrderVariants: Record<string, OrderVariant[]> = _(orders ?? [])
    .flatMap("orderVariants")
    .filter((ov) => ov.qty > 0)
    .groupBy("variant.id")
    .value();

  if (!orderSet) return <CircularProgress />;

  return (
    <SmartTable
      rows={orderSetVariants.filter((osv) => (osv.totalQty ?? 0) > 0)}
      columns={[
        {
          id: "variant.variantSku",
          label: "Item Number",
          render: (sku, osv) => (
            <div tw="flex gap-1">
              {sku}
              {osv.variant.item.includeBeacon && <BeaconIcon />}
            </div>
          ),
        },
        { id: "variant.item.type", label: "Item Type" },
        {
          id: "estimatedCost",
          label: "Est. Cost",
          align: "right",
          render: formatMoneyString,
        },
        {
          id: "_totalQty",
          label: "Quantity",
          align: "right",
          render: (v, osv) =>
            _.sumBy(groupedOrderVariants[osv.variant.id], "qty"),
        },
        {
          id: "_extendedCost",
          label: "Est. Extended Cost",
          align: "right",
          render: (v, osv) =>
            formatMoneyString(
              _.sumBy(groupedOrderVariants[osv.variant.id], (ov) =>
                getExtendedCost(ov, orderSet.type)
              )
            ),
        },
        ...(orderSet.type !== "pre-order"
          ? [
              {
                id: "standardDeliveryDate",
                label: "Std. Delivery",
                render: formatDateString,
              },
              {
                id: "_inMarketDate",
                label: "Req. Delivery",
                render: (_v, osv) => (
                  <InMarketDateSelector
                    orderSetVariant={osv}
                    handleSaveInMarketDate={handleSaveInMarketDate}
                  />
                ),
              },
            ]
          : [
              {
                id: "inMarketDate",
                label: "In Market Date",
                render: formatDateString,
              },
            ]),
      ]}
      collapsibleContent={(osv) => (
        <div tw="max-w-fit">
          <h4 tw="text-neutral-600 mb-2">Item Allocations</h4>
          <Paper>
            <SmartTable
              rows={groupedOrderVariants[osv.variant.id] ?? []}
              columns={[
                { id: "order.id", label: "Order #" },
                {
                  id: "order.address.abn",
                  label: "Distributor ABN",
                },
                {
                  id: "order.address.name",
                  label: "Distributor / Name",
                  render: (value, ov) =>
                    ov.order.type === "to-inventory" ? (
                      <>
                        <Tags.Restock />
                        {displayToInventoryAllocation(ov.order)}
                      </>
                    ) : (
                      value
                    ),
                },
                { id: "qty", label: "Total Allocated" },
              ]}
            />
          </Paper>
        </div>
      )}
    />
  );
};

export default OrderSetVariantsOverview;
