/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { useUpdateItemMutation } from "@features/items";
import { Item } from "@models/Item";
import { ControlledResourceAutocomplete } from "@utils/forms/ControlledResourceAutocomplete";

const EditItemSupplierModal = ({
  handleClose,
  item,
}: {
  handleClose: () => void;
  item: Item;
}) => {
  const updateItemMutation = useUpdateItemMutation();

  const defaultValues = { id: item.id, supplierId: item.supplier.id };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = (data: typeof defaultValues) => {
    updateItemMutation.mutate(data, {
      onSuccess: handleClose,
    });
  };

  return (
    <Dialog
      open
      disableScrollLock
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle tw="flex items-center justify-between">
        <span>Editing Item #{item.itemNumber}</span>
        <div tw="flex items-center space-x-2">
          <IconButton onClick={handleClose} edge="end">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent tw="overflow-visible ">
        <p tw="mb-4 text-sm text-neutral-500">
          This will affect all Rollup Items for this Item.
        </p>
        <div tw="flex gap-4">
          <ControlledResourceAutocomplete
            resource={"suppliers" as any}
            control={control}
            name="supplier"
            label="Supplier"
            tw="flex-1"
            skipPagination
          />

          <StyledButton
            tw="float-right"
            cta
            onClick={handleSubmit(onSubmit)}
            loading={updateItemMutation.isPending}
          >
            Save for {item.itemNumber}
          </StyledButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditItemSupplierModal;
