/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const anaplanProgramTypes = [
  // { id: "all", name: "All" },
  { id: "national", name: "National" },
  { id: "catch-all", name: "Catch All" },
  { id: "forward-deployed-created", name: "Forward Deployed Created" },
];

const filterAnaplanProgramType = makeRadioOptionsFilter({
  name: "anaplanProgramType",
  title: "Anaplan Program Type",
  options: anaplanProgramTypes,
});

export default filterAnaplanProgramType;
