/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const BasicTable = ({
  rows = [],
  align = [],
  size = "medium",
  paper = true,
  headerStyles = tw`bg-slate-200`,
  ...props
}) => {
  if (!rows.length) return null;

  const headRow = rows[0];
  const bodyRows = rows.slice(1);
  const tableId = headRow[0]; // TODO: unique tabe id

  const wrapper = paper ? { component: Card } : {};

  return (
    <Box {...wrapper} {...props} style={{ overflow: "hidden" }}>
      <TableContainer>
        <Table size={size || "medium"}>
          <TableHead css={headerStyles}>
            <TableRow>
              {headRow.map((cell, i) => (
                <TableCell
                  key={`${tableId}-h${i}`}
                  align={(align ?? [])[i] || "left"}
                  style={{ color: "#333" }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyRows.map((row, i) => (
              <TableRow key={`${tableId}-r${i}`}>
                {row.map((cell, i) => (
                  <TableCell
                    key={`${tableId}-b${i}`}
                    align={(align ?? [])[i] || "left"}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BasicTable;
