/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { Add, Close, EditRounded } from "@mui/icons-material";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { addDays, endOfDay, isPast } from "date-fns";
import { capitalize } from "lodash";

import {
  useCreateOcmMutation,
  useUpcomingOcmsQuery,
  useUpdateOcmMutation,
} from "@features/orderCalendarMonths";
import { Button, PageTitle } from "@features/ui";
import { OrderCalendarMonth } from "@models/OrderCalendarMonth";
import useRoleIs from "@utils/useRoleIs";

import {
  ControlledRadioGroup,
  DateInput,
  TextInput,
} from "../components/Forms/ControlledInputs";
import { FixedHeightScrollLastChild } from "../components/StyledComponents";
import { formatDateString } from "../utility/utilityFunctions";
import ErrorPage from "./ErrorPage";

const Cell = tw(TableCell)`whitespace-nowrap`;
const HeadCell = tw(Cell)`bg-neutral-200 font-bold`;
const StickyCell = tw(
  Cell
)`sticky left-0 bg-white shadow-lg border-x border-neutral-200`;
const StickyHeadCell = tw(StickyCell)`bg-neutral-200 font-bold`;

const dateFieldsInOrder = {
  orderWindowOpenDate: "Order Window Open",
  orderWindowCloseDate: "Order Window Close",
  reviewCloseDate: "Review Close Date",
  purchasingCloseDate: "Leadership Close Date",
  reportDate: "Report Close Date",
  inMarketStartDate: "In Market Start Date",
};

const defaultOcm = {
  orderWindowName: "",
  orderWindowType: "secondary",
  ...Object.fromEntries(
    Object.keys(dateFieldsInOrder).map((field) => [field, ""])
  ),
};

const nextDay = (date) => addDays(endOfDay(date), 2);

const OcmModal = ({ ocm, handleClose, isLoading }) => {
  const updateMutation = useUpdateOcmMutation();
  const createMutation = useCreateOcmMutation();

  const success = updateMutation.isSuccess || createMutation.isSuccess;
  const error = updateMutation.error || createMutation.error;

  const resetMutationState = () => {
    createMutation.reset();
    updateMutation.reset();
  };

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: ocm.id ? ocm : defaultOcm,
  });
  const formValues = watch();

  const controlDate = (name) => {
    const dateFieldnames = Object.keys(dateFieldsInOrder);
    const dateIndex = dateFieldnames.indexOf(name);

    const disabled = isPast(new Date(ocm[name]));

    // Each date must be at least 1 day after the previous date.
    const minDate =
      disabled || dateIndex < 1
        ? undefined
        : nextDay(new Date(formValues[dateFieldnames[dateIndex - 1]]));
    return {
      name,
      control,
      rules: { required: true },
      minDate,
      disablePast: !disabled,
      disabled,
    };
  };

  const sendOcm = (data) => {
    if (data.id) {
      updateMutation.mutate(data, { onSuccess: (ocm) => reset(ocm) });
    } else {
      createMutation.mutate(data, { onSuccess: (ocm) => reset(ocm) });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
      <div tw="flex items-center justify-between">
        <DialogTitle>{`${
          formValues.id ? "Editing" : "New"
        } Order Calendar Month`}</DialogTitle>
        <IconButton onClick={handleClose} title="Close Modal">
          <Close />
        </IconButton>
      </div>
      <DialogContent tw="overflow-y-visible">
        <form onSubmit={handleSubmit(sendOcm)} tw="grid grid-cols-2 gap-4">
          <TextInput
            tw="col-span-2"
            name="orderWindowName"
            control={control}
            rules={{ required: true }}
            fullWidth
            label="Order Window Name"
          />
          <ControlledRadioGroup
            tw="col-span-2"
            control={control}
            name="orderWindowType"
            row
          >
            <FormControlLabel
              value="national"
              label="National"
              control={<Radio />}
            />
            <FormControlLabel
              value="secondary"
              label="Secondary"
              control={<Radio />}
            />
          </ControlledRadioGroup>

          {Object.entries(dateFieldsInOrder).map(([fieldName, label]) => (
            <DateInput
              key={fieldName}
              label={label}
              {...controlDate(fieldName)}
            />
          ))}
        </form>
        {error && (
          <Alert tw="mt-6" severity="error" onClose={resetMutationState}>
            {error.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {success && (
          <Alert
            tw="w-full mr-4"
            severity="success"
            onClose={resetMutationState}
          >
            {updateMutation.isSuccess ? "Updated" : "Created"} successfully!
          </Alert>
        )}
        <Button
          variant="contained"
          onClick={handleSubmit(sendOcm)}
          loading={isLoading}
        >
          {formValues.id ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AdminOrderCalendarMonths = () => {
  const [modalOcm, setModalOcm] = useState<null | OrderCalendarMonth | {}>(
    null
  );
  const { isLoading, error, data: ocms = [] } = useUpcomingOcmsQuery();

  const canEdit = useRoleIs()("super");

  if (error) return <ErrorPage error={error} />;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FixedHeightScrollLastChild>
      {modalOcm && (
        <OcmModal
          ocm={modalOcm}
          handleClose={() => setModalOcm(null)}
          isLoading={isLoading}
        />
      )}
      <div tw="space-y-6">
        <div tw="flex justify-between items-center">
          <PageTitle title="Order Calendar Months" />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => setModalOcm({})}
          >
            New OCM
          </Button>
        </div>
        <TableContainer>
          <Table tw="border-separate">
            <TableHead>
              <TableRow>
                <StickyHeadCell>Name</StickyHeadCell>
                <HeadCell>Visible Date</HeadCell>
                <HeadCell>Order Window Open</HeadCell>
                <HeadCell>Order Window Close</HeadCell>
                <HeadCell>Review Close</HeadCell>
                <HeadCell>Leadership Close</HeadCell>
                <HeadCell>Report Date</HeadCell>
                <HeadCell>In Market Start Date</HeadCell>
                {canEdit && (
                  <StickyHeadCell tw="left-auto right-0"></StickyHeadCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {ocms.map((ocm) => (
                <TableRow key={ocm.id}>
                  <StickyCell tw="font-bold py-0">
                    {ocm.orderWindowName}
                    <div
                      css={[
                        tw`text-xs font-normal text-neutral-500`,
                        ocm.orderWindowType === "secondary" &&
                          tw`text-blue-500`,
                      ]}
                    >
                      {capitalize(ocm.orderWindowType)}
                    </div>
                  </StickyCell>
                  <Cell>{formatDateString(ocm.visibleDate)}</Cell>
                  <Cell>{formatDateString(ocm.orderWindowOpenDate)}</Cell>
                  <Cell>{formatDateString(ocm.orderWindowCloseDate)}</Cell>
                  <Cell>{formatDateString(ocm.reviewCloseDate)}</Cell>
                  <Cell>{formatDateString(ocm.purchasingCloseDate)}</Cell>
                  <Cell>{formatDateString(ocm.reportDate)}</Cell>
                  <Cell>{formatDateString(ocm.inMarketStartDate)}</Cell>
                  {canEdit && (
                    <StickyCell tw="left-auto right-0 py-0">
                      <IconButton title="Edit" onClick={() => setModalOcm(ocm)}>
                        <EditRounded />
                      </IconButton>
                    </StickyCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </FixedHeightScrollLastChild>
  );
};

export default AdminOrderCalendarMonths;
