import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { itemRollupsKeyFactory } from "@features/purchaseOrders/queries/itemRollupQueries";
import { Item } from "@models";
import { VariablePricing } from "@models/VariablePricing";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const itemsKeyFactory = createQueryKeys("items", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<Item>(`items/${id}`).then((res) => res.data),
    contextQueries: {
      variablePricing: (item: Item) => ({
        queryKey: [item.id],
        queryFn: async () => {
          if (item.standardSpecificationCode) {
            return client
              .get<VariablePricing[]>(`variable-pricing`, {
                params: { filter: { itemId: id } },
              })
              .then((res) => res.data);
          }
          return client
            .get<VariablePricing[]>(
              `item-programs/${item.mostRecentItemProgramId}/variable-pricing`
            )
            .then((res) => res.data);
        },
      }),
    },
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Item[]>("items", { params }),
  }),
});

export const useItemQuery = (id: string | null) => {
  return useQuery({
    ...itemsKeyFactory.detail(id!),
    enabled: !!id,
  });
};

export const useItemVariablePricingQuery = (item: Item) => {
  return useQuery({
    ...itemsKeyFactory.detail(item.id)._ctx.variablePricing(item),
    enabled: !!item.id,
  });
};

export const usePaginatedItemsQuery = buildPaginatedQuery<Item>(
  itemsKeyFactory.list,
  { placeholderData: keepPreviousData }
);

type UpdateItemMutationData = {
  id: string;
  warehouse?: string;
  supplierId?: string;
};

export const useUpdateItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: UpdateItemMutationData) =>
      client
        .update<Item>(`items/${id}`, {
          ...data,
        })
        .then((res) => res.data),
    onSuccess: (item) => {
      return Promise.all([
        queryClient.setQueryData(
          itemsKeyFactory.detail(item.id).queryKey,
          item
        ),
        queryClient.setQueriesData(
          { queryKey: itemsKeyFactory.list._def },
          (itemList: Item[] | undefined) =>
            itemList && itemList.map((i) => (i.id === item.id ? item : i))
        ),
        queryClient.invalidateQueries({
          queryKey: itemRollupsKeyFactory.paginated._def,
        }),
      ]);
    },
  });
};
