import { ReactNode, useContext, useMemo } from "react";

import {
  ConfirmContentProps,
  GlobalConfirmContext,
} from "./GlobalConfirmContext";

export function useAlert() {
  const { setPromise, setConfirmContent } = useContext(GlobalConfirmContext);

  const alert = (message: ReactNode, options?: Partial<ConfirmContentProps>) =>
    new Promise<boolean>((resolve) => {
      const confirmContent = {
        title: "Warning!",
        confirmButtonText: "Dismiss",
        hideCancelButton: true,
        message,
        ...options,
      };
      setPromise({ resolve });
      setConfirmContent(confirmContent);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => alert, []);
}
