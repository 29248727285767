export const buildSupplier = ({
  externalId,
  name,
  isPurchaserSelect,
  goesToRFP,
  isActive,
  sapId,
}) => {
  return {
    data: {
      type: "supplier",
      attributes: {
        "external-code": externalId,
        name,
        "is-purchaser-select": isPurchaserSelect,
        "goes-to-rfp": goesToRFP,
        "is-active": isActive,
        "sap-id": sapId,
      },
    },
  };
};

export const buildSupplierUserUpdate = (
  userId,
  supplierId,
  states,
  territories
) => {
  const stateIds = states.map((state) => ({
    type: "state",
    id: state.id,
  }));
  const territoryIds = territories.map((terr) => ({
    type: "territory",
    id: terr.id,
  }));
  return {
    data: {
      type: "user",
      attributes: {
        "supplier-id": supplierId ? supplierId : null,
      },
      relationships: {
        states: {
          data: stateIds,
        },
        territories: {
          data: territoryIds,
        },
      },
    },
  };
};
