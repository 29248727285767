import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { camelCaseKeys } from "src/utility/utilityFunctions";

import { itemRollupsKeyFactory } from "@features/purchaseOrders";
import { OrderVariant } from "@models";
import { TrackingResponse } from "@models/TrackingResponse";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const orderVariantsKeyFactory = createQueryKeys("order-variants", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<OrderVariant[]>("order-variants", { params })
        .then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<OrderVariant[]>("order-variants", { params }),
  }),
  track: (orderVariantId: string) => ({
    queryKey: [orderVariantId],
    queryFn: () =>
      client
        .get(`order-variants/${orderVariantId}/track`, {
          deserializeOutput: false,
        })
        .then((res) => {
          if (res.api_response.errors?.length) {
            throw new Error(res.api_response.errors[0].message);
          }
          return camelCaseKeys(res.api_response) as TrackingResponse;
        }),
  }),
});

export const useOrderVariantsQuery = (params: Record<string, any>) => {
  return useQuery({
    ...orderVariantsKeyFactory.list(params),
  });
};

export const usePaginatedOrderVariantsQuery = buildPaginatedQuery<OrderVariant>(
  orderVariantsKeyFactory.paginated,
  {
    placeholderData: keepPreviousData,
  }
);

export const useOrderVariantTrackingQuery = (orderVariantId: string) => {
  return useQuery({
    ...orderVariantsKeyFactory.track(orderVariantId),
    staleTime: 1000 * 60 * 10,
  });
};

export const useCancelOrderVariantsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      orderVariantIds,
      note,
    }: {
      orderVariantIds: (string | number)[];
      note: string;
    }) => {
      const res = await asyncPool(5, orderVariantIds, (orderVariantId) =>
        client.post(
          `order-variants/${orderVariantId}/cancel`,
          {
            "cancelation-type": "order",
            "cancelation-note": note,
          },
          { serializeBody: false }
        )
      );
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
    },
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: orderVariantsKeyFactory.list._def,
        }),
        queryClient.invalidateQueries({
          queryKey: orderVariantsKeyFactory.paginated._def,
        }),
        queryClient.invalidateQueries({
          queryKey: itemRollupsKeyFactory.paginated._def,
        }),
      ]),
  });
};

export const useMarkAsTransferredMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (orderVariantIds: string[]) =>
      client.post(
        "order-variants/mark-as-transferred",
        { ov_ids: orderVariantIds },
        { serializeBody: false }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderVariantsKeyFactory.list._def,
      });
      queryClient.invalidateQueries({
        queryKey: orderVariantsKeyFactory.paginated._def,
      });
      return queryClient.invalidateQueries({
        queryKey: itemRollupsKeyFactory.paginated._def,
      });
    },
  });
};
