/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterSuppliers = makeResourceAutocompleteFilter({
  name: "suppliers",
  resource: "suppliers" as any,
  titleSingular: "Supplier",
  titlePlural: "Suppliers",
  tapOptions: (opts) => _.sortBy(opts, "name"),
  skipsPagination: true,
});

export default filterSuppliers;
