import * as permissions from "src/constants/permissions";
// import { IconName } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";

import { useActiveOrderWindows } from "@features/orderCalendarMonths";
import useRoleIs from "@utils/useRoleIs";

// type MenuItems = {
//   label: string;
//   to?: string;
//   exactMatch?: boolean;
//   icon?: any;
//   faIcon?: IconName;
//   permissions?: string[];
//   children?: {
//     label: string;
//     to: string;
//     permissions?: string[];
//     exactMatch?: boolean;
//   }[];
// }[];

// Recursively filter for permission
const filterPermissions = (arr, role) =>
  arr.reduce((menuArr, item) => {
    if (!item.permissions || item.permissions.includes(role)) {
      if (item.children) {
        item.children = filterPermissions(item.children, role);
      }
      if (!item.children || item.children.length > 0) {
        menuArr.push(item);
      }
    }
    return menuArr;
  }, []);

const useUserMenu = () => {
  const { role } = useRoleIs();
  const activeOrderWindows = useActiveOrderWindows();

  const hasActiveNational = activeOrderWindows.some(
    (ocm) => ocm.orderWindowType === "national"
  );
  const activeSecondary = activeOrderWindows.find(
    (ocm) => ocm.orderWindowType === "secondary"
  );

  const menu = _.compact([
    {
      label: "Dashboard",
      faIcon: "home",
      to: "/",
      exactMatch: true,
    },
    {
      label: "Admin",
      faIcon: "user-shield",
      permissions: ["super", "purchaser"],
      children: [
        { label: "Brands", to: "/settings/brands" },
        { label: "Item Types", to: "/settings/item-types" },
        {
          label: "Order Calendar Months",
          to: "/settings/order-calendar-months",
          permissions: ["super", "purchaser"],
        },
        {
          label: "Suppliers",
          to: "/settings/suppliers",
          permissions: ["super", "purchaser"],
        },
        { label: "Territories", to: "/settings/territories" },
        { label: "Users", to: "/settings/users" },
        { label: "Variable Pricing", to: "/settings/variable-pricing" },
        {
          label: "Anaplan Troubleshooting",
          to: "/settings/anaplan-troubleshooting",
        },
      ],
    },
    {
      label: "Program Planning",
      faIcon: "map",
      permissions: permissions.PLANNING_TOOL_ROLES,
      children: [
        { label: "Programs", to: "/planning/programs" },
        { label: "Activate Programs", to: "/planning/activate" },
        { label: "RFQs", to: "/planning/rfqs" },
        { label: "Reports", to: "/planning/reports" },
      ],
    },
    {
      label: "Ordering",
      faIcon: "truck",
      permissions: permissions.NON_SUPPLIER_ROLES,
      children: _.compact([
        hasActiveNational && {
          label: "Monthly Pre-Order",
          to: "/programs/national",
        },
        activeSecondary && {
          label: activeSecondary.orderWindowName,
          to: `/programs/secondary`,
          permissions: permissions.SECONDARY_ORDER_WINDOWS,
        },
        {
          label: "On-Demand Order",
          to: "/orders/items/on-demand",
        },
        {
          label: "Inventory Order",
          to: "/orders/items/inventory",
        },
        {
          label: "Order History",
          to: "/orders/history",
        },

        {
          label: "Budgets",
          to: "/budgets",
        },
        {
          label: "Order Window Review",
          to: "/rollup",
          permissions: _.without(permissions.NON_SUPPLIER_ROLES, "field1"),
        },
        {
          label: "Order Approval",
          to: "/approvals",
          permissions: _.without(permissions.NON_SUPPLIER_ROLES, "field1"),
        },
      ]),
    },
    {
      label: "Purchasing",
      faIcon: "money-check-dollar-pen",
      permissions: permissions.ADMIN_ROLES,
      children: [
        {
          label: "New Purchase Order",
          to: "/purchasing/po-rollup",
        },
        {
          label: "Purchase Order History",
          to: `/purchasing/purchase-orders`,
        },
        {
          label: "New RFP",
          to: "/purchasing/rfp-rollup",
        },
        {
          label: "RFP History",
          to: `/purchasing/rfp-history`,
        },
        { label: "Shelf Inventory", to: "/shelf-inventory" },
      ],
    },
    {
      label: "Compliance",
      faIcon: "shield-alt",
      permissions: _.without(permissions.NON_SUPPLIER_ROLES, "field1"),
      children: [
        { label: "Item Rules", to: "/compliance/items" },
        { label: "General Rules", to: "/compliance/rules" },
        { label: "Overrides", to: "/compliance/overrides" },
      ],
    },
    {
      label: "Items",
      faIcon: "shapes",
      permissions: permissions.NON_SUPPLIER_ROLES,
      children: [
        {
          label: "Current Items",
          to: "/items",
          exactMatch: true,
        },
        {
          label: "Archived",
          to: "/items/archive",
        },
      ],
    },
    {
      label: "Reports",
      faIcon: "chart-simple",
      permissions: _.without(
        permissions.NON_SUPPLIER_ROLES,
        permissions.COMPLIANCE
      ),
      children: [
        {
          label: "All Reports",
          to: "/reports",
          exactMatch: true,
        },
        {
          label: "New Item PDF Report",
          to: "/reports/items",
        },
      ],
    },
    // supplier navigation
    {
      label: "Quote Requests",
      faIcon: "file-invoice",
      to: "/quotes",
      permissions: permissions.SUPPLIER_ROLES,
    },
    {
      label: "Price Requests",
      faIcon: "dollar-sign",
      to: "/purchasing/rfp-history",
      permissions: permissions.SUPPLIER_ROLES,
    },
    {
      label: "Purchase Orders",
      faIcon: "box",
      to: "/purchasing/purchase-orders",
      permissions: permissions.SUPPLIER_ROLES,
    },

    // {
    //   label: "Style Guide",
    //   faIcon: "brush",
    //   to: "/styles",
    // },
  ]);
  return filterPermissions(menu, role);
};

export default useUserMenu;
