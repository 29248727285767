/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState, useTransition } from "react";
import { Link, useLocation } from "react-router-dom";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import { isEqual } from "lodash";
import logoBlackUrl from "src/assets/RTA_Logo_Stacked.png";
import rtaIcon from "src/assets/rta-icon.png";

import { FaIcon } from "@features/ui";
import { useBreakpoint } from "@utils/tailwind";

import UserSection from "./UserSection";
import useUserMenu from "./useUserMenu";

const Badge = tw.div`rounded-sm w-4 h-4 text-xs flex items-center justify-center bg-primary-600 text-white font-bold`;

const isUrlMatch = (menuItem, url) =>
  menuItem.exactMatch
    ? menuItem.to === url
    : url.startsWith(new URL(menuItem.to, window.location).pathname);

const Sidebar = ({ setOpen, open }) => {
  const location = useLocation();
  const isMobile = useBreakpoint("md");
  const [_pending, startTransition] = useTransition();
  const [active, setActive] = useState(null);
  const [userOpened, setUserOpened] = useState(null);
  const [userClosedActive, setUserClosedActive] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const sidebarExpanded = open || mouseOver;

  const menu = useUserMenu();

  useEffect(() => {
    const active = menu
      .flatMap((m) => m.children || [m])
      .find((m) => isUrlMatch(m, location.pathname));
    setActive(active);
    setUserOpened(null);
    setUserClosedActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <nav
      className="sidebar"
      tw="flex flex-col pt-4 bg-neutral-100 text-neutral-900 border-r-2 border-neutral-200 relative"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Link to="/" className="logo__container">
        <img src={logoBlackUrl} className="logo logo--full" alt="Logo" />
        <img
          src={rtaIcon}
          className="logo logo--icon"
          tw="max-h-none object-contain object-top absolute top-0 right-0 px-1 h-16"
          alt="Logo icon"
        />
      </Link>
      <List
        tw="p-0 pb-24 overflow-x-hidden overflow-y-auto w-full"
        className="sidebar__list"
      >
        {menu.map((menuItem, i) => {
          const MuiIcon = menuItem.icon;
          const totalBadgeValue = menuItem.children?.reduce(
            (a, b) => a + (b.badgeValue || 0),
            0
          );
          const isActive = active?.to && active.to === menuItem.to;
          const hasActiveChild = menuItem.children?.find(
            ({ to }) => active?.to === to
          );
          const activeStyles = isActive || hasActiveChild;
          const isOpen = Boolean(
            (isEqual(menuItem, userOpened) && !hasActiveChild) ||
              (!userClosedActive && hasActiveChild)
          );

          const handleClick = () => {
            if (hasActiveChild) {
              setUserClosedActive(!userClosedActive);
            } else if (menuItem.children) {
              setUserOpened(isOpen ? null : menuItem);
            } else {
              setActive(menuItem);
              if (isMobile) setOpen(false);
            }
          };
          return (
            <div key={`menu-${i}`}>
              <ListItemButton
                className="group"
                component={menuItem.children ? Box : Link}
                css={[
                  tw`flex items-center justify-between px-5 py-3 font-medium text-neutral-600`,
                  tw`bg-transparent hover:text-neutral-900`,
                  activeStyles && tw`text-primary-950!`,
                ]}
                title={menuItem.label}
                to={menuItem.to}
                onClick={() => startTransition(handleClick)}
              >
                <div tw="flex items-center">
                  <ListItemIcon
                    css={[
                      tw`min-w-0 text-lg transition-colors text-neutral-400 group-hover:text-primary-500`,
                      activeStyles && tw`text-primary-500`,
                    ]}
                  >
                    {menuItem.faIcon ? (
                      <FaIcon icon={menuItem.faIcon} />
                    ) : (
                      <MuiIcon />
                    )}
                    {/* {menuItem.badgeContent && (
                        <StyledBadge
                          badgeContent={menuItem.badgeContent}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <FaIcon />
                        </StyledBadge>
                      )} */}
                  </ListItemIcon>
                  <div className="nav__label">{menuItem.label}</div>
                </div>
                <div tw="flex gap-2 items-center">
                  {!!totalBadgeValue && (
                    <Badge
                      css={[
                        tw`transition delay-200`,
                        isOpen && tw`opacity-0 delay-0`,
                      ]}
                    >
                      {totalBadgeValue}
                    </Badge>
                  )}
                  {menuItem.children && (
                    <KeyboardArrowDownRoundedIcon
                      tw="text-neutral-400"
                      css={isOpen && tw`rotate-180 text-primary-950`}
                    />
                  )}
                </div>
              </ListItemButton>
              {menuItem.children && (
                <Collapse in={isOpen && sidebarExpanded} timeout={300}>
                  <List tw="pt-1">
                    {menuItem.children.map((subItem) => {
                      const isActive = subItem.to === active?.to;
                      return (
                        <ListItemButton
                          key={`children-${subItem.to}`}
                          component={Link}
                          tw="py-2 pl-1 flex text-neutral-700 text-sm items-center mx-2 bg-transparent 
                              hover:text-neutral-900 hover:bg-transparent"
                          title={subItem.label}
                          onClick={() =>
                            startTransition(() => {
                              setActive(subItem);
                              setUserOpened(menuItem);
                              isMobile && setOpen(false);
                            })
                          }
                          to={subItem.to}
                        >
                          <div
                            className="nav__label"
                            css={isActive && tw`font-medium text-primary-950`}
                          >
                            {subItem.label}
                          </div>
                          {!!subItem.badgeValue && (
                            <Badge tw="ml-auto mr-4">
                              {subItem.badgeValue}
                            </Badge>
                          )}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
      <UserSection />
    </nav>
  );
};

export default Sidebar;
