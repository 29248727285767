/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { useBreakpoint } from "@utils/tailwind";

import "../../styles/navWrapper.css";
import FilterDrawer from "../Filtering/FilterDrawer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const NavWrapper = ({ children }) => {
  const [open, setOpen] = useState(true);
  const isMobile = useBreakpoint("md");

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  return (
    // important: z-0 is required to create a new stacking context
    // this insures that any elements outside of this (any portal stuff, like modals and poppers) will appear on top of this
    <div className="main-grid" tw="max-h-screen relative z-0">
      <div
        className={`sidebar-area ${open && "sidebar-area--open"}`}
        tw="relative z-[60]"
      >
        {isMobile && open && (
          <div
            tw="fixed inset-0 bg-neutral-900/50"
            onClick={() => setOpen(false)}
          >
            <IconButton tw="fixed top-4 right-4 bg-neutral-800!">
              <CloseRounded tw="text-neutral-50" />
            </IconButton>
          </div>
        )}
        <Sidebar setOpen={setOpen} open={open} />
      </div>
      <div className="body">
        <Header open={open} setOpen={setOpen} />
        <div tw="flex">
          <div tw="flex-1 relative min-w-0">{children}</div>
          <FilterDrawer />
        </div>
      </div>
    </div>
  );
};

export default NavWrapper;
