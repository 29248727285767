/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Close } from "@mui/icons-material";
import { Card, Collapse, IconButton, Typography } from "@mui/material";

import { Button, Type } from "@features/ui";

const NewWrapper = ({ name, open, setOpen, children }) => {
  return (
    <>
      <div tw="flex justify-between items-baseline mb-4 mt-8">
        <Type.H2>{name}s</Type.H2>
        {!open && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Create {name}
          </Button>
        )}
      </div>

      <Collapse in={open}>
        <Card tw="py-4 px-6 pb-6 mb-4 border-neutral-200 ">
          <div tw="flex items-center justify-between  mb-6">
            <Typography variant="h6" tw="text-neutral-700">
              Create new {name}
            </Typography>
            <IconButton onClick={() => setOpen(false)} size="large">
              <Close />
            </IconButton>
          </div>
          {children}
        </Card>
      </Collapse>
    </>
  );
};
export default NewWrapper;
