import PropTypes from "prop-types";

import { SmartTable } from "@components/SmartTable";

const yesNo = (value) => (value ? "Yes" : "No");

const SupplierTable = ({ handleSupplierClick, suppliers, isLoading }) => {
  return (
    <SmartTable
      onRowClick={(row) => handleSupplierClick(row.id, "edit")}
      rows={suppliers}
      isLoading={isLoading}
      columns={[
        { id: "id", label: "Supplier Id" },
        { id: "name", label: "Name" },
        { id: "externalId", label: "Supplier Code" },
        { id: "goesToRFP", label: "Goes to RFP" },
        { id: "isPurchaserSelect", label: "Is Select", render: yesNo },
        {
          id: "assignedUsers",
          label: "Assigned Users",
          render: (users) => users.map((user) => user.name).join(", "),
        },
      ]}
    />
  );
};

SupplierTable.propTypes = {
  handleSupplierClick: PropTypes.func,
  suppliers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default SupplierTable;
