/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";

import {
  useDetailedInput,
  useDetailedNumberOnlyInput,
} from "../../hooks/InputHooks";
import { fetchC2MItemHistory } from "../../redux/slices/c2mData/c2mItemHistorySlice";
import {
  fetchC2MOrderHistory,
  resetHistoryReport,
  setTriggerCSVFalse as setTriggerC2MCSVFalse,
} from "../../redux/slices/c2mData/c2mOrderHistorySlice";
import {
  resetFilters,
  setChips,
  setClearFreeType,
  setFetchCurrent,
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "../../redux/slices/filterSlice";
import { clearBrands } from "../../redux/slices/items/brandSlice";
import { fetchFilteredUsers } from "../../redux/slices/users/userUpdateSlice";
import FiltersC2MHistory from "./FiltersC2MHistory";
import FiltersC2MItemHistory from "./FiltersC2MItemHistory";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  selectedButton: {
    fontWeight: "600",
    fontSize: "1rem",
    textAlign: "center",
    color: "#737373",
  },
}));

const FilterDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filterType = useSelector((state) => state.filters.filterType);
  const setToClear = useSelector((state) => state.filters.clearFilters);
  const sorted = useSelector((state) => state.filters.sorted);
  const fetchCurrent = useSelector((state) => state.filters.fetchCurrent);
  const defaultFilters = useSelector((state) => state.filters.defaultFilters);
  const allFilters = useSelector((state) => state.filters);
  const clearFreeType = useSelector((state) => state.filters.clearFreeType);
  const open = useSelector((state) => state.filters.filtersOpen);
  const currentTerritoryId = useSelector(
    (state) => state.user.currentTerritory
  );

  const validFilters = [
    "itemNumber",
    "rfpNum",
    "poNum",
    "orderNum",
    "tag",
    "ruleType",
    "status",
    "bu",
    "channel",
    "itemType",
    "month",
    "sortProgramsBy",
    "groupBy",
    "program",
    "brand",
    "user",
    "purchaser",
    "address",
    "orderType",
    "territory",
    "exclusiveTerritory",
    "supplier",
    "favItems",
    "itemDesc",
    "stateIds",
    "type",
    "isAccolade",
    "itemOrderType",
    "excludedSupplier",
    "poInvoiceNumber",
    "poExternalSapId",
    "supplierReference",
    "c2mTerritory",
    "c2mProgramName",
    "c2mBrands",
    "c2mBusinessUnit",
    "c2mDistributor",
    "c2mName",
    "c2mProgramTheme",
    "c2mItemType",
    "supplierQuoteId",
    "programName",
    "workfrontId",
    "programId",
    "couponBarcodeId",
    "programType",
    "inMarketYear",
    "inMarketMonthYears",
    "orderWindowMonthYear",
    "codeOrName",
    "ocmIds",
    "anaplanCode",
    "budgetId",
    "anaplanProgramType",
  ];

  const freeTypeFields = [
    "poNum",
    "rfpNum",
    "itemNumber",
    "itemDesc",
    "orderNum",
    "poInvoiceNumber",
    "poExternalSapId",
    "supplierReference",
    "c2mTerritory",
    "c2mProgramName",
    "c2mBrands",
    "c2mBusinessUnit",
    "c2mDistributor",
    "c2mName",
    "c2mProgramTheme",
    "c2mItemType",
    "programName",
    "codeOrName",
    "workfrontId",
    "programId",
    "couponBarcodeId",
    "anaplanCode",
    "budgetId",
  ];

  const dateFilterFields = [
    "fromDate",
    "toDate",
    "c2mOrderDateFrom",
    "c2mOrderDateTo",
    "c2mInMarketDateFrom",
    "c2mInMarketDateTo",
  ];

  const handleFilters = useCallback(
    (value, filter, type) => {
      let currentFilters = { ...allFilters };
      if (validFilters.includes(filter)) {
        let trimmedValue = freeTypeFields.includes(filter)
          ? value.trim()
          : value;
        dispatch(updateSingleFilter({ filter: filter, value: trimmedValue }));
        if (filter === "status" && trimmedValue === "shipping-hold") {
          dispatch(updateSingleFilter({ filter: "hasShipHold", value: true }));
          currentFilters.hasShipHold = true;
        }
        if (filter === "status" && trimmedValue !== "shipping-hold") {
          dispatch(updateSingleFilter({ filter: "hasShipHold", value: false }));
          currentFilters.hasShipHold = false;
        }
        currentFilters[filter] = trimmedValue;
        if (!freeTypeFields.includes(filter)) {
          dispatch(setChips({ filterType: type }));
        }
      } else if (dateFilterFields.includes(filter)) {
        dispatch(
          updateSingleFilter({
            filter: filter,
            value: format(value, "MM/dd/yyyy"),
          })
        );
        currentFilters[filter] = format(value, "MM/dd/yyyy");
      } else if (filter === "c2mOrderDateRange") {
        dispatch(
          updateMultipleFilters({
            filterObject: {
              c2mOrderDateFrom: value.from,
              c2mOrderDateTo: value.to,
            },
          })
        );
        currentFilters.c2mOrderDateFrom = value.from;
        currentFilters.c2mOrderDateTo = value.to;
      } else if (filter === "c2mInMarketDateRange") {
        dispatch(
          updateMultipleFilters({
            filterObject: {
              c2mInMarketDateFrom: value.from,
              c2mInMarketDateTo: value.to,
            },
          })
        );
        currentFilters.c2mInMarketDateFrom = value.from;
        currentFilters.c2mInMarketDateTo = value.to;
      }
      if (
        filterType === "c2m-order-history" &&
        !freeTypeFields.includes(filter)
      ) {
        dispatch(fetchC2MOrderHistory(currentFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilters, filterType, dispatch]
  );

  const {
    value: itemNumber,
    bind: bindSequenceNum,
    reset: resetSequenceNum,
  } = useDetailedInput("", handleFilters, "itemNumber", filterType);

  const {
    value: orderNum,
    bind: bindOrderNum,
    reset: resetOrderNum,
  } = useDetailedNumberOnlyInput("", handleFilters, "orderNum", filterType);

  const {
    value: c2mTerritory,
    bind: bindC2mTerritory,
    reset: resetC2mTerritory,
  } = useDetailedInput("", handleFilters, "c2mTerritory", filterType);
  const {
    value: c2mProgramName,
    bind: bindC2mProgramName,
    reset: resetC2mProgramName,
  } = useDetailedInput("", handleFilters, "c2mProgramName", filterType);
  const {
    value: c2mBrands,
    bind: bindC2mBrands,
    reset: resetC2mBrands,
  } = useDetailedInput("", handleFilters, "c2mBrands", filterType);
  const {
    value: c2mBusinessUnit,
    bind: bindC2mBusinessUnit,
    reset: resetC2mBusinessUnit,
  } = useDetailedInput("", handleFilters, "c2mBusinessUnit", filterType);
  const {
    value: c2mDistributor,
    bind: bindC2mDistributor,
    reset: resetC2mDistributor,
  } = useDetailedInput("", handleFilters, "c2mDistributor", filterType);
  const {
    value: c2mName,
    bind: bindC2mName,
    reset: resetC2mName,
  } = useDetailedInput("", handleFilters, "c2mName", filterType);
  const {
    value: c2mItemType,
    bind: bindC2mItemType,
    reset: resetC2mItemType,
  } = useDetailedInput("", handleFilters, "c2mItemType", filterType);
  const {
    value: c2mProgramTheme,
    bind: bindC2mProgramTheme,
    reset: resetC2mProgramTheme,
  } = useDetailedInput("", handleFilters, "c2mProgramTheme", filterType);

  const resetAllFilters = useCallback(() => {
    resetSequenceNum();
    resetOrderNum();
    resetC2mTerritory();
    resetC2mProgramName();
    resetC2mBrands();
    resetC2mBusinessUnit();
    resetC2mDistributor();
    resetC2mItemType();
    resetC2mName();
    resetC2mProgramTheme();
    dispatch(clearBrands());
    dispatch(resetFilters());
    if (defaultFilters) {
      let territoryFilters = {
        ...defaultFilters,
        currentTerritoryId: currentTerritoryId,
      };
      dispatch(updateMultipleFilters({ filterObject: territoryFilters }));

      if (filterType === "user-settings") {
        dispatch(fetchFilteredUsers({}));
      }
      if (filterType === "c2m-order-history") {
        dispatch(fetchC2MOrderHistory(defaultFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
      if (filterType === "c2m-item-history") {
        dispatch(fetchC2MItemHistory(defaultFilters));
      }
      dispatch(setChips({ filterType: allFilters.filterType }));
    }
  }, [
    resetSequenceNum,
    resetOrderNum,
    resetC2mTerritory,
    resetC2mProgramName,
    resetC2mBrands,
    resetC2mBusinessUnit,
    resetC2mDistributor,
    resetC2mItemType,
    resetC2mName,
    resetC2mProgramTheme,
    dispatch,
    defaultFilters,
    currentTerritoryId,
    filterType,
    allFilters.filterType,
  ]);

  const handleC2MHistoryFetch = () => {
    dispatch(setChips({ filterType: "c2m-order-history" }));
    dispatch(fetchC2MOrderHistory(allFilters));
    dispatch(setTriggerC2MCSVFalse());
    dispatch(resetHistoryReport());
    resetSequenceNum();
    resetOrderNum();
    resetC2mDistributor();
    resetC2mTerritory();
    resetC2mProgramName();
    resetC2mBrands();
    resetC2mBusinessUnit();
  };

  const handleC2MItemHistoryFetch = () => {
    dispatch(setChips({ filterType: "c2m-item-history" }));
    dispatch(fetchC2MItemHistory(allFilters));
    resetSequenceNum();
    resetC2mName();
    resetC2mItemType();
    resetC2mProgramTheme();
    resetC2mBrands();
  };

  useEffect(() => {
    if (setToClear) {
      resetAllFilters();
    }
  }, [setToClear, resetAllFilters, allFilters, filterType, dispatch]);

  useEffect(() => {
    if (sorted || fetchCurrent) {
      if (filterType === "user-settings") {
        dispatch(fetchFilteredUsers({}));
      }
      if (filterType === "c2m-order-history") {
        dispatch(fetchC2MOrderHistory(allFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
      if (filterType === "c2m-item-history") {
        dispatch(fetchC2MItemHistory(allFilters));
      }
      if (sorted) {
        dispatch(setSorted());
      }
      if (fetchCurrent) {
        dispatch(setFetchCurrent());
      }
    }
  }, [
    sorted,
    fetchCurrent,
    dispatch,
    filterType,
    allFilters,
    currentTerritoryId,
  ]);

  const clearMap = {
    itemNumber: resetSequenceNum,
    orderNum: resetOrderNum,
    c2mTerritory: resetC2mTerritory,
    c2mProgramName: resetC2mProgramName,
    c2mBrands: resetC2mBrands,
    c2mBusinessUnit: resetC2mBusinessUnit,
    c2mDistributor: resetC2mDistributor,
    c2mName: resetC2mName,
    c2mItemType: resetC2mItemType,
    c2mProgramTheme: resetC2mProgramTheme,
  };

  useEffect(() => {
    if (clearFreeType && clearFreeType.length > 0) {
      clearFreeType.forEach((type) => {
        clearMap[type]();
      });
      dispatch(setClearFreeType({ value: false }));
    }
  });

  return (
    <>
      <Collapse in={open} orientation="horizontal" tw="flex-shrink-0">
        <Card tw="w-[240px] p-3 mr-3 max-h-full">
          {filterType && filterType === "c2m-order-history" && (
            <FiltersC2MHistory
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              orderNum={orderNum}
              bindOrderNum={bindOrderNum}
              c2mTerritory={c2mTerritory}
              bindC2mTerritory={bindC2mTerritory}
              c2mProgramName={c2mProgramName}
              bindC2mProgramName={bindC2mProgramName}
              c2mBrands={c2mBrands}
              bindC2mBrands={bindC2mBrands}
              c2mBusinessUnit={c2mBusinessUnit}
              bindC2mBusinessUnit={bindC2mBusinessUnit}
              c2mDistributor={c2mDistributor}
              bindC2mDistributor={bindC2mDistributor}
              handleFilters={handleFilters}
              handleSearch={handleC2MHistoryFetch}
            />
          )}
          {filterType && filterType === "c2m-item-history" && (
            <FiltersC2MItemHistory
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              c2mBrands={c2mBrands}
              bindC2mBrands={bindC2mBrands}
              c2mName={c2mName}
              bindC2mName={bindC2mName}
              c2mItemType={c2mItemType}
              bindC2mItemType={bindC2mItemType}
              c2mProgramTheme={c2mProgramTheme}
              bindC2mProgramTheme={bindC2mProgramTheme}
              handleSearch={handleC2MItemHistoryFetch}
            />
          )}
        </Card>
      </Collapse>
    </>
  );
};

export default FilterDrawer;
