/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  StyledButton,
  TableCardContainer,
} from "@components/StyledComponents";
import { useMutateError } from "@features/errors";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemRollupTable,
  usePaginatedItemRollupsQuery,
} from "@features/purchaseOrders";
import { useCreateRequestForPriceMutation } from "@features/purchaseOrders/queries/requestForPriceQueries";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import { ItemRollup } from "@models/ItemRollup";
import { useDeepCompareEffect } from "@services/api/helperFunctions";

const RfpRollup = () => {
  const setError = useMutateError();
  const navigate = useNavigate();
  const [filters] = useFilterParams();
  const { channel } = useSelector((state: any) => state.app);
  const [selectedItemRollups, setSelectedItemRollups] = useState<ItemRollup[]>(
    []
  );

  const createRFPMutation = useCreateRequestForPriceMutation();

  const handleNewRFP = () => {
    const [ir] = selectedItemRollups;
    createRFPMutation.mutate(
      {
        itemId: ir.variant.item.id,
        programId: ir.orderProgram.id,
        orderVariantIds: ir.orderVariantIds,
      },
      {
        onSuccess: (rfp) => navigate(`/purchasing/rfp/${rfp.id}`),
        onError: (e) => setError(e, "Create Request For Price"),
      }
    );
  };

  const params = {
    filter: {
      isForRfp: true,
      orderSetType: "pre-order",
      channel: channel,
      programIds: filters.programs,
      businessUnitNames: filters.businessUnits,
      supplierIds: filters.suppliers,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
    },
  };
  useReport("rfp-rollup", ["item-rollups", params]);
  const { data, ...tableProps } = usePaginatedItemRollupsQuery(params);

  useNoFetch();

  useDeepCompareEffect(() => {
    setSelectedItemRollups([]);
  }, [params]);

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex flex-wrap items-center justify-between gap-6">
        <PageTitle tw="m-0" title="RFP Rollup" />

        <div tw="flex flex-wrap gap-3">
          <StyledButton
            disabled={selectedItemRollups.length !== 1}
            loading={createRFPMutation.isPending}
            onClick={() => handleNewRFP()}
          >
            Create Price Request
          </StyledButton>
          <RequestReportButton reportName="rfp-rollup" />
        </div>
      </div>
      <TableCardContainer>
        <Filters
          searchTitle="Search Seq #"
          slots={[
            "brands",
            "programs",
            "itemTypes",
            "businessUnits",
            "suppliers",
          ]}
          alwaysShow={["brands", "suppliers"]}
        />
        <ItemRollupTable
          data={data}
          isRowSelected={(row) =>
            selectedItemRollups.some((r) => r.id === row.id)
          }
          onCheckRow={async (row) =>
            setSelectedItemRollups(_.xorBy(selectedItemRollups, [row], "id"))
          }
          isRowDisabled={(row) =>
            selectedItemRollups.length > 0 &&
            !selectedItemRollups[0]?.id === row.id
          }
          view={"rfp"}
          {...tableProps}
        />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default RfpRollup;
