/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { useNoFetch } from "src/hooks/UtilityHooks";

import ProgramActivateModal from "@components/PlanningTool/Programs/ProgramActivateModal";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { usePaginatedAnaplanProgramsQuery } from "@features/programs";
import AnaplanProgramsTable from "@features/programs/components/AnaplanProgramsTable";
import { PageTitle } from "@features/ui";
import { AnaplanProgram } from "@models/AnaplanProgram";

const PlanningActivate = () => {
  const [filters] = useFilterParams();

  const [selectedProgram, setSelectedProgram] = useState<null | AnaplanProgram>(
    null
  );

  const handleClose = () => {
    setSelectedProgram(null);
  };

  const params = {
    filter: {
      codeOrName: filters.q,
      isActivated: false,
      inMarketMonthYears: filters.inMarketMonths,
      programTypeIds: filters.programTypes,
    },
    sort: filters.sort,
  };
  const tableData = usePaginatedAnaplanProgramsQuery(params);
  useNoFetch();

  return (
    <>
      {selectedProgram && (
        <ProgramActivateModal
          handleClose={handleClose}
          anaplanProgram={selectedProgram}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between items-center gap-3 flex-wrap">
          <PageTitle title="Activate Programs" />
        </div>
        <TableCardContainer>
          <Filters
            searchTitle="Search Anaplan Program"
            alwaysShow={["inMarketMonths", "programTypes"]}
            slots={["inMarketMonths", "programTypes"]}
          />
          <AnaplanProgramsTable
            {...tableData}
            onActivate={setSelectedProgram}
          />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PlanningActivate;
