import { Dashboard } from "@features/ui";

const ComplianceDash = () => {
  const cardData = [
    {
      titleText: "Item Catalog: Current",
      link: "/items",
      info: "View catalog of and details about currently available items",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
    },
    {
      titleText: "Item Catalog: Archive",
      link: "/items/archive",
      info: "View archive of and details about items no longer available",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Archive_wgetjy.png",
    },
    {
      titleText: "Item Rules",
      link: "/compliance/items",
      info: "View details about regional item compliance",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
    },
    {
      titleText: "Compliance Rules",
      link: "/compliance/rules",
      info: "View details bout specific rules",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
    },
    {
      titleText: "Knowledge Base",
      link: "https://help.readytoactivate.com/",
      info: "Access the knowledge base for more information about RTA's functionality",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
    },
  ];
  return (
    <Dashboard>
      {cardData.map((data, index) => (
        <Dashboard.Card
          key={index}
          title={data.titleText}
          secondary={data.info}
          link={data.link}
        />
      ))}
    </Dashboard>
  );
};

export default ComplianceDash;
