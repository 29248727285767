import { useMemo } from "react";
import { useSelector } from "react-redux";

import { UserRole } from "@models/UserRole";

export default function useRoleIs() {
  const role: UserRole = useSelector((state: any) => state.user.role);

  const roleIs = useMemo(() => {
    function roleIs(...roles: (UserRole | readonly UserRole[])[]) {
      return roles.flat().includes(role);
    }

    roleIs.role = role;

    return roleIs;
  }, [role]);
  return roleIs;
}
