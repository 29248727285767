import { createSlice } from "@reduxjs/toolkit";

const triggeredRulesSlice = createSlice({
  name: "triggeredRules",
  initialState: {
    selection: [],
  },
  reducers: {
    setSelection(state, action) {
      state.selection = action.payload;
    },
  },
});

export const { setSelection } = triggeredRulesSlice.actions;
export default triggeredRulesSlice.reducer;
