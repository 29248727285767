/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const orderWindowHeader = {
  national: "National Order Windows",
  secondary: "Secondary Order Windows",
};

const filterOrderCalendarMonths = makeResourceAutocompleteFilter({
  name: "ocms",
  resource: "order-calendar-months",
  titleSingular: "Order Window",
  titlePlural: "Order Windows",
  requestParams: { sort: "-order-window-open-date" },
  resourceFilterName: "orderWindowName",
  getOptionText: (opt) => opt.orderWindowName,
  tapOptions: (opts) =>
    _(opts)
      .sortBy("orderWindowType")
      .filter("orderWindowName")
      .map((opt) => ({
        ...opt,
        header: orderWindowHeader[opt.orderWindowType],
      }))
      .value(),
  groupBy: (opt: any) => opt.header,
});

export default filterOrderCalendarMonths;
