import AdminAnaplanTroubleshooting from "src/pages/AdminAnaplanTroubleshooting";
import AdminBrands from "src/pages/AdminBrands";
import AdminOrderCalendarMonths from "src/pages/AdminOrderCalendarMonths";
import AdminSuppliers from "src/pages/AdminSuppliers";
import AdminTerritories from "src/pages/AdminTerritories";
import AdminUsers from "src/pages/AdminUsers";
import AdminVariablePricing from "src/pages/AdminVariablePricing";

import { ItemTypesAdmin } from "@features/itemTypes";

import { protectedRoute } from "./types";

const routeSettings: protectedRoute = {
  path: "settings",
  allowedRoles: ["super", "purchaser"],
  defaultChildrenAllowedRoles: ["super"],
  children: [
    { path: "users", element: <AdminUsers /> },
    { path: "territories", element: <AdminTerritories /> },
    { path: "brands", element: <AdminBrands /> },
    { path: "item-types", element: <ItemTypesAdmin /> },
    {
      path: "order-calendar-months",
      allowedRoles: ["super", "purchaser"],
      element: <AdminOrderCalendarMonths />,
    },
    {
      path: "suppliers",
      allowedRoles: ["super", "purchaser"],
      element: <AdminSuppliers />,
    },
    { path: "variable-pricing", element: <AdminVariablePricing /> },
    {
      path: "anaplan-troubleshooting",
      element: <AdminAnaplanTroubleshooting />,
    },
  ],
};

export default routeSettings;
