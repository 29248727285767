/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "src/components/StyledComponents";
import { useNoFetch } from "src/hooks/UtilityHooks";

import { BudgetsTable, usePaginatedBudgetsQuery } from "@features/budgets";
import { Filters, useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";

const BudgetSummary = () => {
  const [filters] = useFilterParams();

  const params = {
    filter: {
      externalId: filters.q,
      brandIds: filters.brands,
      subStateIds: filters.subStates,
      businessUnitNames: filters.businessUnits,
      years: filters.fiscalYear,
      channel: filters.channel,
    },
  };

  useReport("budgets-summary", ["budgets", params]);
  const { data, ...tableProps } = usePaginatedBudgetsQuery(params);

  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center justify-between">
        <PageTitle title="Budget Overview" />
        <RequestReportButton
          reportName="budgets-summary"
          title="Download CSV"
        />
      </div>
      <TableCardContainer>
        <Filters
          searchTitle="Search Budget ID"
          slots={[
            "brands",
            "businessUnits",
            "channel",
            "fiscalYear",
            "subStates",
          ]}
          alwaysShow={["brands"]}
        />
        <BudgetsTable rows={data ?? []} {...tableProps} />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default BudgetSummary;
