import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setDraftOrderId } from "src/redux/slices/appSlice";

import { OrderSet } from "@models/OrderSet";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import { useOptionalCurrentOrderSet } from "../CurrentOrderSetContext";
import { useOptionalOrderSetId } from "../useOrderSetId";

export default function useDeleteOrderSetMutation(id?: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOptionalOrderSetId();
  const orderSetCtx = useOptionalCurrentOrderSet();
  return useMutation({
    mutationFn: () => client.delete(`/order-sets/${orderSetId ?? id}`),
    onSuccess: ({ id }) => {
      // get order-set type
      const deletedOrderSet = queryClient.getQueryData(
        orderSetsKeyFactory.detail(id).queryKey
      ) as OrderSet;

      dispatch(setDraftOrderId({ orderType: deletedOrderSet.type, id: null }));

      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });

      orderSetCtx?.onOrderSetDelete(deletedOrderSet);
    },
  });
}
