import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import _ from "lodash";
import PropTypes from "prop-types";
import { countryNames } from "src/utility/constants";

import { useInput } from "../../hooks/InputHooks";
import {
  addNewAddress,
  checkName,
  createChangeRequestAndApply,
  fetchAddressChangeRequests,
  setNameTaken,
  setUpdateSuccess,
  updateAddressById,
} from "../../redux/slices/addresses/addressSlice";
import { DatePicker } from "../Forms/DefaultInputs";
import CountrySelector from "../Utility/Selectors/CountrySelector";
import StateSelector from "../Utility/Selectors/StateSelector";
import SubstateSelector from "../Utility/Selectors/SubstateSelector";
import AddressChangeRequest from "./AddressChangeRequest";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  emailRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  changeRequest: {
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    border: `2px solid ${theme.palette.primary.light}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    textAlign: "center",
  },
}));

const DistributorModal = ({ open, type, id, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addRef = useRef(null);

  const {
    addressList,
    changeRequests,
    isChangeRequestsLoading,
    isUpdateLoading,
    isNameCheckLoading,
    isNameTaken,
    updateStatus,
    error,
  } = useSelector((state) => state.addresses);
  const { role } = useSelector((state) => state.user);

  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
    setChange: setNameChange,
    change: nameChange,
  } = useInput("");
  const {
    value: abn,
    bind: bindAbn,
    setValue: setAbn,
    reset: resetAbn,
  } = useInput("");
  const {
    value: addressOne,
    bind: bindAddressOne,
    setValue: setAddressOne,
    reset: resetAddressOne,
  } = useInput("");
  const {
    value: addressTwo,
    bind: bindAddressTwo,
    setValue: setAddressTwo,
    reset: resetAddressTwo,
  } = useInput("");
  const {
    value: attn,
    bind: bindAttn,
    setValue: setAttn,
    reset: resetAttn,
  } = useInput("");
  const {
    value: city,
    bind: bindCity,
    setValue: setCity,
    reset: resetCity,
  } = useInput("");
  const {
    value: zip,
    bind: bindZip,
    setValue: setZip,
    reset: resetZip,
  } = useInput("");
  const {
    value: phoneNumber,
    bind: bindPhoneNumber,
    setValue: setPhoneNumber,
    reset: resetPhoneNumber,
  } = useInput("");
  const {
    value: changeNote,
    bind: bindChangeNote,
    reset: resetChangeNote,
  } = useInput("");
  const {
    value: deactivationNote,
    bind: bindDeactivationNote,
    setValue: setDeactivationNote,
    reset: resetDeactivationNote,
  } = useInput("");

  const [country, setCountry] = useState("USA");
  const [state, setState] = useState(null);
  const [selectedSubstateId, setSelectedSubstateId] = useState("");
  const [hasFetchedName, setHasFetchedName] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [modalError, setModalError] = useState(false);
  const [status, setStatus] = useState("active");
  const [changeEffectiveDate, setChangeEffectiveDate] = useState(new Date());
  const [changeRequestsVisible, setChangeRequestsVistible] = useState(false);

  const handleSetCountry = (value) => {
    setState(null);
    setCountry(value);
  };

  const clearAddress = () => {
    resetName();
    resetAddressOne();
    resetAddressTwo();
    resetAttn();
    resetCity();
    resetZip();
    resetPhoneNumber();
    resetChangeNote();
    resetAbn();
    resetDeactivationNote();
    setCountry("USA");
    setState(null);
    setStatus("active");
    setHasFetchedName(false);
    dispatch(setNameTaken({ value: false, address: null }));
  };

  const handleBlur = () => {
    if (nameChange) {
      dispatch(checkName(name));
      setHasFetchedName(true);
      setNameChange(false);
    }
  };

  const handleActiveSwitch = () => {
    setStatus(status === "active" ? "inactive" : "active");
  };

  const handleSubmit = (applyNow) => {
    if (
      abn.length === 0 ||
      name.length === 0 ||
      addressOne.length === 0 ||
      city.length === 0 ||
      !state ||
      zip.length === 0 ||
      phoneNumber.length === 0 ||
      (status === "inactive" && deactivationNote.length === 0) ||
      (status === "active" &&
        type === "change-request" &&
        changeNote.length === 0)
    ) {
      setModalError("Please fill out all fields");
    } else {
      setModalError(null);
      const address = {
        name: name,
        abn: abn,
        addressOne: addressOne,
        addressTwo: addressTwo,
        attn: attn.length > 0 && attn !== "---" ? attn : null,
        city: city,
        state: state.id,
        zip: zip,
        phoneNumber:
          phoneNumber.length > 0 ? phoneNumber : "Phone Number Needed!",
        country: countryNames[country],
        territoryId: null,
        isActive: status === "active" ? true : false,
        changeNote:
          status === "active" && type === "change-request" ? changeNote : null,
        changeEffectiveDate:
          status === "active" && type === "change-request"
            ? format(changeEffectiveDate, "yyyy-MM-dd")
            : null,
        addressToChangeId:
          status === "active" && type === "change-request"
            ? currentAddress.id
            : null,
        type:
          status === "active" && type === "change-request"
            ? "change-request"
            : "distributor",
        deactivationNote: deactivationNote.length > 0 ? deactivationNote : null,
        ...(state.hasSubStateOptions &&
          selectedSubstateId && { substate: selectedSubstateId }),
      };
      if (applyNow) {
        dispatch(createChangeRequestAndApply(address));
      } else if (status === "inactive") {
        dispatch(updateAddressById(id, address));
      } else {
        dispatch(addNewAddress(address));
      }
    }
  };

  useEffect(() => {
    if (hasFetchedName && !isNameTaken && !isNameCheckLoading) {
      addRef.current.childNodes[1].firstChild.focus();
      setHasFetchedName(false);
    }
  }, [hasFetchedName, isNameTaken, isNameCheckLoading]);

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (type === "change-request" || updateStatus) &&
      (!currentAddress || currentAddress.id !== id || updateStatus)
    ) {
      let address = addressList.find((add) => add.id === id);
      if (address) {
        setCurrentAddress(address);
        setName(address.name);
        setAbn(address.distributorAbn);
        setAddressOne(address.streetAddressOne);
        setAddressTwo(address.streetAddressTwo ? address.streetAddressTwo : "");
        setAttn(address.attn);
        setCity(address.city);
        setState(address.state);
        setSelectedSubstateId(address.substate ? address.substate.id : "");
        setZip(address.zip);
        setPhoneNumber(address.phoneNumber ? address.phoneNumber : "");
        setCountry(_.invert(countryNames)[address.country] ?? address.country);
        setStatus(address.isActive === "Yes" ? "active" : "inactive");
        setDeactivationNote(address.deactivationNote ?? "");
      }
      if (
        address &&
        ["super", "purchaser", "select-purchaser"].includes(role)
      ) {
        dispatch(fetchAddressChangeRequests(address.id));
      }
    }
  }, [
    role,
    addressList,
    isUpdateLoading,
    type,
    updateStatus,
    currentAddress,
    id,
    setCurrentAddress,
    setName,
    setAbn,
    setAddressOne,
    setAddressTwo,
    setAttn,
    setCity,
    setStatus,
    setState,
    setZip,
    setPhoneNumber,
    setCountry,
    setDeactivationNote,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setModalError(error);
    }
  }, [error, setModalError]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          setModalError(false);
          clearAddress();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <div
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <DialogTitle>
            <Typography className={classes.headerText}>
              {type === "new" ? "New Address" : `Change Request for ${name}`}
            </Typography>
          </DialogTitle>
          {["super", "purchaser", "select-purchaser"].includes(role) && (
            <FormControlLabel
              style={{ marginLeft: "20px" }}
              control={
                <Switch
                  checked={status === "active"}
                  onChange={handleActiveSwitch}
                  name="active-switch"
                />
              }
              label={status === "active" ? "Active" : "Inactive"}
            />
          )}
        </div>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setModalError(false);
              clearAddress();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          {status === "inactive" &&
            currentAddress &&
            currentAddress.isActive === "Yes" && (
              <div className={classes.fullWidthCenterColumn}>
                <Typography
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  If you would like to deactivate this distributor, please
                  provide a reason why:
                </Typography>
                <TextField
                  fullWidth
                  className={classes.settingsMargin}
                  variant="outlined"
                  color="secondary"
                  name="deactivation-note"
                  type="text"
                  label="Note"
                  {...bindDeactivationNote}
                />
              </div>
            )}
          {status === "inactive" &&
            currentAddress &&
            currentAddress.isActive === "No" && (
              <div className={classes.fullWidthCenterColumn}>
                <Typography
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  {["super", "purchaser", "select-purchaser"].includes(role)
                    ? "This distributor has been deactivated, to make changes, please switch it's status to Active."
                    : "This distributor has been deactivated, if this has been done in error, please contact your administrator."}
                </Typography>
                <Typography
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  <b>Deactivated By:</b>
                  {` ${currentAddress.deactivatedBy ?? "---"}`}
                </Typography>
                <Typography
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  <b>Deactivated At:</b>
                  {` ${currentAddress.deactivatedAt ?? "---"}`}
                </Typography>
                <Typography
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  <b>Note:</b>
                  {` ${deactivationNote}`}
                </Typography>
              </div>
            )}
          {status === "active" && (
            <div className={classes.fullWidthCenterColumn}>
              {["super", "purchaser", "select-purchaser"].includes(role) &&
                currentAddress &&
                changeRequests &&
                changeRequests.length > 0 && (
                  <>
                    {currentAddress.hasChangeRequests && (
                      <Typography
                        className={clsx(
                          classes.bodyText,
                          classes.settingsMargin
                        )}
                      >
                        This address has pending change requests.
                      </Typography>
                    )}
                    <div className={classes.fullWidthCenterColumn}>
                      <Button
                        className={classes.largeButton}
                        variant="contained"
                        color="secondary"
                        id="change-request-toggle"
                        onClick={() =>
                          setChangeRequestsVistible(!changeRequestsVisible)
                        }
                      >
                        {isChangeRequestsLoading ? (
                          <CircularProgress size={28} />
                        ) : changeRequestsVisible ? (
                          "HIDE CHANGE REQUESTS"
                        ) : currentAddress.hasChangeRequests ? (
                          "VIEW CHANGE REQUESTS"
                        ) : (
                          "VIEW CHANGE REQUEST HISTORY"
                        )}
                      </Button>
                      <br />
                    </div>
                  </>
                )}
              {["super", "purchaser", "select-purchaser"].includes(role) &&
                changeRequestsVisible && (
                  <>
                    <br />
                    <Typography className={classes.headerText}>
                      Current Address:
                    </Typography>
                    <br />
                    <Typography className={classes.bodyText}>
                      {currentAddress.name}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>Street Address One: </b>
                      {currentAddress.streetAddressOne}
                    </Typography>
                    {currentAddress.streetAddressTwo && (
                      <Typography className={classes.bodyText}>
                        <b>Street Address Two: </b>
                        {currentAddress.streetAddressTwo}
                      </Typography>
                    )}
                    {currentAddress.defaultAttn && (
                      <Typography className={classes.bodyText}>
                        <b>Attention: </b>
                        {currentAddress.defaultAttn}
                      </Typography>
                    )}
                    <Typography className={classes.bodyText}>
                      <b>Phone Number: </b>
                      {currentAddress.phoneNumber}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>City: </b>
                      {currentAddress.city}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>Country: </b>
                      {currentAddress.country}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>State: </b>
                      {currentAddress.stateCode}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>State: </b>
                      {currentAddress.subState}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      <b>Zip: </b>
                      {currentAddress.zip}
                    </Typography>
                    <br />
                    {changeRequests.map((cr) => (
                      <AddressChangeRequest
                        key={cr.id}
                        classes={classes}
                        changeRequest={cr}
                        address={currentAddress}
                      />
                    ))}
                    <br />
                  </>
                )}
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="name"
                type="text"
                label="Name / Reference Id"
                onBlur={(evt) => handleBlur(evt)}
                error={isNameTaken}
                helperText={isNameTaken ? "Address name already in use" : null}
                disabled={isNameCheckLoading}
                {...bindName}
              />
              <TextField
                ref={addRef}
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="abn"
                type="text"
                label="ABN"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindAbn}
              />
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="address-one"
                type="text"
                label="Address Line One"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindAddressOne}
              />
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="address-two"
                type="text"
                label="Address Line Two"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindAddressTwo}
              />
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="attn"
                type="text"
                label="Attention"
                disabled={name.length === 0}
                {...bindAttn}
              />
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="phone-number"
                type="text"
                label="Phone Number"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindPhoneNumber}
              />
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="city"
                type="text"
                label="City"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindCity}
              />

              <StateSelector
                setState={setState}
                state={state}
                countryFilter={country}
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
              />

              {state?.hasSubStateOptions && (
                <div style={{ marginTop: 15, width: "100%" }}>
                  <SubstateSelector
                    selectedSubstateId={selectedSubstateId}
                    setSelectedSubstateId={setSelectedSubstateId}
                    state={state}
                    setError={setModalError}
                    disabled={
                      isNameCheckLoading || isNameTaken || name.length === 0
                    }
                  />
                </div>
              )}

              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="zip"
                type="text"
                label="Zip Code"
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
                {...bindZip}
              />
              <CountrySelector
                currentCountry={country}
                setCurrentCountry={handleSetCountry}
                role={role}
                disabled={
                  isNameCheckLoading || isNameTaken || name.length === 0
                }
              />
              {type === "change-request" && (
                <>
                  <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <DatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="change-request-date"
                      label="Effective Date"
                      value={changeEffectiveDate}
                      onChange={(value) => {
                        setChangeEffectiveDate(new Date(value));
                      }}
                    />
                  </div>

                  <TextField
                    fullWidth
                    className={classes.settingsMargin}
                    variant="outlined"
                    color="secondary"
                    name="changeNote"
                    type="text"
                    label="Change Note"
                    disabled={
                      isNameCheckLoading || isNameTaken || name.length === 0
                    }
                    {...bindChangeNote}
                  />
                </>
              )}
              <br />
              {isNameTaken && (
                <>
                  <Typography className={classes.bodyText}>
                    Address Name Already Taken
                  </Typography>
                  <br />
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => clearAddress()}
                  >
                    CLEAR ADDRESS
                  </Button>
                  <br />
                </>
              )}
            </div>
          )}

          {!(
            status === "inactive" &&
            ["field1", "field2", "compliance"].includes(role)
          ) && (
            <div className={classes.fullWidthCenterColumn}>
              {!isUpdateLoading && modalError && (
                <Typography
                  className={clsx(classes.settingsMargin, classes.bodyText)}
                  style={{ color: "#920000" }}
                >
                  {modalError}
                </Typography>
              )}
              {!isUpdateLoading && updateStatus && (
                <Typography
                  className={clsx(classes.settingsMargin, classes.bodyText)}
                >
                  {type === "new"
                    ? "New Address Added Successfully!"
                    : type === "change-request"
                    ? "Change Request Submitted!"
                    : "Update Successful!"}
                </Typography>
              )}
              <div className={classes.fullWidthSpaceAround}>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  id="profile"
                  onClick={() => handleSubmit(false)}
                  disabled={isNameTaken}
                >
                  {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
                </Button>
                {["super", "purchaser", "select-purchaser"].includes(role) &&
                  status === "active" &&
                  type === "change-request" && (
                    <Button
                      className={classes.largeButton}
                      variant="contained"
                      color="secondary"
                      id="profile"
                      onClick={() => handleSubmit(true)}
                      disabled={
                        isNameTaken ||
                        !isSameDay(new Date(), changeEffectiveDate)
                      }
                    >
                      {isUpdateLoading ? (
                        <CircularProgress />
                      ) : (
                        "SUBMIT AND APPLY CHANGES"
                      )}
                    </Button>
                  )}
              </div>
              <br />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

DistributorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default DistributorModal;
