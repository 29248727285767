/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@mui/icons-material";
import {
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import PropTypes from "prop-types";

import ScrollIntoView from "./Utility/ScrollIntoView";

const CollapsibleTableRow = ({ row, content, expanded, onClickRow }) => {
  const [open, setOpen] = useState(false);
  // Only render content if it's been expanded
  const [renderContent, setRenderContent] = useState(!!expanded);

  const handleToggle = () => {
    setOpen(!open);
    setRenderContent(true);
  };

  useEffect(() => {
    if (expanded) {
      setOpen(true);
      setRenderContent(true);
    }
  }, [expanded]);

  return (
    <>
      <TableRow hover={onClickRow ? true : false} onClick={onClickRow}>
        <TableCell component="th" scope="row" width={"1px"}>
          {!!content && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleToggle();
              }}
            >
              {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {row.map(({ cell, ...props }, i) => (
          <TableCell key={i} {...props}>
            {cell}
          </TableCell>
        ))}
      </TableRow>
      {content && renderContent && (
        <TableRow>
          <TableCell tw="p-0" colSpan="100%">
            {!!expanded && <ScrollIntoView trigger={expanded} />}
            <Collapse in={open} timeout="auto" tw="overflow-hidden w-full">
              <div tw="py-4 px-6 shadow-inner">{content}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

CollapsibleTableRow.propTypes = {
  row: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.any.isRequired,
    })
  ),
  content: PropTypes.element,
};

const CollapsibleTable = ({ headers, rows, onClickRow }) => {
  return (
    <TableContainer component={Card}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {headers.map(({ cell, ...props }, i) => (
              <TableCell key={i} {...props}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ row, content, expanded, key, ...props }, i) => (
            <CollapsibleTableRow
              key={key ?? i}
              row={row}
              expanded={expanded}
              content={content}
              onClickRow={onClickRow}
              {...props}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CollapsibleTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.any,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.arrayOf(
        PropTypes.shape({
          cell: PropTypes.any,
        })
      ),
      content: PropTypes.any,
    })
  ),
};

export default CollapsibleTable;
