import addDays from "date-fns/addDays";
import format from "date-fns/format";
import getYear from "date-fns/getYear";

import {
  displayId,
  formatDateString,
  stringToCents,
} from "../../../utility/utilityFunctions";
import {
  handleImages,
  handleVariants,
  mapSpecifications,
} from "../../mapHelpers";

export const mapPOChangeNote = (n) => ({
  id: n.id,
  name: n["user-name"],
  note: n.message,
  updatedAt: n["updated-at"],
});

const mapPOChangeNotes = (notes) => {
  if (!notes || notes.length === 0) return [];

  return notes.map(mapPOChangeNote);
};

export const mapPOVariants = (variants) => {
  let isPriceCompliant = true;
  let totalCost = 0;
  let totalGalloCost = 0;
  let allocatedTerritory = null;
  const mappedVariants = variants.map((variant) => {
    if (!variant["is-price-compliant"] && !variant["is-direct-cost"]) {
      isPriceCompliant = false;
    }
    if (variant["allocated-territory-name"]) {
      allocatedTerritory = variant["allocated-territory-name"];
    }
    const totalActualItemCost =
      stringToCents(variant["actual-cost"]) * variant.qty;
    const totalGalloItemCost =
      stringToCents(variant["gallo-cost"]) * variant.qty;
    totalCost += totalActualItemCost;
    totalGalloCost += totalGalloItemCost;
    if (variant["is-direct-cost"]) {
      return {
        id: variant.id,
        itemId: "---",
        itemNumber: "---",
        program: "---",
        itemType: "Set Up Fee",
        itemDescription: variant["direct-cost-desc"],
        packSize: "1",
        itemSpec: "---",
        totalQty: variant.qty,
        estCost: "---",
        actCost: stringToCents(variant["actual-cost"]),
        galloCost: stringToCents(variant["gallo-cost"]),
        totalCost: totalActualItemCost,
        totalGalloCost: totalGalloItemCost,
        isPriceCompliant: true,
        isMetalOrWood: false,
        packout: false,
        isTerritoryAllocated: false,
        variantName: "---",
      };
    } else {
      return {
        id: variant.id,
        itemId: variant.variant.id,
        itemNumber: variant.variant["variant-sku"],
        displayId: displayId(
          variant.variant["variant-sku"],
          variant.variant["run-number"]
        ),
        program:
          variant["program-names"].length > 0
            ? variant["program-names"]
            : "---",
        itemType: variant["item-type-description"],
        itemDescription: variant["item-comment"]
          ? variant["item-comment"]
          : "---",
        packSize: variant["actual-qty-per-pack"],
        itemSpec: variant["item-specification"]
          ? mapSpecifications(variant["item-specification"], "supplier-display")
          : null,
        totalQty: variant.qty,
        estCost: stringToCents(variant["item-estimated-cost"]),
        actCost: stringToCents(variant["actual-cost"]),
        galloCost: stringToCents(variant["gallo-cost"]),
        totalCost: totalActualItemCost,
        totalGalloCost: totalGalloItemCost,
        isPriceCompliant: variant["is-price-compliant"],
        packOut: variant["has-packout"] ? variant["has-packout"] : false,
        isMetalOrWood: variant["item-is-metal-or-wood"] ? true : false,
        isCoupon: variant["item-is-coupon"],
        allocatedTerritory: variant["allocated-territory-name"]
          ? variant["allocated-territory-name"]
          : null,
        couponStartDate: variant["item-coupon-issue-date"]
          ? formatDateString(variant["item-coupon-issue-date"])
          : null,
        couponExpirationDate: variant["item-coupon-expiration-date"]
          ? formatDateString(variant["item-coupon-expiration-date"])
          : null,
        couponOfferType: variant["item-coupon-offer-type-code"],
        couponOfferDescription: variant["item-coupon-offer-description"],
        couponTypeCode: variant["item-coupon-type-code"],
        variantName: handleVariants([variant.variant])[0].name,
      };
    }
  });
  return {
    variants: mappedVariants,
    compliant: isPriceCompliant,
    totalCost,
    totalGalloCost,
    allocatedTerritory,
  };
};

export const mapPOShippingParamVariants = (variants) => {
  let currentYear = getYear(new Date()).toString().slice(2);
  const mappedVariants = variants.map((variant) => ({
    id: variant.id,
    itemNumber: variant.variant["variant-sku"],
    displayId: displayId(
      variant.variant["variant-sku"],
      variant.variant["run-number"]
    ),
    itemType: variant["item-type-description"]
      ? variant["item-type-description"]
      : "---",
    totalQty: variant.qty,
    shipFromZip: variant["ship-from-zip"] ? variant["ship-from-zip"] : "---",
    carrier:
      variant["tracking-data"] && variant["tracking-data"].carrier
        ? variant["tracking-data"].carrier
        : "---",
    method: variant.method ? variant.method : "---",
    actShipDate: variant["actual-ship-date"]
      ? formatDateString(variant["actual-ship-date"])
      : "---",
    shippedQuantity: variant["shipped-qty"] ? variant["shipped-qty"] : "---",
    packageCount: variant["package-count"] ? variant["package-count"] : "---",
    packageType: variant["package-type"] ? variant["packageType"] : "---",
    expectedArrival: variant["expected-arrival-date"]
      ? variant["expected-arrival-date"]
      : "---",
    shippingLabel: variant["custom-shipping-label"]
      ? variant["custom-shipping-label"]
      : variant["shipping-label"]
      ? `${variant["shipping-label"].title} - ${variant["shipping-label"].desc} - ${currentYear}-${variant["shipping-label"].code}`
      : "---",
    trackingNum:
      variant["tracking-data"] && variant["tracking-data"].number
        ? variant["tracking-data"].number
        : "---",
    shipHoldStatus: variant["compliance-status"],
    isPriceCompliant: variant["is-price-compliant"],
    isComplianceCanceled:
      variant.status === "canceled" &&
      variant["cancelation-type"] === "compliance",
    isCanceled:
      variant.status === "canceled" && variant["cancelation-type"] === "order",
    isDestroyed: variant.status === "destroyed",
    tax: variant.tax ? stringToCents(variant.tax) : "---",
    variantName: handleVariants([variant.variant])[0].name,
  }));
  return mappedVariants;
};

export const mapPOShippingParams = (params) => {
  const formatAddress = (shipObj) => {
    let addOne = shipObj["street-address-1"];
    let addTwo = shipObj["street-address-2"]
      ? shipObj["street-address-2"]
      : false;
    let city = shipObj.city;
    let state = shipObj.state.code;
    let country = shipObj.country;
    let zip = shipObj.zip;
    return [addOne, addTwo, city, state, zip, country]
      .filter((address) => address)
      .join(", ");
  };
  const mappedParams = params.map((param) => {
    const handleCompStatus = (statusArray) => {
      if (statusArray.includes("denied")) {
        return "denied";
      } else if (statusArray.includes("prior-approval-pending")) {
        return "prior-approval-pending";
      } else {
        return "ok";
      }
    };
    const handlePriceStatus = (statusArray) => {
      return statusArray.filter((stat) => !stat).length > 0;
    };
    const handleDestroyedStatus = (statusArray) => {
      return statusArray.filter((stat) => stat).length > 0;
    };
    const handleCanceledStatus = (statusArray) => {
      return statusArray.filter((stat) => stat).length > 0;
    };
    let paramVariants = mapPOShippingParamVariants(
      param["shipping-parameter-variants"]
    );
    let carriers = [...new Set(paramVariants.map((item) => item.carrier))].join(
      ", "
    );
    let paramTaxArray = paramVariants
      .map((item) => item.tax)
      .filter((tax) => tax !== "---");
    let totalParamTax =
      paramTaxArray.length > 0 ? paramTaxArray.reduce((a, b) => a + b) : 0;
    return {
      id: param.id,
      addressName: param.address ? param.address.name.replace(/,/g, "") : "---",
      distributorAbn: param.address ? param.address.abn : "---",
      isAddressActive: param.address ? param.address["is-active"] : true,
      name: param.name ? param.name.replace(/,/g, "") : "---",
      attn: param.attn ? param.attn : "---",
      address: formatAddress(param),
      addressOne: param["street-address-1"],
      addressTwo: param["street-address-2"] ? param["street-address-2"] : "---",
      city: param.city,
      state: param.state.code,
      zip: param.zip,
      phoneNumber: param["phone-number"] ?? "---",
      country: param.country,
      carrier: carriers,
      method: param.method ? param.method : "---",
      orderNote: param.notes ? param.notes : "---",
      actualShip: param["actual-ship-date"] ? param["actual-ship-date"] : "---",
      variants: paramVariants,
      isKeyAccount: param.territory && param.territory.type === "Customer",
      territoryNames: param["territory-names"],
      isDirectShip: param["is-direct-ship"],
      keyAccountName:
        param.territory && param.territory.type === "Customer"
          ? param.territory.name
          : "---",
      shipHoldStatus: handleCompStatus(
        paramVariants.map((item) => item.shipHoldStatus)
      ),
      hasPriceViolation: handlePriceStatus(
        paramVariants.map((item) => item.isPriceCompliant)
      ),
      isDestroyed: handleDestroyedStatus(
        paramVariants.map((item) => item.isDestroyed)
      ),
      isComplianceCanceled: handleCanceledStatus(
        paramVariants.map((item) => item.isComplianceCanceled)
      ),
      isCanceled: handleCanceledStatus(
        paramVariants.map((item) => item.isCanceled)
      ),
      isRedirect: param["is-redirect"],
      redirectedBy: param["redirected-by"]?.name ?? null,
      redirectNote: param["redirect-note"] ?? null,
      tax: totalParamTax,
      warehouse: param.warehouse,
    };
  });
  return mappedParams;
};

export const mapPurchaseOrder = (purchaseOrder) => {
  const params = mapPOShippingParams(purchaseOrder["shipping-parameters"]);
  const variantDetail = mapPOVariants(purchaseOrder["purchase-order-variants"]);
  const changeNotes = mapPOChangeNotes(purchaseOrder.notes);
  const formattedPO = {
    id: purchaseOrder.id,
    type: purchaseOrder.type,
    brand: purchaseOrder["brand-names"],
    status: purchaseOrder.status,
    accepted: false,
    inMarketDate: purchaseOrder["in-market-date"]
      ? formatDateString(purchaseOrder["in-market-date"])
      : addDays(new Date(), 120),
    invoiceDate: purchaseOrder["invoice-date"],
    deliverToWarehouseDate: purchaseOrder["deliver-to-warehouse-date"]
      ? formatDateString(purchaseOrder["deliver-to-warehouse-date"])
      : null,
    expectedShip: purchaseOrder["expected-ship-date"]
      ? purchaseOrder["expected-ship-date"]
      : addDays(new Date(), 90),
    invoiceNum: purchaseOrder["invoice-number"]
      ? purchaseOrder["invoice-number"]
      : "",
    supplierReference: purchaseOrder["supplier-reference"] ?? "",
    cdcTrackingNumber: purchaseOrder["cdc-tracking-number"] ?? "",
    terms: purchaseOrder.terms ? purchaseOrder.terms : "Net 30 Days",
    supplier: purchaseOrder?.supplier?.name ?? "---",
    supplierId: purchaseOrder?.supplier?.id,
    isPurchaserSelect:
      purchaseOrder?.supplier?.["is-purchaser-select"] ?? false,
    contactName: purchaseOrder?.supplier?.contact ?? "---",
    email: purchaseOrder?.supplier?.email ?? "---",
    phone: purchaseOrder?.supplier?.phone ?? "---",
    purchasedBy: purchaseOrder?.purchaser?.name ?? "---",
    method: purchaseOrder.method ? purchaseOrder.method : "",
    includeBeacon: purchaseOrder["include-beacon"] ?? false,
    additionalFreightInvoiceNumber:
      purchaseOrder["additional-freight-invoice-number"] ?? null,
    additionalFreightCost: purchaseOrder["additional-freight-cost"]
      ? stringToCents(purchaseOrder["additional-freight-cost"])
      : null,
    supplierNotes: purchaseOrder.note ? purchaseOrder.note : "",
    changeNotes: changeNotes,
    keyAcctTape: purchaseOrder["key-account-tape"]
      ? purchaseOrder["key-account-tape"]
      : "",
    additionalFile: purchaseOrder["additional-file-cloudinary-id"]
      ? purchaseOrder["additional-file-cloudinary-id"]
      : null,
    poVariants: variantDetail.variants,
    baseFreight: stringToCents(purchaseOrder["base-freight-cost"]),
    baseGalloFreight: stringToCents(purchaseOrder["gallo-base-freight-cost"]),
    totalCost: variantDetail.totalCost,
    totalGalloCost: variantDetail.totalGalloCost,
    isDirectShip: purchaseOrder["is-direct-ship"],
    submittedDate: purchaseOrder["submitted-at"]
      ? format(new Date(purchaseOrder["submitted-at"]), "MM/dd/yyyy")
      : "---",
    sentToFlowAt: purchaseOrder["sent-to-flow-at"]
      ? format(new Date(purchaseOrder["sent-to-flow-at"]), "MM/dd/yyyy")
      : null,
    shippingParams: params,
    shippingLabel: [
      ...new Set(
        [].concat.apply(
          [],
          params.map((param) =>
            param.variants.map((v) => `${v.itemNumber}: ${v.shippingLabel}`)
          )
        )
      ),
    ],
    isPriceCompliant: variantDetail.compliant,
    allocatedTerritory: variantDetail.allocatedTerritory,
    onShipHold:
      params.filter(
        (param) =>
          param.shipHoldStatus === "prior-approval-pending" ||
          param.shipHoldStatus === "denied"
      ).length > 0,
    totalTax: params.map((param) => param.tax).reduce((a, b) => a + b),
    accrualYear: purchaseOrder["accrual-year"],
    hasBrokerFreight: purchaseOrder["has-broker-freight"],
    actualBeaconTotalCost: stringToCents(
      purchaseOrder["actual-beacon-total-cost"]
    ),
    externalSapId: purchaseOrder["external-sap-id"],
    programName: purchaseOrder["program-name"] ?? "---",
    receivedByWarehouse: purchaseOrder["received-by-warehouse"],
  };
  return formattedPO;
};

export const mapBids = (bids) => {
  return bids.map((bid) => ({
    id: bid.id,
    status: bid.status,
    supplierId: bid.supplier ? bid.supplier.id : bid.id,
    note: bid.note ? bid.note : "",
    price: bid.price ? stringToCents(bid.price) : 0,
  }));
};

export const mapRFP = (rfp) => {
  const images = handleImages(rfp.item.images);
  let mappedRFP = {
    id: rfp.id,
    status: rfp.status ? rfp.status : "Pending",
    dueDate: rfp["due-date"] ? formatDateString(rfp["due-date"]) : "---",
    inMarketDate: rfp.program?.["start-date"]
      ? formatDateString(rfp.program["start-date"])
      : "---",
    bids: mapBids(rfp.bids),
    program: rfp.program.name,
    brand: rfp.item.brands.map((brand) => brand.name).join(", "),
    itemType: rfp.item.type,
    itemDescription: rfp.item.comment ? rfp.item.comment : "---",
    projectNum: rfp.item["at-task-project-id"],
    itemNumber: rfp.item["item-number"],
    totalQty: rfp.qty,
    estCost: stringToCents(rfp.item["estimated-cost"]),
    totalEstCost: rfp.qty * stringToCents(rfp.item["estimated-cost"]),
    supplierNote: rfp.note ? rfp.note : "",
    itemSpec: rfp.item.specification
      ? mapSpecifications(rfp.item.specification, "supplier-display")
      : null,
    imgUrlThumb: images.imgUrlThumb,
    imgUrlLg: images.imgUrlLg,
  };
  return mappedRFP;
};

export const mapRFPHistory = (rfps) => {
  let mappedRFPHistory = rfps.map((rfp) => mapRFP(rfp));
  return mappedRFPHistory;
};
