import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  staticModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    minHeight: "200px",
    textAlign: "center",
  },
}));

const CancelInfoModal = ({ open, handleClose, info }) => {
  const classes = useStyles();

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.staticModal}>
            <br />
            <Typography className={classes.headerText}>
              Cancelation Reason:
            </Typography>
            <br />
            <Typography className={classes.bodyText}>{info}</Typography>
            <br />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CancelInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  info: PropTypes.string.isRequired,
};

export default CancelInfoModal;
