/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch } from "react-redux";

import { keepPreviousData } from "@tanstack/react-query";
import { useNoFetch } from "src/hooks/UtilityHooks";
import { openRFQModal } from "src/redux/slices/planningTool/rfqSlice";

import RfqModal from "@components/PlanningTool/RFQ/RfqModal";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { PlanningRFQsTable, usePaginatedRFQsQuery } from "@features/rfqs";
import { Button, PageTitle } from "@features/ui";

const PlanningRFQs = () => {
  const [filters] = useFilterParams();
  const dispatch = useDispatch();
  const params = {
    filter: {
      searchTerm: filters.q,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
      businessUnitNames: filters.businessUnits,
    },
    sort: "-id",
  };
  const tableData = usePaginatedRFQsQuery(params, {
    placeholderData: keepPreviousData,
  });
  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <RfqModal />
      <div tw="flex justify-between items-center gap-3 flex-wrap">
        <PageTitle title="RFQs" />
        <div tw="flex gap-3">
          <Button
            onClick={() => dispatch(openRFQModal({}))}
            variant="contained"
            color="secondary"
          >
            New RFQ
          </Button>
        </div>
      </div>
      <TableCardContainer>
        <Filters
          searchTitle="Search RFQs"
          slots={["brands", "businessUnits", "itemTypes"]}
          alwaysShow={["brands"]}
        />
        <PlanningRFQsTable {...tableData} />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default PlanningRFQs;
