import { kebabCaseKeys } from "src/utility/utilityFunctions";

export const buildAllocationPatch = (id, terrId) => {
  return {
    data: {
      type: "purchase-order-variant",
      id: +id,
      attributes: {
        "allocated-territory-id": terrId,
      },
    },
  };
};

export const buildPurchaseOrderPatch = (id, field, value) => {
  return {
    data: {
      type: "purchase-order",
      id: +id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildUpdateSupplierPatch = (id, supplierId) => {
  return {
    data: {
      type: "purchase-order",
      id: +id,
      relationships: {
        supplier: {
          data: supplierId
            ? {
                type: "supplier",
                id: supplierId,
              }
            : null,
        },
      },
    },
  };
};

export const buildAdditionalFreightPatch = (id, invoiceNumber, cost) => {
  return {
    data: {
      type: "purchase-order",
      id: id,
      attributes: {
        "additional-freight-invoice-number": invoiceNumber,
        "additional-freight-cost": cost,
      },
    },
  };
};

export const buildPOChangeNote = (id, note) => {
  return {
    data: {
      type: "note",
      attributes: {
        message: note,
      },
      relationships: {
        "purchase-order": {
          data: {
            type: "purchase-order",
            id: +id,
          },
        },
      },
    },
  };
};

export const buildNotePatch = (id, note) => {
  return {
    data: {
      type: "note",
      id: id,
      attributes: {
        message: note,
      },
    },
  };
};

export const buildSendToFlowPost = (id, jobId) => {
  return {
    data: {
      id: +id,
      "flow-project-solution-id": +jobId.trim(),
    },
  };
};

export const buildShippingParameterPatch = (id, field, value) => {
  return {
    data: {
      type: "shipping-parameter",
      id: id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildShippingParameterUpdate = (
  paramId,
  addressId,
  attributes
) => {
  return {
    data: {
      type: "shipping-parameter",
      id: paramId,
      attributes: kebabCaseKeys(attributes),
      ...(addressId && {
        relationships: {
          address: {
            data: {
              type: "address",
              id: +addressId,
            },
          },
        },
      }),
    },
  };
};

export const buildRequestForPricePatch = (id, field, value) => {
  return {
    data: {
      type: "request-for-price",
      id: +id,
      attributes: {
        [`${field}`]: value,
      },
    },
  };
};

export const buildSendBidPost = (id, idArray) => {
  const formattedIds = idArray.map((i) => +i);
  return {
    data: {
      id: +id,
      type: "request-for-price",
      supplier_ids: formattedIds,
    },
  };
};

export const buildBidPatch = (id, price, note) => {
  return {
    data: {
      type: "bid",
      id: id,
      attributes: {
        price: price,
        note: note,
      },
    },
  };
};
