import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { PurchaseOrder } from "@models/PurchaseOrder";
import client from "@services/api";

export const purchaseOrdersKeyFactory = createQueryKeys("purchase-orders", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client
        .get<PurchaseOrder>(`purchase-orders/${id}`)
        .then((res) => res.data),
  }),
});

export const usePurchaseOrderQuery = (id: string | null) => {
  return useQuery({
    ...purchaseOrdersKeyFactory.detail(id!),
    staleTime: 1000 * 60 * 5,
    enabled: !!id,
  });
};
