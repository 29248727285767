/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef } from "react";

const ScrollIntoView = ({ trigger }) => {
  const scrollRef = useRef(null);
  const scrollContainer = useRef(document.querySelector(".body"));

  useEffect(() => {
    setTimeout(() => {
      if (!scrollRef.current) return;
      const y = scrollRef.current.getBoundingClientRect().top - 200;
      scrollContainer.current.scrollTo({ top: y, behavior: "smooth" });
    }, 100);
  }, [trigger]);

  return <div ref={scrollRef} />;
};

export default ScrollIntoView;
