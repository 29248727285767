import { formatMoneyString } from "src/utility/utilityFunctions";

import { Budget } from "@models/Budget";
import { CsvSchema } from "@utils/csv";

const budgetSummarySchema: CsvSchema<Budget> = [
  { id: "id", label: "ID" },
  {
    id: "externalId",
    label: "External ID",
  },
  {
    id: "_brand",
    label: "Brand",
    f: (_, budget) => budget.brandExternalId + " " + budget.brandName,
  },
  {
    id: "_subState",
    label: "State",
    f: (_, budget) =>
      budget.subStateCode === budget.subStateName
        ? budget.subStateCode
        : budget.subStateCode + " " + (budget.subStateName ?? ""),
  },
  { id: "year", label: "year" },
  {
    id: "totalAmount",
    label: "Initial Amount",
    f: formatMoneyString,
  },
  {
    id: "availableAmount",
    label: "Available Amount",
    f: formatMoneyString,
  },
];

export default budgetSummarySchema;
