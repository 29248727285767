/** @jsxImportSource @emotion/react */
import "twin.macro";

import { PaginatedResourceProps, SmartTable } from "@components/SmartTable";
import StatusChip from "@components/Utility/StatusChip";
import { Quote } from "@models/Quote";

type QuotesTableProps = PaginatedResourceProps & {
  data: Quote[];
};

const statusMap = {
  draft: "",
  sent: "warning",
  accepted: "info",
  complete: "success",
  canceled: "error",
};

const QuotesTable = ({ data, ...props }: QuotesTableProps) => {
  const to = (q) => `/quotes/${q.id}`;
  return (
    <SmartTable
      {...props}
      rows={data}
      columns={[
        { id: "supplierQuoteNumber", label: "Quote Number", to },
        { id: "itemNumber", label: "Seq #", to },
        { id: "brands", label: "Brand(s)", to },
        { id: "itemType", label: "Item Type", to },
        { id: "itemDesc", label: "Item Desc.", to },
        {
          id: "status",
          label: "Status",
          render: (v) => (
            <StatusChip
              status={statusMap[v]}
              label={v === "sent" ? "new" : v}
              size="small"
            />
          ),
        },
        { id: "round", label: "Quote Round" },
      ]}
    />
  );
};

export default QuotesTable;
