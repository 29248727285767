/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { addDays } from "date-fns";
import _ from "lodash";
import { utcDate } from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";
import { useCreateFulfillmentPurchaseOrderMutation } from "@features/purchaseOrders";
import { Button } from "@features/ui";
import { ItemRollup } from "@models/ItemRollup";
import { OrderVariant } from "@models/OrderVariant";

import { DatePicker } from "../Forms/DefaultInputs";
import OrderVariantList from "./OrderVariantList";

const FulfillFromInventoryModal = ({
  handleClose,
  itemRollups,
}: {
  handleClose: () => void;
  itemRollups: ItemRollup[];
}) => {
  const setError = useMutateError();
  const navigate = useNavigate();
  const createFulfillmentPOMutation =
    useCreateFulfillmentPurchaseOrderMutation();

  const {
    inMarketDate: dateFromItemRollup,
    orderProgram: { id: programId },
    itemNumber,
  } = itemRollups[0];
  const orderVariantIdsByVariant = _(itemRollups)
    .groupBy("variant.id")
    .mapValues((irs) => irs.flatMap((ir) => ir.orderVariantIds))
    .value();

  const [valid, setValid] = useState<boolean[]>(
    new Array(Object.keys(orderVariantIdsByVariant).length).fill(true)
  );
  const [selectedOvs, setSelectedOvs] = useState<OrderVariant[]>([]);
  const [inMarketDate, setInMarketDate] = useState<Date | null>(
    _.isDate(utcDate(dateFromItemRollup))
      ? utcDate(dateFromItemRollup)
      : addDays(new Date(), 5)
  );

  const handleCreateFulfillmentPurchaseOrder = async () => {
    const ovIds = _.map(selectedOvs, "id");
    createFulfillmentPOMutation.mutate(
      {
        orderVariantIds: ovIds,
        inMarketDate: inMarketDate!,
        programId: programId,
      },
      {
        onSuccess: (po) => navigate(`/purchasing/purchase-orders/${po.id}`),
        onError: (e) => setError(e, "Create fulfillment PO"),
      }
    );
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle tw="flex justify-between items-center">
        Creating Fulfillment PO for #{itemNumber.split("-")[0]}
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-6">
        {Object.entries(orderVariantIdsByVariant).map(
          ([vId, orderVariantIds], i) => (
            <OrderVariantList
              key={vId}
              singleItemRollup={
                itemRollups.find((ir) => ir.variant.id === vId)!
              }
              orderVariantIds={orderVariantIds}
              selectedOvs={selectedOvs}
              setSelectedOvs={setSelectedOvs}
              setValid={(val) =>
                setValid((valid) =>
                  valid.map((v, idx) => (idx === i ? val : v))
                )
              }
            />
          )
        )}
      </DialogContent>
      <DialogActions tw="justify-between px-6">
        <DatePicker
          label="In Market Date"
          disablePast
          value={inMarketDate}
          onChange={setInMarketDate}
        />
        <Button
          variant="contained"
          onClick={handleCreateFulfillmentPurchaseOrder}
          loading={createFulfillmentPOMutation.isPending}
          disabled={
            selectedOvs.length === 0 || valid.some((v) => !v) || !inMarketDate
          }
        >
          Create Fulfillment PO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FulfillFromInventoryModal;
