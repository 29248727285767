/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Alert } from "@mui/material";

import _ from "lodash";

const FormErrors = ({ errors, onClose }) => {
  const prettyErrors = (Object.entries(errors) as [string, any]).map(
    ([field, { message }]) =>
      `${_.startCase(field.replace(/Ids?$/i, ""))} ${message ?? "Is required"}`
  );
  if (prettyErrors.length === 0) return null;
  return (
    <Alert severity="error" onClose={onClose}>
      <ul>
        {prettyErrors.map((err) => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    </Alert>
  );
};

export default FormErrors;
