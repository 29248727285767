import format from "date-fns/format";

import { camelCaseKeys } from "../../../utility/utilityFunctions";
import { mapSpecifications } from "../../mapHelpers";
import { mapBrands } from "../items/maps";

export const mapProgram = (program) => ({
  id: program.id,
  createdBy: program["created-by"],
  channel: program.channel,
  name: program.name,
  brand: mapBrands(program.brands),
  clonedFromProgram: program["cloned-from-program"],
  orderType: program["order-type"],
  externalId: program["external-id"],
  externalWorkfrontId: program["external-workfront-id"],
  externalWorkfrontEprId: program["external-workfront-epr-id"],
  sponsorTerritoryId: program["sponsor-territory"]?.id,
  programTypeId: program["program-type"]?.id,
  type: program.type,
  territories: program.territories,
  status: program.status,
  orderCalendarMonthId: program["order-calendar-month"]?.id,
  orderCalendarMonth: program["order-calendar-month"]
    ? camelCaseKeys(program["order-calendar-month"])
    : null,
  isTerritoryExclusive: program["is-territory-exclusive"]
    ? program["is-territory-exclusive"]
    : program.territories.length > 0
    ? true
    : false,
  isBrandFunded: program["is-brand-funded"],
  // sendToContentHub: program["send-to-content-hub"],
  endDate: program["end-date"],
  startDate: program["start-date"],
  cprSubmissionUrl: program["cpr-submission-url"],
  workfrontUrl: program.workfrontUrl,
  anaplanProgramCodes: program["anaplan-program-codes"] ?? "",
  // "program" or "item"
  brandAssignmentLevel: program["brand-assignment-level"],
});

export const mapAnaplanProgram = (program) => ({
  channel: program.channel,
  programTypeId: program["program-type"]?.id,
  code: program.code,
  id: program.id,
  name: program.name,
  type: program.type,
  startDate: program["start-date"],
  endDate: program["end-date"],
});

export const mapAnaplanPrograms = (programs) => programs.map(mapAnaplanProgram);

export const mapCost = (cost) => ({
  id: cost.id,
  costType: cost["cost-type"],
  name: cost.name,
  amount: (+cost.amount).toFixed(2),
});

export const mapPricingTierQuote = (tier) => ({
  id: tier.id,
  price: tier.cost,
  qty: tier["pricing-tier-qty"],
  leadTimeInDays: tier["lead-time-in-days"],
  pricingTierId: tier["pricing-tier"].id,
});

export const mapDetailedQuote = (quote, cloudinaryFiles = []) => ({
  id: quote.id,
  supplierId: quote.supplier.id,
  supplierQuoteNumber: quote["supplier-quote-number"],
  status: quote.status,
  brands: quote["brand-names"],
  supplierName: quote["supplier-name"],
  updatedAt: quote["updated-at"],
  createdAt: quote["inserted-at"],
  rfqId: quote["request-for-quote"]?.id,
  itemId: quote["request-for-quote"]?.item?.id,
  itemType: quote["item-type"],
  itemNumber: quote["item-number"],
  itemSpecification: quote["item-specification"]
    ? mapSpecifications(quote["item-specification"], "supplier-display")
    : [],
  round: quote["rfq-round"],
  rfqNote: quote["rfq-note"],
  brandingArea: quote["branding-area"],
  colorOptions: quote["color-options"],
  decorationOptions: quote["decoration-options"],
  description: quote["description"],
  ideas: quote["ideas"],
  itemDimensions: quote["item-dimensions"],
  preProCost: quote["pre-pro-cost"],
  preProLeadTimeInDays: quote["pre-pro-lead-time-in-days"],
  pricingBasedOn: quote["pricing-based-on"],
  pricingTierQuotes: quote["pricing-tier-quotes"].map(mapPricingTierQuote),
  costs: quote["costs"].map(mapCost),
  cloudinaryFiles: cloudinaryFiles.map((file) => ({
    id: file["id"],
    cloudinaryId: file["cloudinary-id"],
    fileName: file["file-name"],
  })),
});

export const mapQuotes = (quotes) =>
  quotes.map((quote) => mapDetailedQuote(quote, []));

export const mapSingleRFQ = (rfq, cloudinaryFiles = []) => {
  return {
    id: rfq.id,
    itemId: rfq.item.id,
    itemName: rfq["item-name"],
    itemNumber: rfq["item-number"],
    itemType: rfq["item-type"],
    brands: rfq["brand-names"]?.split(", "),
    programId: rfq.program.id,
    programName: rfq["program-name"],
    round: rfq.round,
    status: rfq.status,
    note: rfq.note,
    quotes: mapQuotes(rfq.quotes),
    pricingTiers: rfq["pricing-tiers"].map(({ id, qty }) => ({
      id,
      qty,
    })),
    userName: rfq["user-name"],
    cloudinaryFiles: cloudinaryFiles.map((file) => ({
      id: file["id"],
      cloudinaryId: file["cloudinary-id"],
      fileName: file["file-name"],
    })),
  };
};

export const mapRFQItems = (rfqItems) =>
  rfqItems.map((item) => mapSingleRFQ(item, []));

export const mapChanges = (changes) =>
  changes
    .filter(({ field }) => field !== "updated_at")
    .map((change) => ({
      field: change.field,
      originalValue: change["original-value"],
      updatedValue: change["updated-value"],
    }));

const mapLog = (log) => ({
  id: log.id,
  action: log.action,
  entityId: log["entity-id"],
  itemNumber: log["item-number"],
  recordType: log["record-type"],
  recordedAt: log["recorded-at"]
    ? format(new Date(log["recorded-at"]), "PPp")
    : null,
  rollback: log.rollback,
  type: log.type,
  userName: log["user-name"],
  changes: [],
});

const mergeChanges = (changes, newChanges) =>
  changes
    .reduce((accumulator, newChange) => {
      const same = accumulator.find((c) => c.field === newChange.field);
      if (same) {
        same.updatedValue = newChange.updatedValue;
      } else {
        accumulator.push(newChange);
      }
      return accumulator;
    }, newChanges)
    .filter((change) => change.originalValue !== change.updatedValue);

export const mapChangelogs = (logs) => {
  // usings keys, we can group together simialar entries
  const createLogKey = (change) =>
    [
      change.action,
      change["entity-id"],
      change["user-name"],
      change["recorded-at"].substr(0, 15), // only compare first 15 chars of date-time string (tens of minutes)
    ].join(".");

  // Group by log-key
  const changesArray = logs.reduce((acc, log) => {
    const key = createLogKey(log);
    if (!acc[key]) acc[key] = mapLog(log);
    if (log.changes) {
      acc[key].changes = mergeChanges(
        acc[key].changes,
        mapChanges(log.changes)
      );
    }
    return acc;
  }, {});

  // Filter out empty update logs
  return Object.values(changesArray).filter(
    (log) => log.action !== "updated" || log.changes.length > 0
  );
};

export const mapNotes = (notes) => {
  let notesArray = [];
  notes.forEach((note) => {
    notesArray.push({
      id: note.id,
      message: note.message,
      userName: note["user-name"],
      updatedAt: format(new Date(note["updated-at"]), "MM/dd/yyyy pp"),
    });
  });
  return notesArray;
};
