/** @jsxImportSource @emotion/react */
import "twin.macro";

const LabelValuePair = ({ label, value, ...props }) => (
  <div tw="mb-2" {...props}>
    <div tw="text-neutral-600 text-sm">{label}</div>
    <div tw="text-neutral-800 text-lg">{value}</div>
  </div>
);
export default LabelValuePair;
