/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import _ from "lodash";

import { routeResourceTypes } from "@services/api";

import ControlledResourceFilterAutocomplete, {
  ControlledResourceFilterAutocompleteProps,
} from "../components/ControlledResourceFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

type makeResourceAutocompleteFilterProps<
  TResourceName extends keyof routeResourceTypes,
> = {
  name: string;
  resource: TResourceName;
  titleSingular: string;
  titlePlural: string;
  requestParams?: ControlledResourceFilterAutocompleteProps<TResourceName>["requestParams"];
  tapOptions?: ControlledResourceFilterAutocompleteProps<TResourceName>["tapOptions"];
  getOptionText?: ControlledResourceFilterAutocompleteProps<TResourceName>["getOptionText"];
  groupBy?: ControlledResourceFilterAutocompleteProps<TResourceName>["groupBy"];
  resourceFilterName?: string;
  skipsPagination?: boolean;
};

function makeResourceAutocompleteFilter<
  TResourceName extends keyof routeResourceTypes,
>({
  name,
  resource,
  titleSingular,
  titlePlural,
  skipsPagination,
  tapOptions,
  getOptionText,
  requestParams,
  resourceFilterName,
  groupBy,
}: makeResourceAutocompleteFilterProps<TResourceName>) {
  const Popper = () => {
    const { control } = useFilterContext();
    return (
      <ControlledResourceFilterAutocomplete
        name={name}
        resource={resource}
        inputPlaceholder={`Filter ${titlePlural}`}
        control={control}
        getOptionText={getOptionText}
        skipsPagination={skipsPagination}
        tapOptions={tapOptions}
        requestParams={requestParams}
        resourceFilterName={resourceFilterName}
        groupBy={groupBy}
      />
    );
  };

  const Chip = (props) => {
    const { filterValues } = useFilterContext();
    const selectedIds = filterValues[name] ?? [];
    return (
      <ResourceFilterChip
        resource={resource}
        ids={selectedIds}
        prefix={`${titleSingular} is`}
        contentWhenEmpty={`Select ${titleSingular}`}
        getResourceText={getOptionText}
        skipsPagination={skipsPagination}
        {...props}
      />
    );
  };

  const resourceFilter = {
    name,
    menuText: titlePlural,
    Popper: Popper,
    Chip: Chip,
  };
  return resourceFilter;
}
export default makeResourceAutocompleteFilter;
