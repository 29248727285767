/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { ReactElement } from "react";

import { Button, Divider, Tooltip, tooltipClasses } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { getItemPreviewImage } from "@features/items/helpers";
import { PaperTooltip, Type } from "@features/ui";
import { OrderSet } from "@models/OrderSet";
import { CldImage } from "@services/cloudinary";

import { getExtendedCost, getVariantName } from "../helpers";
import useDeleteOrderSetMutation from "../orderSet/data/mutations/useDeleteOrderSetMutation";

const OrderSetPreviewTooltip = ({
  children,
  orderSet,
}: {
  children: ReactElement<any, any>;
  orderSet?: OrderSet;
}) => {
  const deleteOrderSetMutation = useDeleteOrderSetMutation(orderSet?.id);

  if (!orderSet) {
    return (
      <Tooltip title={!orderSet && "Add items to start a new order."}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  const content = (
    <div tw="px-3 space-y-2">
      <div tw="flex justify-between items-center text-base gap-6 whitespace-nowrap">
        <h3 tw="text-lg">Order-set #{orderSet.id}</h3>
        <Type.P>
          {orderSet.orderSetVariants.length} item
          {orderSet.orderSetVariants.length > 1 && "s"}
        </Type.P>
        <Type.P>
          {formatMoneyString(getExtendedCost(orderSet, orderSet.type))}
        </Type.P>
      </div>
      <Divider />
      <div tw="grid grid-cols-[4em auto] gap-4 my-2 max-h-60 overflow-y-auto -mx-6 px-6">
        {orderSet.orderSetVariants.map((osv) => (
          <React.Fragment key={osv.id}>
            <CldImage
              tw="w-14 aspect-square object-contain"
              image={getItemPreviewImage(osv.variant.item)}
              size="thumbnail"
            />
            <div tw="flex-grow mr-3">
              <div tw="text-primary-600">{osv.variant.variantSku}</div>

              {osv.variant.item.comment}
              {osv.variant.selectedVariantOptions.length > 0 && (
                <div tw="text-xs text-neutral-500">
                  {getVariantName(osv.variant)}
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div tw="flex justify-end">
        <Button
          variant="danger"
          tw="text-xs normal-case -mr-3"
          onClick={() => deleteOrderSetMutation.mutate()}
        >
          Delete
        </Button>
      </div>
    </div>
  );

  return (
    <PaperTooltip
      title={content}
      placement="bottom-start"
      css={{ [`& .${tooltipClasses.tooltip}`]: tw`max-w-none` }}
    >
      <span>{children}</span>
    </PaperTooltip>
  );
};

export default OrderSetPreviewTooltip;
