/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const statusOptions = [
  { id: "sent", name: "New" },
  { id: "accepted", name: "In Progress" },
  { id: "complete", name: "Complete" },
  { id: "canceled", name: "Canceled" },
];

const filterQuoteStatus = makeRadioOptionsFilter({
  name: "quoteStatus",
  title: "Status",
  options: statusOptions,
});

export default filterQuoteStatus;
