/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "@mui/material";

import _ from "lodash";
import { SmartTable } from "src/components/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import {
  useOrderWindowBudgetSpendReport,
  usePaginatedBudgetsQuery,
} from "./queries";

const OverBudgetByStateTable = ({ brandId, ocmId }) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<string | undefined>();
  const { currentChannel, currentTerritory } = useSelector(
    (state: any) => state.user
  );
  const { data: budgets, ...tableProps } = usePaginatedBudgetsQuery({
    filter: {
      brandIds: brandId && [brandId],
      channel: currentChannel,
      hasSubmittedOrdersForTypes: "pre-order",
      territoryId: currentTerritory,
    },
    sort,
  });

  const {
    data: orderWindowBudgetSpend = [],
    isLoading: isOrderWindowSpendLoading,
  } = useOrderWindowBudgetSpendReport(ocmId, brandId);

  const orderWindowSpendByBudgetId = useMemo(
    () => _.keyBy(orderWindowBudgetSpend, "budgetId"),
    [orderWindowBudgetSpend]
  );

  const handleRowClick = (row) => {
    navigate(`/rollup/item?budgets[]=${row.id}`);
  };

  return (
    <SmartTable
      maxHeight={400}
      filterState={{ sort }}
      setFilterState={(state) => setSort(state.sort)}
      onRowClick={handleRowClick}
      noResultsText="No budgets found for this brand."
      columns={[
        { id: "id", label: "ID" },
        {
          id: "_subState",
          label: "State",
          render: (_, budget) =>
            budget.subStateCode === budget.subStateName
              ? budget.subStateCode
              : budget.subStateCode + " " + (budget.subStateName ?? ""),
        },
        {
          id: "_orderWindowSpend",
          label: "Order Window Spend",
          align: "right",
          render: (v, budget) => {
            const orderWindowSpend = orderWindowSpendByBudgetId[budget.id];
            if (isOrderWindowSpendLoading) {
              return (
                <Skeleton
                  variant="text"
                  tw="inline-block"
                  height={24}
                  width={32}
                />
              );
            }
            return formatMoneyString(
              orderWindowSpend ? orderWindowSpend.orderWindowPendingAmount : 0
            );
          },
        },
        {
          id: "totalAmount",
          label: "Initial Amount",
          align: "right",
          render: formatMoneyString,
        },
        {
          id: "remainingAmount",
          label: "Remaining Amount",
          align: "right",
          render: (v) => (
            <span css={[v < 0 && tw`font-medium text-red-700`]}>
              {formatMoneyString(v)}
            </span>
          ),
        },
        {
          id: "availableAmount",
          label: "Available Amount",
          align: "right",
          render: (v) => (
            <span css={[v < 0 && tw`font-medium text-red-700`]}>
              {formatMoneyString(v)}
            </span>
          ),
        },
      ]}
      rows={budgets ?? []}
      {...tableProps}
    />
  );
};

export default OverBudgetByStateTable;
