/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { SelectInput } from "@components/Forms/ControlledInputs";
import { useOnDemandNationalCouponPrograms } from "@features/programs";

import { Bordered } from "../components/StyledComponents";
import { useFilterContext } from "../filterContext";

const Chip = () => {
  const { control, reset, setValue, submitFilters, getValues } =
    useFilterContext();

  const programIds = useWatch({ name: "programs", control });
  const programFilterId = programIds?.[0];

  const programs = useOnDemandNationalCouponPrograms();

  const disabled = programs.length < 2;

  useEffect(() => {
    if (
      programs.length > 0 &&
      !programs.some((p) => p.id === programFilterId)
    ) {
      setValue("programs", []);
      submitFilters();
    }
  }, [programs, programFilterId, setValue, submitFilters]);

  // Reset on mount
  useEffect(() => {
    reset({
      programs: getValues("programs"),
    });
    submitFilters();
  }, [reset, submitFilters, getValues]);

  return (
    <Bordered
      role={disabled ? "disabled" : "button"}
      tw="h-8 text-sm text-neutral-600"
    >
      {programs.length === 0 && (
        <span tw="px-4">No National Coupon Programs</span>
      )}
      {programs.length === 1 && <span tw="px-4">{programs[0].name}</span>}
      {programs.length > 1 && (
        <SelectInput
          control={control}
          name="ocmId"
          options={programs}
          label=""
          renderValue={(val) =>
            val
              ? programs.find((ow) => ow.id === val)?.name
              : "Select Order Window"
          }
          displayEmpty
          css={{
            "&": tw`h-full rounded-full appearance-none bg-neutral-200`,
            ".MuiOutlinedInput-root fieldset": tw`border-0`,
            ".MuiInputBase-root": tw`text-sm text-neutral-600`,
          }}
          onChange={() => submitFilters()}
        />
      )}
    </Bordered>
  );
};

const filterNationalCouponPrograms = {
  name: "nationalCouponPrograms",
  formControls: ["programs"],
  Chip,
  alwaysShow: true,
};

export default filterNationalCouponPrograms;
