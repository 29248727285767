/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Budget } from "@models/Budget";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterBudgets = makeResourceAutocompleteFilter({
  name: "budgets",
  resource: "budgets",
  titleSingular: "Budget",
  titlePlural: "Budgets",
  resourceFilterName: "searchTerm",
  getOptionText: (budget: Budget) => budget.externalId,
});

export default filterBudgets;
