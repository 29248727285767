/** @jsxImportSource @emotion/react */
import "twin.macro";

import { triggeredRuleStatusMap } from "@features/compliance/constants";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const statusOptions = Object.entries(triggeredRuleStatusMap).map(
  ([id, name]) => ({ id, name })
);

const filterTriggeredRuleStatus = makeRadioOptionsFilter({
  name: "triggeredRuleStatus",
  title: "Status",
  options: statusOptions,
});

export default filterTriggeredRuleStatus;
