/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Suspense } from "react";
import { useState } from "react";
import { useImage } from "react-image";
import { useDispatch } from "react-redux";

import { Add } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";

import fp from "lodash/fp";

import cld from "@services/cloudinary";
import imageSizeOptions from "@services/cloudinary/imageSizeOptions";

import {
  addImage,
  deleteImage,
} from "../../../../redux/slices/planningTool/itemProgramSlice";
import { openUploadWidget } from "../../../../utility/cloudinary";
import Carousel from "../../../Carousel";
import { StyledButton } from "../../../StyledComponents";

export const cloudinaryUrl = (id, thumb = false, pageCount) =>
  cld.url(id + ".jpg", {
    ...imageSizeOptions[thumb ? "thumbnail" : "large"],
    ...(pageCount && { page: pageCount }),
    secure: true,
  });

const ImageSlide = ({ url, page, pageCount }) => {
  const { src } = useImage({ srcList: [url, "/images/NotFound.png"] });
  return (
    <div tw="relative">
      {pageCount > 1 && (
        <Typography tw="absolute top-0 left-0 text-xs text-neutral-500 bg-white pr-2 pb-2 rounded uppercase tracking-wider">
          PDF page {page} of {pageCount}
        </Typography>
      )}
      <Suspense fallback={<CircularProgress />}>
        <img tw="h-full object-contain object-center" src={src} alt="" />
      </Suspense>
    </div>
  );
};

const handleImages = fp.pipe(fp.sortBy("position"), (imgs) =>
  imgs.map((img, i) => ({
    ...img,
    // Converts PDF's pages into an array of jpgs
    thumbs: Array(img.pdfPageCount || 1)
      .fill(0)
      .map((_, i) => cloudinaryUrl(img.cloudinaryId, true, i + 1)),
    urls: Array(img.pdfPageCount || 1)
      .fill(0)
      .map((_, i) => cloudinaryUrl(img.cloudinaryId, false, i + 1)),
    url: cloudinaryUrl(img.cloudinaryId, false),
    thumb: cloudinaryUrl(img.cloudinaryId, true),
    startIndex: imgs.slice(0, i).reduce((a, b) => a + (b.pdfPageCount || 1), 0),
  }))
);

const ImageTile = ({ url }) => {
  const { src } = useImage({ srcList: [url, "/images/NotFound.png"] });
  return (
    <img
      css={[
        tw`object-cover w-24 h-24 rounded shadow-md`,
        tw`transition duration-300 hover:scale-105`,
      ]}
      src={src}
      alt="Uploaded asset"
    />
  );
};

const UploadedAsset = ({ urls, startIndex, onView, onDelete }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <div
      className="group"
      css={[
        tw`p-3 border border-transparent border-solid rounded hover:bg-slate-100 hover:border-slate-200`,
        disabled && tw`opacity-50 pointer-events-none`,
      ]}
    >
      <div tw="flex gap-2">
        {urls.map((url, i) => (
          <button
            key={url}
            tw="cursor-pointer"
            onClick={() => onView(startIndex + i)}
          >
            <ImageTile url={url} />
          </button>
        ))}
      </div>
      {onDelete && (
        <button
          onClick={() => {
            setDisabled(true);
            onDelete();
          }}
          disabled={disabled}
          tw="inline-block mt-2 text-xs text-slate-600 opacity-0 group-hover:opacity-100 hover:text-red-500 cursor-pointer transition"
        >
          Remove
        </button>
      )}
    </div>
  );
};

const ImageUploadSection = ({
  itemId,
  itemProgramId,
  images,
  thumbnails,
  editable = true,
}) => {
  const dispatch = useDispatch();
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [slideHighlight, setSlideHighlight] = useState(0);

  const sortedImages = handleImages(images);

  const handleCarouselIndex = (idx) => {
    setSlideHighlight(idx);
    setCarouselOpen(true);
  };

  const handleSendToAPI = (cloudinaryInfo) => {
    dispatch(
      addImage(itemProgramId, {
        itemId,
        cloudinaryId: cloudinaryInfo.public_id,
        originalUrl: cloudinaryInfo.secure_url,
        position: images.length + 1,
        type: "large",
        pdfPageCount: cloudinaryInfo.pages,
      })
    );
  };

  const handleDelete = (imageId, i) => {
    dispatch(deleteImage(itemProgramId, itemId, imageId));
    if (i === 0) {
      // delete thumbnail, if any
      if (thumbnails[0])
        dispatch(deleteImage(itemProgramId, itemId, thumbnails[0].id));
    }
  };

  const uploadToCloudinary = () => {
    const options = {
      cloudName: "brandhub",
      uploadPreset: "h1raksv4",
      showUploadMoreButton: false,
    };
    openUploadWidget(options, (error, file) => {
      if (!error) {
        if (file.event === "success") {
          handleSendToAPI(file.info);
        }
      } else {
        console.log(error.toString());
      }
    });
  };

  return (
    <>
      <div tw="flex flex-wrap items-center">
        <Suspense fallback={<CircularProgress />}>
          {sortedImages.map(({ thumbs, id, startIndex }, i) => (
            <UploadedAsset
              key={id}
              urls={thumbs}
              startIndex={startIndex}
              onView={handleCarouselIndex}
              onDelete={editable ? () => handleDelete(id, i) : null}
            />
          ))}
        </Suspense>
        {editable && (
          <div className="group" tw="text-center">
            <StyledButton
              cta
              tw="block w-16 h-16 m-4"
              onClick={uploadToCloudinary}
            >
              <Add />
            </StyledButton>
            <div tw="text-slate-600 text-xs transition opacity-0 group-hover:opacity-100">
              Upload new
            </div>
          </div>
        )}
      </div>
      <Dialog open={carouselOpen} onClose={() => setCarouselOpen(false)}>
        <DialogContent tw="p-6">
          <Suspense fallback={<CircularProgress />}>
            <Carousel
              startIndex={slideHighlight}
              imageIdsOrUrls={sortedImages.map((img) => img.cloudinaryId)}
            >
              {sortedImages.flatMap(({ id, urls, pdfPageCount }) =>
                urls.map((url, i) => (
                  <ImageSlide
                    key={id + i}
                    url={url}
                    pageCount={pdfPageCount}
                    page={i + 1}
                  />
                ))
              )}
            </Carousel>
          </Suspense>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageUploadSection;
