import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const SingleSupplierAutoComplete = ({
  classes,
  initialValue,
  handleChange,
  reset,
  setReset,
  disabled,
  noMargin,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [supplier, setSupplier] = useState(initialValue?.name ?? "");
  const [currentSupplier, setCurrentSupplier] = useState(initialValue ?? "");

  const options = useSelector((state) => state.suppliers.supplierList);

  const handleSuppliers = (value) => {
    setCurrentSupplier(value);
  };

  useEffect(() => {
    if (reset) {
      setSupplier("");
      setCurrentSupplier("");
      setReset(false);
    }
  }, [reset, setSupplier, setReset]);

  useEffect(() => {
    setSupplier(initialValue?.name ?? "");
    setCurrentSupplier(initialValue ?? "");
  }, [initialValue]);

  return (
    <Autocomplete
      autoHighlight
      fullWidth
      freeSolo
      className={!noMargin ? classes.settingsMargin : null}
      id="supplier-auto-complete"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      inputValue={supplier}
      onInputChange={(_evt, value) => setSupplier(value)}
      onChange={(_evt, value) => {
        handleChange(value);
        handleSuppliers(value);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name ?? ""}
      options={options}
      value={currentSupplier}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label ?? "Supplier"}
          id="supplier-auto-search"
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
      {...props}
    />
  );
};

SingleSupplierAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SingleSupplierAutoComplete;
