import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { AuthUser } from "@models/AuthUser";
import client from "@services/api";

export const currentUserKeyFactory = createQueryKeys("current-user", {
  detail: {
    queryKey: null,
  },
});

export const useToggleFavoriteItemMutation = (ids: number[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (itemId: string | number) => {
      const newIds = _.xor(ids, [+itemId]);
      return client
        .patch<AuthUser>("current-user", {
          favoriteItemIds: newIds,
        })
        .then((res) => res.data);
    },
    onMutate: async (itemId) => {
      // Cancel any outgoing queries for the current user
      // so data doesn't get overwritten
      await queryClient.cancelQueries(currentUserKeyFactory.detail);

      const snapshot = queryClient.getQueryData<AuthUser>(
        currentUserKeyFactory.detail.queryKey
      );
      queryClient.setQueryData(
        currentUserKeyFactory.detail.queryKey,
        (user: AuthUser) => {
          return {
            ...user,
            favoriteItemIds: _.xor(user.favoriteItemIds, [+itemId]),
          };
        }
      );
      return () =>
        queryClient.setQueryData(
          currentUserKeyFactory.detail.queryKey,
          snapshot
        );
    },
    onSettled: () => {
      return queryClient.invalidateQueries(currentUserKeyFactory.detail);
    },
    onError: (error, _, rollback) => {
      console.error(error);
      return rollback?.();
    },
  });
};
