import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import _ from "lodash";

export const useLocalPagination = (data) => {
  const [originalData, setOriginalData] = useState(data || []);
  const [pageCount, setPageCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  const handleBottomScroll = () => {
    if (
      scrollRef.current &&
      scrollRef.current.scrollTop !== 0 &&
      pageCount !== pages.length
    ) {
      setCurrentData(currentData.concat(pages[pageCount]));
      setPageCount(pageCount + 1);
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, 500, 0);

  useEffect(() => {
    if (data && data.length !== originalData.length) {
      setOriginalData([...data]);
      let chunkedArray = data.length > 50 ? _.chunk(data, 50) : [[...data]];
      setPages(chunkedArray);
      setPageCount(1);
      setCurrentData(chunkedArray[0]);
    }
  }, [data, originalData.length]);

  useEffect(() => {
    setOriginalData([]);
    setCurrentData([]);
  }, []);

  return {
    scrollRef: scrollRef,
    reportData: currentData,
  };
};
