import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { TriggeredRule } from "@models/TriggeredRule";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const triggeredRulesKeyFactory = createQueryKeys("triggered-rules", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<TriggeredRule[]>("triggered-rules", {
        params,
      }),
  }),
});

export const usePaginatedTriggeredRulesQuery = buildPaginatedQuery(
  triggeredRulesKeyFactory.paginated,
  { placeholderData: keepPreviousData, structuralSharing: true }
);

export const useSendTriggeredRulesActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      action,
      values,
    }: {
      action: "approve" | "deny";
      values: { id: string; note: string }[];
    }) => {
      const res = await asyncPool(5, values, async ({ id, note }) => {
        return client.post<TriggeredRule>(`triggered-rules/${id}/${action}`, {
          note,
        });
      });

      if (res.errors) {
        throw res.errors[0];
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: triggeredRulesKeyFactory.paginated._def,
      }),
  });
};
