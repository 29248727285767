/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { FormControlLabel, Switch, TextField } from "@mui/material";

import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import { useUsersPaginated } from "@features/users";
import { User } from "@models/User";
import useDebouncedValue from "@utils/useDebouncedValue";

import EditUserModal from "../components/Settings/EditUserModal";
import { UserTable } from "../components/Settings/UserTable";
import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "../components/StyledComponents";

const AdminUsers = () => {
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("active");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [modalType, setModalType] = useState<"edit" | "new">("edit");

  const searchTermFilter = useDebouncedValue(searchTerm, 300);

  const params = {
    filter: {
      ...(searchTermFilter && { "name-or-email": searchTermFilter }),
      status: status,
    },
  };
  const { data: users, ...tableProps } = useUsersPaginated(params);

  useReport("users-summary", ["users", { ...params, page: { size: 100 } }]);

  const handleActiveSwitch = (e: any) => {
    setStatus(e.target.checked ? "active" : "inactive");
  };

  const handleModalOpen = (user: User | null, type: "new" | "edit") => {
    setSelectedUser(user);
    setModalType(type);
    setEditUserModalOpen(true);
  };

  return (
    <>
      {editUserModalOpen && (
        <EditUserModal
          handleModalClose={() => setEditUserModalOpen(false)}
          modal={editUserModalOpen}
          user={selectedUser}
          type={modalType}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between">
          <PageTitle title="Edit Users" />
          <div tw="flex justify-end items-center">
            <RequestReportButton
              reportName="users-summary"
              title="Export CSV"
              disabled={users.length === 0}
            />
            <StyledButton
              cta
              tw="ml-2"
              onClick={() => handleModalOpen(null, "new")}
            >
              NEW USER
            </StyledButton>
          </div>
        </div>

        <div tw="flex">
          <div tw="flex-1">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              tw="w-full"
            />
          </div>
          <div tw="flex-1 flex items-center ml-5">
            <div>Status:</div>
            <FormControlLabel
              tw="ml-2"
              control={
                <Switch
                  checked={status === "active"}
                  onChange={handleActiveSwitch}
                  name="active-switch"
                />
              }
              label={status === "active" ? "Active" : "Inactive"}
            />
          </div>
        </div>
        <UserTable
          rows={users}
          onRowClick={(user) => handleModalOpen(user, "edit")}
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminUsers;
