/** @jsxImportSource @emotion/react */
import "twin.macro";

import { SUPPLIER_ROLES } from "src/constants/permissions";
import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { useAuthUser } from "@features/auth";
import { Filters, useFilterParams } from "@features/filters";
import { RfpHistoryTable } from "@features/purchaseOrders";
import { usePaginatedRfpQuery } from "@features/purchaseOrders/queries/requestForPriceQueries";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

const RfpHistory = () => {
  const [filters] = useFilterParams();
  const { channel } = useAuthUser();
  const roleIs = useRoleIs();
  const isSupplier = roleIs(SUPPLIER_ROLES);

  const params = {
    filter: {
      searchTerm: filters.q,
      [isSupplier ? "bidStatus" : "status"]: filters.rfpStatus,
      brandIds: filters.brands,
      programIds: filters.programs,
      supplierIds: filters.suppliers,
      itemTypeIds: filters.itemTypes,
      channel: !isSupplier ? channel : null,
    },
    sort: filters.sort,
  };
  const tableProps = usePaginatedRfpQuery(params);
  useReport("rfp-history", ["request-for-prices", params]);

  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center justify-between flex-wrap gap-3">
        <PageTitle title="RFP History" />
        <div tw="flex gap-2">
          <RequestReportButton reportName="rfp-history" />
        </div>
      </div>
      <TableCardContainer>
        <Filters
          slots={[
            "rfpStatus",
            "brands",
            "programs",
            "itemTypes",
            ...(!isSupplier ? ["suppliers"] : []),
          ]}
          alwaysShow={["rfpStatus", "brands"]}
          defaultValues={{
            searchBy: "itemNumber",
            sort: "-due-date",
          }}
        />
        <RfpHistoryTable {...tableProps} />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default RfpHistory;
