/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Card, CircularProgress } from "@mui/material";

const NoItemsToShow = ({ name, loading }) => {
  return (
    <Card tw="px-4 py-6 bg-neutral-100 text-neutral-600">
      {loading ? (
        <CircularProgress />
      ) : (
        `No ${name} have been added to this program.`
      )}
    </Card>
  );
};

export default NoItemsToShow;
