/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";

import { Card } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { Type } from "@features/ui";

import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";
import useOrderSetId from "../orderSet/data/useOrderSetId";
import { orderSetsKeyFactory } from "../queries/orderSetQueries";
import OrderSetBudgetImpact from "./OrderSetBudgetImpact";
import OrderSetOrdersOverview from "./OrderSetOrdersOverview";
import OrderSetSummaryActionPanel from "./OrderSetSummaryActionPanel";
import OrderSetVariantsOverview from "./OrderSetVariantsOverview";

const OrderOverview = () => {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const { orders } = useCurrentOrderSet();

  const hasBudgets = orders?.some((o) =>
    o.orderVariants.some((ov) => ov.budgets.length > 0)
  );

  useEffect(() => {
    queryClient.invalidateQueries(orderSetsKeyFactory.detail(orderSetId));
  }, [orderSetId, queryClient]);

  return (
    <FixedHeightScrollLastChild>
      {/* minmax trick: https://css-tricks.com/preventing-a-grid-blowout/ */}
      <div tw="grid grid-flow-dense grid-cols-1 lg:grid-cols-[minmax(0, 1fr) 320px] gap-4 lg:gap-6 relative">
        <div tw="lg:col-start-2 relative">
          <div tw="sticky top-[var(--header-height)]">
            <Type.H3 tw="mb-2">Summary</Type.H3>
            <OrderSetSummaryActionPanel />
          </div>
        </div>
        <div tw="space-y-8">
          {orders && hasBudgets && (
            <div>
              <Type.H3 tw="mb-2">Budget Impact</Type.H3>
              <Card>
                <OrderSetBudgetImpact />
              </Card>
            </div>
          )}

          <div>
            <Type.H3 tw="mb-2">Item Overview</Type.H3>
            <Card>
              <OrderSetVariantsOverview />
            </Card>
          </div>

          <div>
            <Type.H3 tw="mb-2">Order Overview</Type.H3>
            <Card>
              <OrderSetOrdersOverview />
            </Card>
          </div>
        </div>
      </div>
    </FixedHeightScrollLastChild>
  );
};

export default OrderOverview;
