/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
import BackButton from "src/components/Reporting/BackButton";

import { CSVLink } from "@utils/csv";

import { DatePicker, SelectField } from "../components/Forms/DefaultInputs";
import ReportTable from "../components/Reporting/ReportTable";
import SupplierAutoComplete from "../components/Utility/AutoCompleteFields/SupplierAutoComplete";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getSupplierSpendReportById,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportSupplierSpend = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [reset, setReset] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("po_submitted");
  const [fromDate, setFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));
  const [currentSuppliers, setCurrentSuppliers] = useState([]);

  //const currentUserRole = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentSupplierFilter = useSelector((state) => state.filters.supplier);

  const { setCsvData, data, headers } = useReportCsv(
    reportType,
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleSupplier = (value, _type, _filter) => {
    setCurrentSuppliers(value);
    dispatch(updateSingleFilter({ filter: "supplier", value: value }));
  };

  const handleSupplierChipClick = (id) => {
    let currentSupplierArray = currentSupplierFilter.filter(
      (sup) => sup.id !== id
    );
    setCurrentSuppliers(currentSupplierArray);
    dispatch(
      updateSingleFilter({ filter: "supplier", value: currentSupplierArray })
    );
  };

  const handleReset = () => {
    setFromDate(format(subDays(new Date(), 7), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
    setDateRangeType("po_submitted");
    setCurrentSuppliers([]);
    setReset(true);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = (type) => {
    const filterObject = {
      dateRangeType,
      dateRange: `${fromDate} - ${toDate}`,
      supplier: currentSuppliers,
    };
    dispatch(getSupplierSpendReportById(type, filterObject));
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Supplier Spend Report</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Supplier Spend Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename={`${reportType}.csv`}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponentsWide} tw="gap-2">
          <SelectField
            tw="w-60"
            label="Date Range filter"
            value={dateRangeType}
            onChange={(e) => setDateRangeType(e.target.value)}
            options={[
              { id: "po_submitted", label: "PO Submitted Dates" },
              { id: "po_in_market", label: "In Market Dates" },
            ]}
          />
          <DatePicker
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="fromDate"
            label="From Date"
            value={fromDate}
            onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
            disabled={isLoading}
          />

          <DatePicker
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="toDate"
            label="To Date"
            value={toDate}
            onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
            disabled={isLoading}
          />

          <SupplierAutoComplete
            classes={classes}
            handleChange={handleSupplier}
            reset={reset}
            setReset={setReset}
            filterType="report"
            id="report-supplier-complete"
          />
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Supplier Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentSuppliers.length > 0 &&
            currentSuppliers.map((user) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={user.id}
                label={user.name}
                onDelete={() => handleSupplierChipClick(user.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("supplier-summary")}
            style={{ marginRight: "20px" }}
          >
            GENERATE SUMMARY
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("supplier-detail")}
            style={{ marginRight: "20px" }}
          >
            GENERATE DETAIL
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          (reportType === "supplier-summary" ||
            reportType === "supplier-detail") && (
            <ReportTable report={report} type={reportType} />
          )}
      </Container>
    </>
  );
};

ReportSupplierSpend.propTypes = {};

export default ReportSupplierSpend;
