/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { forwardRef } from "react";

import { ButtonProps, Button as MuiButton } from "@mui/material";

import { FaIcon } from "@features/ui";

type ButtonOptions = {
  loading?: boolean;
};

const Button = forwardRef(function <C extends React.ElementType>(
  { loading, ...props }: ButtonProps<C, ButtonOptions>,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <MuiButton
      ref={ref}
      {...props}
      {...(loading && {
        endIcon: <FaIcon icon="spinner" tw="animate-spin" />,
        disabled: true,
      })}
    />
  );
});

export default Button;
