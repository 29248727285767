/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";

import _, { xor } from "lodash";
import { updateItemSelection } from "src/redux/slices/items/itemSlice";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { insertIf, showFirst } from "@components/SmartTable";
import SmartTable, { Column } from "@components/SmartTable/SmartTable";
import { useAuthUser, useToggleFavoriteItemMutation } from "@features/auth";
import { useFilterParams } from "@features/filters";
import { orderTypeLabel } from "@features/orders";
import useGetVariantAvailableInventory from "@features/orders/ordering/hooks/useGetVariantAvailableInventory";
import { Item } from "@models";

import { ItemCatalogProps } from "./ItemCatalogView";
import ItemOptionsCount from "./ItemOptionsCount";
import ItemPreviewImageButton from "./ItemPreviewImageButton";
import VariantAllocationBreakdownTooltip from "./VariantAllocationBreakdownTooltip";

const ItemCatalogTable = ({
  rows,
  showCheckbox,
  isRowDisabled,
  getCheckboxText,
  rowActions,
  orderView,
  hideBrands,
  ...tableProps
}: ItemCatalogProps) => {
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: any) => state.items);
  const { favoriteItemIds } = useAuthUser();
  const toggleFavoriteItemMutation =
    useToggleFavoriteItemMutation(favoriteItemIds);

  const getVariantAvailableInventory = useGetVariantAvailableInventory();

  const [filters] = useFilterParams();
  const brandIdsFilter = filters?.brands ?? [];

  const toggleSelection = (id) =>
    dispatch(updateItemSelection({ selectedItems: xor(selectedItems, [id]) }));

  const onClickAll = () => {
    if (selectedItems.length === rows.length) {
      dispatch(updateItemSelection({ selectedItems: [] }));
    } else {
      dispatch(
        updateItemSelection({ selectedItems: rows.map((row) => row.id) })
      );
    }
  };

  const columns: Column<Item>[] = [
    ...insertIf<Item>(showCheckbox, {
      id: "checkbox",
      label: (
        <Checkbox
          tw="-m-4 -ml-2"
          indeterminate={
            selectedItems.length > 0 && selectedItems.length < rows.length
          }
          checked={rows.length > 0 && selectedItems.length === rows.length}
          onChange={onClickAll}
          inputProps={{ "aria-label": "select all items" }}
          disabled={rows.length === 0}
        />
      ),
      render: (_, row) => (
        <FormControlLabel
          tw="-m-4 -ml-2"
          control={
            <Checkbox
              checked={selectedItems.includes(row.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => toggleSelection(row.id)}
              disabled={isRowDisabled?.(row) ?? false}
            />
          }
          label={getCheckboxText?.(row) ?? ""}
          labelPlacement="bottom"
        />
      ),
    }),
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => <ItemPreviewImageButton item={row} />,
    },

    {
      id: "item",
      label: "Item",
      sort: "item-number",
      render: (_, row) => (
        <div>
          <div tw="flex gap-2 items-center text-neutral-600 font-medium tracking-wide">
            <Tooltip title="Favorite Item">
              <Checkbox
                className="group"
                tw="-m-2"
                edge="start"
                checked={favoriteItemIds.includes(+row.id)}
                size="small"
                icon={
                  <FavoriteBorderRounded tw="text-lg text-neutral-400 group-hover:text-primary-500" />
                }
                checkedIcon={<FavoriteRounded tw="text-lg text-primary-500" />}
                onClick={(e) => e.stopPropagation()}
                onChange={() => toggleFavoriteItemMutation.mutate(row.id)}
              />
            </Tooltip>
            <span>{row.itemNumber}</span>
          </div>
          {row.comment}
          {row.variants!.length > 1 && <ItemOptionsCount item={row} />}
        </div>
      ),
    },
    ...insertIf(!hideBrands, {
      id: "brands",
      label: "Brands",
      sort: "brand",
      render: (brands) =>
        _(brands)
          // set brands that are in the filter to the front
          .sortBy((b) => !brandIdsFilter.includes(b.id), "name")
          .map("name")
          .join(", "),
    }),
    ...insertIf<Item>(orderView === "on-demand", {
      id: "programs",
      label: "Programs",
      render: (programs) => showFirst(programs.map((p) => p.name)),
    }),
    {
      id: "qtyPerPack",
      label: "Pack Size",
      render: (qtyPerPack) =>
        qtyPerPack === 1 ? (
          <span>Each</span>
        ) : (
          <>
            <span>{qtyPerPack}</span>
            <span tw="text-gray-500"> / pack</span>
          </>
        ),
    },
    ...insertIf(!orderView, {
      id: "itemOrderType",
      label: "Order Type",
      render: (orderType) => orderTypeLabel(orderType),
    }),
    ...insertIf<Item>(
      orderView === "from-inventory",
      {
        id: "_allInventory",
        label: "On Hand Nat.",
        align: "right",
        render: (__, i) => _.sumBy(i.variants, "cachedWarehouseQty"),
      },
      {
        id: "_territoryInventory",
        label: "On Hand Terr.",
        align: "right",
        render: (__, i) => (
          <VariantAllocationBreakdownTooltip
            variants={i.variants}
            qty={_.sumBy(i.variants, getVariantAvailableInventory)}
            tw="-my-1 inline-flex flex-row-reverse"
          />
        ),
      }
    ),
    {
      id: "mostRecentEstimatedCost",
      label: "Est. Cost",
      align: "right",
      render: (cost) => (
        <span tw="text-base text-neutral-900">{formatMoneyString(cost)}</span>
      ),
    },
    ...insertIf<Item>(rowActions, {
      id: "actions",
      label: "Actions",
      render: (_, row) => <div tw="my-[-6px]">{rowActions!(row)}</div>,
    }),
  ];

  return <SmartTable {...tableProps} rows={rows} columns={columns} />;
};

export default ItemCatalogTable;
