/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms";
import { displayOrderSetStatus } from "@features/orders";
import { displayDate } from "@features/reports/utils";
import { OrderSet } from "@models/OrderSet";
import { CsvSchema } from "@utils/csv";

const approvalsOrderSetSchema: CsvSchema<OrderSet> = [
  { label: "Person", id: "user.name" },
  { label: "Program", id: "anaplanPrograms", f: getAnaplanProgramsLabel },
  {
    label: "Brand",
    id: "_brands",
    f: (__, os) =>
      _(os.orderSetVariants)
        .flatMap("variant.item.brands")
        .map("name")
        .uniq()
        .join(", "),
  },
  { label: "State", id: "stateNames" },
  {
    label: "Est. Unit Costs",
    id: "totalProductCost",
    f: (v, os) => formatMoneyString(os.type === "from-inventory" ? 0 : v),
  },
  {
    label: "Total Beacon Cost",
    id: "totalBeaconCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Freight",
    id: "totalEstimatedFreight",
    f: formatMoneyString,
  },
  {
    label: "Est. Taxes",
    id: "totalEstimatesTaxes",
    f: formatMoneyString,
  },
  {
    label: "Est. Extended Total",
    id: "totalExtendedCost",
    f: (v, row) =>
      formatMoneyString(
        row.type === "from-inventory" ? v - +row.totalEstimatedCost : v
      ),
  },
  { label: "Order Submitted", id: "submittedAt", f: displayDate },
  {
    label: "Order Window Close",
    id: "orderCalendarMonth.orderWindowCloseDate",
    f: displayDate,
  },
  {
    label: "In Market Date",
    id: "orderCalendarMonth.inMarketStartDate",
    f: displayDate,
  },
  { label: "Status", id: "status", f: displayOrderSetStatus },
];
export default approvalsOrderSetSchema;
