/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useLocation } from "react-router-dom";

import { keepPreviousData } from "@tanstack/react-query";
import _ from "lodash";
import {
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
} from "src/constants/permissions";
import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  CreateNationalProgramModal,
  usePaginatedProgramsQuery,
} from "@features/programs";
import PlanningProgramsTable from "@features/programs/components/PlanningProgramsTable";
import { Button, PageTitle } from "@features/ui";
import { UserRole } from "@models/UserRole";
import { useSetLocation } from "@services/reactRouterDom";
import useRoleIs from "@utils/useRoleIs";

const NEW_PROGRAM_HASH = "#new";

const PlanningPrograms = () => {
  const [filters] = useFilterParams();
  const roleIs = useRoleIs();
  const location = useLocation();
  const setLocation = useSetLocation();
  const setHash = (hash: "" | `#${string}`) =>
    setLocation({ hash }, { state: { preventLastLocation: true } });
  const canCreateNationalPrograms = roleIs(
    _.without(
      PLANNING_TOOL_ROLES,
      PLANNING_TOOL_SUPPLIER,
      "field2"
    ) as UserRole[]
  );
  const isSupplier = roleIs("planning-tool-supplier");

  const enabled = !_.isEmpty(_.omit(filters, "searchBy"));

  const searchBy =
    { programName: "name" }[filters.searchBy] || filters.searchBy;

  const params = {
    filter: {
      [searchBy]: filters.q,
      brandIds: filters.brands,
      programCreatorIds: filters.programCreators,
      inMarketMonthYears: filters.inMarketMonths,
      orderCalendarMonthIds: filters.ocms,
      programTypeIds: filters.programTypes,
      channel: filters.channel,
      businessUnitNames: filters.businessUnits,
      supplierIds: filters.suppliers,
    },
    sort: "-id",
  };
  const tableData = usePaginatedProgramsQuery(params, {
    placeholderData: keepPreviousData,
    enabled,
  });
  useNoFetch();

  return (
    <>
      <FixedHeightScrollLastChild>
        {location.hash === NEW_PROGRAM_HASH && (
          <CreateNationalProgramModal handleClose={() => setHash("")} />
        )}
        <div tw="flex justify-between items-center gap-3 flex-wrap">
          <PageTitle title="Programs" />
          <div tw="flex gap-3">
            {canCreateNationalPrograms && (
              <Button
                onClick={() => setHash(NEW_PROGRAM_HASH)}
                variant="contained"
                color="secondary"
              >
                Create National Program
              </Button>
            )}
          </div>
        </div>
        <TableCardContainer>
          <Filters
            slots={[
              "brands",
              "businessUnits",
              "channel",
              "inMarketMonths",
              "ocms",
              "programCreators",
              "programTypes",
              ...(!isSupplier ? ["suppliers"] : []),
            ]}
            slotProps={{
              search: {
                searchBy: [
                  "itemNumber",
                  "programIdOrAnaplanCode",
                  "programName",
                  "workfrontId",
                  "couponBarcodeId",
                ],
              },
            }}
            alwaysShow={["brands", "inMarketMonths"]}
            defaultValues={{ searchBy: "programIdOrAnaplanCode" }}
          />
          {enabled && <PlanningProgramsTable {...tableData} />}
          {!enabled && (
            <div>
              <div tw="m-3 border-2 border-neutral-100 rounded-xl p-6 py-12  text-2xl text-neutral-500">
                Add some filters to get started
              </div>
            </div>
          )}
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PlanningPrograms;
