import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selected: [],
};

const orderSetHistorySlice = createSlice({
  name: "orderSetHistory",
  initialState,
  reducers: {
    setOrderSetSelection(state, action) {
      state.selected = action.payload;
    },
  },
});

export const { setOrderSetSelection } = orderSetHistorySlice.actions;

export default orderSetHistorySlice.reducer;
