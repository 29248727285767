import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import Carousel from "../Carousel";
import ImageWrapper from "../Utility/ImageWrapper";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  previewGrid: {
    display: "flex",
    justifyContent: "space-around",
  },
  dialogGrid: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  infoDiv: {
    margin: "5% 0",
    width: "100%",
    maxHeight: "90%",
    paddingRight: "20px",
    [theme.breakpoints.down("md")]: {
      overflowY: "visible",
      height: "fit-content",
      paddingRight: "0px",
    },
  },
  largeImageWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "25px 0",
  },
  largeImage: {
    maxHeight: "500px",
    objectFit: "contain",
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  carouselRoot: {
    [theme.breakpoints.down("md")]: {
      marginTop: "100%",
    },
  },
}));

const ItemPreviewModal = ({ item, open, handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableScrollLock
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Grid container spacing={5} className={classes.dialogGrid}>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                handleClose();
              }}
              size="large"
            >
              <CancelIcon fontSize="large" color="secondary" />
            </IconButton>
            <Grid item className={classes.previewGrid} md={7} xs={12}>
              <Carousel
                classes={{ root: classes.carouselRoot }}
                imageIdsOrUrls={item.images.imgUrlLg}
              >
                {item.images.imgUrlLg &&
                  item.images.imgUrlLg.map((url, index) => (
                    <div className={classes.largeImageWrapper} key={index}>
                      <ImageWrapper
                        imgUrl={url}
                        alt={item.productName}
                        imgClass={classes.largeImage}
                        id={item.id}
                      />
                    </div>
                  ))}
              </Carousel>
            </Grid>
            <Grid item className={classes.detailGrid} md={5} xs={12}>
              <div
                style={{
                  display: "flex",
                  height: "Calc(100vh - 300px)",
                  alignItems: "center",
                }}
              >
                <div className={classes.infoDiv}>
                  <Typography variant="body1" color="textSecondary">
                    {`#${item.itemNumber}`}
                  </Typography>
                  <Typography className={classes.headerText}>
                    {`Product Name:  ${item.productName}`}
                  </Typography>
                  <Typography className={classes.headerText}>
                    {`Brand(s): ${item.brands}`}
                  </Typography>
                  <Typography className={classes.headerText}>
                    {`Item Type:  ${item.itemType}`}
                  </Typography>
                  <Typography className={classes.headerText}>
                    {`Program Theme: ${item.programTheme}`}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ItemPreviewModal.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ItemPreviewModal;
