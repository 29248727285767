/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import _ from "lodash";

import ControlledResourceFilterAutocomplete from "../components/ControlledResourceFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const BrandPopper = () => {
  const { control } = useFilterContext();
  return (
    <ControlledResourceFilterAutocomplete
      skipsPagination
      name="brands"
      resource="brands"
      inputPlaceholder="Filter Brands"
      tapOptions={(opts) => _.sortBy(opts, "name")}
      control={control}
    />
  );
};

const BrandFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedBrands = filterValues.brands ?? [];
  return (
    <ResourceFilterChip
      resource="brands"
      ids={selectedBrands}
      prefix="Brand is"
      contentWhenEmpty="Select Brand"
      skipsPagination
      {...props}
    />
  );
};

const brandsFilter = {
  name: "brands",
  menuText: "Brands",
  Popper: BrandPopper,
  Chip: BrandFilterChip,
};

export default brandsFilter;
