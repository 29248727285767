/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { useAuthUser } from "@features/auth";
import { Filters, useFilterParams } from "@features/filters";
import {
  ShelfInventoryItemTable,
  usePaginatedItemsQuery,
} from "@features/items";
import { PageTitle } from "@features/ui";

const ShelfInventoryAdmin = () => {
  const [filters] = useFilterParams();
  const { channel, territory } = useAuthUser();

  const params = {
    filter: {
      rtaDeployment: true,
      isArchived: false,
      isInventory: true,
      searchTerm: filters.q,
      favorites: filters.favorites,
      brandIds: filters.brands,
      programIds: filters.programs,
      itemTypeIds: filters.itemTypes,
      businessUnitNames: filters.businessUnits,
      orderCalendarMonthIds: filters.orderCalendarMonths,
      programTerritoryIds: filters.territories ?? (territory && [territory]),
      isAccolade: filters.isAccolade && filters.isAccolade === "yes",
      channel,
    },
  };

  const { data, ...tableProps } = usePaginatedItemsQuery(params);
  useNoFetch();

  return (
    <>
      <FixedHeightScrollLastChild>
        <div tw="flex items-center justify-between">
          <PageTitle title="Shelf Inventory Admin" />
        </div>
        <TableCardContainer>
          <Filters
            searchTitle="Search Items"
            alwaysShow={["brands"]}
            slots={[
              "favorites",
              "brands",
              "itemTypes",
              "programs",
              "businessUnits",
              "isAccolade",
              "territories",
            ]}
          />
          <ShelfInventoryItemTable rows={data} {...tableProps} />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default ShelfInventoryAdmin;
