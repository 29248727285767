/// <reference types="react/experimental" />
import { useEffect } from "react";

type ModifierKey = "shift" | "ctrl" | "alt" | "meta" | "cmd";
type KeyInput = string | [ModifierKey, string];

const useHotKey = (
  keyInput: KeyInput,
  callback: (event: KeyboardEvent) => void,
  deps: any[] = []
) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      let modifierMatch = true;
      let key: string;
      if (Array.isArray(keyInput)) {
        const [modifier, keyValue] = keyInput;
        console.log(event);
        key = keyValue;
        switch (modifier.toLowerCase()) {
          case "shift":
            modifierMatch = event.shiftKey;
            break;
          case "ctrl":
            modifierMatch = event.ctrlKey;
            break;
          case "alt":
            modifierMatch = event.altKey;
            break;
          case "meta":
          case "cmd":
            modifierMatch = event.metaKey;
            break;
          default:
            modifierMatch = false;
            break;
        }
      } else {
        key = keyInput;
      }

      if (event.key === key && modifierMatch) {
        callback(event);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useHotKey;
