/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Close } from "@mui/icons-material";
import { DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { StyledButton } from "@components/StyledComponents";
import { useMutateError } from "@features/errors";
import { useCancelOrderVariantsMutation } from "@features/orders";

import { useInput } from "../../../hooks/InputHooks";

const ConfirmCancelRollupItem = ({ open, handleClose, itemIds, type }) => {
  const setError = useMutateError();

  const [validationError, setValidationError] = useState<null | string>(null);
  const [option, setOption] = useState("comp");

  const cancelOrderVariantsMutation = useCancelOrderVariantsMutation();
  const handleCancel = (orderVariantIds: number[], note: string) => {
    cancelOrderVariantsMutation.mutate(
      { orderVariantIds, note },
      {
        onError: (e) => setError(e, "Cancel order variants"),
        onSuccess: () => handleClose(),
      }
    );
  };

  const {
    value: note,
    bind: bindNote,
    setValue: setNote,
  } = useInput("Compliance Canceled");

  const handleOption = (event) => {
    let opt = event.target.value;
    if (opt === "comp") {
      setNote("Compliance Canceled");
    } else if (opt === "moq") {
      setNote("MOQ not reached");
    } else setNote("");
    setOption(opt);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle tw="flex items-center justify-between">
        <span>Cancel Order Items</span>
        <div tw="flex items-center space-x-2">
          <IconButton onClick={handleClose} edge="end">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent tw="space-y-4">
        <Typography>
          {type === "po"
            ? "Deleting this Purchase Order Item will cancel all order items on approved orders that contain this item"
            : "Deleting this Quote Item will cancel all order items on approved orders that contain this item"}
        </Typography>
        <Typography>This action cannot be undone.</Typography>

        <FormControl variant="outlined" component="fieldset" focused={false}>
          <FormLabel component="legend">
            {"Cancellation Reason (Required)"}
          </FormLabel>
          <RadioGroup
            aria-label="reason"
            name="cancel-reason"
            value={option}
            onChange={handleOption}
          >
            <FormControlLabel
              value="comp"
              control={<Radio />}
              label="Compliance Canceled"
            />
            <FormControlLabel
              value="moq"
              control={<Radio />}
              label="MOQ not reached"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>

        <TextField
          color="secondary"
          multiline
          fullWidth
          variant="outlined"
          size="small"
          minRows={3}
          disabled={option !== "other"}
          {...bindNote}
        />

        <StyledButton
          variant="contained"
          color="secondary"
          onClick={() => {
            if (note.length === 0) {
              setValidationError(
                "Must provide a reason for cancelling item..."
              );
            } else {
              handleCancel(itemIds, note);
            }
          }}
          loading={cancelOrderVariantsMutation.isPending}
        >
          CANCEL ITEMS
        </StyledButton>
        {validationError && (
          <Typography tw="text-red-800">{validationError}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

ConfirmCancelRollupItem.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemId: PropTypes.any,
  type: PropTypes.string,
};

export default ConfirmCancelRollupItem;
