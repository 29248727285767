/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";

import { Card } from "@mui/material";

import { FaIcon } from "@features/ui";

export const ResourceError = ({ error, ...props }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);
  return (
    <Card
      tw="flex gap-3 items-start bg-red-50 border-red-100 p-6 max-w-md"
      {...props}
    >
      <FaIcon
        icon="triangle-exclamation"
        variant="solid"
        tw="text-red-600 mt-1"
      />
      <div>
        <h3 tw="text-lg text-red-600">Error fetching resource:</h3>
        <p tw="text-red-800">{error?.message}</p>
      </div>
    </Card>
  );
};
