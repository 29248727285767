/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";

import { ADMIN_ROLES } from "src/constants/permissions";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Bordered } from "@features/filters";
import { allocationName, useItemQuery } from "@features/items";
import VariantAllocationTable from "@features/items/variantAllocations/VariantAllocationTable";
import { FaIcon, PageTitle } from "@features/ui";
import { VariantAllocation } from "@models/VariantAllocation";
import useRoleIs from "@utils/useRoleIs";

import VariantAllocationModal from "../features/items/variantAllocations/VariantAllocationModal";
import { useDetailedInput } from "../hooks/InputHooks";
import { useNoFetch } from "../hooks/UtilityHooks";

/*
Shelf inventory is a bit of a confusing term, but it stands for inventory allocations for Gallo. Each
variant can have any of it's inventory allocated to various territories, and this view handles creating
and editing those allocations.
*/

const ShelfInventory = () => {
  const { id } = useParams();
  const roleIs = useRoleIs();

  const [showInactiveVariants, setShowInactiveVariants] = useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState<
    null | "new" | VariantAllocation
  >(null);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );

  const { data: item, isFetching } = useItemQuery(id!);

  const { value: search, bind: bindSearch } = useDetailedInput("", () => {});

  const variants = item?.variants ?? [];

  const variantOptions = useMemo(
    () =>
      variants
        .filter((v) => showInactiveVariants || v.isActive)
        .map((v) => ({
          id: v.id,
          label: v.variantSku + (!v.isActive ? " (Inactive)" : ""),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item?.variants, showInactiveVariants]
  );

  const variant = variants.find((v) => v.id === selectedVariantId);

  const searchSeqments = search.toLowerCase().split(" ").filter(Boolean);
  const variantAllocations = (variant?.variantAllocations ?? []).filter((a) => {
    const str = allocationName(a).toLowerCase();
    return searchSeqments.every((s) => str.includes(s));
  });

  useEffect(() => {
    if (
      variantOptions.length > 0 &&
      !variantOptions.some((v) => v.id === selectedVariantId)
    ) {
      setSelectedVariantId(variantOptions[0].id);
    }
  }, [variantOptions, selectedVariantId]);

  useNoFetch();

  return (
    <>
      {selectedAllocation && variant && (
        <VariantAllocationModal
          variant={variant}
          allocation={selectedAllocation === "new" ? null : selectedAllocation}
          item={item!}
          handleClose={() => setSelectedAllocation(null)}
        />
      )}

      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap items-center lg:justify-between gap-6">
          <PageTitle
            fallbackUrl="/shelf-inventory"
            title={`Setting Shelf Inventory for ${item?.itemNumber ?? ""}`}
          >
            {!item && <Skeleton variant="text" width={100} height={48} />}
          </PageTitle>
          {item && variant && (
            <div tw="flex flex-row-reverse lg:flex-row flex-1 gap-6 justify-end">
              {item.variants!.some((v) => !v.isActive) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={showInactiveVariants}
                      onChange={() => setShowInactiveVariants((v) => !v)}
                    />
                  }
                  label={
                    <span tw="text-sm whitespace-nowrap">
                      Show Inactive Variants
                    </span>
                  }
                  labelPlacement="start"
                />
              )}
              <FormControl size="small" tw="flex-1 w-full max-w-sm">
                <InputLabel id="variant-label">Sequence #</InputLabel>
                <Select
                  label="Sequence #"
                  labelId="variant-label"
                  value={variant.id}
                  onChange={(evt) => setSelectedVariantId(evt.target.value)}
                >
                  {variantOptions.map((v) => (
                    <MenuItem value={v.id} key={v.id}>
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        {!item && <CircularProgress />}
        {variant && item && (
          <div tw="flex flex-col gap-6 lg:flex-row lg:items-start overflow-hidden">
            <Card tw="flex lg:flex-col">
              <div tw="space-y-3 p-6 relative">
                <Typography>
                  <b>On Hand Inventory:</b>
                  {` ${variant.cachedWarehouseQty}`}
                </Typography>
                <Typography>
                  <b>Available Allocated Inventory:</b>
                  {` ${variant.availableToOrderQty}`}
                </Typography>
                <Typography>
                  <b>Pending Inventory: </b>
                  {variant.cachedWarehouseQty ??
                    0 - (variant.availableToOrderQty ?? 0)}
                </Typography>
                <Typography>
                  <b>Available Unallocated Inventory:</b>
                  {` ${variant.nationalAvailableToOrderQty}`}
                </Typography>
                {isFetching && (
                  <LinearProgress
                    tw="absolute inset-0 top-auto"
                    color="secondary"
                  />
                )}
              </div>
              <Divider />
              <div tw="space-y-3 p-6">
                <Typography>
                  <b>Item Type:</b>
                  {` ${item.type}`}
                </Typography>
                <Typography>
                  <b>Brand:</b>
                  {` ${item.brands.map((b) => b.name).join(", ")}`}
                </Typography>
                <Typography>
                  <b>Description:</b>
                  {` ${item.comment}`}
                </Typography>
              </div>
            </Card>

            <div tw="flex-1 flex flex-col h-full overflow-hidden">
              <TableCardContainer>
                <div tw="flex justify-between items-center gap-3">
                  <Bordered tw="relative h-8 focus-within:border-primary-500 focus-within:bg-white w-72">
                    <InputBase
                      startAdornment={<FaIcon icon="search" tw="mr-2" />}
                      autoFocus
                      tw="py-1 pl-3 pr-8 text-sm w-full"
                      placeholder={"Search Territory Allocations"}
                      inputProps={{
                        sx: {
                          "&::placeholder": tw`opacity-100 text-neutral-500`,
                        },
                      }}
                      {...bindSearch}
                    />
                  </Bordered>
                  {roleIs(ADMIN_ROLES as any) && (
                    <Button
                      variant="contained"
                      onClick={() => setSelectedAllocation("new")}
                      endIcon={<FaIcon icon="plus" />}
                    >
                      New Allocation
                    </Button>
                  )}
                </div>

                <VariantAllocationTable
                  variantAllocations={variantAllocations}
                  setSelectedAllocation={setSelectedAllocation}
                  isFetching={isFetching}
                />
              </TableCardContainer>
            </div>
          </div>
        )}
      </FixedHeightScrollLastChild>
    </>
  );
};

export default ShelfInventory;
