/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useProgramInfo } from "../../../hooks/planningToolHooks";
import { clearNewEntityDefaults } from "../../../redux/slices/planningTool/itemProgramSlice";
import CollapsibleTable from "../../CollapsibleTable";
import { SmallIndexCounter } from "../../StyledComponents";
import ItemEdit from "./ItemEdit";
import ItemNew from "./ItemNew";
import NoItemsToShow from "./NoItemsToShow";

const ItemSection = ({ items }) => {
  const dispatch = useDispatch();
  const { newEntityDefaults, isLoading } = useSelector(
    (state) => state.itemPrograms
  );
  const [defaultValues, setDefaultValues] = useState({});
  const { brandAssignmentLevel } = useProgramInfo();

  const whenItemBrands = (col) =>
    brandAssignmentLevel === "item" ? [col] : [];

  useEffect(() => {
    if (newEntityDefaults.type === "item") {
      setDefaultValues(newEntityDefaults);
      dispatch(clearNewEntityDefaults());
    }
  }, [newEntityDefaults, dispatch]);
  return (
    <>
      <ItemNew defaultValues={defaultValues} />

      {!isLoading && items.length > 0 ? (
        <CollapsibleTable
          unmountOnExit={false}
          headers={[
            { cell: "Sequence #" },
            ...whenItemBrands({ cell: "Brand" }),
            { cell: "Item Type" },
            { cell: "Description" },
            { cell: "Status" },
            { cell: "" },
          ]}
          rows={items.map(({ openItemRow, ...item }, i) => ({
            key: item.id,
            row: [
              {
                cell: <b>{item.itemNumber || "---"}</b>,
              },
              ...whenItemBrands({
                cell: item.brands.map((i) => i.name).join(", "),
              }),
              { cell: item.type },
              { cell: item.comment || "" },
              { cell: item.itemStatus || "" },
              {
                cell: <SmallIndexCounter>{i + 1}</SmallIndexCounter>,
                style: tw`absolute inset-0 left-auto border-0`,
              },
            ],
            content: <ItemEdit item={item} />,
            expanded: openItemRow,
          }))}
        />
      ) : (
        <NoItemsToShow name="items" loading={isLoading} />
      )}
    </>
  );
};

export default ItemSection;
