import addDays from "date-fns/addDays";
import format from "date-fns/format";
import _ from "lodash";

import {
  formatDateString,
  stringToCents,
} from "../../../utility/utilityFunctions";
import {
  handleImages,
  handleVariants,
  mapSpecifications,
} from "../../mapHelpers";

const orderTypeMap = {
  "pre-order": "Pre-Order",
  "from-inventory": "In Stock",
  "on-demand": "On-Demand",
};

const handleCalendarMonths = (program) => {
  if (program["order-calendar-months"].length === 1) {
    return (
      new Date(program["order-calendar-months"][0]["order-window-open-date"]) <=
        new Date() &&
      new Date() <=
        new Date(
          program["order-calendar-months"][0]["order-window-close-date"]
        ) &&
      !program["is-ad-hoc"]
    );
  } else {
    for (let i = 0; i < program["order-calendar-months"].length; i++) {
      if (
        new Date(
          program["order-calendar-months"][i]["order-window-open-date"]
        ) <= new Date() &&
        new Date() <=
          new Date(
            program["order-calendar-months"][i]["order-window-close-date"]
          ) &&
        !program["is-ad-hoc"]
      ) {
        return true;
      }
    }
    return false;
  }
};

const sortPrograms = (programs) => {
  let sortedPrograms = programs
    .sort((a, b) => {
      return new Date(a["start-date"]) - new Date(b["start-date"]);
    })
    .reverse();
  let currentProgram = sortedPrograms.find((prog) =>
    handleCalendarMonths(prog)
  );
  if (currentProgram) {
    sortedPrograms.sort((a, b) =>
      a.id === currentProgram.id ? -1 : b.id === currentProgram.id ? 1 : 0
    );
  }
  return sortedPrograms;
};

const mapItem = (item) => {
  const images = handleImages(item.images);
  const sortedPrograms = sortPrograms(item.programs);
  const variants = handleVariants(item.variants);

  const variantNames =
    variants.filter((v) => v.name).length > 0
      ? variants
          .filter((v) => v.name)
          .map((v) => v.name.split(": ")[1])
          .join(", ")
      : null;
  return {
    id: item.id,
    itemNumber: item["item-number"],
    variants: variants,
    variantNames: variantNames,
    brand: item.brands.map((brand) => brand.name),
    program: sortedPrograms.map((prog) => prog.name),
    programs: sortedPrograms.map((p) => ({
      ..._.pick(p, ["id", "name", "status"]),
      orderWindowOpenDate:
        p["order-calendar-month"]?.["order-window-open-date"],
      orderCalendarMonthId: p["order-calendar-month"]?.id,
      inMarketStartDate: formatDateString(
        p["order-calendar-month"]?.["in-market-start-date"]
      ),
    })),
    unit:
      item.brands?.length > 0
        ? [
            ...new Set(item.brands.map((brand) => brand["business-unit"].name)),
          ][0]
        : "---",
    programIds: item.programs.map((prog) => prog.id),
    itemType: item.type,
    orderType:
      item["item-status"] === "Ok"
        ? orderTypeMap[item["item-order-type"]]
        : item["item-status"],
    projectNum: item["at-task-project-id"] ? item["at-task-project-id"] : "---",
    specification: item.specification
      ? mapSpecifications(item.specification, "item-display")
      : null,
    fullSpecification: item.specification
      ? mapSpecifications(item.specification, "supplier-display")
      : null,
    itemDescription: item.comment ? item.comment : "---",
    estCost: stringToCents(item["most-recent-estimated-cost"]),
    moq: item["most-recent-moq"],
    packSize: item["qty-per-pack"],
    stock: variants.reduce((a, b) => a + b.qty, 0),
    inMarketDate: sortedPrograms[0]
      ? formatDateString(sortedPrograms[0]["start-date"])
      : null,
    poInMarketDate: item["po-in-market-date"]
      ? format(addDays(new Date(item["po-in-market-date"]), 15), "MM/dd/yyyy")
      : null,
    warehouse: item.warehouse,
    supplierId: item.supplier?.id,
    isCoupon: item["is-coupon"],
    isInventory: item["is-inventory"],
    couponInfo: item["coupon-custom-raw-payload"]
      ? {
          startDate: formatDateString(
            item["coupon-custom-raw-payload"].CouponStartDate
          ),
          expirationDate: formatDateString(
            item["coupon-custom-raw-payload"].CouponExpirationDate
          ),
          typeCode: item["coupon-custom-raw-payload"].CouponTypeCode,
          offerType: item["coupon-custom-raw-payload"].CouponOfferTypeCode,
          description: item["coupon-custom-raw-payload"].CouponOfferDescription,
          bottles: item["coupon-custom-raw-payload"].Quantity,
          bottleDiscount:
            item["coupon-custom-raw-payload"].CouponFaceValue * 100,
        }
      : null,
    couponStartDate: item["coupon-issue_date"]
      ? formatDateString(item["coupon-issue-date"])
      : null,
    couponExpirationDate: item["coupon-expiration-date"]
      ? formatDateString(item["coupon-expiration-date"])
      : null,
    couponOfferType: item["coupon-offer-type-code"]
      ? item["coupon-offer-type-code"]
      : null,
    couponOfferDescription: item["coupon-offer-description"]
      ? item["coupon-offer-description"]
      : null,
    couponTypeCode: item["coupon-type-code"] ? item["coupon-type-code"] : null,
    imgUrlThumb: images.imgUrlThumb,
    imgUrlLg: images.imgUrlLg,
    removeFromCatalogDate: item["remove-from-catalog-date"],
    includeBeacon: item["include-beacon"],
    mostRecentProgramId: item["most-recent-program-id"],
    mostRecentBeaconCost: item["most-recent-beacon-cost"],
    standardSpecificationCode: item["standard-specification-code"],
    baseVariantIsActive: item.variants.some(
      (v) => v["is-active"] && v["selected-variant-options"].length === 0
    ),
  };
};

export const mapItems = (items) => items.map(mapItem);

export const mapItemPrograms = (ips) =>
  ips.map((ip) => ({
    // Get what we can from the item map
    ...mapItem(ip.item),
    // Override `most-recent-...` with actual value
    estCost: stringToCents(ip["estimated-cost"]),
    moq: ip["moq"],
  }));

export const mapBrands = (brands) => {
  return brands.map((brand) => ({
    id: brand.id,
    name: brand.name,
    isActive: brand["is-active"],
    brandCode: brand["external-id"],
    productFamilyId: brand["product-family"]?.id || null,
    productFamilyCode: brand["product-family"]?.["external-id"] || null,
    buId: brand["business-unit"]?.id,
    buCode: brand["business-unit"]?.["external-id"],
    label: brand["external-id"] + " " + brand.name,
    company: brand.company,
    mandatoryLine: brand["mandatory-line"],
  }));
};

export const mapItemReport = (itemPrograms) =>
  itemPrograms.map((ip) => ({
    ...mapItem(ip.item),
    program: [ip.program.name],
    inMarketDate: formatDateString(
      ip.program["order-calendar-month"]?.["in-market-start-date"]
    ),
    // Override `most-recent-...` with actual value
    estCost: stringToCents(ip["estimated-cost"]),
    moq: ip.moq,
  }));
