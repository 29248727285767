/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Card, Skeleton } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { PageTitle } from "@features/ui";

import { useBudgetQuery } from "../queries";

export const BudgetInfo = ({ budgetId }) => {
  const { data: budget } = useBudgetQuery(budgetId);
  return (
    <div>
      <div tw="flex items-center mt-6 mb-3">
        <PageTitle
          tw="my-0"
          fallbackUrl="/budgets"
          title={
            budget ? `Budget ${budget.subStateCode} ${budget.brandName}` : ""
          }
        >
          {!budget && <Skeleton variant="text" width={200} height={48} />}
        </PageTitle>
      </div>
      {budget && (
        <div tw="flex gap-x-4 flex-wrap text-neutral-600">
          <div tw="p-3">
            <ul>
              <li>External ID: {budget.externalId}</li>
              <li>Brand: {budget.brandName}</li>
              <li>
                State:{" "}
                {!budget.subStateName ||
                budget.subStateCode === budget.subStateName
                  ? budget.subStateCode
                  : budget.subStateCode + " " + budget.subStateName}
              </li>
              <li>Year: {budget.year}</li>
            </ul>
          </div>
          <Card tw="space-y-3 bg-neutral-50 p-6 flex-1">
            {[
              ["Budget Total", budget.totalAmount],
              ["Pending", budget.pendingAmount],
              ["Committed", budget.committedAmount],
              ["Spent", budget.spentAmount],
              ["Available", budget.availableAmount],
            ].map(([label, value]) => (
              <div
                tw="flex justify-between items-baseline last-of-type:font-medium first-of-type:(border-b pb-2)"
                key={label}
              >
                <div tw="text-neutral-800">{label}</div>
                <div tw="text-right text-neutral-900">
                  {formatMoneyString(value)}
                </div>
              </div>
            ))}
          </Card>
        </div>
      )}
    </div>
  );
};
