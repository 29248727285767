import React, { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { PageTitle, Type } from "@features/ui";

import { useDetailedInput } from "../../hooks/InputHooks";
import {
  clearAllAddressList,
  fetchAddresses,
  fetchAnyNextAddresses,
  fetchInitialAddresses,
  setTriggerCSVFalse,
} from "../../redux/slices/addresses/addressSlice";
import AddressBookTable from "./AddressBookTable";
import AddressModal from "./AddressModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const CustomAddresses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [modal, handleModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [status, setStatus] = useState("active");
  const [currentAddressId, setCurrentAddressId] = useState(null);

  const { addressList, isLoading, nextLink, isNextLoading } = useSelector(
    (state) => state.addresses
  );
  const { currentTerritory, states, role } = useSelector((state) => state.user);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading && !isLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchAnyNextAddresses(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleModalClose = () => {
    handleModal(false);
    setCurrentAddressId(null);
  };

  const handleModalOpen = (id, type) => {
    setCurrentAddressId(id);
    setModalType(type);
    handleModal(true);
  };

  const handleAddressSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      role !== "super" && role !== "purchaser" && role !== "select-purchaser"
        ? dispatch(
            fetchAddresses(
              value,
              currentTerritory,
              states.map((state) => state.id).join(","),
              false,
              "custom"
            )
          )
        : dispatch(fetchAddresses(value, currentTerritory, false, false, null));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleAddressSearch
  );

  const handleActiveSwitch = () => {
    role !== "super" && role !== "purchaser" && role !== "select-purchaser"
      ? dispatch(
          fetchAddresses(
            search,
            currentTerritory,
            states.map((state) => state.id).join(","),
            false,
            "custom",
            status === "active" ? false : true
          )
        )
      : currentTerritory
      ? dispatch(
          fetchAddresses(
            search,
            currentTerritory,
            false,
            false,
            "custom",
            status === "active" ? false : true
          )
        )
      : dispatch(
          fetchAddresses(
            search,
            false,
            false,
            false,
            "custom",
            status === "active" ? false : true
          )
        );
    setStatus(status === "active" ? "inactive" : "active");
  };

  useEffect(() => {
    dispatch(fetchInitialAddresses(currentTerritory));
    dispatch(clearAllAddressList());
    dispatch(setTriggerCSVFalse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {modal && (
        <AddressModal
          open={modal}
          handleClose={handleModalClose}
          type={modalType}
          id={currentAddressId}
          addressList={addressList}
          disabled={!currentTerritory}
        />
      )}
      <div className={classes.titleBar}>
        <PageTitle title="Edit Custom Addresses" />
        <div className={classes.innerConfigDiv}>
          <Button
            className={classes.largeButton}
            variant="contained"
            onClick={() => handleModalOpen(null, "new")}
            disabled={!currentTerritory}
          >
            NEW ADDRESS
          </Button>
        </div>
      </div>
      {!currentTerritory && (
        <Type.Small>
          <i>
            * Editing and Creating Addresses is disabled in All Regions, select
            a Territory if you wish to edit or create.
          </i>
        </Type.Small>
      )}
      <br />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Addresses"
          value={search}
          {...bindSearch}
        />
        <div
          style={{
            width: "22%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={status === "active"}
                onChange={handleActiveSwitch}
                name="active-switch"
              />
            }
            label={status === "active" ? "Active" : "Inactive"}
          />
        </div>
      </div>
      <br />
      <br />
      <AddressBookTable
        handleAddressClick={handleModalOpen}
        addresses={addressList}
        isLoading={isLoading}
        scrollRef={scrollRef}
        type={"custom"}
      />
      {isNextLoading && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
      {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
    </>
  );
};

export default CustomAddresses;
