/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useQueryClient } from "@tanstack/react-query";
import { formatMoneyString } from "src/utility/utilityFunctions";

import {
  Column,
  PaginatedResourceProps,
  SmartTable,
  TableLink,
} from "@components/SmartTable";
import { Budget } from "@models/Budget";

import { budgetsKeyFactory } from "./queries";

type BudgetsTableProps = PaginatedResourceProps & {
  rows: Budget[];
  onRowClick?: (row: Budget) => void;
};

const BudgetsTable = (tableProps: BudgetsTableProps) => {
  const queryClient = useQueryClient();
  const budgetTableColumns: Column<Budget>[] = [
    { id: "id", label: "ID" },
    {
      id: "externalId",
      label: "External ID",
      render: (externalId, budget) => (
        <TableLink
          to={`/budgets/${budget.id}`}
          onClick={() => {
            queryClient.setQueryData(
              budgetsKeyFactory.detail(budget.id).queryKey,
              budget
            );
          }}
        >
          {externalId}
        </TableLink>
      ),
    },
    {
      id: "_brand",
      label: "Brand",
      render: (_, budget) => budget.brandExternalId + " " + budget.brandName,
    },
    {
      id: "_subState",
      label: "State",
      render: (_, budget) =>
        budget.subStateCode === budget.subStateName
          ? budget.subStateCode
          : budget.subStateCode + " " + (budget.subStateName ?? ""),
    },
    { id: "year", label: "year", align: "right" },
    {
      id: "totalAmount",
      label: "Initial Amount",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "availableAmount",
      label: "Available Amount",
      align: "right",
      renderText: (v) => formatMoneyString(v),
      render: (v) => (
        <span css={[v < 0 && tw`font-medium text-red-700`]}>
          {formatMoneyString(v)}
        </span>
      ),
    },
  ];

  return <SmartTable columns={budgetTableColumns} {...tableProps} />;
};

export default BudgetsTable;
