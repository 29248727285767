/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterTerritories = makeResourceAutocompleteFilter({
  name: "territories",
  resource: "territories",
  titleSingular: "Territory",
  titlePlural: "Territories",
  tapOptions: (opts) => _.sortBy(opts, (t) => t.type === "Customer", "name"),
  skipsPagination: true,
});

export default filterTerritories;
