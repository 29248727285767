import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import subDays from "date-fns/subDays";

import { DatePicker } from "../components/Forms/DefaultInputs";
import ReduxReportGenerator from "../components/Reporting/ReduxReportGenerator";
import { getCustomCouponReport } from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportCoupon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const isLoading = useSelector((state) => state.reports.isLoading);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const handleReset = () => {
    setFromDate(format(subDays(new Date(), 7), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
  };

  const handleGetReport = () => {
    const filterObject = {
      dateRange: `${fromDate} - ${toDate}`,
      channel: currentChannel,
    };
    dispatch(getCustomCouponReport(filterObject));
  };

  const datePickerSharedStyles = {
    fullWidth: true,
    color: "secondary",
    className: classes.queryField,
    disableToolbar: true,
    variant: "inline",
    format: "MM/dd/yyyy",
    margin: "normal",
    disabled: isLoading,
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle={"Coupon Report"}
        fileName={"coupon_report.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
        reportType={"coupon"}
      >
        <div className={classes.searchComponents}>
          <DatePicker
            {...datePickerSharedStyles}
            id="fromDate"
            label="Order From Date"
            value={fromDate}
            onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
          />
          <DatePicker
            {...datePickerSharedStyles}
            id="toDate"
            label="Order To Date"
            value={toDate}
            onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
          />
        </div>
      </ReduxReportGenerator>
    </>
  );
};

export default ReportCoupon;
