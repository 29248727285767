/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import {
  setChips,
  setSorted,
  updateMultipleFilters,
} from "src/redux/slices/filterSlice";

import { Brand } from "@models/Brand";
import { useApiResource } from "@services/api";

const OrderSetsWithNoBudgetsAlert = ({ brand }: { brand: Brand }) => {
  const dispatch = useDispatch();
  const { currentChannel } = useSelector((state: any) => state.user);
  const { meta, isLoading } = useApiResource("order-sets", {
    params: {
      filter: {
        type: "pre-order",
        brandIds: [brand.id],
        noBudgets: true,
        channel: currentChannel,
        status: "submitted",
      },
      page: { size: 1 },
    },
    staleTime: 5 * 60 * 1000,
  });

  const handleSetFilters = () => {
    dispatch(
      updateMultipleFilters({
        filterObject: {
          brand: [{ id: brand.id, name: brand.name }],
          noBudgets: true,
          preventFetch: false,
        },
      })
    );
    dispatch(setChips({}));
    dispatch(setSorted());
  };

  if (isLoading)
    return (
      <div tw="bg-neutral-200 text-sm p-2 rounded-lg text-neutral-700">
        Checking for other orders...
      </div>
    );

  return meta && meta.totalEntries > 0 ? (
    <div tw="bg-orange-50 text-sm p-2 rounded-lg text-orange-900">
      This brand has {meta.totalEntries} order set
      {meta.totalEntries === 1 ? "" : "s"} without budgets.{" "}
      <button onClick={handleSetFilters} tw="underline text-sm">
        View Here
      </button>
    </div>
  ) : null;
};

export default OrderSetsWithNoBudgetsAlert;
