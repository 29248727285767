/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { addMonths, format, isBefore } from "date-fns";
import _ from "lodash";

import ControlledFilterAutocomplete from "../components/ControlledFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const RTA_START_DATE = new Date("2021-01-02");
const LATEST_MONTH_DATE = addMonths(new Date().setDate(0), 12);
const today = new Date();
const months: Date[] = [];
for (let i = RTA_START_DATE; i <= LATEST_MONTH_DATE; i = addMonths(i, 1)) {
  months.push(i);
}

const options = _(months)
  .map((date) => ({
    id: format(date, "M-yyyy"),
    name: format(date, "yyyy MMMM"),
    group: isBefore(date, today) ? "Past" : "Upcoming",
  }))
  .orderBy("group", "desc")
  .value();

const Popper = () => {
  const { control } = useFilterContext();
  return (
    <ControlledFilterAutocomplete
      name="inMarketMonths"
      options={options}
      inputPlaceholder="Filter In Market Month"
      control={control}
      groupBy={(option) => option.group}
    />
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedOptions = filterValues.inMarketMonths ?? [];
  return (
    <ResourceFilterChip
      options={options}
      ids={selectedOptions}
      prefix="In Market Month is"
      contentWhenEmpty="Select In Market Month"
      {...props}
    />
  );
};

const filterInMarketMonths = {
  name: "inMarketMonths",
  menuText: "In Market Month",
  Popper,
  Chip,
};

export default filterInMarketMonths;
