/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import {
  ComplianceRulesTable,
  usePaginatedRulesQuery,
} from "@features/compliance";
import { Filters, useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";

const ComplianceRules = () => {
  const [filters] = useFilterParams();

  const params = {
    filter: {
      isActive: true,
      status: filters.triggeredRuleStatus,
      type: filters.ruleType,
    },
    sort: filters.sort,
  };
  useReport("rules", ["rules", params]);
  const tableData = usePaginatedRulesQuery(params);
  useNoFetch();

  return (
    <>
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between items-center gap-3 flex-wrap">
          <PageTitle title="Compliance Rules" />
          <div tw="flex items-center gap-3">
            <RequestReportButton reportName="rules" />
          </div>
        </div>
        <TableCardContainer>
          <Filters
            hideSearch
            alwaysShow={["ruleType", "triggeredRuleStatus"]}
            slots={["ruleType", "triggeredRuleStatus"]}
          />
          <ComplianceRulesTable {...tableData} />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default ComplianceRules;
