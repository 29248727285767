/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterProgramCreators = makeResourceAutocompleteFilter({
  name: "programCreators",
  resource: "users",
  titleSingular: "Program Creator",
  titlePlural: "Program Creators",
  requestParams: { filter: { isProgramCreator: true }, sort: "name" },
  resourceFilterName: "name-or-email",
});

export default filterProgramCreators;
