import { useSelector } from "react-redux";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { UserRole } from "@models/UserRole";

import FourOhFour from "../pages/FourOhFour";
import routesRoot from "./routesRoot";
import { protectedRoute } from "./types";

// Recursively filters protectedRoutes based on the user's role
// If the user is not allowed to access a route, return a 404 page without children,
// otherwise, recursively protect child routes
const protectRoutes = (
  role: UserRole,
  routes: protectedRoute[]
): RouteObject[] =>
  routes.map((route) => {
    if (!route.allowedRoles || route.allowedRoles.includes(role)) {
      return {
        ...route,
        // Recursively protect child routes
        children: protectRoutes(
          role,
          route.children?.map((child) => ({
            ...child,
            allowedRoles:
              child.allowedRoles ?? route.defaultChildrenAllowedRoles,
          })) || []
        ),
      } as RouteObject;
    }
    return {
      ...route,
      element: <FourOhFour />,
    } as RouteObject;
  });

const ProtectedRouterProvider = (props) => {
  const { role } = useSelector((state: any) => state.user);
  const protectedRouter = createBrowserRouter(protectRoutes(role, routesRoot));
  return <RouterProvider router={protectedRouter} {...props} />;
};

export default ProtectedRouterProvider;
