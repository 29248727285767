/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import fiscalYear from "@features/budgets/fiscalYear";

import ControlledFilterAutocomplete from "../components/ControlledFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const Popper = () => {
  const { control } = useFilterContext();
  return (
    <ControlledFilterAutocomplete
      name="fiscalYear"
      options={fiscalYear.options}
      inputPlaceholder="Filter Fiscal Year"
      control={control}
    />
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedFiscalYears = filterValues.fiscalYear ?? [];
  return (
    <ResourceFilterChip
      options={fiscalYear.options}
      ids={selectedFiscalYears}
      prefix="Fiscal Year is"
      contentWhenEmpty="Select Fiscal Year"
      {...props}
    />
  );
};

const fiscalYearFilter = {
  name: "fiscalYear",
  menuText: "Fiscal Year",
  Popper,
  Chip,
};

export default fiscalYearFilter;
