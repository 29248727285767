import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import _ from "lodash";
import { formatDateString, utcDate } from "src/utility/utilityFunctions";
import colors from "tailwindcss/colors";

import { getItemPreviewImage } from "@features/items/helpers";
import { orderTypeLabel } from "@features/orders";
import { OrderVariant } from "@models/OrderVariant";
import cld from "@services/cloudinary";
import imageSizeOptions from "@services/cloudinary/imageSizeOptions";

const monthFromDateString = (dateString) =>
  dateString && format(utcDate(dateString), "MMMM");

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
  ],
});

const columns = [
  {
    label: "Image Preview",
    key: "itemImage",
    width: 100,
  },
  { label: "Sequence #", key: "itemNumber", width: 100 },
  {
    label: "Order Type / Window",
    key: "_orderType",
    width: 120,
    tf: (_, ov) =>
      ov.orderType === "pre-order"
        ? ov.orderWindowName ?? orderTypeLabel(ov.orderType)
        : orderTypeLabel(ov.orderType),
  },
  {
    label: "In Market Month",
    key: "_inMarketMonth",
    width: 100,
    tf: (_, ov) =>
      monthFromDateString(ov.inMarketDate) ??
      monthFromDateString(ov.poInMarketDate),
  },
  {
    label: "Brand",
    key: "variant.item.brands",
    width: 80,
    tf: (brands) => brands.map((b) => b.name).join(", "),
  },
  {
    label: "BU",
    key: "_businessUnitNames",
    width: 90,
    tf: (__, ov) =>
      _(ov.variant.item.brands).map("businessUnit.name").join(", "),
  },
  {
    label: "Program",
    key: "programNames",
    width: 200,
    tf: (p) => p.join(", "),
  },
  { label: "Item Type", key: "itemTypeDescription", width: 90 },
  {
    label: "Item Description",
    key: "variant.item.comment",
    width: 150,
  },
  {
    label: "Distributor / Name",
    key: "addressName",
    width: 130,
  },
  { label: "Total Qty", key: "qty", width: 60, align: "right" },
  {
    label: "In Market Date",
    key: "inMarketDate",
    width: 120,
    tf: (d) => formatDateString(d),
  },
  {
    label: "Act. Ship Date",
    key: "shipDate",
    width: 120,
    tf: (d) => formatDateString(d),
  },
  { label: "Tracking #", key: "tracking", width: 120 },
];

const OrderHistorySalesSummaryPDF = ({ data }: { data: OrderVariant[] }) => {
  return (
    <Document>
      <Page
        size={[600, 1000]}
        orientation="landscape"
        style={{ fontFamily: "Roboto" }}
      >
        <View
          style={{
            flexDirection: "row",
            fontSize: 10,
            fontWeight: "bold",
          }}
          fixed
        >
          {columns.map((col) => (
            <View
              style={{
                width: col.width,
                padding: 5,
              }}
              key={col.key}
            >
              <Text style={{ textAlign: (col.align as any) ?? "left" }}>
                {col.label}
              </Text>
            </View>
          ))}
        </View>
        {data.map((row, i) => (
          <View
            key={i}
            style={{
              // borderBottom: `1px solid ${colors.neutral[200]}`,
              ...(i % 2 === 0 && { backgroundColor: colors.gray[100] }),
              flexDirection: "row",
              fontSize: 10,
              marginVertical: 10,
              marginRight: 2,
            }}
            wrap={false}
          >
            {columns.map((col) => {
              const image = getItemPreviewImage(row.variant.item);
              const imageSrc = image
                ? cld.url(
                    image?.cloudinaryId + ".jpg",
                    imageSizeOptions.thumbnail
                  )
                : "https://res.cloudinary.com/brandhub/image/upload/v1685722984/prod/Brandhub/no-image-square_iefxuz.png";
              return (
                <View
                  style={{
                    width: col.width,
                    padding: 5,
                  }}
                  key={col.key}
                >
                  {col.key === "itemImage" ? (
                    <Image src={imageSrc} />
                  ) : (
                    <Text
                      style={{
                        textAlign: (col.align as any) ?? "left",
                        width: "100%",
                      }}
                    >
                      {col.tf
                        ? col.tf(_.get(row, col.key), row)
                        : _.get(row, col.key)}
                    </Text>
                  )}
                </View>
              );
            })}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default OrderHistorySalesSummaryPDF;
