/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterUsers = makeResourceAutocompleteFilter({
  name: "users",
  resource: "users",
  titleSingular: "User",
  titlePlural: "Users",
  requestParams: { sort: "name" },
  resourceFilterName: "name-or-email",
});

export default filterUsers;
