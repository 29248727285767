/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";
import { stateNames } from "src/utility/constants";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterStates = makeResourceAutocompleteFilter({
  name: "states",
  resource: "states",
  titleSingular: "State",
  titlePlural: "States",
  getOptionText: (state) => stateNames[state.code] ?? state.code ?? "",
  tapOptions: (opts) =>
    _.sortBy(opts, (opts) => stateNames[opts.code] ?? opts.code),
  skipsPagination: true,
});

export default filterStates;
