import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import Logo from "../assets/RTA_Logo_Stacked.png";

/*
New Gallo users that have not yet been assigned a role, or territories will be presented with
this view upon logging in.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  newUserWrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
  },
  infoWrapper: {
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    position: "fixed",
    top: "20px",
    left: "20px",
    width: "150px",
    height: "auto",
    filter: "brightness(0%)",
  },
  logout: {
    position: "fixed",
    top: "20px",
    right: "20px",
  },
}));

const NewUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.newUserWrapper}>
      <img src={Logo} className={classes.logo} alt="Logo" />
      <Button
        className={clsx(classes.logout, classes.largeButton)}
        variant="contained"
        color="secondary"
        onClick={() => navigate("/logout")}
      >
        LOGOUT
      </Button>
      <Container className={classes.infoWrapper}>
        <div
          style={{
            height: "70vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item sm={2} xs={1} />
            <Grid item sm={8} xs={10} style={{ textAlign: "center" }}>
              <Typography className={classes.titleText} variant="h5">
                {`Welcome to Ready to Activate ${firstName} ${lastName}`}
              </Typography>
              <br />
              <Typography className={classes.headerText}>
                It looks like your account hasn't been set up yet.
              </Typography>
              <br />
              <Typography className={classes.headerText}>
                Please Email the help desk to have your permissions and regions
                configured.
              </Typography>
              <Typography className={classes.headerText}>
                help@readytoactivate.com
              </Typography>
            </Grid>
            <Grid item sm={2} xs={1} />
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default NewUser;
