import { useEffect, useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import _ from "lodash";

import { routeResourceTypes, useApiResource } from "@services/api";

export default function useSelectedResources<
  T extends keyof routeResourceTypes,
>(
  resourceType: T,
  resourceIds: string[],
  areAllResourcesOnFirstPage?: boolean
): { data: routeResourceTypes[T][]; isLoading: boolean } {
  const [shouldFilter, setShouldFilter] = useState(!areAllResourcesOnFirstPage);
  const [filterIds, setFilterIds] = useState(resourceIds ?? []);
  const { data, isLoading } = useApiResource(
    resourceIds.length > 0 && resourceType,
    {
      ...(shouldFilter && { filter: { ids: filterIds } }),
      staleTime: 5 * 60 * 1000,
      placeholderData: keepPreviousData,
    }
  );

  useEffect(() => {
    if (areAllResourcesOnFirstPage) {
      setShouldFilter(false);
    }
  }, [areAllResourcesOnFirstPage]);

  useEffect(() => {
    // If the resourceIds is a subset of filterIds, then we don't need to re-fetch data
    setFilterIds((ids) =>
      _.intersection(resourceIds, ids).length === resourceIds.length
        ? ids
        : resourceIds
    );
  }, [resourceIds]);

  return {
    data: (data ?? []).filter((entity) => resourceIds.includes(entity.id)),
    isLoading,
  };
}
