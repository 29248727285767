/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const channels = [
  { id: "retail", name: "Retail" },
  { id: "on_premise", name: "On Premise" },
];

const channelFilter = makeRadioOptionsFilter({
  name: "channel",
  title: "Channel",
  options: channels,
});

export default channelFilter;
