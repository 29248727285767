import { ResourceAutocomplete } from "@utils/forms";

const AutocompleteItem = ({ itemIdState, ...props }) => {
  const [itemId, setItemId] = itemIdState;
  return (
    <ResourceAutocomplete
      resource="items"
      resourceFilterName="searchTerm"
      requestParams={{
        filter: {
          hasItemNumber: true,
        },
        sort: "-item-number",
      }}
      value={itemId}
      onChange={(e, v) => setItemId(v?.id || null)}
      getOptionText={(opt) =>
        [opt.itemNumber, opt.type, opt.comment].filter(Boolean).join(" | ")
      }
      minimumCharacters={3}
      {...props}
    />
  );
};

export default AutocompleteItem;
