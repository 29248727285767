import React from "react";

import PropTypes from "prop-types";

import { SmartTable } from "@components/SmartTable";
import { Brand } from "@models/Brand";

const BrandTable = ({ handleBrandClick, brands, isLoading }) => {
  return (
    <SmartTable<Brand>
      rows={brands}
      isLoading={isLoading}
      onRowClick={(brand) => handleBrandClick(brand.id)}
      noResultsText="There are currently no brands that match your search."
      columns={[
        { id: "id", label: "Brand Id" },
        { id: "name", label: "Name" },
        { id: "brandCode", label: "Brand Code" },
        { id: "buCode", label: "Brand Type Code" },
        { id: "productFamilyCode", label: "Brand Group Code" },
      ]}
    />
  );
};

BrandTable.propTypes = {
  handleBrandClick: PropTypes.func,
  brands: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default BrandTable;
