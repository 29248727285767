/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { TableLink } from "@components/SmartTable";
import SmartTable, {
  Column,
  PaginatedResourceProps,
} from "@components/SmartTable/SmartTable";
import { useFilterParams } from "@features/filters";
import { Item } from "@models";

import { itemsKeyFactory } from "../queries";
import ItemPreviewImageButton from "./ItemPreviewImageButton";

type ShelfInventoryItemTableProps = PaginatedResourceProps & { rows: Item[] };

const ShelfInventoryItemTable = ({
  rows,
  ...tableProps
}: ShelfInventoryItemTableProps) => {
  const queryClient = useQueryClient();
  const [filters] = useFilterParams();
  const brandIdsFilter = filters?.brands ?? [];

  const columns: Column<Item>[] = [
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => <ItemPreviewImageButton item={row} />,
    },

    {
      id: "itemNumber",
      label: "Sequence #",
      sort: "item-number",
      render: (itemNumber, row) => (
        <TableLink
          to={`/shelf-inventory/${row.id}`}
          onClick={() => {
            queryClient.setQueryData<Item>(
              itemsKeyFactory.detail(row.id).queryKey,
              row
            );
          }}
        >
          {itemNumber}
        </TableLink>
      ),
    },
    {
      id: "brands",
      label: "Brands",
      render: (brands) =>
        _(brands)
          // set brands that are in the filter to the front
          .sortBy((b) => !brandIdsFilter.includes(b.id), "name")
          .map("name")
          .join(", "),
    },

    {
      id: "qtyPerPack",
      label: "Pack Size",
      render: (qtyPerPack) =>
        qtyPerPack === 1 ? (
          <span>Each</span>
        ) : (
          <>
            <span>{qtyPerPack}</span>
            <span tw="text-gray-500"> / pack</span>
          </>
        ),
    },
    {
      id: "_allInventory",
      label: "On Hand",
      align: "right",
      render: (__, i) =>
        _.sumBy(i.variants, (v) => v.nationalAvailableToOrderQty),
    },

    {
      id: "mostRecentEstimatedCost",
      label: "Est. Cost",
      align: "right",
      render: (cost) => (
        <span tw="text-base text-neutral-900">{formatMoneyString(cost)}</span>
      ),
    },
  ];

  return <SmartTable {...tableProps} rows={rows} columns={columns} />;
};

export default ShelfInventoryItemTable;
