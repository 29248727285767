/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import {
  MonthSelector,
  YearSelector,
} from "../components/Reporting/ReportSelectors";
import ReportTable from "../components/Reporting/ReportTable";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import { setError } from "../redux/slices/errorSlice";
import { updateAllShippingParams } from "../redux/slices/purchasing/purchaseOrderSlice";
import {
  clearReports,
  getCDCReportById,
} from "../redux/slices/reports/reportSlice";
import { mapShippingCSV } from "../utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportCDC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const csvRef = useRef(null);

  const [hasGenerated, setHasGenerated] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("1");
  const [currentYear, setCurrentYear] = useState("2021");
  const [isUploadLoading, setUploadLoading] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    reportType,
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    setCurrentMonth("1");
    setCurrentYear("2021");
    dispatch(clearReports());
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = (type) => {
    const filterObject = {
      orderMonth: currentMonth,
      orderYear: currentYear,
      channel: currentChannel,
    };
    dispatch(getCDCReportById(type, filterObject));
    setHasGenerated(true);
  };

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  const handleFileUpload = (data) => {
    const mappedData = mapShippingCSV(data);
    if (mappedData.filter((data) => data.tax === "error").length > 0) {
      dispatch(
        setError({
          error: "The tax field must be a number or be left blank",
          source: "Shipping Information Upload",
        })
      );
    } else {
      dispatch(updateAllShippingParams(mappedData));
    }
    setUploadLoading(false);
  };

  const handleFileUploadError = (err, file, inputElem, reason) => {
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order File Upload" })
    );
    console.log(err, file, inputElem, reason);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | CDC Reports</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>CDC Reports</Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVReader
                ref={csvRef}
                onFileLoad={handleFileUpload}
                onError={handleFileUploadError}
                noClick
                noDrag
                config={{
                  header: true,
                  beforeFirstChunk: (_chunk) => {
                    setUploadLoading(true);
                  },
                }}
                noProgressBar
              >
                {({ file }) => (
                  <div style={{ position: "relative" }}>
                    <Button
                      className={classes.largeButton}
                      style={{
                        width: isUploadLoading ? "77.156px" : "auto",
                        marginRight: "10px",
                      }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PublishIcon />}
                      onClick={handleOpenDialog}
                    >
                      {isUploadLoading ? (
                        <CircularProgress size={27.78} />
                      ) : (
                        "SHIPPING"
                      )}
                    </Button>
                    {file && (
                      <Typography
                        style={{
                          position: "absolute",
                          top: "Calc(100% + 5px)",
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        {file.name}
                      </Typography>
                    )}
                  </div>
                )}
              </CSVReader>
              <CSVLink
                data={data}
                headers={headers}
                filename={`${reportType}.csv`}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <MonthSelector
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            classes={classes}
          />
          <YearSelector
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            classes={classes}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("inbound-item-summary")}
            style={{ marginRight: "20px" }}
          >
            INBOUND ITEM SUMMARY
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("ship-list")}
            style={{ marginRight: "20px" }}
          >
            SHIP LIST
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("transfer-advice")}
            style={{ marginRight: "20px" }}
          >
            TRANSFER ADVICE
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          (reportType === "inbound-item-summary" ||
            reportType === "ship-list" ||
            reportType === "transfer-advice") && (
            <ReportTable report={report} type={reportType} />
          )}
      </Container>
    </>
  );
};

export default ReportCDC;
