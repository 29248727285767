import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import { axiosGet, axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { mapItemPrograms } from "../items/maps";

let initialState = {
  isLoading: false,
  isProgramListLoading: false,
  isItemsLoading: false,
  isFocusPDFLoading: false,
  programs: [],
  programList: [],
  currentPreOrderVariants: [],
  focusPDFItems: [],
  isPrograms: false,
  isActive: true,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isProgramListLoading = false;
  state.isItemsLoading = false;
  state.isNextItemsLoading = false;
  state.isFocusPDFLoading = false;
  state.error = error;
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setItemsIsLoading(state) {
      state.isItemsLoading = true;
    },
    setListLoading(state) {
      state.isProgramListLoading = false;
    },
    setFocusPDFLoading(state) {
      state.isFocusPDFLoading = true;
    },
    getProgramsSuccess(state, action) {
      const { programs } = action.payload;
      if (programs.length === 0) {
        state.initialLoading = false;
        state.programs = [];
        state.isPrograms = false;
      } else if (state.programs.length === 0) {
        state.programs = [...programs];
        state.initialLoading = false;
        state.isPrograms = true;
      } else {
        state.programs = [...programs];
        state.isPrograms = true;
      }
      state.isLoading = false;
      state.error = null;
    },
    getProgramItemsSuccess(state, action) {
      const { program, orderMonthId, items } = action.payload;
      let updatedPrograms = state.programs.map((prog) => {
        if (prog.id === program && prog.orderMonthId === orderMonthId) {
          return {
            ...prog,
            items: [...items],
            isItemsFetched: true,
          };
        } else {
          return prog;
        }
      });
      state.programs = updatedPrograms;
      state.isItemsLoading = false;
      state.error = null;
    },
    getFocusPDFSuccess(state, action) {
      const { items } = action.payload;
      state.focusPDFItems = [...items];
      state.isFocusPDFLoading = false;
      state.error = null;
    },
    getProgramListSuccess(state, action) {
      const { programs } = action.payload;
      state.programList = programs;
      state.isProgramListLoading = false;
      state.error = null;
    },
    setProgramStatus(state, action) {
      const { program, orderMonthId, status } = action.payload;
      let updatedPrograms = state.programs.map((prog) => {
        if (prog.id === program && prog.orderMonthId === orderMonthId) {
          return {
            ...prog,
            status: status,
          };
        } else return prog;
      });
      state.programs = updatedPrograms;
    },
    addPreOrderVariants(state, action) {
      const { variants } = action.payload;
      const newVariants = state.currentPreOrderVariants.concat(variants);
      state.currentPreOrderVariants = newVariants;
    },
    deletePreOrderVariants(state, action) {
      const { id } = action.payload;
      const variants = state.currentPreOrderVariants.filter((v) => v.id !== id);
      state.currentPreOrderVariants = variants;
    },
    setIsActive(state, action) {
      const { value } = action.payload;
      state.isActive = value;
    },
    resetPreOrderVariants(state) {
      state.currentPreOrderVariants = [];
    },
    resetFocusPDFItems(state) {
      state.focusPDFItems = [];
    },
    clearPrograms(state) {
      state.isLoading = false;
      state.isProgramListLoading = false;
      state.isItemsLoading = false;
      state.isFocusPDFLoading = false;
      state.programs = [];
      state.programList = [];
      state.currentPreOrderVariants = [];
      state.focusPDFItems = [];
      state.isPrograms = false;
      state.error = null;
    },
    clearProgramList(state) {
      state.isProgramListLoading = false;
      state.programList = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setListLoading,
  setItemsIsLoading,
  setFocusPDFLoading,
  getProgramsSuccess,
  getProgramItemsSuccess,
  getFocusPDFSuccess,
  getProgramListSuccess,
  setProgramStatus,
  addPreOrderVariants,
  deletePreOrderVariants,
  setIsActive,
  resetPreOrderVariants,
  resetFocusPDFItems,
  clearPrograms,
  clearProgramList,
  setFailure,
} = programsSlice.actions;

export default programsSlice.reducer;

export const fetchItemPrograms = (id, orderMonthId) => async (dispatch) => {
  try {
    dispatch(setItemsIsLoading());
    dispatch(startGlobalLoad());

    const filters = {
      programId: id,
      orderCalendarMonthIds: orderMonthId && [orderMonthId],
      isActive: true,
      rtaDeployment: true,
    };
    const queryString = `/api/item-programs?${query({ filter: filters })}`;

    let next = "initial";
    let dataArray = [];
    while (next) {
      let response = await axiosGetWithNext(
        next === "initial" ? queryString : next
      );
      if (response.error) throw response.error;
      dataArray = dataArray.concat(response.data.data);
      next = response.data.nextLink;
    }
    const mappedData = mapItemPrograms(dataArray);
    dispatch(
      getProgramItemsSuccess({
        program: id,
        orderMonthId: orderMonthId,
        items: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.error(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Programs fetch items" })
    );
    dispatch(stopGlobalLoad());
  }
};

export const fetchProgramList = (name) => async (dispatch) => {
  try {
    dispatch(setListLoading());
    const url = `/api/programs?filter[name]=${name}`;

    const response = await axiosGet(url);
    if (response.error) throw response.error;
    dispatch(getProgramListSuccess({ programs: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Programs" }));
  }
};
