import React, { forwardRef } from "react";

import { IconName } from "@fortawesome/fontawesome-svg-core";

const FaIcon = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & {
    icon: IconName;
    variant?: "solid" | "regular";
  }
>(({ icon, className, variant = "regular", ...props }, ref) => {
  return (
    <i
      ref={ref}
      className={`fa-${variant} fa-${icon} ${className}`}
      {...props}
    />
  );
});

export default FaIcon;
