/** @jsxImportSource @emotion/react */
import "twin.macro";

import { SUPPLIER_ROLES } from "src/constants/permissions";

import useRoleIs from "@utils/useRoleIs";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const statusOptions = [
  { id: "draft", name: "Draft" },
  { id: "submitted", name: "Submitted" },
  { id: "in-progress", name: "In Progress" },
  { id: "shipping-hold", name: "Shipping Hold" },
  { id: "complete", name: "Complete" },
  { id: "sent-to-flow", name: "Sent to Flow" },
  { id: "canceled", name: "Canceled" },
];

const supplierStatusOptions = [
  { id: "submitted", name: "New" },
  { id: "in-progress", name: "In Progress" },
  { id: "shipping-hold", name: "Shipping Hold" },
  { id: "complete", name: "Complete" },
  { id: "not-invoiced", name: "Not Invoiced" },
];

const useOptions = () => {
  const roleIs = useRoleIs();
  if (roleIs(SUPPLIER_ROLES)) {
    return supplierStatusOptions;
  }
  return statusOptions;
};

const filterPurchaseOrderStatus = makeRadioOptionsFilter({
  name: "purchaseOrderStatus",
  title: "Status",
  useOptionsHook: useOptions,
});

export default filterPurchaseOrderStatus;
