/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";

import { SelectInput } from "@components/Forms/ControlledInputs";
import { useActiveOrderWindows } from "@features/orderCalendarMonths";

import { Bordered } from "../components/StyledComponents";
import { useFilterContext } from "../filterContext";

const Chip = () => {
  const { control, getValues, setValue, submitFilters } = useFilterContext();
  const options = useActiveOrderWindows({ endDate: "reportDate" });
  const disabled = options.length < 2;

  useEffect(() => {
    const orderWindowId = getValues("orderWindowId");
    if (!orderWindowId && options.length) {
      setValue("orderWindowId", options[0].id);
      submitFilters();
    } else if (
      orderWindowId &&
      !options.find((ow) => ow.id === orderWindowId)
    ) {
      setValue("orderWindowId", options[0]?.id ?? null);
      submitFilters();
    }
  }, [options, setValue, getValues, submitFilters]);

  return (
    <Bordered role={disabled ? "disabled" : "button"} tw="h-8">
      <SelectInput
        control={control}
        disabled={options.length < 2}
        name="orderWindowId"
        displayEmpty
        options={options.map((ocm) => ({
          id: ocm.id,
          name: ocm.orderWindowName,
        }))}
        label=""
        renderValue={(val) =>
          val ? (
            <span>
              <b>Order Window is </b>
              {options.find((ow) => ow.id === val)?.orderWindowName}
            </span>
          ) : (
            <b>No Active Order Windows</b>
          )
        }
        css={{
          "&": tw`h-full rounded-full appearance-none bg-neutral-200`,
          ".MuiOutlinedInput-root fieldset": tw`border-0`,
          ".MuiInputBase-root": tw`text-sm text-neutral-600`,
        }}
        onChange={() => submitFilters()}
      />
    </Bordered>
  );
};

const filterActiveOrderWindowSelector = {
  name: "activeOrderWindow",
  formControls: ["orderWindowId"],
  Chip,
  alwaysShow: true,
};

export default filterActiveOrderWindowSelector;
