import "twin.macro";

import { useEffect } from "react";

/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { Contained } from "@components/StyledComponents";
import { PageTitle } from "@features/ui";

import QuoteAccepted from "../components/PlanningTool/Quotes/QuoteAccepted";
import QuoteCompleted from "../components/PlanningTool/Quotes/QuoteCompleted";
import QuoteSent from "../components/PlanningTool/Quotes/QuoteSent";
import RfqDetails from "../components/PlanningTool/Quotes/RfqDetails";
import {
  clearSingleQuote,
  fetchSingleQuote,
} from "../redux/slices/planningTool/rfqSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolQuote = () => {
  const { qid } = useParams(); // get seqNum from url path
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentQuote } = useSelector((state) => state.rfq);

  useEffect(() => {
    dispatch(fetchSingleQuote(qid));
    return () => {
      dispatch(clearSingleQuote());
    };
  }, [qid, dispatch]);

  const viewProps = {
    currentQuote,
    classes,
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {qid === currentQuote.id && (
        <Contained tw="space-y-6">
          <PageTitle title={`Quote Request #${qid}`} fallbackUrl="/quotes" />
          <RfqDetails {...viewProps} />
          <Box sx={{ mt: 2 }}>
            {currentQuote.status === "sent" && <QuoteSent {...viewProps} />}

            {currentQuote.status === "accepted" && (
              <QuoteAccepted {...viewProps} />
            )}
            {currentQuote.status === "complete" && (
              <QuoteCompleted {...viewProps} />
            )}
          </Box>
        </Contained>
      )}
    </>
  );
};

PlanningToolQuote.propTypes = {};

export default PlanningToolQuote;
