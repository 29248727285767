import _ from "lodash";
import { ALL_REGION_ROLES } from "src/constants/permissions";

const mapTerritory = (terr) => ({
  name: terr.name,
  id: terr.id,
  type: terr.type,
  code: terr["external-code"],
  billingId: terr["external-billing-id"],
  exemptFromBudgeting: terr["exempt-from-budgeting"],
  externalAnaplanId: terr["external-anaplan-id"],
  stateCodes: _(terr.states)
    .map("code")
    .sortBy((code) => code.toLowerCase())
    .join(", "),
  states: terr.states,
  status: terr.status,
});

export const mapAndSortTerritories = (terrArray, role) => {
  const territories = _(terrArray)
    .map(mapTerritory)
    .sortBy(
      (terr) => terr.type !== "Regional",
      (terr) => terr.name.toLowerCase()
    )
    .value();

  const allRegionOption = {
    name: "All Regions",
    id: null,
    type: "Regional",
    code: "ALR",
    states: [],
  };

  if (ALL_REGION_ROLES.includes(role)) {
    return [allRegionOption].concat(territories);
  }

  return territories;
};
