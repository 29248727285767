import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { Order } from "@models/Order";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const ordersKeyFactory = createQueryKeys("orders", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<Order>(`orders/${id}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Order[]>("orders", {
        params,
      }),
  }),
});

export const usePaginatedOrdersQuery = buildPaginatedQuery<Order>(
  ordersKeyFactory.paginated,
  { placeholderData: keepPreviousData }
);
