/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";

import PropTypes from "prop-types";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import PageTitle from "@features/ui/PageTitle";

import { useNoFetch } from "../hooks/UtilityHooks";

const ItemCatalog = ({ archive = false }) => {
  const [filters] = useFilterParams();
  const { channel, territory } = useSelector((state: any) => state.app);

  const params = {
    filter: {
      rtaDeployment: true,
      isActive: true,
      isArchived: archive,
      searchTerm: filters.q,
      favorites: filters.favorites,
      brandIds: filters.brands,
      programIds: filters.programs,
      itemTypeIds: filters.itemTypes,
      businessUnitNames: filters.businessUnits,
      orderCalendarMonthIds: filters.orderCalendarMonths,
      isOnDemand: filters.orderType === "on-demand" || null,
      isInventory: filters.orderType === "inventory" || null,
      programTerritoryIds: filters.territories ?? (territory && [territory]),
      isAccolade: filters.isAccolade && filters.isAccolade === "yes",
      channel,
    },
    sort: filters.sort,
  };

  const { data, ...tableProps } = usePaginatedItemsQuery(params);
  useNoFetch();

  return (
    <>
      <FixedHeightScrollLastChild>
        <div tw="flex items-center justify-between">
          <PageTitle title={!archive ? "Item Catalog" : "Item Archive"} />
          <Button component={Link} to="/items/c2m" tw="font-normal normal-case">
            C2M Item History
          </Button>
        </div>
        <TableCardContainer>
          <div tw="flex items-start justify-between gap-3">
            <Filters
              searchTitle="Search Items"
              alwaysShow={["orderType", "brands"]}
              slots={[
                "favorites",
                "orderType",
                "brands",
                "itemTypes",
                "programs",
                "businessUnits",
                "isAccolade",
                "territories",
              ]}
            />
            <ItemViewButtons />
          </div>
          <ItemCatalogView rows={data} {...tableProps} />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

ItemCatalog.propTypes = {
  userType: PropTypes.string,
};

export default ItemCatalog;
