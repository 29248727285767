/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";

import { displayDate } from "@features/reports";
import { Button, LabelValuePair } from "@features/ui";
import { AnaplanProgram } from "@models/AnaplanProgram";

import AnaplanProgramSearch from "./AnaplanProgramSearch";

const ChangeAnaplanProgramModal = ({ onClose, program, handleChange }) => {
  const [ap, setAp] = useState<null | AnaplanProgram>(null);
  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle tw="flex justify-between items-start">
        Change Anaplan Program
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-4">
        <div tw="grid grid-cols-2 gap-4">
          <LabelValuePair label="Existing Program Name" value={program.name} />
          <LabelValuePair
            label="In Market Date"
            value={displayDate(program.startDate)}
          />
        </div>
        <Divider />
        <AnaplanProgramSearch
          program={program}
          selectedAnaplanProgram={ap}
          setSelectedAnaplanProgram={setAp}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!ap}
          onClick={() => handleChange(ap)}
        >
          Update Program
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeAnaplanProgramModal;
