import { Column } from "@components/SmartTable";

import { CsvSchema } from "./CsvSchema";

const csvSchemaFromTableSchema = <T>(tableSchema: Column<T>[]): CsvSchema<T> =>
  tableSchema.map((col) => ({
    ...col,
    f: col.renderText ?? col.render,
  })) as any;

export default csvSchemaFromTableSchema;
