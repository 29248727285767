/** @jsxImportSource @emotion/react */
import "twin.macro";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";

import { CheckboxInput } from "@components/Forms/ControlledInputs";

import FilterChip from "../components/FilterChip";
import { useFilterContext } from "../filterContext";

const FavoritesMenuItem = () => {
  const { control } = useFilterContext();
  return (
    <>
      <CheckboxInput
        tw="-my-2"
        name="favorites"
        label="Favorites"
        control={control}
        icon={<FavoriteBorderRounded fontSize="small" />}
        checkedIcon={<FavoriteRounded fontSize="small" />}
      />
    </>
  );
};

const FavoritesFilterTile = (props) => {
  return (
    <FilterChip {...props}>
      <FavoriteRounded tw="mr-2 text-primary-600" fontSize="small" />
      Favorites
    </FilterChip>
  );
};

const filterFavorites = {
  name: "favorites",
  Chip: FavoritesFilterTile,
  menuText: <FavoritesMenuItem />,
};

export default filterFavorites;
