/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterPrograms = makeResourceAutocompleteFilter({
  name: "programs",
  resource: "programs",
  titleSingular: "Program",
  titlePlural: "Programs",
  requestParams: { sort: "name" },
});

export default filterPrograms;
