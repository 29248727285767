import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { isFuture, isThisMonth } from "date-fns";
import { format } from "date-fns/esm";

import { useItemTypesQuery } from "@features/itemTypes";
import { useUpcomingOcmsQuery } from "@features/orderCalendarMonths";

import { axiosGet } from "../api/axiosCalls";
import { camelCaseKeys, formatDate } from "../utility/utilityFunctions";

export const useOrderCalendarMonthOptions = (ocm) => {
  const { data = [] } = useUpcomingOcmsQuery();

  return useMemo(
    () => {
      // Filter past order window open dates
      let ocms = data.filter(
        ({ orderWindowCloseDate }) =>
          isFuture(new Date(orderWindowCloseDate)) ||
          isThisMonth(new Date(orderWindowCloseDate))
      );

      if (
        ocm &&
        ocm.inMarketStartDate &&
        !ocms.find(({ id }) => id === ocm.id)
      ) {
        // prepend to array
        ocms.unshift(ocm);
      }

      ocms = ocms.map((ocm) => ({
        ...ocm,
        label: ocm.orderWindowName,
      }));

      return ocms;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, ocm?.id]
  );
};

export const useFutureInMarketMonths = () => {
  const { data: ocms = [] } = useUpcomingOcmsQuery();

  return useMemo(
    () =>
      ocms.map((ocm) => ({
        ...ocm,
        label: format(formatDate(ocm.inMarketStartDate), "MMMM yyyy"),
      })),
    [ocms]
  );
};

export const useItemTypes = (filter) => {
  const { data = [] } = useItemTypesQuery();
  return useMemo(
    () =>
      data
        .filter((type) => (filter ? filter(type) : true)) // use given filter if provided,
        .map((t) => ({ ...t, label: `${t.externalId} ${t.description}` })),
    [data, filter]
  );
};

export const useCouponTypes = () => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let cancelled = false;
    axiosGet("/api/coupon-types").then(
      (res) =>
        !cancelled &&
        setOptions(
          res.data.map((obj) => ({
            ...camelCaseKeys(obj),
            label: `${obj["type-code"]} ${obj["offer-type-code"]}`,
          }))
        )
    );
    return () => (cancelled = true);
  }, []);

  return options;
};

export const useProgramInfo = () => {
  const program = useSelector((state) => state.program.currentProgram);

  const { status, orderType, id } = program;

  return {
    programId: id,
    requiresOCM: orderType === "pre-order",
    isSubmitted: status !== "draft",
    becomesOnDemand: orderType === "on-demand",
    isInventory: orderType === "from-inventory",
    ...program,
  };
};

export const useHandleSubmitErrors = (setError, id) => {
  const errors = useSelector(
    (state) => state.itemPrograms.submitErrors[id] || []
  );
  useEffect(() => {
    errors.forEach(({ name, message }) =>
      setError(name, { type: "custom", message })
    );
  }, [errors, setError, id]);

  return errors.length > 0;
};

export const useMapItemIdToItemProgramId = (itemId) => {
  // Warning, a direct one-to-one relationship between itemProgramId and itemId cannot be guarandeed for re-runs.
  const itemPrograms = useSelector((state) => state.itemPrograms.entities);
  if (!itemId) return;
  return Object.values(itemPrograms).find((ip) => ip.itemId === itemId)?.id;
};
