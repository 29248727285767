import "twin.macro";

/** @jsxImportSource @emotion/react */
import { Contained } from "@components/StyledComponents";
import { Dashboard, PageTitle } from "@features/ui";

import { useNoFetch } from "../hooks/UtilityHooks";

const PlanningToolReports = () => {
  const cardData = [
    {
      titleText: "Audit Report",
      link: "/planning/reports/audit",
      icon: "magnifying-glass",
    },
    {
      titleText: "Calendar Report",
      link: "/planning/reports/calendar",
      icon: "calendar-range",
    },
  ];

  useNoFetch();

  return (
    <>
      <Contained tw="space-y-6">
        <PageTitle title="Planning Tool Reports" />
        <Dashboard>
          {cardData.map((card, i) => (
            <Dashboard.Card
              key={i}
              title={card.titleText}
              link={card.link}
              icon={card.icon}
              color="gray"
            />
          ))}
        </Dashboard>
      </Contained>
    </>
  );
};

PlanningToolReports.propTypes = {};

export default PlanningToolReports;
