/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import _ from "lodash";
import OrderVariantList from "src/components/Purchasing/OrderVariantList";
import { StyledButton } from "src/components/StyledComponents";

import { useMarkAsTransferredMutation } from "@features/orders";
import { ItemRollup } from "@models/ItemRollup";
import { OrderVariant } from "@models/OrderVariant";

const MarkAsTransferredModal = ({
  itemRollups,
  handleClose,
}: {
  itemRollups: ItemRollup[];
  handleClose: () => void;
}) => {
  const markAsTransferMutation = useMarkAsTransferredMutation();

  const orderVariantIdsByVariant = _(itemRollups)
    .groupBy("variant.id")
    .mapValues((irs) => irs.flatMap((ir) => ir.orderVariantIds))
    .value();
  const [selectedOvs, setSelectedOvs] = useState<OrderVariant[]>([]);

  const handleMarkAsTransferred = async () => {
    const ovIdArray = _.map(selectedOvs, "id");
    markAsTransferMutation.mutate(ovIdArray);
  };

  return (
    <Dialog open maxWidth="lg">
      <DialogTitle tw="flex items-center justify-between">
        Choose Orders to Mark as Transferred
        <IconButton edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {Object.entries(orderVariantIdsByVariant).map(
          ([vId, orderVariantIds]) => (
            <OrderVariantList
              key={vId}
              singleItemRollup={
                itemRollups.find((ir) => ir.variant.id === vId)!
              }
              orderVariantIds={orderVariantIds}
              selectedOvs={selectedOvs}
              setSelectedOvs={setSelectedOvs}
            />
          )
        )}
      </DialogContent>
      {markAsTransferMutation.error && (
        <DialogContent>{markAsTransferMutation.error.message}</DialogContent>
      )}
      {(markAsTransferMutation.isIdle || markAsTransferMutation.isPending) && (
        <DialogActions>
          <StyledButton
            cta
            onClick={handleMarkAsTransferred}
            loading={markAsTransferMutation.isPending}
            disabled={selectedOvs.length === 0}
          >
            Mark as Transferred
          </StyledButton>
        </DialogActions>
      )}
      {markAsTransferMutation.isSuccess && (
        <>
          <DialogActions>
            <div tw="mr-4">Success</div>
            <StyledButton
              cta
              onClick={handleClose}
              disabled={selectedOvs.length === 0}
            >
              Close
            </StyledButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default MarkAsTransferredModal;
