/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useLocation, useRouteError } from "react-router-dom";

import { Contained } from "src/components/StyledComponents";

import { Button } from "@features/ui";
import DocTitle from "@utils/DocTitle";

import FourOhFour from "./FourOhFour";

type ErrorPageProps = {
  error?: Error | null;
};

const ErrorPage = ({ error: providedError }: ErrorPageProps) => {
  const routeError = useRouteError() as any;
  const location = useLocation();

  const error = providedError ?? routeError;

  error && console.error(error);

  if (error?.status === 404) return <FourOhFour />;

  return (
    <>
      <DocTitle title={"Error"} />
      <Contained>
        <h1 tw="text-6xl text-neutral-300 font-bold">error.</h1>
        <h2 tw="text-4xl text-neutral-600 font-bold">
          Something unexpected happened.
        </h2>
        <p tw="text-lg max-w-prose mt-2">
          If this error persists, please contact support and we'll get it
          resolved.
        </p>
        {error && (
          <pre tw="mt-2 p-3 rounded-lg bg-neutral-200 shadow-inner whitespace-pre-line">
            {error?.message ?? error}
          </pre>
        )}
        <div tw="flex gap-4">
          <Button
            variant="contained"
            tw="mt-6"
            cta
            component="a"
            href={location.pathname}
          >
            Try again
          </Button>
          <Button
            variant="contained"
            color="secondary"
            tw="mt-6"
            component="a"
            href={"/"}
          >
            Go Home
          </Button>
        </div>
      </Contained>
    </>
  );
};

export default ErrorPage;
