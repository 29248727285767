/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useNavigate } from "react-router-dom";

import { Tab, Tabs } from "@mui/material";

import { PageTitle } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

import { PLANNING_TOOL_SUPPLIER, RFQ_ROLES } from "../../constants/permissions";
import { Contained } from "../StyledComponents";

const PlanningToolNav = ({ title, view, programId }) => {
  const roleIs = useRoleIs();
  const navigate = useNavigate();
  return (
    <Contained tw="flex justify-between items-center flex-wrap gap-4">
      <PageTitle
        title={title}
        fallbackUrl={view === "rfq" ? `/planning/rfqs` : `/planning/programs`}
      />

      <Tabs value={view} tw="min-h-0">
        <Tab
          label="Program VIew"
          value={"program"}
          tw="py-2 min-h-0 text-neutral-400 tracking-wider"
          onClick={() => navigate(`/planning/programs/${programId}`)}
        />
        {roleIs(RFQ_ROLES) && (
          <Tab
            label="RFQ View"
            value={"rfq"}
            tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            onClick={() => navigate(`/planning/rfqs/${programId}`)}
          />
        )}
        {!roleIs(PLANNING_TOOL_SUPPLIER) && (
          <Tab
            label="Log View"
            value={"changelog"}
            tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            onClick={() =>
              navigate(`/planning/programs/${programId}/changelog`)
            }
          />
        )}
      </Tabs>

      {/* <div tw="flex gap-4">
        <StyledButton
          component={Link}
          to={`/planning/program/${programId}`}
          disabled={view === "program"}
          cta={view === "program"}
        >
          PROGRAM VIEW
        </StyledButton>

        {roleIs(RFQ_ROLES) && (
          <StyledButton
            component={Link}
            to={`/planning/rfqs/${programId}`}
            disabled={view === "rfq"}
            cta={view === "rfq"}
          >
            RFQ VIEW
          </StyledButton>
        )}
        {!roleIs(PLANNING_TOOL_SUPPLIER) && (
          <StyledButton
            component={Link}
            to={`/planning/program/${programId}/changelog`}
            disabled={view === "changelog"}
            cta={view === "changelog"}
          >
            LOG VIEW
          </StyledButton>
        )} 
      </div>*/}
    </Contained>
  );
};

export default PlanningToolNav;
