import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQueries, useQuery } from "@tanstack/react-query";

import { useAuthUser } from "@features/auth";
import { useActiveOrderWindows } from "@features/orderCalendarMonths";
import { Program } from "@models";
import { OrderCalendarMonth } from "@models/OrderCalendarMonth";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const programsKeyFactory = createQueryKeys("programs", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Program[]>("programs", { params }),
  }),
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Program>(`programs/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Program[]>("programs", {
          params: { ...params, skipPagination: true },
        })
        .then((res) => res.data),
  }),
});

export const usePaginatedProgramsQuery = buildPaginatedQuery(
  programsKeyFactory.paginated
);

export const useProgramQuery = (id?: string | null) => {
  return useQuery({ ...programsKeyFactory.detail(id!), enabled: !!id });
};

const PROGRAM_STALE_TIME = 15 * 60 * 1000;

export const useProgramsByOcmIdQuery = (ocmId?: string | null) => {
  const { channel } = useAuthUser();
  return useQuery({
    ...programsKeyFactory.list({
      filter: {
        orderCalendarMonthIds: [ocmId],
        isPreOrderActive: true,
        isPreOrder: true,
        isCanceled: false,
        channel,
      },
    }),
    enabled: !!ocmId,
    // we don't expect these to change
    staleTime: PROGRAM_STALE_TIME,
  });
};

export type ProgramWithTargetOCM = Program & {
  targetOCM: OrderCalendarMonth;
};

export const useOCMProgramsQuery = (
  orderWindowType: "national" | "secondary",
  visibility: "active" | "visible"
) => {
  const { channel } = useAuthUser();

  // Since a program can have multiple ocms for each item-program, we need to first find the ocms
  // that are orderable, and then find that ocm's programs. This means that if there are multiple orderable ocms
  // a program may appear twice in this list, once for each ocm.
  const ocmParams =
    visibility === "visible"
      ? { startDate: "visibleDate" as const }
      : undefined;
  const ocms = useActiveOrderWindows(ocmParams).filter(
    (ocm) => ocm.orderWindowType === orderWindowType
  );

  return useQueries({
    queries: ocms.map((ocm) => ({
      ...programsKeyFactory.list({
        filter: {
          orderCalendarMonthIds: [ocm.id],
          [visibility === "active" ? "isPreOrderActive" : "isPreOrderVisible"]:
            true,
          isAdHoc: false,
          isPreOrder: true,
          isCanceled: false,
          channel,
        },
      }),
      staleTime: PROGRAM_STALE_TIME,
    })),
    combine: (data) => {
      if (data.length === 0) return { data: [], isLoading: true, error: null };
      return {
        isLoading: data.every((d) => d.isLoading),
        error: data.find((d) => d.error),
        data: data.flatMap(
          (d, i) =>
            (d.data ?? []).map((p) => ({
              ...p,
              targetOCM: ocms[i],
            })) as ProgramWithTargetOCM[]
        ),
      };
    },
  });
};
