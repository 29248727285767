/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useWatch } from "react-hook-form";

import { ButtonBase } from "@mui/material";

import { format, setDate, setMonth, subDays } from "date-fns";
import { uniqBy } from "lodash";
import { formatDateString } from "src/utility/utilityFunctions";

import { DateInput } from "@components/Forms/ControlledInputs";

import FilterChip from "../components/FilterChip";
import { useFilterContext } from "../filterContext";

const today = new Date();
const past7Days = subDays(today, 7);
const thisMonth = setDate(today, 1);
const thisYear = setMonth(thisMonth, 0);

const encodeDate = (date) => format(date, "yyyy-MM-dd");

// Handles the case where two presets can have the same value
// eg. Today and This Month are the same (first of the month)
// This month and This year are the same (January)
const presets = uniqBy(
  [
    {
      label: "Today",
      from: encodeDate(today),
      to: encodeDate(today),
    },
    {
      label: "Past 7 days",
      from: encodeDate(past7Days),
      to: encodeDate(today),
    },
    {
      label: "This Month",
      from: encodeDate(thisMonth),
      to: encodeDate(today),
    },

    {
      label: "This Year",
      from: encodeDate(thisYear),
      to: encodeDate(today),
    },
  ],
  (pre) => pre.from + pre.to
);

const DateRangePopper = ({ title }) => {
  const { setValue, control } = useFilterContext();
  const from = useWatch({ control, name: "fromDate" });
  const to = useWatch({ control, name: "toDate" });

  // Insure only one preset is active at a time
  const activePresetIdx = presets.findIndex(
    (pre) => pre.from === from && pre.to === to
  );

  return (
    <div tw="space-y-3 p-4 max-w-fit">
      {title && <div tw="text-neutral-700">{title}</div>}
      <div tw="flex flex-wrap gap-2">
        {presets.map((pre, i) => (
          <ButtonBase
            key={i}
            onClick={() => {
              setValue("fromDate", pre.from);
              setValue("toDate", pre.to);
            }}
            css={[
              tw`px-2 py-1 text-sm border border-solid rounded border-neutral-400`,
              activePresetIdx === i && tw`bg-neutral-100! border-neutral-600`,
            ]}
          >
            {pre.label}
          </ButtonBase>
        ))}
      </div>
      <div tw="flex gap-3">
        <DateInput
          tw="w-44 flex-shrink-0"
          label="From date"
          name="fromDate"
          control={control}
        />
        <DateInput
          tw="w-44 flex-shrink-0"
          label="To date"
          name="toDate"
          control={control}
        />
      </div>
    </div>
  );
};
const DateRangeChip = ({
  chipTextPrefix = "",
  emptyText = "Select Date Range",
  ...props
}: any) => {
  const { filterValues } = useFilterContext();
  const from = filterValues["fromDate"];
  const to = filterValues["toDate"];

  const matchedPreset = presets.find(
    (pre) => pre.from === from && pre.to === to
  );

  const content =
    !from || !to ? (
      emptyText
    ) : matchedPreset ? (
      <>
        {chipTextPrefix} <b>{matchedPreset.label}</b>
      </>
    ) : (
      <>
        {chipTextPrefix} Between{" "}
        <b>
          {formatDateString(from)} - {formatDateString(to)}
        </b>
      </>
    );

  return (
    <FilterChip {...props} showCloseButton={Boolean(from || to)}>
      {content}
    </FilterChip>
  );
};

const filterDateRange = {
  name: "dateRange",
  formControls: ["fromDate", "toDate"],
  menuText: "Date Range",
  Popper: DateRangePopper,
  Chip: DateRangeChip,
};

export default filterDateRange;
