/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { Tab, Tabs } from "@mui/material";

import { keepPreviousData } from "@tanstack/react-query";
import "date-fns";
import _ from "lodash";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "src/components/StyledComponents";

import OverBudgetByBrandTable from "@features/budgets/OverBudgetByBrandTable";
import { Filters, useFilterParams } from "@features/filters";
import {
  useOrderSetVariantSummariesPaginated,
  useOrderSetsPaginated,
} from "@features/orders";
import ApprovalsOrderSetTable from "@features/orders/approvals/ApprovalsOrderSetTable";
import ApprovalsOrderSetVariantSummariesTable from "@features/orders/approvals/ApprovalsOrderSetVariantSummariesTable";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import { useSetLocation } from "@services/reactRouterDom";

import { useNoFetch } from "../hooks/UtilityHooks";

const useOrderSetParams = () => {
  const { channel, territory } = useSelector((state: any) => state.app);
  const [filters] = useFilterParams();
  const params = {
    filter: {
      type: "pre-order",
      [filters.searchBy ?? "search-term"]: filters.q,
      channel,
      orderCalendarMonthIds: filters.orderWindowId && [filters.orderWindowId],
      budgetIds: filters.budgets,

      status: filters.orderSetStatus?.split(","),

      userIds: filters.users,
      brandIds: filters.brands,
      programIds: filters.programs,
      itemTypeIds: filters.itemTypes,
      stateIds: filters.states,
      territoryIds: filters.territories ?? (territory && [territory]),
      supplierIds: filters.supplier,
    },
    sort: filters.sort,
  };
  return params;
};

const orderSetParamsToVariantParams = (params: Record<string, any>) => {
  const { filter, sort, ...rest } = params;

  const sortPrefix = sort?.startsWith("-") ? "-" : "";
  const sortKey = sort?.replace(/^-/, "");
  const mappedSortKey =
    { id: "order-set-id", "submitted-at": "order-set-submitted-at" }[sortKey] ??
    sortKey;

  const mappedFilter = _.mapKeys(filter, (_val, key) => {
    return { status: "orderSetStatus", type: "orderSetType" }[key] ?? key;
  });

  return {
    sort: sortPrefix + mappedSortKey,
    filter: mappedFilter,
    ...rest,
  };
};

const RollupByOrder = () => {
  const params = useOrderSetParams();

  useReport("approvals-order-set", ["order-sets", params]);

  const { data: orderSets = [], ...orderSetsTableProps } =
    useOrderSetsPaginated(params, {
      placeholderData: keepPreviousData,
    });
  return (
    <ApprovalsOrderSetTable
      data={orderSets}
      {...orderSetsTableProps}
      view="rollup"
    />
  );
};

const RollupByItem = () => {
  const orderSetParams = useOrderSetParams();
  const params = orderSetParamsToVariantParams(orderSetParams);

  // this report queries order-variants, not order-set-variant-summaries like the table
  const reportParams = _(params)
    .set("filter.orderType", "not-pre-order")
    .set("filter.qtyNotZero", true)
    .value();
  useReport("approvals-order-variant", ["order-variants", reportParams]);

  // Order Set Variant Summaries
  const { data: osvs = [], ...osvsTableProps } =
    useOrderSetVariantSummariesPaginated(params, {
      placeholderData: keepPreviousData,
    });

  return (
    <ApprovalsOrderSetVariantSummariesTable
      data={osvs}
      {...osvsTableProps}
      view="rollup"
    />
  );
};

const Rollup = ({ view }: { view: "overview" | "order" | "item" }) => {
  const setLocation = useSetLocation();

  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex justify-between items-center gap-3 flex-wrap">
        <div tw="flex items-end gap-6">
          <PageTitle tw="m-0" title="Order Window Rollup" />
          <Tabs
            value={view}
            onChange={(_e, v) =>
              setLocation({ pathname: `/rollup/${v}` }, { replace: true })
            }
            tw="min-h-0"
          >
            <Tab
              label="Overview"
              value={"overview"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
            <Tab
              label="Item"
              value={"item"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
            <Tab
              label="Order"
              value={"order"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
          </Tabs>
        </div>
        <div tw="flex gap-3 flex-wrap">
          {["item", "order"].includes(view) && (
            <RequestReportButton
              reportName={
                view === "item"
                  ? "approvals-order-variant"
                  : "approvals-order-set"
              }
            />
          )}
        </div>
      </div>
      <TableCardContainer>
        {view === "overview" ? (
          <Filters slots={["activeOrderWindow"]} hideSearch />
        ) : (
          <Filters
            slots={[
              "activeOrderWindow",
              "brands",
              "budgets",
              "itemTypes",
              "orderSetStatus",
              "programs",
              "states",
              "suppliers",
              "territories",
              "users",
            ]}
            slotProps={{
              search: {
                searchBy: ["searchTerm", "programId"],
              },
            }}
            alwaysShow={["orderSetStatus"]}
            defaultValues={{
              searchBy: "searchTerm",
              orderSetStatus: "submitted",
            }}
          />
        )}
        {view === "overview" && <OverBudgetByBrandTable />}
        {view === "order" && <RollupByOrder />}
        {view === "item" && <RollupByItem />}
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default Rollup;
