/** @jsxImportSource @emotion/react */
import "twin.macro";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { displayOrderSetStatus, getExtendedCost } from "@features/orders";
import { displayDate } from "@features/reports/utils";
import { OrderVariant } from "@models/OrderVariant";
import { CsvSchema } from "@utils/csv";

const approvalsOrderVariantSchema: CsvSchema<OrderVariant> = [
  { label: "Person", id: "orderUserName" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Program", id: "anaplanProgramsLabel" },
  {
    label: "Brand",
    id: "_brands",
    f: (_, ov) => ov.variant.item.brands.map((b) => b.name).join(", "),
  },
  {
    label: "Business unit",
    id: "_bus",
    f: (_, ov) =>
      ov.variant.item.brands
        .map((brand) => brand["business-unit"]?.name)
        .join(", "),
  },
  { label: "Item Type", id: "itemTypeDescription" },
  { label: "Item Desc.", id: "variant.item.comment" },

  {
    label: "Address ID/ABN",
    id: "_addressIdOrAbn",
    f: (_, ov) => ov.addressAbn ?? "c" + ov.addressId,
  },
  { label: "Address Name", id: "addressName" },
  { label: "Street 1", id: "streetAddress1" },
  { label: "Street 2", id: "streetAddress2" },
  { label: "City", id: "city" },
  { label: "State", id: "state" },
  { label: "Zip code", id: "zip" },

  { label: "Qty / Pack", id: "qtyPerPack" },
  { label: "Quantity", id: "qty" },
  { label: "Est. Cost", id: "estimatedCost", f: formatMoneyString },
  {
    label: "Est. Total Costs",
    id: "totalEstimatedCost",
    f: formatMoneyString,
  },
  {
    label: "Total Beacon Cost",
    id: "totalBeaconCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Freight",
    id: "totalEstimatedShippingCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Taxes",
    id: "totalEstimatedTax",
    f: formatMoneyString,
  },
  {
    label: "Est. Extended Total",
    id: "_totalExtendedCost",
    f: (_, ov) => formatMoneyString(getExtendedCost(ov, ov.orderType)),
  },
  { label: "Order Submitted", id: "orderSubmittedAt", f: displayDate },
  { label: "Order Window Close", id: "purchasingCloseDate", f: displayDate },
  { label: "In Market Date", id: "inMarketDate", f: displayDate },
  {
    label: "Status",
    id: "status",
    f: (status) => displayOrderSetStatus(status),
  },
];
export default approvalsOrderVariantSchema;
