import _ from "lodash";

import { Program } from "@models/Program";

import ControlledFilterRadioGroup from "../components/ControlledFilterRadioGroup";
import FilterChip from "../components/FilterChip";
import { useFilterContext } from "../filterContext";

const name = "preOrderProgramBrand";
const title = "Brand";
const popperTitleText = `${title} is`;

const formatOptions = (programs: Program[]) =>
  _(programs)
    .flatMap((p) => p.brands.map((b) => _.pick(b, ["id", "name"])))
    .uniqBy("id")
    .sortBy("name")
    .value();

const Popper = ({ programs }) => {
  const { control } = useFilterContext();
  const options = formatOptions(programs);
  return (
    <ControlledFilterRadioGroup
      name={name}
      options={options}
      titleText={popperTitleText ?? `${title} is`}
      control={control}
    />
  );
};

const Chip = ({ programs, ...props }) => {
  const { filterValues } = useFilterContext();
  const options = formatOptions(programs);
  const value = filterValues[name];
  const valueText = options.find((c) => c.id === value)?.name;

  const prefix = `${title} is`;

  return (
    <FilterChip {...props} showCloseButton={!!value}>
      {value ? (
        <>
          {prefix && <span>{prefix}&nbsp;</span>}
          <b>{valueText}</b>
        </>
      ) : (
        `Select ${title}`
      )}
    </FilterChip>
  );
};

const filterPreOrderProgramBrand = {
  name,
  menuText: title,
  Popper,
  Chip,
};

export default filterPreOrderProgramBrand;
