/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterItemTypes = makeResourceAutocompleteFilter({
  name: "itemTypes",
  resource: "item-types" as any,
  titleSingular: "Item Type",
  titlePlural: "Item Types",
  getOptionText: (t) => t.description,
  tapOptions: (opts) => _.sortBy(opts, (o) => o.description.toLowerCase()),
  skipsPagination: true,
});

export default filterItemTypes;
