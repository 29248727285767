import React, { memo } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import PropTypes from "prop-types";

const CountrySelector = ({
  currentCountry,
  setCurrentCountry,
  role,
  disabled,
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      size={"medium"}
      style={{
        maxWidth: "100%",
        minWidth: "100px",
      }}
      disabled={role === "field1" || role === "field2" || disabled}
    >
      <InputLabel id="country-selector-label">Country</InputLabel>
      <Select
        variant="outlined"
        label="Country"
        name="country"
        labelId="country-selector-label"
        id="country-selector"
        value={currentCountry}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        onChange={(evt) => setCurrentCountry(evt.target.value)}
      >
        <MenuItem value={"USA"}>United States</MenuItem>
        <MenuItem value={"CAN"}>Canada</MenuItem>
        <MenuItem value={"HK"}>Hong Kong</MenuItem>
      </Select>
    </FormControl>
  );
};

CountrySelector.propTypes = {
  currentCountry: PropTypes.string,
  setCurrentCountry: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default memo(CountrySelector);
