import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    territory: null,
    channel: null,
    isOrdering: false,
    productView: "grid",
    draftOrderIds: {},
  },
  reducers: {
    setTerritory: (state, action) => {
      state.territory = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setIsOrdering: (state, action) => {
      state.isOrdering = action.payload;
    },
    setProductView: (state, action) => {
      state.productView = action.payload;
    },
    setDraftOrderId: (state, action) => {
      const { orderType, id } = action.payload;
      state.draftOrderIds[orderType] = id;
    },
  },
});

export const {
  setTerritory,
  setChannel,
  setIsOrdering,
  setProductView,
  setDraftOrderId,
} = appSlice.actions;

export default appSlice.reducer;
