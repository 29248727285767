import { without } from "lodash";
import {
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
  RFQ_ROLES,
} from "src/constants/permissions";
import PlanningActivate from "src/pages/PlanningActivate";
import PlanningProgram from "src/pages/PlanningProgram";
import PlanningProgramRFQs from "src/pages/PlanningProgramRFQs";
import PlanningPrograms from "src/pages/PlanningPrograms";
import PlanningRFQs from "src/pages/PlanningRFQs";
import PlanningToolChangelog from "src/pages/PlanningToolChangelog";
import PlanningToolReports from "src/pages/PlanningToolReports";
import ReportAudit from "src/pages/ReportAudit";
import ReportCalendar from "src/pages/ReportCalendar";

import PlanningDash from "@components/Dashboard/PlanningDash";

import { protectedRoute } from "./types";

const routeProgramPlanning: protectedRoute = {
  path: "planning",
  defaultChildrenAllowedRoles: PLANNING_TOOL_ROLES,
  children: [
    { path: "", element: <PlanningDash /> },
    {
      path: "activate",
      element: <PlanningActivate />,
      allowedRoles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER),
    },
    {
      path: "programs",
      children: [
        { path: "", element: <PlanningPrograms /> },

        { path: ":pid", element: <PlanningProgram /> },
        {
          path: ":pid/changelog",
          element: <PlanningToolChangelog />,
        },
      ],
    },
    {
      path: "rfqs",
      allowedRoles: RFQ_ROLES,
      children: [
        { path: "", element: <PlanningRFQs /> },
        { path: ":programId", element: <PlanningProgramRFQs /> },
        {
          path: ":programId/item/:itemId",
          element: <PlanningProgramRFQs />,
        },
      ],
    },
    {
      path: "reports",
      children: [
        { path: "", element: <PlanningToolReports /> },
        {
          path: "audit",
          element: <ReportAudit />,
        },
        {
          path: "calendar",
          element: <ReportCalendar />,
        },
      ],
    },
  ],
};

export default routeProgramPlanning;
