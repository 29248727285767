import { useEffect } from "react";

const beaconId = "521f5954-7022-46e2-9707-6a82501f23e7";

const useHelpScoutBeacon = () => {
  useEffect(() => {
    // from helpscout beacon script
    (function (e, t, n) {
      function a() {
        const e = t.getElementsByTagName("script")[0];
        const n = t.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = "https://beacon-v2.helpscout.net";
        e.parentNode!.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue?.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        "complete" === t.readyState)
      ) {
        return a();
      }
      e.addEventListener("load", a);
    })(window, document, window.Beacon || function () {});
  }, []);
  useEffect(() => {
    window.Beacon("init", beaconId);
    window.Beacon("on", "article-viewed", ({ id }) => {
      window.Beacon("article", id, { type: "modal" });
    });

    const closeBeacon = () => window.Beacon("close");

    window.Beacon("on", "open", () =>
      window.addEventListener("click", closeBeacon)
    );
    window.Beacon("on", "close", () =>
      window.removeEventListener("click", closeBeacon)
    );
  }, []);
};

export default useHelpScoutBeacon;
