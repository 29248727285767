import _ from "lodash";

import { useAuthUser } from "@features/auth";
import { useProgramsByOcmIdQuery } from "@features/programs";

import { useOrderSetsPaginated } from "../queries/orderSetQueries";

export default function usePreOrderPrograms(orderWindowId: string | undefined) {
  const { id: userId, channel, territory } = useAuthUser();

  const programsQuery = useProgramsByOcmIdQuery(orderWindowId);
  const orderSetQuery = useOrderSetsPaginated(
    {
      filter: {
        isPreOrderActive: true,
        type: "pre-order",
        isAdHoc: false,
        userIds: [userId],
        ...(territory ? { territoryIds: [territory] } : { allRegions: true }),
        channel,
      },
    },
    {
      initialPagesToLoad: 100,
      enabled: !!orderWindowId,
    }
  );

  const { data: orderSets } = orderSetQuery;
  const { data: programs = [] } = programsQuery;

  const orderSetLookup = _.keyBy(orderSets, "program.id");

  const data = programs.map((program) => {
    const orderSet = orderSetLookup[program.id];
    return {
      program,
      orderSet,
    };
  });

  return { data, programsQuery, orderSetQuery };
}
