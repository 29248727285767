/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import {
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";

import { orderBy } from "lodash";

import { Button } from "@features/ui";
import { AddressFavoriteSet } from "@models/AddressFavoriteSet";
import { useApiResource } from "@services/api";

type HandleAddressSetFn = (set: AddressFavoriteSet) => void;

const FavoriteAddressList = ({
  onAddressSetClick,
}: {
  onAddressSetClick: HandleAddressSetFn;
}) => {
  const { currentTerritory: territoryId } = useSelector(
    (state: any) => state.user
  );
  const { data } = useApiResource("address-favorite-sets", {
    params: { skipPagination: true },
    filter: {
      territoryIds: territoryId,
    },
  });

  const sortedSets = orderBy(data ?? [], [
    (set) => set.name.toLowerCase(),
  ]).filter((set) => set.addresses.length > 0);

  return (
    <MenuList>
      {!data && (
        <MenuItem>
          <ListItemIcon>
            <CircularProgress size={24} />
          </ListItemIcon>
          <span>Loading...</span>
        </MenuItem>
      )}
      {sortedSets.map((set) => (
        <MenuItem key={set.id} onClick={() => onAddressSetClick(set)}>
          {set.name}
        </MenuItem>
      ))}
    </MenuList>
  );
};

const FavoriteAddressSetsMenuButton = ({
  handleAddAddressSet,
}: {
  handleAddAddressSet: HandleAddressSetFn;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >
        Favorites
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FavoriteAddressList
          onAddressSetClick={(set) => {
            handleClose();
            handleAddAddressSet(set);
          }}
        />
      </Menu>
    </>
  );
};
export default FavoriteAddressSetsMenuButton;
