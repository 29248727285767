import React from "react";
import { useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { PageTitle } from "@features/ui";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const General = () => {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.user);

  return (
    <>
      <PageTitle title="Profile Information" />
      <br />
      <List classes={{ root: classes.root }}>
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Name: {currentUser.name}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Email: {currentUser.email}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Role:{" "}
            {currentUser.role[0].toUpperCase() + currentUser.role.slice(1)}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>
            Assigned Territories:
          </Typography>
          <br />
          <Typography className={classes.bodyText}>
            {currentUser.territories.map((terr) => terr.name).join(", ")}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>Assigned States:</Typography>
          <br />
          <Typography className={classes.bodyText}>
            {currentUser.states.map((state) => state.code).join(", ")}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography className={classes.bodyText}>
              On Premise:
              <Checkbox
                disabled
                checked={currentUser.isOnPremise ? true : false}
              />
            </Typography>
            <Typography className={classes.bodyText}>
              Retail:
              <Checkbox
                disabled
                checked={currentUser.isRetail ? true : false}
              />
            </Typography>
          </div>
        </ListItem>
      </List>
    </>
  );
};

export default General;
