/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Close } from "@mui/icons-material";
import {
  Card,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import _ from "lodash";
import { BeaconIcon } from "src/components/StyledComponents";
import { mapSpecifications } from "src/redux/mapHelpers";
import { setItemPreview } from "src/redux/slices/items/itemSlice";

import { SmartTable } from "@components/SmartTable";
import { FaIcon, Type } from "@features/ui";
import { Item } from "@models/Item";
import cld, { CldImage, imageSizeOptions } from "@services/cloudinary";

import Carousel from "../../components/Carousel";
import {
  NON_SUPPLIER_ROLES,
  PLANNING_TOOL_ROLES,
} from "../../constants/permissions";
import {
  formatDateString,
  formatMoneyString,
} from "../../utility/utilityFunctions";
import ItemOptionsCount from "./components/ItemOptionsCount";
import { useItemQuery, useItemVariablePricingQuery } from "./queries";

const Title = tw(Type.H3)` mb-2`;

const LabelValuePair = ({ label, value }) => (
  <div tw="flex py-1.5 leading-tight">
    <div tw="flex-none mr-2 w-48 text-neutral-600 font-light [text-wrap: pretty]">
      {label}:
    </div>
    <span>{value}</span>
  </div>
);

const VariablePricingTable = ({ item }: { item: Item }) => {
  const { data } = useItemVariablePricingQuery(item);
  if (!data) return <CircularProgress />;
  return data.length > 0 ? (
    <>
      <Typography tw="my-4 ml-2">
        Standard Spec. Code: {data[0].standardSpecificationCode}
      </Typography>
      <Card tw="max-w-sm">
        <SmartTable
          columns={[
            {
              id: "_range",
              label: "Qty Range",
              render: (_, row) =>
                `${row.minRangeValue} - ${row.maxRangeValue ?? "♾️"}`,
            },
            {
              id: "unitPrice",
              label: "Unit Price",
              align: "right",
              render: formatMoneyString,
            },
            { id: "_", label: "" },
          ]}
          rows={data}
          maxHeight={400}
        />
      </Card>
    </>
  ) : (
    <Card tw="p-6">
      <Typography>No variable pricing data for this item</Typography>
    </Card>
  );
};

const ItemModal = ({
  open,
  itemId,
  handleClose,
}: {
  open: boolean;
  itemId: string;
  handleClose: () => void;
}) => {
  const { data: item } = useItemQuery(itemId);
  const [showAllPrograms, setShowAllPrograms] = useState(false);

  const [isFullSpec, setIsFullSpec] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Item Info");

  const currentUserRole = useSelector((state: any) => state.user.role);

  const largeImages = item?.images.filter((img) => img.type === "large") ?? [];
  const carouselImages =
    largeImages.length > 0 ? largeImages : item?.images ?? [];
  const carouselImageSrcs = carouselImages.flatMap((img) =>
    Array(img.pdfPageCount || 1)
      .fill(0)
      .map((_, i) =>
        cld.url(img.cloudinaryId + ".jpg", {
          page: i + 1,
          ...imageSizeOptions.large,
        })
      )
  );

  const specifications = item
    ? mapSpecifications(item.specification, "item-display")
    : [];
  const fullSpecifications = item
    ? mapSpecifications(item.specification, "supplier-display")
    : [];

  const currentSpec = isFullSpec ? fullSpecifications : specifications;

  let mappedSpec =
    currentSpec[0]?.type === "websiteDesc"
      ? Object.entries(currentSpec[0].value).map(([key, value]) => ({
          key,
          value,
        }))
      : currentSpec;

  mappedSpec = mappedSpec.filter(({ key }) => key !== "MOQ" && key !== "MOQ:");

  const amountAvailable = !item?.warehouse
    ? "Currently Unavailable"
    : (item.cachedWarehouseQty ?? 0) <= 0
    ? "Out of Stock"
    : item.cachedWarehouseQty;

  const orderType =
    item?.itemStatus === "Ok"
      ? {
          "pre-order": "Pre-Order",
          inventory: "In Stock",
          "on-demand": "On-Demand",
        }[item.itemOrderType]
      : item?.itemStatus;

  const info = item
    ? {
        Type: item.itemTypeName,
        Description: item.comment,
        ...(item.itemOrderType && { "Order Status": orderType }),
        MOQ: item.mostRecentMoq ?? "---",
        "Pack Size": item.qtyPerPack,
        ...(item.itemOrderType === "from-inventory" && {
          "Amount available": amountAvailable,
        }),
        ...(item.removeFromCatalogDate && {
          "Available to order until": formatDateString(
            item.removeFromCatalogDate
          ),
        }),
        "Has Beacon": item.includeBeacon ? (
          <div tw="flex gap-1 items-center">
            Yes <BeaconIcon />
          </div>
        ) : (
          "No"
        ),
        ...(item.includeBeacon && {
          "Beacon Cost": formatMoneyString(item.mostRecentBeaconCost),
        }),
        ...(item.variants?.length > 1 && {
          Options: <ItemOptionsCount item={item} />,
        }),
      }
    : {};

  const programOverflow = item
    ? item.programs.map((p) => p.name).join(" --- ").length > 60
    : false;

  const hasFullSpecsPermissions = NON_SUPPLIER_ROLES.includes(currentUserRole);

  const hasProgramPermissions = PLANNING_TOOL_ROLES.includes(currentUserRole);

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      disableScrollLock
      fullWidth
      scroll="paper"
      maxWidth="lg"
    >
      {!item && (
        <DialogContent tw="flex justify-center py-12">
          <CircularProgress />
        </DialogContent>
      )}
      {item && (
        <DialogContent tw="grid lg:grid-cols-2 gap-4 p-0">
          <IconButton
            tw="absolute top-1 right-1 z-10"
            onClick={handleModalClose}
            size="large"
          >
            <Close color="secondary" />
          </IconButton>
          <div tw="flex items-center justify-center p-4">
            {!carouselImages.length && (
              <p tw="text-neutral-400 text-lg">No image</p>
            )}
            {!!carouselImages.length && (
              <Carousel
                startIndex={0}
                imageIdsOrUrls={carouselImages.map((img) => img.cloudinaryId)}
              >
                {carouselImageSrcs.map((src) => (
                  <div key={src}>
                    <CldImage
                      src={src}
                      width="100%"
                      height="100%"
                      tw="object-contain"
                      size="large"
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          <div tw="lg:max-h-full lg:overflow-y-auto text-base text-neutral-800">
            <Typography
              component="div"
              tw="max-w-prose my-12 p-4 mx-auto lg:mx-0"
            >
              <div tw="text-neutral-500"># {item.itemNumber}</div>
              <h1 tw="text-3xl mt-2">{item.comment ?? item.itemTypeName}</h1>
              <div tw="mt-3 flex items-center text-neutral-400">
                <div tw="font-bold tracking-wider text-neutral-600">
                  {formatMoneyString(item.mostRecentEstimatedCost)}
                </div>
                &nbsp; / &nbsp;
                <div tw="">
                  {item.isInventory
                    ? `${Number(item.cachedWarehouseQty)} in stock`
                    : item.itemOrderType}
                </div>
              </div>

              <Title tw="mt-6">{`Brand${
                item.brands.length !== 1 ? "s" : ""
              }`}</Title>
              <div tw="flex flex-wrap items-start gap-2">
                {item.brands.map((brand) => (
                  <Chip key={brand.id} label={brand.name} />
                ))}
              </div>

              <div tw="flex justify-between items-center">
                <Title tw="mt-6">{`Program${
                  item.programs.length !== 1 ? "s" : ""
                }`}</Title>
                {programOverflow && (
                  <IconButton
                    onClick={() => setShowAllPrograms(!showAllPrograms)}
                    title={showAllPrograms ? "Less" : "More"}
                  >
                    <FaIcon
                      tw="text-lg"
                      icon={showAllPrograms ? "chevron-up" : "chevron-down"}
                    />
                  </IconButton>
                )}
              </div>

              <div
                css={
                  showAllPrograms
                    ? tw`flex flex-wrap items-start gap-2`
                    : tw`relative flex items-start gap-2 overflow-x-hidden
                    after:(absolute inset-0 left-auto w-16 bg-gradient-to-r from-transparent via-white to-white pointer-events-none)`
                }
              >
                {_.orderBy(
                  item.programs,
                  [
                    (p) => (+p.id === item.mostRecentProgramId ? 0 : 1),
                    "startDate",
                  ],
                  ["asc", "desc"]
                ).map((program) => (
                  <Tooltip
                    key={program.id}
                    placement="top"
                    arrow
                    title={
                      +program.id === item.mostRecentProgramId
                        ? "Pricing Reference Program"
                        : ""
                    }
                  >
                    <Chip
                      tw="text-base"
                      css={
                        +program.id === item.mostRecentProgramId &&
                        tw`shadow-[inset 0px 0px 0px 2px] shadow-primary-500`
                      }
                      {...(hasProgramPermissions && {
                        component: Link,
                        to: `/planning/programs/${program.id}#${item.id}`,
                        target: "_blank",
                        clickable: true,
                        icon: (
                          <FaIcon icon="external-link" tw="pl-2 text-xs!" />
                        ),
                      })}
                      label={program.name}
                    />
                  </Tooltip>
                ))}
              </div>

              <Tabs
                value={selectedTab}
                onChange={(_, v) => setSelectedTab(v)}
                tw="min-h-0 mt-6 mb-2 border-b-2 border-neutral-200"
              >
                <Tab
                  label="Item Info"
                  value={"Item Info"}
                  tw="min-h-0 py-3 text-neutral-400 tracking-wider"
                />
                <Tab
                  label="Tiered Pricing"
                  value={"Tiered Pricing"}
                  tw="min-h-0 py-3 text-neutral-400 tracking-wider"
                />
              </Tabs>
              {selectedTab === "Item Info" && (
                <div>
                  <Title tw="mt-6">Item info</Title>
                  {Object.entries(info).map(([key, value], i) => (
                    <LabelValuePair key={i} label={key} value={value} />
                  ))}

                  <div tw="mt-6 flex justify-between items-center">
                    <Title>Specifications</Title>
                    {hasFullSpecsPermissions &&
                      fullSpecifications?.length > specifications?.length && (
                        <IconButton
                          onClick={() => setIsFullSpec(!isFullSpec)}
                          title={isFullSpec ? "Less" : "More"}
                        >
                          <FaIcon
                            icon={isFullSpec ? "chevron-down" : "chevron-up"}
                          />
                        </IconButton>
                      )}
                  </div>
                  {mappedSpec.map(({ key, value }, i) => {
                    return (
                      <LabelValuePair
                        key={i}
                        label={key.replace(":", "")}
                        value={
                          <span
                            css={
                              (!value ||
                                (value as string).toLowerCase() === "n/a") &&
                              tw`opacity-40`
                            }
                          >
                            {(value as string) || "---"}
                          </span>
                        }
                      />
                    );
                  })}
                </div>
              )}
              {selectedTab === "Tiered Pricing" && (
                <VariablePricingTable item={item} />
              )}
            </Typography>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

const ItemModalWrapper = () => {
  const dispatch = useDispatch();
  const { itemPreviewId } = useSelector((state: any) => state.items);
  return (
    itemPreviewId && (
      <ItemModal
        open={true}
        itemId={itemPreviewId}
        handleClose={() => dispatch(setItemPreview({ id: null }))}
      />
    )
  );
};
export default ItemModalWrapper;
