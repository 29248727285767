/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import BackButton from "src/components/Reporting/BackButton";

import { CSVLink } from "@utils/csv";

import { DatePicker } from "../components/Forms/DefaultInputs";
import { ReportOrderTypeSelector } from "../components/Reporting/ReportSelectors";
import ReportTable from "../components/Reporting/ReportTable";
import SupplierAutoComplete from "../components/Utility/AutoCompleteFields/SupplierAutoComplete";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getInvoicingReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportInvoicing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [currentOrderType, setCurrentOrderType] = useState("all");
  const [reset, setReset] = useState(false);
  const [currentSuppliers, setCurrentSuppliers] = useState([]);
  const [fromDate, setFromDate] = useState(
    format(new Date("02/01/2021"), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const { reportData, reportType, isLoading } = useSelector(
    (state) => state.reports
  );
  const currentSupplierFilter = useSelector((state) => state.filters.supplier);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    "invoicing",
    hasGenerated,
    setHasGenerated,
    isLoading,
    reportData
  );

  const handleReset = () => {
    setReset(true);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCurrentSuppliers([]);
    setCurrentOrderType("all");
    setFromDate(format(new Date("02/01/2021"), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
    setCsvData({ data: [], headers: [] });
  };

  const handleSupplier = (value, _type, _filter) => {
    setCurrentSuppliers(value);
    dispatch(updateSingleFilter({ filter: "supplier", value: value }));
  };

  const handleSupplierChipClick = (id) => {
    let currentSupplierArray = currentSupplierFilter.filter(
      (prog) => prog.id !== id
    );
    setCurrentSuppliers(currentSupplierArray);
    dispatch(
      updateSingleFilter({ filter: "supplier", value: currentSupplierArray })
    );
  };

  const handleGetReport = () => {
    const filterObject = {
      channel: currentChannel,
      orderType: currentOrderType,
      dateRange: `${fromDate} - ${toDate}`,
      supplier: currentSuppliers,
    };
    dispatch(getInvoicingReport(filterObject));
    setHasGenerated(true);
  };

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Select Invoicing</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Select Invoicing Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="select_invoicing.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <ReportOrderTypeSelector
            currentOrderType={currentOrderType}
            setCurrentOrderType={setCurrentOrderType}
            classes={classes}
            wide={false}
            noInventory={true}
          />
          <SupplierAutoComplete
            classes={classes}
            handleChange={handleSupplier}
            reset={reset}
            setReset={setReset}
            filterType="report"
          />
        </div>
        <br />
        <div className={classes.searchComponents}>
          <DatePicker
            fullWidth
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="fromDate"
            label="Shipped From Date"
            value={fromDate}
            onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
            disabled={isLoading}
          />

          <DatePicker
            fullWidth
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="toDate"
            label="Shipped To Date"
            value={toDate}
            onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
            disabled={isLoading}
          />
        </div>
        <br />
        <br />
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Supplier Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentSuppliers.length > 0 &&
            currentSuppliers.map((supplier) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={supplier.id}
                label={supplier.name}
                onDelete={() => handleSupplierChipClick(supplier.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading && reportData.length > 0 && reportType === "invoicing" && (
          <ReportTable report={reportData} type="invoicing" />
        )}
      </Container>
    </>
  );
};

ReportInvoicing.propTypes = {};

export default ReportInvoicing;
