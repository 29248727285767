/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { without } from "lodash";
import { useNoFetch } from "src/hooks/UtilityHooks";

import { Contained } from "@components/StyledComponents";
import useRoleIs from "@utils/useRoleIs";

import ComplianceDash from "../components/Dashboard/ComplianceDash";
import FieldDash from "../components/Dashboard/FieldDash";
import SupplierDash from "../components/Dashboard/SupplierDash";
import {
  COMPLIANCE,
  FREIGHT_FORWARDER,
  NON_SUPPLIER_ROLES,
  SUPPLIER_ROLES,
} from "../constants/permissions";
import FreightForwarderDashboard from "./FreightForwarderDashboard";

const Dashboard = () => {
  const { firstName } = useSelector((state) => state.user);

  const roleIs = useRoleIs();

  useNoFetch();

  return (
    <Contained>
      <h2 tw="mb-6 mt-4 text-2xl text-neutral-600">{`Welcome back, ${firstName}!`}</h2>
      {roleIs(SUPPLIER_ROLES) && <SupplierDash />}
      {roleIs(COMPLIANCE) && <ComplianceDash />}
      {roleIs(without(NON_SUPPLIER_ROLES, COMPLIANCE)) && <FieldDash />}
      {roleIs(FREIGHT_FORWARDER) && <FreightForwarderDashboard />}
    </Contained>
  );
};

export default Dashboard;
