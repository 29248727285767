/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useSelector } from "react-redux";

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

const NavSelector = ({
  value,
  onChange,
  options,
}: {
  value: string | number | null;
  onChange: (event: SelectChangeEvent<string | number>) => void;
  options: { id: string | number; name: string }[];
}) => {
  const { isOrdering } = useSelector((state: any) => state.app);
  const isStepperLoad = useSelector(
    (state: any) => state.globalLoad.isProgStepperLoad
  );

  if (options.length === 1) {
    return (
      <div tw="text-neutral-600 px-3">
        <Typography variant="body2">{options[0].name}</Typography>
      </div>
    );
  }

  return (
    <FormControl
      size="small"
      css={{
        ".MuiOutlinedInput-root:not(.Mui-disabled)": tw`py-1 -my-1 transition-colors hover:bg-neutral-100`,
        ".MuiOutlinedInput-root fieldset": tw`border-none`,
        ".MuiInputBase-input": tw`py-0 pr-4 text-sm text-neutral-700`,
      }}
    >
      <Select
        value={value ?? ""}
        onChange={onChange}
        disabled={
          (isOrdering && window.location.href.includes("/orders/open")) ||
          isStepperLoad
        }
        displayEmpty
      >
        {options.map((opt, index) => (
          <MenuItem value={opt.id ?? ""} key={index ?? "default"}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NavSelector;
