/** @jsxImportSource @emotion/react */
import { useDispatch } from "react-redux";

import _ from "lodash";
import { LS_CHANNEL } from "src/constants/localStorage";
import { setChannel } from "src/redux/slices/appSlice";

import { useAuthUser } from "@features/auth";

import { updateCurrentChannel } from "../../redux/slices/users/userSlice";
import NavSelector from "./NavSelector";

const OnPremiseRetailSelector = () => {
  const dispatch = useDispatch();

  const { isRetail, isOnPremise, channel } = useAuthUser();

  const handleChangeSelect = (evt) => {
    const val = evt.target.value;
    dispatch(updateCurrentChannel({ channel: val }));
    dispatch(setChannel(evt.target.value));
    window.localStorage.setItem(LS_CHANNEL, val);
  };

  const options = _.compact([
    isRetail && { id: "retail", name: "Retail" },
    isOnPremise && { id: "on_premise", name: "On Premise" },
  ]);

  return (
    <NavSelector
      value={channel}
      onChange={handleChangeSelect}
      options={options}
    />
  );
};

export default OnPremiseRetailSelector;
