import { QueryCache, QueryClient } from "@tanstack/react-query";

import { axiosOrApiError } from "@services/api/clientHelpers";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      // Catch 401, invalid_token errors and redirect to login
      if (error.status === 401) {
        console.error(error);
        window.location.href = "/login";
      }
    },
  }),
  defaultOptions: {
    queries: {
      // because most of our data contains circular data, we can't use this optimization
      structuralSharing: false,
      retry: (failureCount, error: ReturnType<typeof axiosOrApiError>) => {
        if (!("status" in error)) {
          // not an axios error, the error is with the query client
          return false;
        }
        if (error.status <= 500) return false; // do not retry for client errors
        if (failureCount > 2) return false; // do not retry more than 3 times
        return true;
      },
    },
  },
});

export default queryClient;
