import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import client from "@services/api";

const Logout = () => {
  const dispatch = useDispatch();
  const expired = false;
  const { logout } = useAuth0();

  useEffect(() => {
    delete axios.defaults.headers.common["Authorization"];
    delete client.headers["Authorization"];

    let url = expired
      ? `${window.location.origin}?expired=true`
      : `${window.location.origin}`;

    logout({ logoutParams: { returnTo: url } });
  }, [dispatch, expired, logout]);
  return <h1>Logging out...</h1>;
};

export default Logout;
