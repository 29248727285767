import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setClear,
  setDefaultFilters,
  setFetchCurrent,
  setFilterType,
  setFiltersOpen,
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import { filter } from "../utility/utilityFunctions";

/*
Manages sorting and filtering of programs in the Pre-Order Program view
This is the only view where sorting is handled in the UI
*/
export const useProgramSort = (programList, sortOption, filters) => {
  const [sortedList, setSortedList] = useState(programList);
  const monthValue = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  useEffect(() => {
    if (programList.length === 0) {
      setSortedList([]);
    } else {
      let tempList = filter(programList, filters);
      tempList = tempList.slice();
      tempList.sort((a, b) => {
        return a.brand[0].toLowerCase() < b.brand[0].toLowerCase()
          ? -1
          : a.brand[0].toLowerCase() > b.brand[0].toLowerCase()
          ? 1
          : 0;
      });

      if (sortOption === "brand") {
        setSortedList(tempList);
      } else if (sortOption === "unit") {
        let unitList = [...tempList];
        unitList.sort((a, b) => {
          return a.unit.toLowerCase() < b.unit.toLowerCase()
            ? -1
            : a.unit.toLowerCase() > b.unit.toLowerCase()
            ? 1
            : 0;
        });
        setSortedList(unitList);
      } else if (sortOption === "month") {
        let monthList = [...tempList];
        monthList.sort((a, b) => {
          return monthValue[a.focusMonth] < monthValue[b.focusMonth]
            ? -1
            : monthValue[a.focusMonth] > monthValue[b.focusMonth]
            ? 1
            : 0;
        });
        setSortedList(monthList);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption, programList, filters, filters.length]);
  return sortedList;
};

/*
For views using tabs, manages history and makes sure that browser functions
are recognized by the ui where there are tabs that render different views
*/
export const useWindowHash = (
  hashArray,
  updateFunc,
  secondaryUpdateFunc = undefined
) => {
  const handleChangeTab = (_evt, newValue) => {
    window.location.hash = hashArray[newValue];
    updateFunc(newValue);
  };

  const handleHash = useCallback(() => {
    const hashIndex = hashArray.indexOf(window.location.hash);
    if (hashIndex !== -1) {
      updateFunc(hashIndex);
      if (secondaryUpdateFunc !== undefined) {
        let currentHash = window.location.hash;
        let newHash =
          currentHash.slice(1, 3) +
          currentHash.slice(3, 4).toUpperCase() +
          currentHash.slice(4);
        secondaryUpdateFunc(newHash);
      }
    }
  }, [updateFunc, hashArray, secondaryUpdateFunc]);

  useEffect(() => {
    handleHash();
  }, [handleHash]);

  useEffect(() => {
    window.addEventListener("popstate", handleHash);
    return () => window.removeEventListener("popstate", handleHash);
  }, [handleHash]);

  return handleChangeTab;
};

/*
Handles setting initial filters for views with filters
*/
export const useInitialFilters = (filterType, defaultFilters) => {
  const dispatch = useDispatch();
  const { filterType: savedFilterType } = useSelector((state) => state.filters);
  const { role } = useSelector((state) => state.user);
  const [needsToFetch, setNeedsToFetch] = useState(false);
  useEffect(() => {
    let currentFilters = { ...defaultFilters };
    dispatch(setFilterType({ type: filterType }));
    if (filterType !== savedFilterType) {
      dispatch(
        setDefaultFilters({
          filterObject: currentFilters,
        })
      );
      dispatch(
        updateMultipleFilters({
          filterObject: currentFilters,
        })
      );
      if (needsToFetch) {
        dispatch(setSorted());
        setNeedsToFetch(false);
      }
    }
    dispatch(setFiltersOpen({ open: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role.length > 0 && filterType !== savedFilterType) {
      dispatch(setClear());
    } else {
      dispatch(setFetchCurrent());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useChannelUpdate = (channel, channelFilter, dispatch) => {
  useEffect(() => {
    if (!channel || !channelFilter) return;
    if (channel !== channelFilter) {
      dispatch(
        updateSingleFilter({
          filter: "channel",
          value: channel,
        })
      );
      dispatch(setSorted());
    }
  }, [channel, channelFilter, dispatch]);
};

export const useNoFetch = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterType({ type: null }));
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);
};

export const useDebounce = (func, delay) => {
  const debounce = useRef(null);

  useEffect(() => {
    return () => {
      // clear when component unmounts
      clearTimeout(debounce.current);
    };
  }, []);

  return (...args) => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => func(...args), delay);
  };
};
