import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { Rule } from "@models/Rule";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const rulesKeyFactory = createQueryKeys("rules", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Rule[]>("rules", {
        params,
      }),
  }),
});

export const usePaginatedRulesQuery = buildPaginatedQuery(
  rulesKeyFactory.paginated,
  { placeholderData: keepPreviousData, structuralSharing: true }
);
