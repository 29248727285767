/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Divider } from "@mui/material";

import _ from "lodash";

import { orderTypeLabel } from "@features/orders";

import ControlledFilterRadioGroup from "../components/ControlledFilterRadioGroup";
import ControlledResourceFilterAutocomplete from "../components/ControlledResourceFilterAutocomplete";
import FilterChip from "../components/FilterChip";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const additionalOrderTypeNames = {
  inventory: "Inventory",
};

const displayOrderTypeName = (orderType: string) =>
  orderTypeLabel(orderType as any) ??
  additionalOrderTypeNames[orderType] ??
  orderType;

const orderWindowHeader = {
  national: "National Order Windows",
  secondary: "Secondary Order Windows",
};

const Popper = ({ orderTypeOptions }) => {
  const { control, setValue } = useFilterContext();

  // The use-case for order-types vary, so allow parent component to pass down list of options through slotProps
  // eg <Filters slots={["orderType"]} slotProps={{ orderType: { orderTypeOptions: ["on-demand", "from-inventory", "to-inventory"] }} />
  const options = (orderTypeOptions ?? ["on-demand", "inventory"]).map(
    (ot) => ({
      id: ot,
      name: displayOrderTypeName(ot),
    })
  );

  return (
    <div>
      <ControlledFilterRadioGroup
        name="orderType"
        options={options}
        titleText="Order Type is"
        control={control}
        onChange={() => setValue("orderCalendarMonths", [])}
      />
      <Divider />
      <ControlledResourceFilterAutocomplete
        inputPlaceholder="Search Order Windows"
        name="orderCalendarMonths"
        resource="order-calendar-months"
        requestParams={{ sort: "-order-window-open-date" }}
        resourceFilterName="orderWindowName"
        control={control}
        getOptionText={(opt) => opt.orderWindowName}
        tapOptions={(opts) =>
          _(opts)
            .sortBy("orderWindowType")
            .filter("orderWindowName")
            .map((opt) => ({
              ...opt,
              header: orderWindowHeader[opt.orderWindowType],
            }))
            .value()
        }
        groupBy={(opt: any) => opt.header}
        onChange={() => setValue("orderType", "")}
      />
    </div>
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const orderType = filterValues.orderType;
  const ocmIds = filterValues.orderCalendarMonths ?? [];
  const orderTypeText = orderType && displayOrderTypeName(orderType);

  if (ocmIds.length > 0) {
    return (
      <ResourceFilterChip
        resource="order-calendar-months"
        ids={ocmIds}
        getResourceText={"orderWindowName"}
        prefix="Order Window is"
        contentWhenEmpty="Select Order Type"
        {...props}
      />
    );
  }

  return (
    <FilterChip {...props} showCloseButton={!!orderType}>
      {orderType ? (
        <>
          <span>Order Type is&nbsp;</span>
          <b>{orderTypeText}</b>
        </>
      ) : (
        "Select Order Type"
      )}
    </FilterChip>
  );
};

const filterOrderType = {
  name: "orderType",
  menuText: "Order Type",
  formControls: ["orderType", "orderCalendarMonths"],
  uniqueId: "orderType",
  Popper,
  Chip,
};

export default filterOrderType;
