/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs } from "@mui/material";

import { keepPreviousData } from "@tanstack/react-query";
import "date-fns";
import _ from "lodash";
import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "src/components/StyledComponents";
import { setOrderSetSelection } from "src/redux/slices/ordering/orderSetHistorySlice";

import ConfirmDenyModal from "@components/Utility/Modals/ConfirmDenyModal";
import { useMutateError } from "@features/errors";
import { Filters, useFilterParams } from "@features/filters";
import {
  useApproveOrderSetsMutation,
  useDenyOrderSetMutation,
  useOrderSetVariantSummariesPaginated,
  useOrderSetsPaginated,
} from "@features/orders";
import ApprovalsOrderSetTable from "@features/orders/approvals/ApprovalsOrderSetTable";
import ApprovalsOrderSetVariantSummariesTable from "@features/orders/approvals/ApprovalsOrderSetVariantSummariesTable";
import { RequestReportButton, useReport } from "@features/reports";
import { Button, PageTitle } from "@features/ui";
import { useSetLocation } from "@services/reactRouterDom";
import useDeepCompareEffect from "@utils/useDeepCompareEffect";
import useRoleIs from "@utils/useRoleIs";

import { useNoFetch } from "../hooks/UtilityHooks";

const useOrderSetParams = () => {
  const { channel, territory } = useSelector((state: any) => state.app);
  const [filters] = useFilterParams();
  const params = {
    filter: {
      type: "not-pre-order",
      status: ["submitted"],

      [filters.searchBy ?? "search-term"]: filters.q,
      channel,
      orderCalendarMonthIds: filters.orderWindowId && [filters.orderWindowId],
      budgetIds: filters.budgets,
      userIds: filters.users,
      brandIds: filters.brands,
      programIds: filters.programs,
      itemTypeIds: filters.itemTypes,
      stateIds: filters.states,
      territoryIds: filters.territories ?? (territory && [territory]),
      supplierIds: filters.supplier,
    },
    sort: filters.sort,
  };
  return params;
};

const orderSetParamsToVariantParams = (params: Record<string, any>) => {
  const { filter, sort, ...rest } = params;

  const sortPrefix = sort?.startsWith("-") ? "-" : "";
  const sortKey = sort?.replace(/^-/, "");
  const mappedSortKey =
    { id: "order-set-id", "submitted-at": "order-set-submitted-at" }[sortKey] ??
    sortKey;

  const mappedFilter = _.mapKeys(filter, (_val, key) => {
    return { status: "orderSetStatus", type: "orderSetType" }[key] ?? key;
  });

  return {
    sort: sortPrefix + mappedSortKey,
    filter: mappedFilter,
    ...rest,
  };
};

const ApprovalsByOrder = ({ mutationPending }) => {
  const dispatch = useDispatch();
  const params = useOrderSetParams();
  const setMutateError = useMutateError();
  const [denyOrderSetId, setDenyOrderSetId] = useState<null | string>(null);

  const denyMutation = useDenyOrderSetMutation();
  const handleDeny = (id: string, note: string) =>
    denyMutation.mutateAsync({ id, note }).catch(setMutateError);

  useReport("approvals-order-set", ["order-sets", params]);

  const { data: orderSets = [], ...orderSetsTableProps } =
    useOrderSetsPaginated(params, {
      placeholderData: keepPreviousData,
    });

  useDeepCompareEffect(() => {
    // clear selection when params change
    dispatch(setOrderSetSelection([]));
  }, [params]);

  return (
    <>
      {denyOrderSetId && (
        <ConfirmDenyModal
          open
          handleClose={() => setDenyOrderSetId(null)}
          handleDeny={handleDeny}
          orderSetId={denyOrderSetId}
        />
      )}
      <ApprovalsOrderSetTable
        data={orderSets}
        {...orderSetsTableProps}
        view="approvals"
        mutationPending={mutationPending}
        handleDeny={(id) => setDenyOrderSetId(id)}
      />
    </>
  );
};

const ApprovalsByItem = () => {
  const orderSetParams = useOrderSetParams();
  const params = orderSetParamsToVariantParams(orderSetParams);

  // this report queries order-variants, not order-set-variant-summaries like the table
  const reportParams = _(params)
    .set("filter.orderType", "not-pre-order")
    .set("filter.qtyNotZero", true)
    .value();
  useReport("approvals-order-variant", ["order-variants", reportParams]);

  // Order Set Variant Summaries
  const { data: osvs = [], ...osvsTableProps } =
    useOrderSetVariantSummariesPaginated(params, {
      placeholderData: keepPreviousData,
    });

  return (
    <ApprovalsOrderSetVariantSummariesTable
      data={osvs}
      {...osvsTableProps}
      view="approvals"
    />
  );
};

const Approvals = ({ view }: { view: "order" | "item" }) => {
  const dispatch = useDispatch();
  const setLocation = useSetLocation();
  const { selected } = useSelector((state: any) => state.orderSetHistory);
  const setError = useMutateError();
  const roleIs = useRoleIs();
  const approveOrderSetsMutation = useApproveOrderSetsMutation();
  const handleApproveSelected = () => {
    if (selected.length === 0) return;
    approveOrderSetsMutation.mutate(selected, {
      onError: (e) => setError(e, "Approve order-sets"),
      onSettled: () => dispatch(setOrderSetSelection([])),
    });
  };

  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex justify-between items-center gap-3 flex-wrap">
        <div tw="flex items-end gap-6">
          <PageTitle title="Order Approvals" tw="m-0" />
          <Tabs
            value={view}
            onChange={(_e, v) =>
              setLocation({ pathname: `/approvals/${v}` }, { replace: true })
            }
            tw="min-h-0"
          >
            <Tab
              label="Item"
              value={"item"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
            <Tab
              label="Order"
              value={"order"}
              tw="py-2 min-h-0 text-neutral-400 tracking-wider"
            />
          </Tabs>
        </div>
        <div tw="flex gap-3 flex-wrap">
          {["item", "order"].includes(view) && (
            <RequestReportButton
              reportName={
                view === "item"
                  ? "approvals-order-variant"
                  : "approvals-order-set"
              }
            />
          )}
          {view === "order" &&
            roleIs(["field2", "purchaser", "select-purchaser", "super"]) && (
              <Button
                variant="contained"
                onClick={handleApproveSelected}
                loading={approveOrderSetsMutation.isPending}
                disabled={selected.length === 0}
              >
                Approve Selected
              </Button>
            )}
        </div>
      </div>

      <TableCardContainer>
        <Filters
          slots={[
            "brands",
            "budgets",
            "itemTypes",
            "programs",
            "states",
            "suppliers",
            "territories",
            "users",
          ]}
          slotProps={{
            search: {
              searchBy: ["searchTerm", "programId"],
            },
          }}
          alwaysShow={["orderSetStatus"]}
          defaultValues={{
            searchBy: "searchTerm",
          }}
        />
        {view === "order" && (
          <ApprovalsByOrder
            mutationPending={approveOrderSetsMutation.isPending}
          />
        )}
        {view === "item" && <ApprovalsByItem />}
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default Approvals;
