/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";

import { LS_TERRITORY } from "src/constants/localStorage";
import { setTerritory } from "src/redux/slices/appSlice";

import useRoleIs from "@utils/useRoleIs";

import {
  clearAddressList,
  fetchInitialAddresses,
} from "../../redux/slices/addresses/addressSlice";
import { updateSingleFilter } from "../../redux/slices/filterSlice";
import { fetchStatesByIds } from "../../redux/slices/territories/territorySlice";
import { updateCurrentTerritory } from "../../redux/slices/users/userSlice";
import NavSelector from "./NavSelector";

const RegionSelector = () => {
  const dispatch = useDispatch();
  const roleIs = useRoleIs();

  const territories = useSelector((state: any) => state.user.territories);
  const { territory } = useSelector((state: any) => state.app);

  const handleChangeSelect = (evt) => {
    const terrId = evt.target.value || null;

    if (window.location.hash === "#addressBook") {
      dispatch(fetchInitialAddresses(terrId));
    }
    if (window.location.hash !== "#addressBook") {
      window.location.hash = "";
    }
    dispatch(clearAddressList());
    dispatch(updateCurrentTerritory({ territory: terrId }));
    dispatch(setTerritory(terrId));

    if (terrId) {
      localStorage.setItem(LS_TERRITORY, terrId);
    } else {
      localStorage.removeItem(LS_TERRITORY);
    }
    if (!roleIs("supplier")) {
      dispatch(fetchStatesByIds([terrId]));
    }
    dispatch(
      updateSingleFilter({
        filter: "currentTerritoryId",
        value: terrId,
      })
    );
  };

  return (
    <NavSelector
      value={territory}
      onChange={handleChangeSelect}
      options={territories}
    />
  );
};
export default RegionSelector;
