import { useDispatch } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { setRedirect } from "src/redux/slices/globalLoadSlice";
import {
  patchSuccess,
  setFailure,
  setIsLoading,
} from "src/redux/slices/patchOrderSlice";
import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { PurchaseOrder } from "@models/PurchaseOrder";
import { PurchaseOrderVariant } from "@models/PurchaseOrderVariant";
import client from "@services/api";
import { BodyWithId, buildPaginatedQuery } from "@utils/reactQuery";

import usePurchaseOrderId from "../usePurchaseOrderId";
import { purchaseOrdersKeyFactory } from "./purchaseOrderQueries";

export const purchaseOrderVariantsKeyFactory = createQueryKeys(
  "purchase-order-variants",
  {
    paginated: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client.get<PurchaseOrderVariant[]>("purchase-order-variants", {
          params,
        }),
    }),
  }
);

export const usePaginatedPurchaseOrderVariantsQuery = buildPaginatedQuery(
  purchaseOrderVariantsKeyFactory.paginated,
  {
    placeholderData: keepPreviousData,
  }
);

export function useRemovePOVariantMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();

  return useMutation({
    mutationFn: (id: string) => {
      dispatch(setIsLoading());
      return client.remove(`purchase-order-variants/${id}`).then(() => id);
    },
    onSuccess: (deletedId) => {
      dispatch(patchSuccess());
      queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        (po: PurchaseOrder) => ({
          ...po,
          purchaseOrderVariants: po.purchaseOrderVariants.filter(
            (v) => v.id !== deletedId
          ),
        })
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}

export function useAddSetUpFeeMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: ({
      cost,
      description,
    }: {
      cost: number | string;
      description: string;
    }) => {
      dispatch(setIsLoading());
      return client
        .post<PurchaseOrderVariant>(`purchase-order-variants`, {
          __type: "purchase-order-variant",
          isDirectCost: true,
          directCostDesc: description,
          actualCost: cost,
          purchaseOrder: {
            type: "purchase-order",
            id: purchaseOrderId,
          },
          relationshipNames: ["purchaseOrder"],
        })
        .then((res) => res.data);
    },
    onSuccess: (poVariant) => {
      dispatch(patchSuccess());
      queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        (po: PurchaseOrder) => ({
          ...po,
          purchaseOrderVariants: [...po.purchaseOrderVariants, poVariant],
        })
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}

export function useUpdatePOVariantMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: ({ id, ...data }: BodyWithId) => {
      dispatch(setIsLoading());
      return client
        .update<PurchaseOrderVariant>(`purchase-order-variants/${id}`, {
          __type: "purchase-order-variant",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (poVariant) => {
      dispatch(patchSuccess());
      queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        (po: PurchaseOrder) => ({
          ...po,
          purchaseOrderVariants: po.purchaseOrderVariants.map((v) =>
            v.id === poVariant.id ? poVariant : v
          ),
        })
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}

export function useUpdatePOVariantsByVariantIdMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: ({
      variantId,
      ...data
    }: {
      variantId: string;
      [key: string]: any;
    }) => {
      dispatch(setIsLoading());
      return client
        .post<PurchaseOrder>(
          `purchase-orders/${purchaseOrderId}/update-variant`,
          kebabCaseKeys({
            variantId: variantId,
            ...data,
          }),
          { serializeBody: false }
        )
        .then((res) => res.data);
    },
    onSuccess: (purchaseOrder) => {
      dispatch(patchSuccess());
      queryClient.setQueryData(
        purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        purchaseOrder
      );
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}

export function useRemovePOVariantsByVariantIdMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const purchaseOrderId = usePurchaseOrderId();
  return useMutation({
    mutationFn: (variantId: string) => {
      dispatch(setIsLoading());
      return client
        .post<PurchaseOrder>(
          `purchase-orders/${purchaseOrderId}/remove-variant`,
          kebabCaseKeys({
            variantId: variantId,
          }),
          { serializeBody: false }
        )
        .then((res) => res.data);
    },
    onSuccess: (purchaseOrder) => {
      const hasNonDirectCostVariants = purchaseOrder.purchaseOrderVariants.some(
        (pov) => pov.isDirectCost === false
      );
      dispatch(patchSuccess());
      if (!hasNonDirectCostVariants) {
        // Purchase Order had been deleted in the back-end, redirect to PO Rollup
        dispatch(setRedirect({ url: "/purchasing/po-rollup" }));
        queryClient.invalidateQueries({
          queryKey: purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
        });
      } else {
        queryClient.setQueryData(
          purchaseOrdersKeyFactory.detail(purchaseOrderId).queryKey,
          purchaseOrder
        );
      }
      queryClient.invalidateQueries({
        queryKey: purchaseOrderVariantsKeyFactory.paginated._def,
      });
    },
    onError: (e) => dispatch(setFailure({ error: e.message })),
  });
}
