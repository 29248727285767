/** @jsxImportSource @emotion/react */
import "twin.macro";

import _ from "lodash";

import { ruleTypes } from "@features/compliance/constants";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const options = ruleTypes.map((id) => ({ id, name: _.startCase(id) }));
const filterRuleType = makeRadioOptionsFilter({
  name: "ruleType",
  title: "Rule Type",
  options: options,
});

export default filterRuleType;
