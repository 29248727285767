/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Button } from "@mui/material";

import _ from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { FaIcon } from "@features/ui";
import { Order } from "@models/Order";
import { OrderSetVariant } from "@models/OrderSetVariant";
import { downloadAsCsv } from "@utils/csv";

import { displayToInventoryAllocation } from "../helpers";
import { useOrderSet } from "./data/useOrderSet";
import { useOptionalOrderSetId } from "./data/useOrderSetId";

const osvHeaderRows = [
  { key: "variant.item.type", label: "Item Type" },
  { key: "variant.item.comment", label: "Item Description" },
  { key: "variant.item.qtyPerPack", label: "Items Per Pack" },
  { key: "totalQty", label: "Total Qty of Items", f: formatMoneyString },
  { key: "estimatedCost", label: "Item Est Cost", f: formatMoneyString },
  { key: "totalEstimatedCost", label: "Total Est Cost", f: formatMoneyString },
];

const formatCsvData = (
  orderSetVariants: OrderSetVariant[],
  orders: Order[]
) => {
  const csvData: (string | number)[][] = [];
  csvData.push(["Seq #", ..._.map(orderSetVariants, "variant.variantSku")]);

  osvHeaderRows.forEach((row) => {
    csvData.push([
      row.label,
      ..._(orderSetVariants)
        .map(row.key)
        .map((v) => row.f?.(v) ?? v)
        .value(),
    ]);
  });

  orders.forEach((order) => {
    csvData.push([
      order.type === "to-inventory"
        ? `RESTOCK ${displayToInventoryAllocation(order)}`
        : `${order.address.name}: ${order.address.city}, ${order.address.state.code}`,
      ..._.map(order.orderVariants, "qty"),
    ]);
  });
  return csvData;
};

const ExportOrderSetButton = () => {
  const orderSetId = useOptionalOrderSetId();
  const { orders, orderSet, orderSetVariants } = useOrderSet(orderSetId);

  if (!orders || !orderSet || !orderSetVariants) return null;

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<FaIcon icon="arrow-down-to-line" />}
      onClick={(e) => {
        e.stopPropagation();
        downloadAsCsv(
          formatCsvData(orderSetVariants ?? [], orders),
          `order_set_${orderSet?.id}.csv`
        );
      }}
    >
      Export
    </Button>
  );
};

export default ExportOrderSetButton;
