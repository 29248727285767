import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { mapOrderVariants, mapSingleOrder } from "./maps";

let initialState = {
  isLoading: false,
  singleOrder: {
    orderNumber: null,
    user: null,
    address: null,
    distributorAbn: null,
    type: null,
    status: null,
    orderDate: null,
    shipDate: null,
    trackingNum: null,
    totalQty: 0,
    totalEstFreight: 0,
    totalActFreight: 0,
    totalEstTax: 0,
    totalActTax: 0,
    totalEstCost: 0,
    totalActCost: 0,
    totalBeaconCost: 0,
    variants: [],
    note: null,
    attn: null,
    hasUpdated: false,
    newOrderId: null,
  },
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const orderHistorySlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getSingleOrderSuccess(state, action) {
      const { order, variants } = action.payload;
      state.singleOrder = order;
      state.singleOrder.variants = variants;
      state.isLoading = false;
      state.error = null;
    },
    setOrderHasUpdated(state, action) {
      const { value, newOrderId } = action.payload;
      state.singleOrder.hasUpdated = value;
      state.singleOrder.newOrderId = newOrderId;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  getSingleOrderSuccess,
  setOrderHasUpdated,
  setFailure,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;

export const fetchOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/orders/${id}`);
    if (response.error) throw response.error;
    let mappedOrder = mapSingleOrder(response.data);
    let mappedVariants = mapOrderVariants(response.data["order-variants"]);
    dispatch(
      getSingleOrderSuccess({
        order: mappedOrder,
        variants: mappedVariants,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};
