import { ListItem } from "@mui/material";

import { DatePicker } from "src/components/Forms/DefaultInputs";

type DateRangeSelectorProps = {
  classes: any;
  fromLabel: string;
  toLabel: string;
  fromDate: Date;
  toDate: Date;
  onChangeFromDate: (value: Date) => void;
  onChangeToDate: (value: Date) => void;
  disabled: boolean;
  wrapInListItem?: boolean;
};

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  classes,
  fromLabel,
  toLabel,
  fromDate,
  toDate,
  onChangeFromDate,
  onChangeToDate,
  disabled,
  wrapInListItem = false,
}) => {
  const renderDatePicker = (props) => {
    const datePicker = (
      <DatePicker
        {...props}
        fullWidth
        color="secondary"
        className={classes.queryField}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        disabled={disabled}
      />
    );

    return wrapInListItem ? <ListItem>{datePicker}</ListItem> : datePicker;
  };

  return (
    <>
      {renderDatePicker({
        id: "fromDate",
        label: fromLabel,
        value: fromDate,
        onChange: (value) => onChangeFromDate(value),
      })}
      {renderDatePicker({
        id: "toDate",
        label: toLabel,
        value: toDate,
        onChange: (value) => onChangeToDate(value),
      })}
    </>
  );
};

export default DateRangeSelector;
