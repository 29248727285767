import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import {
  axiosDelete,
  axiosGet,
  axiosGetNoFormat,
  axiosPatch,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { stringToCents } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  setRedirect,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { updateValues } from "../suppliers/supplierSlice";
import {
  buildNotePatch,
  buildPOChangeNote,
  buildPurchaseOrderPatch,
  buildSendToFlowPost,
  buildShippingParameterPatch,
  buildShippingParameterUpdate,
  buildUpdateSupplierPatch,
} from "./helpers";
import { mapPOChangeNote, mapPurchaseOrder } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  isUpdateDeliveryLoading: false,
  nextPage: null,
  nextLink: null,
  itemRollups: [],
  itemRollupIdSelection: [],
  poRollupReport: [],
  fulfillmentOrderVariants: [],
  triggerCSVDownload: false,
  currentJobInformation: null,
  currentPO: {
    id: null,
    type: null,
    brand: null,
    status: null,
    submittedDate: null,
    accepted: false,
    inMarketDate: null,
    invoiceDate: null,
    deliverToWarehouseDate: null,
    sentToFlowAt: null,
    invoiceNum: null,
    expectedShip: null,
    actualShip: null,
    terms: null,
    supplier: null,
    isPurchaserSelect: null,
    contactName: null,
    email: null,
    phone: null,
    purchasedBy: null,
    method: "",
    supplierNotes: "",
    supplierReference: "",
    cdcTrackingNumber: "",
    changeNotes: [],
    includeBeacon: false,
    additionalFreightInvoiceNumber: null,
    additionalFreightCost: null,
    rfpNumber: null,
    rfpBidPrice: null,
    shippingLabel: null,
    additionalFile: null,
    keyAcctTape: "",
    specDetails: null,
    poVariants: [],
    baseFreight: null,
    totalCost: null,
    totalTax: null,
    isDirectShip: null,
    isPriceCompliant: null,
    allocatedTerritory: null,
    shippingParams: [],
  },
  newPOSuccess: false,
  error: null,
};

const resetCurrentPO = (state) => {
  state.currentPO.id = null;
  state.currentPO.type = null;
  state.currentPO.brand = null;
  state.currentPO.status = null;
  state.currentPO.submittedDate = null;
  state.currentPO.accepted = false;
  state.currentPO.inMarketDate = null;
  state.currentPO.invoiceDate = null;
  state.currentPO.deliverToWarehouseDate = null;
  state.currentPO.sentToFlowAt = null;
  state.currentPO.invoiceNum = null;
  state.currentPO.expectedShip = null;
  state.currentPO.actualShip = null;
  state.currentPO.terms = null;
  state.currentPO.supplier = null;
  state.currentPO.isPurchaserSelect = null;
  state.currentPO.contactName = null;
  state.currentPO.email = null;
  state.currentPO.phone = null;
  state.currentPO.purchasedBy = null;
  state.currentPO.method = "";
  state.currentPO.supplierNotes = "";
  state.currentPO.supplierReference = "";
  state.currentPO.changeNotes = [];
  state.currentPO.includeBeacon = false;
  state.currentPO.additionalFreightInvoiceNumber = null;
  state.currentPO.additionalFreightCost = null;
  state.currentPO.shippingLabel = null;
  state.currentPO.additionalFile = null;
  state.currentPO.keyAcctTape = "";
  state.currentPO.rfpNumber = null;
  state.currentPO.rfpBidPrice = null;
  state.currentPO.specDetails = null;
  state.currentPO.poVariants = [];
  state.currentPO.totalCost = null;
  state.currentPO.totalTax = null;
  state.currentPO.isDirectShip = null;
  state.currentPO.isPriceCompliant = null;
  state.currentPO.allocatedTerritory = null;
  state.currentPO.shippingParams = [];
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isNextLoading = false;
  state.isUpdateLoading = false;
  state.isUpdateDeliveryLoading = false;
  state.error = error;
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setUpdateDeliveryLoading(state) {
      state.isUpdateDeliveryLoading = true;
    },
    getItemRollupsSuccess(state, action) {
      const { itemRollups, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.itemRollups = [...itemRollups];
      state.isLoading = false;
      state.error = null;
    },
    getNextItemRollupsSuccess(state, action) {
      const { itemRollups, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.itemRollups = state.itemRollups.concat(itemRollups);
      state.isNextLoading = false;
      state.error = null;
    },
    getSinglePOSuccess(state, action) {
      const { purchaseOrder, rfpId, rfpBidPrice, newPO } = action.payload;
      state.currentPO = Object.assign(state.currentPO, purchaseOrder);
      if (rfpId !== "retain") {
        state.currentPO.rfpNumber = rfpId;
        state.currentPO.rfpBidPrice = rfpBidPrice;
      }
      if (newPO) {
        state.newPOSuccess = true;
      }
      state.error = null;
      state.isLoading = false;
    },
    getPORollupReportSuccess(state, action) {
      const { itemRollups } = action.payload;
      state.poRollupReport = itemRollups;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearPORollupReport(state) {
      state.poRollupReport = [];
    },
    setItemRollupIdSelection(state, action) {
      const { selectedItems } = action.payload;
      state.itemRollupIdSelection = selectedItems;
    },
    updatePOSuccess(state, action) {
      const { purchaseOrder } = action.payload;
      state.currentPO = Object.assign(state.currentPO, purchaseOrder);
      state.isUpdateLoading = false;
      state.isUpdateDeliveryLoading = false;
      state.error = null;
    },
    createChangeNoteSuccess(state, action) {
      const { note } = action.payload;
      state.currentPO.changeNotes = state.currentPO.changeNotes.concat(note);
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateChangeNoteSuccess(state, action) {
      const { note } = action.payload;
      state.currentPO.changeNotes = state.currentPO.changeNotes.map((n) => {
        if (n.id === note.id) {
          return { ...note };
        } else return n;
      });
      state.isUpdateLoading = false;
      state.error = null;
    },
    deleteChangeNoteSuccess(state, action) {
      const { id } = action.payload;
      state.currentPO.changeNotes = state.currentPO.changeNotes.filter(
        (n) => n.id !== id
      );
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateDirectShip(state, action) {
      const { value } = action.payload;
      state.currentPO.isDirectShip = value;
      state.currentPO.shippingParams = state.currentPO.shippingParams.map(
        (param) => ({ ...param, isDirectShip: value })
      );
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateParamDirectShip(state, action) {
      const { id, value } = action.payload;
      state.currentPO.shippingParams = state.currentPO.shippingParams.map(
        (param) => {
          if (param.id === id) {
            return { ...param, isDirectShip: value };
          } else return { ...param };
        }
      );
      state.isUpdateLoading = false;
      state.error = null;
    },
    updatePOStatusSuccess(state, action) {
      const { status } = action.payload;
      if (status) {
        state.currentPO.status = status;
      }
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateParamSuccess(state) {
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateAdditionalFreightSuccess(state, action) {
      const { invoiceNumber, cost } = action.payload;
      state.currentPO.additionalFreightInvoiceNumber = invoiceNumber;
      state.currentPO.additionalFreightCost = cost;
      state.isUpdateLoading = false;
      state.error = null;
    },
    checkJobIdSuccess(state, action) {
      const { jobData } = action.payload;
      state.currentJobInformation = { ...jobData };
      state.isUpdateLoading = false;
      state.error = null;
    },
    resetJobInformation(state) {
      state.currentJobInformation = null;
    },
    setNewPOSuccessFalse(state) {
      state.newPOSuccess = false;
    },
    getRollupOrderVariantsSuccess(state, action) {
      const { orderVariants } = action.payload;
      state.fulfillmentOrderVariants = orderVariants;
      state.isUpdateLoading = false;
      state.error = null;
    },
    clearPOError(state) {
      state.error = null;
    },
    deletePOSuccess(state) {
      state.isUpdateLoading = false;
      state.newPOSuccess = false;
      state.error = null;
      resetCurrentPO(state);
    },
    resetPurchaseOrder(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.nextPage = null;
      state.nextLink = null;
      state.itemRollups = [];
      state.itemRollupIdSelection = [];
      state.poRollupReport = [];
      state.triggerCSVDownload = false;
      state.newPOSuccess = false;
      state.error = null;
      resetCurrentPO(state);
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  setUpdateLoading,
  setUpdateDeliveryLoading,
  getItemRollupsSuccess,
  getNextItemRollupsSuccess,
  getSinglePOSuccess,
  getPORollupReportSuccess,
  setTriggerCSVFalse,
  clearPORollupReport,
  setItemRollupIdSelection,
  updatePOSuccess,
  updatePOStatusSuccess,
  updateDirectShip,
  updateParamDirectShip,
  createChangeNoteSuccess,
  updateChangeNoteSuccess,
  deleteChangeNoteSuccess,
  deletePOSuccess,
  updateParamSuccess,
  checkJobIdSuccess,
  resetJobInformation,
  updateAdditionalFreightSuccess,
  setNewPOSuccessFalse,
  getRollupOrderVariantsSuccess,
  resetPurchaseOrder,
  clearPOError,
  setFailure,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;

const getAwardedRFP = async (poId) => {
  const rfpResponse = await axiosGet(
    `/api/request-for-prices?filter[purchase_order_id]=${poId}`
  );

  if (rfpResponse.error) throw rfpResponse.error;
  if (rfpResponse.data.length > 0) {
    let currentRFP = rfpResponse.data.find(
      (r) => r.status === "awarded" || r.status === "complete"
    );
    if (currentRFP) {
      const rfpId = currentRFP.id;
      const rfpBidPrice = stringToCents(
        currentRFP.bids.find((b) => b.status === "awarded")
          ? currentRFP.bids.find((b) => b.status === "awarded").price
          : "---"
      );

      return {
        rfpId,
        rfpBidPrice,
      };
    }
  }
  return null;
};

export const fetchPO = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/purchase-orders/${id}`);
    if (response.error) throw response.error;

    const awardedRFP = await getAwardedRFP(id);

    const mappedData = mapPurchaseOrder(response.data);
    dispatch(
      getSinglePOSuccess({
        purchaseOrder: mappedData,
        rfpId: awardedRFP ? awardedRFP.rfpId : "---",
        rfpBidPrice: awardedRFP ? awardedRFP.rfpBidPrice : "---",
        newPO: true,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    dispatch(stopGlobalLoad());
  }
};

/* ---------- Purchase order create, update and delete ---------- */

export const updatePO = (id, field, value) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildPurchaseOrderPatch(id, field, value);
    const response = await axiosPatch(`/api/purchase-orders/${id}`, patchData);
    if (response.error) throw response.error;

    const mappedData = mapPurchaseOrder(response.data);

    dispatch(
      updatePOSuccess({
        purchaseOrder: mappedData,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updatePODeliveryDate =
  (poId, userId, newDeliveryDate, dateChangeReason) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setUpdateDeliveryLoading());
      const response = await axiosPost(
        `/api/purchase-orders/${poId}/update-delivery-date`,
        {
          purchase_order: poId,
          in_market_date: newDeliveryDate,
          user: userId,
          reason_for_change: dateChangeReason,
        }
      );
      if (response.error) throw response.error;

      const mappedData = mapPurchaseOrder(response.data);

      dispatch(
        updatePOSuccess({
          purchaseOrder: mappedData,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const setDirectShip = (id, value) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildPurchaseOrderPatch(id, "is-direct-ship", value);
    const response = await axiosPatch(`/api/purchase-orders/${id}`, patchData);
    if (response.error) throw response.error;
    dispatch(updateDirectShip({ value: value }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updateSupplier = (id, supplierId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildUpdateSupplierPatch(id, supplierId);
    const response = await axiosPatch(`/api/purchase-orders/${id}`, patchData);
    if (response.error) throw response.error;
    const mappedData = mapPurchaseOrder(response.data);

    dispatch(
      updatePOSuccess({
        purchaseOrder: mappedData,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updatePurchaseOrderStatus =
  (id, status, skipNotification = false) =>
  async (dispatch) => {
    const poStatusMap = {
      submit: "submitted",
      accept: "in-progress",
      complete: "complete",
      decline: null,
    };

    const redirectMap = {
      submit: "/purchasing/po-history/current",
      complete: "/purchasing/po-history/complete",
      decline: "/purchasing/po-history/new",
    };

    try {
      dispatch(patchLoading());
      dispatch(setUpdateLoading());
      const postData =
        status === "complete"
          ? { "skip-warehouse-notification": skipNotification }
          : {};
      const response = await axiosPost(
        `/api/purchase-orders/${id}/${status}`,
        postData
      );
      if (response.error) throw response.error;
      const mappedData = mapPurchaseOrder(response.data);
      dispatch(updatePOStatusSuccess({ status: poStatusMap[status] }));
      if (status === "decline") {
        dispatch(deletePOSuccess());
        dispatch(updateValues({ values: [{ key: "newPO", value: -1 }] }));
      } else {
        dispatch(
          getSinglePOSuccess({
            purchaseOrder: mappedData,
            rfpId: "retain",
            rfpBidPrice: null,
          })
        );
      }
      if (status === "accept") {
        if (mappedData.onShipHold) {
          dispatch(setRedirect({ url: "/purchasing/po-history/shippingHold" }));
          dispatch(
            updateValues({
              values: [
                { key: "newPO", value: -1 },
                { key: "shipHoldPO", value: 1 },
              ],
            })
          );
        } else {
          dispatch(setRedirect({ url: "/purchasing/po-history/inProgress" }));
          dispatch(
            updateValues({
              values: [
                { key: "newPO", value: -1 },
                { key: "inProgressPO", value: 1 },
              ],
            })
          );
        }
      } else {
        dispatch(setRedirect({ url: redirectMap[status] ?? null }));
      }
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const updateCustomShippingLabel =
  (sku, poId, label) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setUpdateLoading());
      const response = await axiosPut(
        "/api/shipping-parameter-variants/add-custom-shipping-label",
        {
          sku: sku,
          "purchase-order-id": +poId,
          label: label,
        }
      );
      if (response.error) throw response.error;
      const poResponse = await fetchPO(poId);
      if (poResponse.error) throw poResponse.error;
      const mappedData = mapPurchaseOrder(poResponse.data);
      dispatch(
        getSinglePOSuccess({
          purchaseOrder: mappedData,
          rfpId: "retain",
          rfpBidPrice: null,
        })
      );
      dispatch(updateParamSuccess());
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const cancelPO = (id, type, note) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = { "cancelation-type": type, "cancelation-note": note };
    const response = await axiosPost(
      `/api/purchase-orders/${id}/cancel`,
      postData
    );
    if (response.error) throw response.error;
    const poResponse = await axiosGet(`/api/purchase-orders/${id}`);
    if (poResponse.error) throw poResponse.error;
    const mappedData = mapPurchaseOrder(poResponse.data);
    dispatch(
      getSinglePOSuccess({
        purchaseOrder: mappedData,
        rfpId: "retain",
        rfpBidPrice: null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};
/* ---------- ---------- ---------- */

/* ---------- Change Notes and Notifications --------- */
export const sendNotification = (id, changeNote) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const postData = buildPOChangeNote(id, changeNote);
    const response = await axiosPost("/api/notes", postData);
    if (response.error) throw response.error;
    const notificationResponse = await axiosPost(
      `/api/purchase-orders/${id}/notify`,
      {}
    );
    if (notificationResponse.error) throw notificationResponse.error;
    const mappedData = mapPOChangeNote(response.data);
    dispatch(createChangeNoteSuccess({ note: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updateChangeNote =
  (id, note, sendNotification) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setUpdateLoading());
      const patchData = buildNotePatch(id, note);
      const response = await axiosPatch(`/api/notes/${id}`, patchData);
      if (response.error) throw response.error;
      if (sendNotification) {
        const notificationResponse = await axiosPost(
          `/api/purchase-orders/${id}/notify`,
          {}
        );
        if (notificationResponse.error) throw notificationResponse.error;
      }
      const mappedData = {
        id: response.data.id,
        note: response.data.message,
        name: response.data["user-name"],
        updatedAt: response.data["updated-at"],
      };
      dispatch(updateChangeNoteSuccess({ note: mappedData }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const deleteChangeNote = (id, sendNotification) => async (dispatch) => {
  try {
    const response = await axiosDelete(`/api/notes/${id}`, {});
    if (response.error) throw response.error;
    if (sendNotification) {
      const notificationResponse = await axiosPost(
        `/api/purchase-orders/${id}/notify`,
        {}
      );
      if (notificationResponse.error) throw notificationResponse.error;
    }
    dispatch(deleteChangeNoteSuccess({ id: id }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};
/* ---------- ---------- ---------- */

/* --------- Send to Flow --------- */
export const checkFlowJobId = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosGetNoFormat(
      `/api/flow-project-solutions/${id}`
    );
    if (response.error) throw response.error;
    const mappedData = {
      jobId: response.data.id,
      jobName: response.data.name,
      projectId: response.data.project_id,
      projectName: response.data.project_name,
    };
    dispatch(checkJobIdSuccess({ jobData: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
  }
};

export const sendToFlow = (id, jobId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const postData = buildSendToFlowPost(id, jobId);
    const response = await axiosPost(
      `/api/purchase-orders/${id}/send-to-flow`,
      postData
    );
    if (response.error) throw response.error;
    dispatch(updateParamSuccess());
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
  }
};

/* ---------- ---------- ---------- */

/* ---------- Shipping Parameter + Variant Calls --------- */

export const updateShipParamDirectShip = (id, value) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildShippingParameterPatch(id, "is-direct-ship", value);
    const response = await axiosPatch(
      `/api/shipping-parameters/${id}`,
      patchData
    );
    if (response.error) throw response.error;
    dispatch(updateParamDirectShip({ id: id, value: value }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const updateShippingParameter =
  ({ purchaseOrderId, paramId, addressId, attributes }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const patchData = buildShippingParameterUpdate(
        paramId,
        addressId,
        attributes
      );
      const response = await axiosPatch(
        `/api/shipping-parameters/${paramId}`,
        patchData
      );
      if (response.error) throw response.error;
      const poResponse = await axiosGet(
        `/api/purchase-orders/${purchaseOrderId}`
      );
      if (poResponse.error) throw poResponse.error;
      const mappedData = mapPurchaseOrder(poResponse.data);
      dispatch(
        getSinglePOSuccess({
          purchaseOrder: mappedData,
          rfpId: "retain",
          rfpBidPrice: null,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const destroyShippingParamVariants = (ids, poId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    await Promise.all(
      ids.map(async (id) => {
        let response = await axiosPost(
          `/api/shipping-parameter-variants/${id}/destroy`,
          {}
        );
        if (response.error) throw response.error;
      })
    );
    const poResponse = await axiosGet(`/api/purchase-orders/${poId}`);
    if (poResponse.error) throw poResponse.error;
    const mappedData = mapPurchaseOrder(poResponse.data);
    dispatch(
      getSinglePOSuccess({
        purchaseOrder: mappedData,
        rfpId: "retain",
        rfpBidPrice: null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
  }
};

export const cancelShippingParamVariants =
  (ids, poId, type, note) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      await Promise.all(
        ids.map(async (id) => {
          let postData = {
            "cancelation-type": type,
            "cancelation-note": note,
          };
          let response = await axiosPost(
            `/api/shipping-parameter-variants/${id}/cancel`,
            postData
          );
          if (response.error) throw response.error;
        })
      );
      const poResponse = await axiosGet(`/api/purchase-orders/${poId}`);
      if (poResponse.error) throw poResponse.error;
      const mappedData = mapPurchaseOrder(poResponse.data);
      dispatch(
        getSinglePOSuccess({
          purchaseOrder: mappedData,
          rfpId: "retain",
          rfpBidPrice: null,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };

export const updateAllShippingParams =
  (updateArray, id) => async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Uploading Shipping Document",
        })
      );
      let filteredParamArray = updateArray.filter(
        (param) => param.id && param.id.length > 0
      );
      const chunkedArray = _.chunk(filteredParamArray, 5);
      let fetchCount = filteredParamArray.length <= 5 ? 1 : chunkedArray.length;
      let response;
      let stepValue;
      if (fetchCount === 1) {
        response = await axiosPut(
          "/api/shipping-parameter-variants/add-shipping-extras",
          {
            "shipping-parameter-variants": chunkedArray,
          }
        );
        dispatch(updateStepperValue({ value: 50 }));
        if (response.error) throw response.error;
      } else {
        stepValue = parseFloat((100 / (fetchCount + 1)).toFixed(2));
        for (let i = 0; i < fetchCount; i++) {
          response = await axiosPut(
            "/api/shipping-parameter-variants/add-shipping-extras",
            {
              "shipping-parameter-variants": chunkedArray[i],
            }
          );
          if (response.error) throw response.error;
          dispatch(updateStepperValue({ value: stepValue }));
        }
      }
      if (id) {
        const poResponse = await axiosGet(`/api/purchase-orders/${id}`);
        if (poResponse.error) throw poResponse.error;
        const mappedData = mapPurchaseOrder(poResponse.data);
        dispatch(
          updateStepperValue({ value: fetchCount === 1 ? 50 : stepValue })
        );
        dispatch(
          getSinglePOSuccess({
            purchaseOrder: mappedData,
            rfpId: "retain",
            rfpBidPrice: null,
          })
        );
      } else {
        dispatch(
          updateStepperValue({ value: fetchCount === 1 ? 50 : stepValue })
        );
      }
      dispatch(updateParamSuccess());
      dispatch(resetStepperValue());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(resetStepperValue());
      dispatch(setError({ error: err.toString(), source: "Purchase Orders" }));
    }
  };
