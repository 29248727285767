import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Quote } from "@models/Quote";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const quotesKeyFactory = createQueryKeys("quotes", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Quote[]>("quotes", { params }),
  }),
});

export const usePaginatedQuotesQuery = buildPaginatedQuery(
  quotesKeyFactory.paginated
);
