import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { useTerritories } from "@features/territories";

const TerritoryAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
  type,
  disabled,
  ...styleProps
}) => {
  const [open, setOpen] = useState(false);
  const [territory, setTerritory] = useState("");
  const [currentTerritories, setCurrentTerritories] = useState([]);

  const { data: options, isLoading } = useTerritories({
    isAwaitingCode: false,
  });

  const currentFiltersTerritory = useSelector(
    (state) => state.filters.territory
  );
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const loading = open && isLoading;

  const handleTerritories = (value) => {
    setCurrentTerritories(value);
  };

  useEffect(() => {
    if (currentFiltersTerritory.length !== currentTerritories.length) {
      setCurrentTerritories(currentFiltersTerritory);
    }
  }, [currentFiltersTerritory, currentTerritories.length]);

  useEffect(() => {
    if (reset && setReset) {
      if (reset) {
        setTerritory("");
        setCurrentTerritories([]);
        setReset(false);
      }
    }
  }, [reset, setTerritory, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        id="territory-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={territory}
        onInputChange={(_evt, value) => setTerritory(value)}
        onChange={(_evt, value) => {
          handleChange(
            value,
            type === "inclusive" ? "territory" : "exclusiveTerritory",
            filterType
          );
          handleTerritories(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options ?? []}
        loading={loading}
        value={currentTerritories}
        disabled={isGlobalLoading || disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Territory"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...styleProps}
      />
    </>
  );
};

TerritoryAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
};

export default TerritoryAutoComplete;
