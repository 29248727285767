import React from "react";

import { Box, Chip } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  default: {
    borderRadius: "999px",
  },
  success: {
    color: darken(theme.palette.success.main, 0.6),
    backgroundColor: lighten(theme.palette.success.main, 0.9),
    // backgroundColor: theme.palette.primary.light,
  },
  info: {
    color: darken(theme.palette.info.main, 0.6),
    backgroundColor: lighten(theme.palette.info.main, 0.9),
  },
  error: {
    color: darken(theme.palette.error.main, 0.6),
    backgroundColor: lighten(theme.palette.error.main, 0.9),
  },
  warning: {
    color: darken(theme.palette.warning.main, 0.6),
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
  },
  large: {
    height: "auto",
    padding: "12px 16px",
  },
  small: {
    height: "auto",
    fontSize: "12px",
    padding: "4px 0px",
  },
}));

const StatusChip = ({ status, size, ...props }) => {
  const globalClasses = useStyles();

  const joinedClasses = [
    globalClasses.default,
    globalClasses[status],
    globalClasses[size],
  ]
    .filter(Boolean)
    .join(" ");

  return <Box component={Chip} {...props} classes={{ root: joinedClasses }} />;
};

export default StatusChip;
