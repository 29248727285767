import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { debounce } from "lodash";
import PropTypes from "prop-types";

import { fetchFilteredUsers } from "../../../redux/slices/users/userUpdateSlice";

const UserAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
  label = "Person",
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);

  const isLoading = useSelector((state) => state.userUpdate.isLoading);
  const options = useSelector((state) => state.userUpdate.userList);
  const currentFiltersUser = useSelector((state) => state.filters.user);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const loading = open && isLoading;

  const handleUsers = (value) => {
    setCurrentUsers(value);
  };

  const handleQuery = (name) => {
    dispatch(
      fetchFilteredUsers({
        name,
        isProgramCreator: filterType === "planningTool",
      })
    );
  };
  const debouncedHandleQuery = useMemo(
    () => debounce(handleQuery, 250),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleInputChange = (userName) => {
    setUser(userName);
    debouncedHandleQuery(userName);
  };

  useEffect(() => {
    if (currentFiltersUser.length !== currentUsers.length) {
      setCurrentUsers(currentFiltersUser);
    }
  }, [currentFiltersUser, currentUsers.length]);

  useEffect(() => {
    if (reset) {
      setUser("");
      setCurrentUsers([]);
      setReset(false);
    }
  }, [reset, setUser, setReset]);

  useEffect(() => {
    handleQuery("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        id="user-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={user}
        onInputChange={(_evt, value) => handleInputChange(value)}
        onChange={(_evt, value) => {
          handleChange(value, "user", filterType);
          handleUsers(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={currentUsers}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            id="user-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

UserAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default UserAutoComplete;
