/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import _ from "lodash";

import useSelectedResources from "../useSelectedResources";
import FilterChip, { FilterChipProps } from "./FilterChip";

interface ResourceOptionProps {
  resource: string;
  getResourceText?: string | ((entity: any) => string);
  options?: never;
}

interface OptionProps {
  options: { id: string; name: string }[];
  resource?: never;
  getResourceText?: never;
}

type CombinedOptionProps = ResourceOptionProps | OptionProps;

type ResourceFilterChipProps = CombinedOptionProps & {
  ids: string[];
  prefix: string;
  contentWhenEmpty: string;
  limitTags?: number;
  skipsPagination?: boolean;
} & FilterChipProps;

const ResourceFilterChip = ({
  resource,
  options,
  ids,
  prefix,
  getResourceText = "name",
  contentWhenEmpty,
  limitTags = 2,
  skipsPagination,
  ...props
}: ResourceFilterChipProps) => {
  const { data: resourceData, isLoading } = useSelectedResources(
    resource as any, // if resource is falsy, the query will not run
    ids,
    skipsPagination
  );

  const selectedOptions = options?.filter((opt) => ids.includes(opt.id));

  const data = selectedOptions ?? resourceData;

  return (
    <FilterChip {...props} showCloseButton={ids.length > 0}>
      {ids.length === 0 && contentWhenEmpty}
      {ids.length > 0 && (
        <>
          <span tw="text-neutral-600">{prefix}&nbsp;</span>
          <span
            css={[
              tw`font-medium transition-opacity text-neutral-800`,
              isLoading && tw`opacity-75`,
            ]}
          >
            {[...data]
              .splice(0, limitTags)
              .map(
                typeof getResourceText === "string"
                  ? (entity) => _.get(entity, getResourceText)
                  : getResourceText
              )
              .join(", ")}
            {ids.length > limitTags && (
              <span>, + {ids.length - limitTags}</span>
            )}
          </span>
        </>
      )}
    </FilterChip>
  );
};

export default ResourceFilterChip;
