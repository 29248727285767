import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/InputHooks";
import {
  addNewSupplier,
  setUpdateSuccess,
  updateSupplierById,
} from "../../redux/slices/suppliers/supplierSlice";
import { fetchFilteredUsers } from "../../redux/slices/users/userUpdateSlice";

const SupplierAutoComplete = ({ classes, handleChange }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);

  const isLoading = useSelector((state) => state.userUpdate.isLoading);
  const options = useSelector((state) => state.userUpdate.userList);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleUsers = (value) => {
    setCurrentUsers(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchFilteredUsers({ name: user, role: "supplier" }));
    }, 250);
  }, [user, dispatch]);

  useEffect(() => {
    if (user.length >= 1) {
      handleQuery();
    }
  }, [user, handleQuery, dispatch]);

  useEffect(() => {
    if (currentUsers.length > 0) {
      setCurrentUsers([]);
    }
  }, [currentUsers.length]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.settingsMargin}
        id="purchaser-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={user}
        onInputChange={(_evt, value) => setUser(value)}
        onChange={(_evt, value) => {
          handleChange(value);
          handleUsers(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={currentUsers}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Supplier User Assignment"
            id="supplier-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

SupplierAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SupplierModal = ({ open, handleClose, type, id, supplierList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
  } = useInput("");
  const {
    value: externalId,
    bind: bindExternalId,
    setValue: setExternalId,
    reset: resetExternalId,
  } = useInput("");
  const { value: sapId, bind: bindSapId, setValue: setSapId } = useInput("");

  const [isPurchaserSelect, setIsPurchaserSelect] = useState("no");
  const [goesToRFP, setGoesToRFP] = useState("no");
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [updateError, setUpdateError] = useState(null);

  const { isUpdateLoading, updateStatus, error } = useSelector(
    (state) => state.suppliers
  );

  const handleUserSelect = (value) => {
    let currentUser = {
      id: value[0].id,
      name: value[0].name,
      email: value[0].email,
      states: value[0].states,
      territories: value[0].territories,
    };
    setCurrentUsers(currentUsers.concat(currentUser));
  };

  const handleSubmit = () => {
    if (name.length === 0 || externalId.length === 0) {
      setUpdateError("You must set a Name and Supplier Code");
    } else {
      setUpdateError(null);
      const supplier = {
        externalId,
        name,
        isPurchaserSelect: isPurchaserSelect === "yes",
        goesToRFP: goesToRFP === "yes",
        isActive,
        users: currentUsers,
        sapId,
      };
      if (type === "edit") {
        dispatch(updateSupplierById(id, supplier));
      } else {
        dispatch(addNewSupplier(supplier));
      }
    }
  };

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (type === "edit" || updateStatus) &&
      (!currentSupplier || currentSupplier.id !== id)
    ) {
      let supplier = supplierList.find((sup) => sup.id === id);
      if (supplier) {
        setCurrentSupplier(supplier);
        setName(supplier.name);
        setExternalId(supplier.externalId);
        setIsPurchaserSelect(supplier.isPurchaserSelect ? "yes" : "no");
        setGoesToRFP(supplier.goesToRFP === "Yes" ? "yes" : "no");
        setIsActive(supplier.isActive);
        setCurrentUsers(supplier.assignedUsers);
        setSapId(supplier.sapId);
      }
    }
  }, [
    isUpdateLoading,
    type,
    updateStatus,
    currentSupplier,
    id,
    setCurrentSupplier,
    setName,
    setExternalId,
    setIsPurchaserSelect,
    setGoesToRFP,
    setCurrentUsers,
    supplierList,
  ]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setUpdateError(error);
    }
  }, [error]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          resetName();
          resetExternalId();
          setCurrentUsers([]);
          setIsPurchaserSelect(false);
          setGoesToRFP(false);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.headerText}>
              {type === "edit" ? `Editing ${name}` : "New Supplier"}
            </Typography>
            <FormControlLabel
              style={{ marginLeft: "20px" }}
              control={
                <Switch
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  name="active-switch"
                />
              }
              label={isActive ? "Active" : "Inactive"}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              resetName();
              resetExternalId();
              setCurrentUsers([]);
              setIsPurchaserSelect(false);
              setGoesToRFP(false);
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="name"
              type="text"
              label="Name"
              {...bindName}
              fullWidth
            />
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="code"
              type="text"
              label="Supplier Code"
              {...bindExternalId}
              fullWidth
            />
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="sapId"
              type="text"
              label="SAP ID"
              {...bindSapId}
              fullWidth
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.settingsMargin}
                component="fieldset"
              >
                <FormLabel component="legend">Is Purchaser Select:</FormLabel>
                <RadioGroup
                  aria-label="is-purchaser-select"
                  name="is-purchaser-select"
                  value={isPurchaserSelect}
                  onChange={(evt) => setIsPurchaserSelect(evt.target.value)}
                  row
                >
                  <FormControlLabel
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"no"}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.settingsMargin}
                component="fieldset"
              >
                <FormLabel component="legend">Goes To RFP:</FormLabel>
                <RadioGroup
                  aria-label="goes-to-rfp"
                  name="goes-to-rfp-bool"
                  value={goesToRFP}
                  onChange={(evt) => setGoesToRFP(evt.target.value)}
                  row
                >
                  <FormControlLabel
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"no"}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <br />
            <Typography className={classes.headerText}>
              User Assignment:
            </Typography>
            <br />
            <SupplierAutoComplete
              classes={classes}
              handleChange={handleUserSelect}
            />
            <br />
            {currentUsers.map((user) => (
              <Card
                key={user.id}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  padding: "10px",
                  position: "relative",
                  marginBottom: "10px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.bodyText}>
                    {`Id: ${user.id}`}
                  </Typography>
                  <Typography className={classes.bodyText}>
                    {`Name: ${user.name}`}
                  </Typography>
                  <Typography className={classes.bodyText}>
                    {`Email: ${user.email}`}
                  </Typography>
                </CardContent>
              </Card>
            ))}
            <br />
            <br />
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              id="profile"
              onClick={handleSubmit}
              style={{
                float: "right",
                marginBottom: "20px",
                minWidth: "62px",
              }}
            >
              {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
            </Button>
            {!isUpdateLoading && updateError && (
              <Typography
                className={classes.bodyText}
                style={{ color: "#920000" }}
              >
                {updateError}
              </Typography>
            )}
            {!isUpdateLoading && updateStatus && (
              <Typography className={classes.bodyText}>
                {type === "edit"
                  ? "Update Successful!"
                  : "New Supplier Added Successfully!"}
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SupplierModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  supplierList: PropTypes.array,
};

export default React.memo(SupplierModal);
