/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { SelectInput } from "@components/Forms/ControlledInputs";
import { useActiveOrderWindows } from "@features/orderCalendarMonths";

import { Bordered } from "../components/StyledComponents";
import { useFilterContext } from "../filterContext";

const Chip = ({
  orderWindowType,
  visibility,
}: {
  orderWindowType: "national" | "secondary";
  visibility: "active" | "visible";
}) => {
  const { control, setValue, submitFilters } = useFilterContext();
  const ocmId = useWatch({ name: "ocmId", control });
  const ocmParams =
    visibility === "visible"
      ? {
          startDate: "visibleDate" as const,
          endDate: "orderWindowOpenDate" as const,
        }
      : undefined;
  const orderWindows = useActiveOrderWindows(ocmParams);
  const options = orderWindows.filter(
    (ocm) => ocm.orderWindowType === orderWindowType
  );
  const selectOptions = [
    { id: "", name: "All" },
    ...options.map((ocm) => ({
      id: ocm.id,
      name: ocm.orderWindowName,
    })),
  ];
  const disabled = options.length < 2;

  useEffect(() => {
    if (ocmId && !options.find((ow) => ow.id === ocmId)) {
      setValue("ocmId", null);
      submitFilters();
    }
  }, [options, setValue, ocmId, submitFilters]);

  return (
    <Bordered
      role={disabled ? "disabled" : "button"}
      tw="h-8 text-sm text-neutral-600"
    >
      {options.length === 0 && (
        <span tw="px-4">
          No {visibility === "visible" ? "Preview" : "Active"} Order Windows
        </span>
      )}
      {options.length === 1 && (
        <span tw="px-4">{options[0].orderWindowName}</span>
      )}
      {options.length > 1 && (
        <SelectInput
          control={control}
          name="ocmId"
          options={selectOptions}
          label=""
          renderValue={(val) =>
            val
              ? options.find((ow) => ow.id === val)?.orderWindowName
              : "Select Order Window"
          }
          displayEmpty
          css={{
            "&": tw`h-full rounded-full appearance-none bg-neutral-200`,
            ".MuiOutlinedInput-root fieldset": tw`border-0`,
            ".MuiInputBase-root": tw`text-sm text-neutral-600`,
          }}
          onChange={() => submitFilters()}
        />
      )}
    </Bordered>
  );
};

const filterActiveOcm = {
  name: "activeOcm",
  formControls: ["ocmId"],
  Chip,
  alwaysShow: true,
};

export default filterActiveOcm;
