/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { Avatar, IconButton, Tooltip } from "@mui/material";

import { useAuthUser } from "@features/auth";
import { useHelpScoutBeacon } from "@features/helpScout";
import { FaIcon } from "@features/ui";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + (hash << 3);
  }

  let h = hash % 360;
  let s = 50 + (hash % 10); // Saturation from 50% to 60%
  let b = 40 + (hash % 20); // Brightness from 40% to 60%

  return `hsl(${h}, ${s}%, ${b}%)`;
}

function stringAvatar(name: string) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

const UserSection = () => {
  const user = useAuthUser();

  // hook to display helpscout beacon
  useHelpScoutBeacon();

  const handleSupportClick = (e) => {
    e.stopPropagation();
    window.Beacon("toggle");
  };
  return (
    <div className="sidebar__user-section">
      <div tw="flex items-center justify-between gap-4 w-[calc(var(--sidebar-open-width) - 3rem)]">
        <Tooltip title={"Profile"} placement="top" arrow>
          <IconButton tw="-my-1" component={Link} to="/profile">
            <Avatar
              tw="w-10 h-10"
              {...stringAvatar(user.name)}
              alt={user.name}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Support" placement="top" arrow>
          <IconButton onClick={handleSupportClick}>
            <FaIcon icon="headset" tw="text-xl" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Log out" placement="top" arrow>
          <IconButton component={Link} to="/logout">
            <FaIcon icon="sign-out-alt" tw="text-xl" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default UserSection;
