import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { clearError } from "src/redux/slices/errorSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  confirmDeleteModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "auto",
    textAlign: "center",
  },
}));

const ErrorModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.error.currentError);
  const source = useSelector((state) => state.error.errorSource);

  const handleErrorClose = () => {
    dispatch(clearError());
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={!!error}
        disableScrollLock
        onClose={handleErrorClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleErrorClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.confirmDeleteModal}>
            <Typography className={classes.headerText}>
              It looks like something went wrong, the following error has
              occured:
            </Typography>
            <br />
            <Typography
              className={[classes.bodyText, classes.wrapNewlines].join(" ")}
            >
              {error}
            </Typography>
            <br />
            <Typography
              className={classes.bodyText}
            >{`Source: ${source}`}</Typography>
            <br />
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                component={Link}
                onClick={handleErrorClose}
                to="/"
                color="secondary"
                variant="contained"
                className={classes.largeButton}
              >
                GO BACK HOME
              </Button>
              <Button
                onClick={handleErrorClose}
                color="secondary"
                variant="contained"
                className={classes.largeButton}
                style={{ marginLeft: "10px" }}
              >
                CLOSE WINDOW
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorModal;
