/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const options = [
  { id: "retail", name: "Retail" },
  { id: "on_premise", name: "On Premise" },
];
const filterTradeClass = makeRadioOptionsFilter({
  name: "tradeClass",
  title: "Trade Class",
  options: options,
});

export default filterTradeClass;
