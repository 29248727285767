import { GlobalStyles } from "twin.macro";

import ReactDOM from "react-dom/client";
import Helmet from "react-helmet";
import { Provider } from "react-redux";

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";

import { queryClient } from "@utils/reactQuery";

import * as serviceWorker from "./serviceWorker";
import "./Index.css";
import { store } from "./redux/store";
import ProtectedRouterProvider from "./routes/ProtectedRouterProvider";
import DateAdapter from "./utility/DateAdapter";
import themeFile from "./utility/theme.js";

const theme = createTheme(themeFile);

axios.defaults.headers.get["Cache-Control"] = "no-cache";
axios.defaults.timeout = 35000;

const onRedirectCallback = (appState) => {
  console.log("appState", appState);
  window.location = appState?.returnTo ?? window.location.pathname;
};

const auth0ProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/login`,
  // cacheLocation: "localstorage",
  onRedirectCallback: onRedirectCallback,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider {...auth0ProviderConfig}>
      <StyledEngineProvider>
        <MuiThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Provider store={store}>
              <Helmet>
                <meta charset="utf-8" />
                <title>Ready to Activate</title>
                <link rel="icon" href="/favicon.ico" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="logo192.png" />
                <link rel="manifest" href="/manifest.json" />
                <meta
                  name="insight-app-sec-validation"
                  content="b0e47055-1c1d-42af-ac4c-c21e7b18370e"
                />
                <script
                  src="https://kit.fontawesome.com/327dd64aed.js"
                  crossorigin="anonymous"
                ></script>
              </Helmet>
              <GlobalStyles />
              <ProtectedRouterProvider />
            </Provider>
          </LocalizationProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Auth0Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
