/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useAuthUser } from "@features/auth";

import ControlledResourceFilterAutocomplete from "../components/ControlledResourceFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { useFilterContext } from "../filterContext";

const filterRoles = {
  super: ["purchaser", "select-purchaser", "super"],
  purchaser: ["purchaser", "super"],
  "select-purchaser": ["select-purchaser", "super"],
  supplier: ["supplier"],
};

const Popper = () => {
  const { control } = useFilterContext();
  const { role } = useAuthUser();

  return (
    <ControlledResourceFilterAutocomplete
      name={"purchasers"}
      resource={"users"}
      inputPlaceholder={`Filter Purchasers`}
      control={control}
      requestParams={{ sort: "name", filter: { roles: filterRoles[role] } }}
      resourceFilterName={"name-or-email"}
    />
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedIds = filterValues["purchasers"] ?? [];
  return (
    <ResourceFilterChip
      resource={"users"}
      ids={selectedIds}
      prefix={`Purchaser is`}
      contentWhenEmpty={`Select Purchaser`}
      {...props}
    />
  );
};

const filterPurchasers = {
  name: "purchasers",
  menuText: "Purchaser",
  Chip,
  Popper,
};

export default filterPurchasers;
