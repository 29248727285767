/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { Bordered } from "./StyledComponents";

export type FilterChipProps = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: (e: React.MouseEvent) => void;
  showCloseButton?: boolean;
  closeTitle?: string;
};

const FilterChip = ({
  children,
  onClose,
  showCloseButton = true,
  closeTitle = "Clear filter",
  ...props
}: FilterChipProps) => {
  return (
    <Bordered
      role="button"
      className="filter-anchor"
      css={[
        tw`px-3 text-sm text-neutral-600`,
        { b: tw`font-medium text-neutral-800` },
      ]}
      {...props}
    >
      <div>{children}</div>
      {showCloseButton && onClose && (
        <Tooltip title={closeTitle} enterDelay={300}>
          <IconButton
            edge="end"
            tw="float-right!"
            onClick={(e) => {
              e.stopPropagation();
              onClose(e);
            }}
          >
            <Close tw="text-base" />
          </IconButton>
        </Tooltip>
      )}
    </Bordered>
  );
};

export default FilterChip;
