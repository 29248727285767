/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useCallback, useState } from "react";
import { ClickAwayListener } from "react-advanced-click-away";
import { useForm } from "react-hook-form";

import { ChevronRightRounded, Tune } from "@mui/icons-material";
import { Card, ListItemText, MenuItem, Popper } from "@mui/material";

import { intersection, isEqual, mapValues, omit } from "lodash";

import useDeepCompareEffect from "@utils/useDeepCompareEffect";
import useIsFirstRender from "@utils/useIsFirstRender";

import { FilterContextWrapper } from "../filterContext";
import filterIndex from "../filterIndex";
import useFilterParams from "../useFilterParams";
import Chip from "./FilterChip";
import Search from "./Search";

const stringifyValues = (obj) =>
  mapValues(obj, (v) =>
    Array.isArray(v)
      ? v.map(stringifyValues)
      : v && typeof v === "object"
      ? stringifyValues(v)
      : v?.toString()
  );

// Recursively delete keys of an object when their value is undefined.
// the object may contain nested objects and arrays.
const removeUndefined = (obj: Record<string, any>) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(!Array.isArray(value) && typeof value === "object" && value !== null
        ? { [key]: removeUndefined(value) }
        : value !== undefined
        ? { [key]: value }
        : {}),
    }),
    {}
  );

type FiltersProps = {
  hideSearch?: boolean;
  searchTitle?: string;
  slots: string[];
  slotProps?: Record<string, Record<string, any>>;
  alwaysShow?: string[];
  defaultValues?: Record<string, any>;
  filterState?: Record<string, any>;
  setFilterState?: (x: Record<string, any>) => void;
};

const Filters: React.FC<FiltersProps> = ({
  hideSearch,
  searchTitle,
  slots,
  slotProps,
  alwaysShow,
  defaultValues = {},
  filterState,
  setFilterState,
}) => {
  const isFirstRender = useIsFirstRender();
  const formParams = useForm({ defaultValues: defaultValues as any });
  const { reset, handleSubmit, getValues } = formParams;

  // this is the default filter state
  const [filterParams, setFilterParams] = useFilterParams();

  const filterValues = filterState || filterParams;
  const setFilterValues = setFilterState || setFilterParams;

  const hasDirtyFilters = !isEqual(
    filterValues,
    stringifyValues(defaultValues)
  );

  const menuOptions = slots.flatMap((slotName) =>
    filterIndex.filter((fo) => fo.name === slotName)
  );

  const keysWithValues = Object.keys(removeUndefined(filterValues));

  const chipsToRender = slots.flatMap((slotName) =>
    filterIndex.filter(
      (fo) =>
        fo.name === slotName &&
        (fo.alwaysShow ||
          alwaysShow?.includes(fo.name) ||
          intersection(keysWithValues, [fo.name, ...fo.formControls]).length >
            0)
    )
  );

  const allSlotsAlwaysShow = slots.every((slot) => {
    const filter = filterIndex.find((fo) => fo.name === slot);
    return filter?.alwaysShow || alwaysShow?.includes(slot);
  });

  const [filterAnchorEl, setFilterAnchorEl] = useState<Element | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<
    keyof typeof filterIndex | null
  >(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const getFilterOption = (key) =>
    filterIndex.find((fopt) => fopt.uniqueId === key) || null;

  const submitFilters = useCallback(
    () => setFilterValues(removeUndefined(getValues())),
    [setFilterValues, getValues]
  );

  const handleOpenFilter = (filter) => (e) => {
    setSelectedFilter(filter);
    setFilterAnchorEl(e.target.closest(".filter-anchor"));
  };
  const handleOpenMenu = (e) => {
    setMenuAnchorEl(e.target.closest(".filter-menu-anchor"));
  };

  const handleCloseFilter = () => {
    submitFilters();
    setFilterAnchorEl(null);
    setSelectedFilter(null);
  };
  const handleCloseMenu = () => {
    if (!menuAnchorEl) return;
    setMenuAnchorEl(null);
    handleCloseFilter();
  };

  const clearFilter = (filter) => {
    reset((formData) => omit(formData, ...filter.formControls));

    if (selectedFilter === filter.uniqueId) {
      handleCloseFilter();
    } else {
      submitFilters();
    }
  };

  const clearAllFilters = () => {
    reset();
    reset(defaultValues);
    setFilterValues(defaultValues);
  };

  useDeepCompareEffect(() => {
    if (isFirstRender) return;
    // this is the case if the filterValues are modified from the form, e.g, clearing all filters
    if (isEqual(filterValues, removeUndefined(getValues()))) return;

    // handle the case where the filterValues are modified from outside the form
    // e.g, a link that doesn't trigger a re-render modifies the filterValues
    // or a call to setFilterValues() from outside the form
    if (Object.keys(filterValues).length === 0) {
      setFilterValues(defaultValues);
    } else {
      reset(() => filterValues);
    }
  }, [filterValues, defaultValues]);

  useDeepCompareEffect(() => {
    const initialValues = { ...defaultValues, ...filterValues };
    reset(() => initialValues);
    setFilterValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const PopperFilter = (props) => {
    const filterOption = getFilterOption(selectedFilter);
    if (!filterOption?.Popper) return null;

    const PopperComponent = filterOption.Popper;

    return (
      <Popper
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        {...props}
      >
        <ClickAwayListener onClickAway={handleCloseFilter}>
          <Card tw="shadow-lg flex-col">
            <PopperComponent
              {...filterOption.props}
              {...slotProps?.[filterOption.name]}
            />
          </Card>
        </ClickAwayListener>
      </Popper>
    );
  };
  return (
    <FilterContextWrapper
      {...{ filterValues, setFilterValues, submitFilters, ...formParams }}
    >
      <form onSubmit={handleSubmit(setFilterValues)} tw="flex flex-wrap gap-2">
        {!hideSearch && (
          <Search
            submit={handleSubmit(setFilterValues)}
            searchTitle={searchTitle}
            {...(slotProps?.search as any)}
          />
        )}

        {/* {menuPosition === 0 && FilterMenuChip} */}

        {chipsToRender.map(({ Chip, ...filter }) => (
          <React.Fragment key={filter.uniqueId}>
            <Chip
              role={filter.Popper ? "button" : undefined}
              onClick={
                filter.Popper ? handleOpenFilter(filter.uniqueId) : undefined
              }
              onClose={() => clearFilter(filter)}
              submit={() => setFilterValues(getValues())}
              {...filter.props}
              {...slotProps?.[filter.name]}
            />
            {/* {i === menuPosition - 1 && FilterMenuChip} */}
          </React.Fragment>
        ))}

        {!menuAnchorEl && <PopperFilter tw="py-1" placement="bottom-start" />}

        <Popper
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          placement="bottom-start"
          tw="pt-1"
        >
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Card
              className="filter-anchor"
              tw="bg-white py-2 flex-col items-start shadow-lg overflow-hidden min-w-[200px] text-sm"
            >
              {menuOptions.map((filter) => {
                if (!filter.menuText) return null;
                return (
                  <MenuItem
                    key={filter.uniqueId}
                    className="group"
                    onClick={handleOpenFilter(filter.uniqueId)}
                    css={[
                      tw`w-full border-t hover:bg-neutral-100 border-neutral-200`,
                      selectedFilter === filter.uniqueId && tw`bg-neutral-200`,
                    ]}
                  >
                    {typeof filter.menuText === "string" ? (
                      <ListItemText>{filter.menuText}</ListItemText>
                    ) : (
                      filter.menuText
                    )}
                    {filter.Popper && (
                      <ChevronRightRounded
                        css={[
                          tw`ml-4 -mr-2 text-xl transition text-neutral-300 group-hover:text-neutral-500`,
                          selectedFilter === filter.uniqueId &&
                            tw`text-neutral-500`,
                        ]}
                      />
                    )}
                  </MenuItem>
                );
              })}
              {menuAnchorEl !== null && (
                <PopperFilter placement="right-start" tw="px-1" />
              )}
            </Card>
          </ClickAwayListener>
        </Popper>

        {slots.length > 0 && !allSlotsAlwaysShow && (
          <Chip
            className="filter-menu-anchor"
            onClick={handleOpenMenu}
            onClose={clearAllFilters}
            showCloseButton={hasDirtyFilters}
            css={[hasDirtyFilters && tw`border-primary-500 text-primary-900 `]}
            closeTitle="Reset filters"
          >
            <div tw="flex items-center">
              <Tune tw="text-xl" />
              <span tw="pl-2">Filters</span>
            </div>
          </Chip>
        )}
      </form>
    </FilterContextWrapper>
  );
};

export default Filters;
