/** @jsxImportSource @emotion/react */
import tw, { css, styled } from "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { CalendarMonth } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns/esm";

import {
  anaplanProgramsKeyFactory,
  useAnaplanProgramByProgramId,
} from "@features/programs";
import { Button, FaIcon, LabelValuePair } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

import { PLANNING_TOOL_SUPPLIER } from "../../../constants/permissions";
import {
  cancelProgram,
  submitProgram,
  updateProgram,
} from "../../../redux/slices/planningTool/programSlice";
import { useCustomForm } from "../../../utility/inputHelpers";
import { formatDate } from "../../../utility/utilityFunctions";
import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import BrandSelector from "../../Utility/Selectors/BrandSelector";
import ProgramTypeSelector from "../../Utility/Selectors/ProgramTypeSelector";
import TerritorySelector from "../../Utility/Selectors/TerritorySelector";
import AnaplanSyncTooltip from "./AnaplanSyncTooltip";
import ChangeAnaplanProgramModal from "./ChangeAnaplanProgramModal";
import NoteBox from "./NoteBox";
import ProgramCopyModal from "./ProgramCopyModal";
import ProgramEdit from "./ProgramEdit";

const FormRow = styled.div(
  css(tw`flex items-start justify-start gap-x-4`, {
    "& > *": tw`flex-1`,
  })
);
const StyledSelectInput = tw(SelectInput)`flex-grow`;

const orderActTypes = [
  { label: "Pre-Order", id: "pre-order" },
  { label: "In Stock", id: "inventory" },
  { label: "On-Demand", id: "on-demand" },
];

const channelOptions = [
  { label: "Retail", id: "retail" },
  { label: "On-Premise", id: "on_premise" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AnaplanProgram = ({ handleSaveAnaplanProgram }) => {
  const { pid } = useParams();

  const queryClient = useQueryClient();
  const { data: anaplanProgram } = useAnaplanProgramByProgramId(pid);
  const [editing, setEditing] = useState(false);

  const handleChange = (ap) => {
    handleSaveAnaplanProgram(ap);
    setEditing(false);
    queryClient.setQueryData(
      anaplanProgramsKeyFactory.byProgramId(pid).queryKey,
      anaplanProgram
    );
  };

  return (
    <>
      {editing && (
        <ChangeAnaplanProgramModal
          program={anaplanProgram}
          handleChange={handleChange}
          onClose={() => setEditing(false)}
        />
      )}
      <LabelValuePair
        label="Anaplan Program"
        value={
          <div tw="flex items-baseline">
            <Tooltip title={anaplanProgram?.name} placement="top" arrow>
              <Typography tw="text-lg flex-shrink" noWrap>
                {anaplanProgram?.name}
              </Typography>
            </Tooltip>
            <Tooltip title="Change Anaplan Program" placement="top">
              <IconButton onClick={() => setEditing(true)} tw="-my-2">
                <FaIcon icon="pen" tw="text-base text-primary-500" />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
    </>
  );
};

const ProgramDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentProgram = useSelector((state) => state.program.currentProgram);
  const roleIs = useRoleIs();
  const userCanEdit = !roleIs(PLANNING_TOOL_SUPPLIER);

  const { isUpdateLoading, updateError } = useSelector(
    (state) => state.program
  );

  const itemProgramsLength = useSelector(
    (state) => Object.values(state.itemPrograms.entities).length
  );

  /* translate resources */
  const programTypes = useSelector((state) => state.programTypes.programTypes);

  const {
    id,
    channel,
    name,
    createdBy,
    brand,
    clonedFromProgram,
    externalId, // Gallo ID
    programTypeId,
    territories,
    status,
    startDate,
    endDate,
    orderCalendarMonth,
    isTerritoryExclusive,
    workfrontUrl,
    cprSubmissionUrl,
    anaplanProgramCodes,
    brandAssignmentLevel,
  } = currentProgram;

  /* handle state from selectors */
  const [error, setError] = useState(null);
  const [primaryBrand, setPrimaryBrand] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [exclusiveTerrs, setExclusiveTerrs] = useState([]);
  const [isExclusive, setIsExclusive] = useState(false);
  const [copyProgModalOpen, setCopyProgModalOpen] = useState(false);

  const [initialLoad, setInitialLoad] = useState(false);

  const currentProgramType =
    programTypes.length > 0
      ? `${programTypeId}-${
          programTypes.find((type) => type.id === programTypeId)?.description
        }`
      : "";

  /* handle form */
  const { control, handleSubmit } = useCustomForm({
    mode: "onBlur",
    defaultValues: currentProgram,
  });

  const controlled = (name, rules) => ({
    name,
    control,
    rules,
    onBlur: handleSubmit(handleSendUpdates),
    disabled: !userCanEdit,
  });

  const handleSendUpdates = async (data) => {
    if (isExclusive === true && exclusiveTerrs.length === 0) {
      setError("Must provide an exclusive territory");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      dispatch(
        updateProgram({
          id,
          programData: data,
          brands: selectedBrands,
          territories: exclusiveTerrs,
          isExclusive,
          primaryBrand,
          ...(data.anaplanProgramId && {
            anaplanProgramId: data.anaplanProgramId,
          }),
        })
      );
    }
  };

  const handleUpdateAnaplanProgram = (anaplanProgram) => {
    handleSubmit((data) =>
      handleSendUpdates({ ...data, anaplanProgramId: anaplanProgram.id })
    )();
  };

  const handleSubmitProgram = (data) => {
    if (brandAssignmentLevel === "item" && itemProgramsLength === 0) {
      alert(
        "We currently require that multi-brand programs have at least one item before submitting. Please add at least one item to this program before submitting."
      );
      return;
    }
    if (isExclusive === true && exclusiveTerrs.length === 0) {
      setError("Must provide an exclusive territory");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      setError(null);
      dispatch(
        submitProgram(
          id,
          data,
          selectedBrands,
          exclusiveTerrs,
          isExclusive,
          primaryBrand
        )
      );
    }
  };

  const handleCancel = () => {
    dispatch(cancelProgram(id));
  };

  useEffect(() => {
    if (id !== null && !initialLoad) {
      setSelectedBrands(brand);
      setExclusiveTerrs(territories);
      setIsExclusive(isTerritoryExclusive);
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgram]);

  useEffect(() => {
    if (selectedBrands) {
      setPrimaryBrand(selectedBrands[0]);
    }
  }, [selectedBrands]);

  return (
    <>
      {copyProgModalOpen && (
        <ProgramCopyModal
          classes={classes}
          open={copyProgModalOpen}
          handleClose={() => {
            setCopyProgModalOpen(false);
          }}
          program={currentProgram}
        />
      )}
      {roleIs("super") && (
        <div tw="flex gap-4 text-sm mb-2 text-neutral-500">
          <span>Gallo ID: {externalId || "N/A"} </span>
        </div>
      )}

      <Card tw="bg-white py-4 px-6 mb-4 space-y-4">
        <div tw="flex justify-between items-center flex-wrap">
          <div tw="flex gap-x-4 items-center">
            <h2 tw="text-xl font-medium text-neutral-700">{name}</h2>
            <div tw="flex gap-1.5 items-center bg-neutral-100 rounded-full py-1.5 px-4">
              <CalendarMonth tw="text-neutral-500 text-xl" />
              <span>
                In Market{" "}
                <span tw="font-medium">
                  {orderCalendarMonth &&
                    format(
                      formatDate(orderCalendarMonth.inMarketStartDate),
                      "MMMM yyyy"
                    )}
                  {!orderCalendarMonth && startDate && endDate && (
                    <>
                      {format(formatDate(startDate), "PP")} -{" "}
                      {format(formatDate(endDate), "PP")}
                    </>
                  )}
                </span>
              </span>
            </div>

            {currentProgram.type.includes("National") && userCanEdit && (
              <ProgramEdit />
            )}
          </div>

          <div tw="flex items-center gap-x-4 text-sm text-neutral-500 font-medium tracking-wider">
            {status === "draft" ? (
              <span>Draft</span>
            ) : status === "canceled" ? (
              <span tw="text-red-900">Canceled</span>
            ) : (
              <AnaplanSyncTooltip lastSync={Boolean(anaplanProgramCodes)}>
                <span tw="cursor-pointer">
                  <FaIcon icon="sync" tw="mr-1" />
                  Submitted
                </span>
              </AnaplanSyncTooltip>
            )}
            {userCanEdit && (
              <StyledButton
                cta
                onClick={handleSubmit(handleSendUpdates)}
                loading={isUpdateLoading}
              >
                Save
              </StyledButton>
            )}
          </div>
        </div>
        {createdBy && (
          <div>
            <span>
              Created By: <span tw="font-medium">{createdBy}</span>
            </span>
          </div>
        )}
        <div tw="mt-2! flex justify-start items-center gap-4 text-neutral-600 text-sm">
          {clonedFromProgram && (
            <span>
              Cloned from{" "}
              <Link
                tw="underline font-medium"
                target="_blank"
                to={`/planning/program/${clonedFromProgram.id}`}
              >
                Program #{clonedFromProgram.id}
              </Link>
            </span>
          )}

          <span>
            ID:
            <span tw="font-medium"> {id}</span>
          </span>
          <span>
            Anaplan Code{anaplanProgramCodes?.split(",").length > 1 ? "s" : ""}:
            {anaplanProgramCodes && (
              <span tw="font-medium"> {anaplanProgramCodes}</span>
            )}
            {!anaplanProgramCodes && <span tw="opacity-75"> pending</span>}
          </span>
        </div>
        <FormRow>
          {programTypeId !== "1" && (
            <div tw="flex-[2] min-w-0">
              <AnaplanProgram
                handleSaveAnaplanProgram={handleUpdateAnaplanProgram}
              />
            </div>
          )}
          {brandAssignmentLevel === "program" && (
            <div tw="flex-grow-[2]!">
              <BrandSelector
                handleSelectedBrands={setSelectedBrands}
                selectedBrands={selectedBrands}
                handleSelectedPrimaryBrand={setPrimaryBrand}
                disabled={!userCanEdit}
              />
            </div>
          )}
          <StyledSelectInput
            label="Activation Type"
            options={orderActTypes}
            {...controlled("orderType", { required: true })}
            disabled
          />
          <ProgramTypeSelector
            classes={classes}
            type={currentProgramType}
            disabled
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="CPR ID"
            {...controlled("externalWorkfrontId")}
            disabled
          />
          <TextInput
            label="EPR ID"
            {...controlled("externalWorkfrontEprId")}
            disabled
          />
          <FormControl size="small" disabled={true}>
            <InputLabel>Trade Class</InputLabel>
            <Select
              disabled={true}
              label="Trade Class"
              options={channelOptions}
              value={channel}
            >
              {channelOptions.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormRow>
        <FormRow>
          <div tw="flex-none flex gap-4">
            <CheckboxInput
              label="Is brand funded?"
              {...controlled("isBrandFunded")}
            />
            <Typography>
              <Checkbox
                checked={isExclusive}
                onChange={() => setIsExclusive(!isExclusive)}
                name="isExclusive"
                disabled={!userCanEdit}
              />
              Is exclusive?
            </Typography>
          </div>
          {isExclusive && (
            <TerritorySelector
              tw="flex-1"
              handleTerritories={setExclusiveTerrs}
              selectedTerritories={exclusiveTerrs}
              disabled={!userCanEdit}
            />
          )}
        </FormRow>
        {error && (
          <Typography className={classes.bodyText} style={{ color: "#ff0000" }}>
            {error}
          </Typography>
        )}

        {userCanEdit && (
          <>
            <NoteBox programId={id} />

            <div tw="flex gap-2">
              {workfrontUrl && (
                <Button
                  href={workfrontUrl}
                  target="_blank"
                  startIcon={<FaIcon icon="external-link" />}
                >
                  Workfront URL
                </Button>
              )}
              {cprSubmissionUrl && (
                <Button
                  href={cprSubmissionUrl}
                  target="_blank"
                  startIcon={<FaIcon icon="external-link" />}
                >
                  CPR Submission URL
                </Button>
              )}
            </div>
            <div tw="flex justify-between">
              <div>
                {status !== "canceled" && (
                  <Button
                    variant="danger"
                    onClick={handleCancel}
                    disabled={isUpdateLoading}
                  >
                    Cancel Program
                  </Button>
                )}
              </div>
              <div tw="space-x-2">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setCopyProgModalOpen(true);
                  }}
                >
                  Copy Program
                </Button>
                {status === "draft" && (
                  <Button
                    variant="contained"
                    onClick={handleSubmit(handleSubmitProgram)}
                    disabled={isUpdateLoading}
                  >
                    Submit
                  </Button>
                )}
              </div>
              {!isUpdateLoading && updateError && (
                <Typography
                  className={classes.bodyText}
                  style={{ color: "#ff0000" }}
                >
                  {updateError}
                </Typography>
              )}
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default React.memo(ProgramDetails);
