/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { format } from "date-fns";
import { capitalize } from "lodash";

import { useVariantAllocationHistoryPaginated } from "@features/items";

import { SmartTable } from "../../../components/SmartTable";

const AllocationHistoryTable = ({ allocationId }: { allocationId: string }) => {
  const { data, ...rest } = useVariantAllocationHistoryPaginated({
    filter: { variantAllocationId: allocationId },
  });

  return (
    <SmartTable
      maxHeight={320}
      rows={data ?? []}
      {...rest}
      columns={[
        {
          id: "updatedBy",
          label: "Purchaser",
        },
        {
          id: "updatedAt",
          label: "Update At",
          render: (d) => format(new Date(d), "PPpp"),
        },
        {
          id: "adjustmentAmount",
          label: "Adjustment",
          align: "right",
          render: (val) => (val > 0 ? `+${val}` : val),
        },
        {
          id: "_qty",
          label: "Qty",
          align: "right",
          render: (_, ah) =>
            ah.adjustmentAmount < 0
              ? ah.donorAdjustedQty
              : ah.recipientAdjustedQty,
        },
        {
          id: "_source",
          label: "Source",
          render: (_, ah) => {
            if (ah.purchaseOrderId) {
              return (
                <Link to={`/purchase-orders/${ah.purchaseOrderId}`}>
                  Purchase Order <u>{ah.purchaseOrderId}</u>
                </Link>
              );
            }
            if (ah.orderNumber) {
              return (
                <>
                  <Link to={`/orders/history/single-order/${ah.orderNumber}`}>
                    Order <u>{ah.orderNumber}</u>
                  </Link>
                  <div tw="text-neutral-500 text-xs">
                    {capitalize(ah.orderStatus ?? "")}
                  </div>
                </>
              );
            }
            if (
              (ah.donorTerritoryName || ah.donorSubStateName) &&
              (ah.recipientTerritoryName || ah.recipientSubStateName)
            ) {
              // It's a transfer
              if (ah.adjustmentAmount > 0)
                return `Transfer from ${
                  ah.donorTerritoryName || ah.donorSubStateName
                }`;
              return `Transfer to ${
                ah.recipientTerritoryName || ah.recipientSubStateName
              }`;
            }
            return "Manual Adjustment";
          },
        },
      ]}
    />
  );
};

export default AllocationHistoryTable;
