/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const accoladeOptions = [
  { id: "yes", name: "Is Accolade" },
  { id: "no", name: "Is Not Accolade" },
];

const filterIsAccolade = makeRadioOptionsFilter({
  name: "isAccolade",
  title: "Accolade",
  options: accoladeOptions,
  chipTitlePrefix: "",
  popperTitleText: "Accolade Options",
});

export default filterIsAccolade;
