import { createQueryKeys } from "@lukemorales/query-key-factory";

import { RequestForQuote } from "@models/RequestForQuote";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const rfqsKeyFactory = createQueryKeys("request-for-quotes", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<RequestForQuote[]>("request-for-quotes", { params }),
  }),
});

export const usePaginatedRFQsQuery = buildPaginatedQuery(
  rfqsKeyFactory.paginated
);
