/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFilterContext } from "../filterContext";
import ControlledFilterRadioGroup from "./ControlledFilterRadioGroup";
import FilterChip from "./FilterChip";

type Options = { id: string; name: string }[];

export default function makeRadioOptionsFilter({
  name,
  options,
  useOptionsHook,
  title,
  chipTitlePrefix,
  popperTitleText,
}: {
  name: string;
  options?: Options;
  useOptionsHook?: () => Options;
  title: string;
  chipTitlePrefix?: string;
  popperTitleText?: string;
}) {
  const useOptions = () => {
    const optionsFromHook = useOptionsHook?.();
    return optionsFromHook ?? options ?? [];
  };

  const Popper = () => {
    const { control } = useFilterContext();
    const options = useOptions();
    return (
      <ControlledFilterRadioGroup
        name={name}
        options={options}
        titleText={popperTitleText ?? `${title} is`}
        control={control}
      />
    );
  };

  const Chip = (props) => {
    const { filterValues } = useFilterContext();
    const value = filterValues[name];
    const options = useOptions();
    const valueText = options.find((c) => c.id === value)?.name;

    const prefix = chipTitlePrefix ?? `${title} is`;

    return (
      <FilterChip {...props} showCloseButton={!!value}>
        {value ? (
          <>
            {prefix && <span>{prefix}&nbsp;</span>}
            <b>{valueText}</b>
          </>
        ) : (
          `Select ${title}`
        )}
      </FilterChip>
    );
  };

  const optionsFilter = {
    name,
    menuText: title,
    Popper,
    Chip,
  };

  return optionsFilter;
}
