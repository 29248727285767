/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";

import { useNoFetch } from "src/hooks/UtilityHooks";
import { setSelection } from "src/redux/slices/compliance/triggeredRulesSlice";
import { formatDateString } from "src/utility/utilityFunctions";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import {
  ComplianceItemsTable,
  usePaginatedTriggeredRulesQuery,
} from "@features/compliance";
import RuleApproveDenyNotesModal from "@features/compliance/RuleApproveDenyNotesModal";
import { Filters, useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";
import useDeepCompareEffect from "@utils/useDeepCompareEffect";

type Action = "approve" | "deny";

const ComplianceItems = () => {
  const [filters] = useFilterParams();
  const dispatch = useDispatch();
  const selection = useSelector((state: any) => state.triggeredRules.selection);
  const [notesModalOpen, setNotesModalOpen] = useState<null | Action>(null);

  const dateRange =
    filters.fromDate &&
    filters.toDate &&
    `${formatDateString(filters.fromDate)} - ${formatDateString(
      filters.toDate
    )}`;
  const params = {
    filter: {
      activityDateRange: dateRange,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
      programIds: filters.programs,
      status: filters.triggeredRuleStatus,
      type: filters.ruleType,
    },
    sort: filters.sort,
  };
  useReport("triggered-rules", ["triggered-rules", params]);
  const tableData = usePaginatedTriggeredRulesQuery(params);
  useNoFetch();

  useDeepCompareEffect(() => {
    // clear selection when params change
    dispatch(setSelection([]));
  }, [params]);

  return (
    <>
      {notesModalOpen && (
        <RuleApproveDenyNotesModal
          triggeredRules={tableData.data.filter((tr) =>
            selection.includes(tr.id)
          )}
          closeModal={() => {
            setNotesModalOpen(null);
          }}
          action={notesModalOpen}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between items-center gap-3 flex-wrap">
          <PageTitle title="Compliance Items" />
          <div tw="flex items-center gap-3">
            <RequestReportButton reportName="triggered-rules" />
            <Button
              variant="contained"
              disabled={selection.length === 0}
              onClick={() => setNotesModalOpen("approve")}
            >
              Approve
            </Button>
            <Button
              disabled={selection.length === 0}
              onClick={() => setNotesModalOpen("deny")}
              variant="outlined"
            >
              Deny
            </Button>
          </div>
        </div>
        <TableCardContainer>
          <Filters
            searchTitle="Search Items"
            alwaysShow={["brands", "dateRange"]}
            slots={[
              "dateRange",
              "ruleType",
              "triggeredRuleStatus",
              "brands",
              "itemTypes",
              "programs",
            ]}
            slotProps={{
              dateRange: {
                title: "Activity Date Range",
                chipTextPrefix: "Activity ",
              },
            }}
            defaultValues={{ sort: "-activity-date" }}
          />
          <ComplianceItemsTable {...tableData} />
        </TableCardContainer>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default ComplianceItems;
