import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { setError } from "src/redux/slices/errorSlice";

import client from "@services/api";

type AuthStatus = "unset" | "loading" | "ready" | "error";

// const TOKEN = "rta-token";

// type AuthPayload = {
//   access_token: string;
//   expires_at: number;
//   user: string;
// };

// function getTokenFromLocalStorage() {
//   const tokenString = window.localStorage.getItem(TOKEN);
//   if (!tokenString) return null;
//   const token = JSON.parse(tokenString);
//   if (token.expires_at * 1000 >= Date.now()) return null;
// }

const useAuthToken = () => {
  const dispatch = useDispatch();
  const [authStatus, setAuthStatus] = useState<AuthStatus>("unset");
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading,
  } = useAuth0();

  useEffect(() => {
    let timeout;
    async function setTokens() {
      console.log("setting tokens");
      try {
        const jwt = await getAccessTokenSilently();
        const claims = await getIdTokenClaims();

        const { data } = await axios.post(`/auth/token`, {
          auth0_token: jwt,
        });

        const authToken = `Bearer ${data.access_token}`;
        axios.defaults.headers.common["Authorization"] = authToken;
        client.headers["Authorization"] = authToken;

        setAuthStatus("ready");

        if (claims?.exp) {
          const timeToExpire = claims.exp * 1000 - Date.now();
          timeout = setTimeout(() => {
            window.location.assign("/logout?expired=true");
          }, timeToExpire);
        }
      } catch (error: any) {
        setAuthStatus("error");
        dispatch(setError({ error: error.toString(), source: "useAuthToken" }));
        console.error(error);
      }
    }
    if (isLoading) {
      setAuthStatus("loading");
    } else if (isAuthenticated) {
      setAuthStatus("loading");
      setTokens();
    } else {
      delete axios.defaults.headers.common["Authorization"];
      delete client.headers["Authorization"];
      setAuthStatus("unset");
    }
    return () => timeout && clearTimeout(timeout);
  }, [
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    dispatch,
  ]);

  return authStatus;
};

export default useAuthToken;
