/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Button, Card, Divider } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import { ResourceError } from "@features/errors";
import { Type } from "@features/ui";
import { FaIcon } from "@features/ui";

const Styles = () => {
  return (
    <Contained tw="space-y-6">
      <div tw="px-6 py-4">
        <Type.H1>RTA Style Guide</Type.H1>
        <Type.P>This is a page for testing out styles.</Type.P>
      </div>
      <Card>
        <div tw="p-6 space-y-3">
          <Type.H3>Typography</Type.H3>
          <Type.H1>H1 - Page Titles</Type.H1>
          <Type.H2>H2 - Secondary Titles</Type.H2>
          <Type.H3>H3 - Tertiary Titles</Type.H3>
          <Type.H4>H4 - Section Headings</Type.H4>
          {/* <Type.H1 tw="text-base text-neutral-600 mb-2">H4</Type.H1>
        <Type.H1 tw="text-sm text-neutral-600 mb-2">H5</Type.H1>
        <Type.H1 tw="text-xs text-neutral-600 mb-2">H6</Type.H1> */}
          <Type.P>
            P - Paragraphs limited in width to 65ch for readability. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Nullam tincidunt, justo
            sed efficitur ultricies, nunc eros varius elit, sed accumsan mi nunc
            vel urna.
          </Type.P>
          <Type.Small>
            Small - <i>Additional information</i>
          </Type.Small>
        </div>
        <Divider />
        <div tw="p-6 space-y-3">
          <Type.H3>Buttons</Type.H3>
          <div tw="flex flex-wrap gap-4 items-center">
            <Button variant="contained">Primary Button</Button>
            <Button variant="contained" color="secondary">
              Secondary Button
            </Button>
            <Button variant="outlined" color="secondary">
              Outlined Button
            </Button>
            <Button variant="danger">Danger Button</Button>
            <Button variant="text" size="small">
              Text Button
            </Button>

            <Button variant="contained" size="small">
              Small CTA
            </Button>
            <Button variant="outlined" size="small">
              Small Outlined
            </Button>
          </div>
          <div tw="flex gap-4 items-center">
            <Button
              variant="contained"
              endIcon={<FaIcon icon="arrow-right" tw="text-sm" />}
            >
              CTA With Icon
            </Button>
          </div>
        </div>
      </Card>
      <ResourceError error={{ message: "This is an error message" }} />
    </Contained>
  );
};

export default Styles;
