/** @jsxImportSource @emotion/react */
import "twin.macro";

import makeRadioOptionsFilter from "../components/makeRadioOptionsFilter";

const orderStatusOptions = [
  { id: "draft", name: "Draft" },
  { id: "submitted", name: "Submitted" },
  { id: "approved", name: "Approved" },
  { id: "order-canceled", name: "Order Item Canceled" },
  { id: "compliance-canceled", name: "Compliance Canceled" },
  { id: "destroyed", name: "Destroyed" },
  { id: "not-draft", name: "All Orders" },
];

const filterOrderStatus = makeRadioOptionsFilter({
  name: "orderStatus",
  title: "Order Status",
  options: orderStatusOptions,
});

export default filterOrderStatus;
