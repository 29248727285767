/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";

import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";

import { utcDate } from "../../utility/utilityFunctions";

export const DefaultTextField = React.forwardRef<any, TextFieldProps>(
  (props, ref) => (
    <TextField
      ref={ref}
      css={{
        ".MuiOutlinedInput-root": tw`bg-white`,
      }}
      color="secondary"
      type="text"
      size="small"
      variant="outlined"
      {...props}
    />
  )
);

type CustomDatePickerProps = DatePickerProps<Date> & {
  onChange?: (date: Date | null) => void;
};

export const DatePicker = React.forwardRef<
  HTMLDivElement,
  CustomDatePickerProps
>(({ onChange, value, ...props }, ref) => {
  const [date, setDate] = useState<Date | null>(value ? utcDate(value) : null);

  useEffect(() => {
    if (value) setDate(utcDate(value));
  }, [value]);

  return (
    <MuiDatePicker
      ref={ref}
      minDate={utcDate("2016-01-01")}
      maxDate={utcDate("2031-01-01")}
      slotProps={
        {
          ...props.slotProps,
          field: {
            size: "small",
            ...props.slotProps?.field,
            onBlur: () => {
              onChange?.(date);
            },
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                onChange?.(date);
              }
            },
          },
        } as any
      }
      value={date}
      onAccept={onChange}
      onChange={(value, context) => {
        if (!context.validationError) {
          setDate(value);
        }
      }}
      {...props}
    />
  );
});

type SelectFieldProps = {
  options: {
    label?: string;
    value?: string | number;
    id?: string | number;
    name?: string;
    header?: boolean;
  }[];
} & React.ComponentProps<typeof Select>;
export const SelectField = ({
  label,
  options,
  className,
  ...props
}: SelectFieldProps) => (
  <FormControl className={className} size="small" fullWidth={props.fullWidth}>
    <InputLabel>{label}</InputLabel>
    <Select label={label} {...props}>
      {options.map((o, i) =>
        o.header ? (
          <ListSubheader key={i}>{o.label}</ListSubheader>
        ) : (
          <MenuItem key={`${o.value ?? o.id}-${i}`} value={o.value ?? o.id}>
            {o.label ?? o.name}
          </MenuItem>
        )
      )}
    </Select>
  </FormControl>
);
