/** @jsxImportSource @emotion/react */
import "twin.macro";

import { SmartTable } from "src/components/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import {
  displayToInventoryAllocation,
  getExtendedCost,
} from "@features/orders";

import TotalCost from "../orderSet/TotalCost";
import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";

const OrderSetOrdersOverview = () => {
  const { orders } = useCurrentOrderSet();

  return (
    <SmartTable
      rows={orders ?? []}
      columns={[
        { id: "id", label: "Order #" },
        {
          id: "address.name",
          label: "Address",
          render: (value, order) =>
            order.type === "to-inventory"
              ? `Restock: ${displayToInventoryAllocation(order)}`
              : value,
        },
        {
          id: "address.abn",
          label: "ABN",
          render: (v) => v || "---",
        },
        {
          id: "totalEstimatedCost",
          label: "Est. Cost",
          align: "right",
          render: (val) => <TotalCost>{val}</TotalCost>,
        },
        {
          id: "totalEstimatedShippingCost",
          label: "Est. Freight",
          align: "right",
          render: formatMoneyString,
        },
        {
          id: "totalEstimatedTax",
          label: "Est. Tax",
          align: "right",
          render: formatMoneyString,
        },
        {
          id: "_extendedTotal",
          label: "Total",
          align: "right",
          render: (v, o) => formatMoneyString(getExtendedCost(o, o.type)),
        },
      ]}
    />
  );
};

export default OrderSetOrdersOverview;
