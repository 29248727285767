import { PropsWithChildren, createContext, useContext } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

type ContextProps = UseFormReturn<FieldValues, any> & {
  filterValues: Record<string, any>;
  setFilterValues: (x: Record<string, any>) => void;
  /* Sets the form values as filter params */
  submitFilters: () => void;
};

const FilterContext = createContext<ContextProps>({} as any);

export const FilterContextWrapper: React.FC<
  PropsWithChildren<ContextProps>
> = ({ children, ...props }) => {
  return (
    <FilterContext.Provider value={props}>{children}</FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);
