/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useParams } from "react-router-dom";

import { Button, Card, CircularProgress, Skeleton } from "@mui/material";

import { format } from "date-fns";
import { PLANNING_TOOL_ROLES } from "src/constants/permissions";
import { utcDate } from "src/utility/utilityFunctions";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { ItemViewButtons } from "@features/items";
import {
  getOrderableEndDateField,
  useActiveOrderWindows,
} from "@features/orderCalendarMonths";
import {
  PreOrderItemPrograms,
  getProgramBrandImageSrc,
  useProgramQuery,
} from "@features/programs";
import ProgramOrderSetButton from "@features/programs/components/ProgramOrderSetButton";
import { FaIcon, LabelValuePair, PageTitle } from "@features/ui";
import { CldImage } from "@services/cloudinary";
import useRoleIs from "@utils/useRoleIs";

import ErrorPage from "./ErrorPage";

const PreOrderProgram = () => {
  const { programId, orderWindowId } = useParams();
  const roleIs = useRoleIs();

  const ocms = useActiveOrderWindows();
  const isOrderable = ocms.some((ocm) => ocm.id === orderWindowId);

  const { data: program, error } = useProgramQuery(programId);
  const orderWindow =
    program &&
    program.orderCalendarMonths.find((ocm) => ocm.id === orderWindowId);

  if (error) return <ErrorPage error={error} />;
  return (
    <FixedHeightScrollLastChild>
      <div tw="flex justify-between items-center gap-3">
        <PageTitle
          tw="my-0"
          fallbackUrl="/programs/national"
          title={program ? program.name : ""}
        >
          {!program && <Skeleton variant="text" width={200} height={48} />}
        </PageTitle>
        <div tw="flex gap-4 items-center">
          <ItemViewButtons />
          {isOrderable && (
            <ProgramOrderSetButton
              programId={programId!}
              orderWindowId={orderWindowId!}
            />
          )}
        </div>
      </div>
      {!program && <CircularProgress />}
      <div tw="flex flex-col gap-6 lg:flex-row lg:items-start overflow-hidden">
        {program && (
          <Card tw="flex min-w-0 gap-6 p-6 lg:flex-col lg:w-1/4">
            <CldImage
              tw="w-28 h-28 flex-none rounded-xl object-contain -m-1"
              src={getProgramBrandImageSrc(program)}
              alt={""}
            />
            <div tw="w-full max-w-screen-sm grid grid-cols-2 gap-x-4 lg:gap-x-6 lg:grid-cols-1 lg:gap-y-4">
              <LabelValuePair
                label="Brands"
                value={
                  program.brands.map((b) => b.name).join(", ") || "Multi-Brand"
                }
              />
              {orderWindow && (
                <>
                  <LabelValuePair
                    label="Order Window"
                    value={orderWindow.orderWindowName}
                  />
                  <LabelValuePair
                    label="Order Window Open"
                    value={format(
                      utcDate(orderWindow.orderWindowOpenDate),
                      "PP"
                    )}
                  />
                  <LabelValuePair
                    label="Order Window Close"
                    value={format(
                      utcDate(
                        orderWindow[getOrderableEndDateField(roleIs.role)]
                      ),
                      "PP"
                    )}
                  />
                  <LabelValuePair
                    label="In Market"
                    value={format(utcDate(orderWindow.inMarketStartDate), "PP")}
                  />
                </>
              )}
              {!orderWindow && (
                <>
                  <LabelValuePair
                    label="Start Date"
                    value={format(utcDate(program.startDate), "PP")}
                  />
                  <LabelValuePair
                    label="End Date"
                    value={format(utcDate(program.endDate), "PP")}
                  />
                </>
              )}
              {roleIs(PLANNING_TOOL_ROLES as any) && (
                <Button
                  tw="place-content-start"
                  variant="text"
                  startIcon={<FaIcon icon="external-link" />}
                  component={Link}
                  to={`/planning/programs/${programId}`}
                  target="_blank"
                >
                  Edit in Planning Tool
                </Button>
              )}
            </div>
          </Card>
        )}
        <PreOrderItemPrograms
          programId={programId!}
          orderWindowId={orderWindowId!}
          program={program}
        />
      </div>
    </FixedHeightScrollLastChild>
  );
};

export default PreOrderProgram;
