/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Card } from "@mui/material";

import { useNoFetch } from "src/hooks/UtilityHooks";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { QuotesTable, usePaginatedQuotesQuery } from "@features/quotes";
import { PageTitle } from "@features/ui";

const Quotes = () => {
  const [filters] = useFilterParams();

  const params = {
    filter: {
      searchTerm: filters.q,
      status: filters.quoteStatus,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
    },
  };
  const tableProps = usePaginatedQuotesQuery(params);

  useNoFetch();

  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center justify-between flex-wrap gap-3">
        <PageTitle title="Quotes" />
      </div>
      <Filters
        slots={["quoteStatus", "brands", "itemTypes"]}
        alwaysShow={["quoteStatus", "brands"]}
      />
      <Card tw="overflow-hidden">
        <QuotesTable {...tableProps} />
      </Card>
    </FixedHeightScrollLastChild>
  );
};

export default Quotes;
