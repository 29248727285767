import { useDispatch } from "react-redux";

import { useReport } from "@features/reports";
import { requestReport } from "@features/reports/globalReportSlice";
import { Button } from "@features/ui";
import { FaIcon } from "@features/ui";

import useOrderSetId from "../orderSet/data/useOrderSetId";

const OrderSetWrapupButton = () => {
  const dispatch = useDispatch();
  const orderSetId = useOrderSetId();
  const reportParams = {
    filter: { orderSetId },
  };
  useReport("order-set-wrapup", ["orders", reportParams]);

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<FaIcon icon="arrow-down-to-line" />}
      onClick={() => dispatch(requestReport("order-set-wrapup"))}
    >
      Wrap Up
    </Button>
  );
};

export default OrderSetWrapupButton;
