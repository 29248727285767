/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { Button, Card } from "@mui/material";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { ItemCatalogView, usePaginatedItemsQuery } from "@features/items";
import { PageTitle, Type } from "@features/ui";

const FavoriteItems = () => {
  const params = {
    filter: {
      favorites: true,
      isArchived: false,
    },
  };

  const { data, ...tableProps } = usePaginatedItemsQuery(params);

  return (
    <FixedHeightScrollLastChild tw="w-full! px-0!">
      <div tw="flex justify-between items-center">
        <PageTitle title="Favorite Items" />
        <Button variant="text" component={Link} to="/items">
          Add Items
        </Button>
      </div>
      <Type.P>
        Viewing all active favorited items accross all channels and territories.
      </Type.P>
      <Card>
        <ItemCatalogView rows={data} {...tableProps} />
      </Card>
    </FixedHeightScrollLastChild>
  );
};

export default FavoriteItems;
