/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { LabelHTMLAttributes, ReactNode } from "react";

import { Radio } from "@mui/material";

import {
  ControlledRadioGroup,
  ControlledRadioGroupProps,
} from "@components/Forms/ControlledInputs";

type ControlledFilterRadioGroupProps = {
  options: ({
    id: string;
    name: ReactNode;
  } & LabelHTMLAttributes<HTMLLabelElement>)[];
  titleText: string;
} & ControlledRadioGroupProps;

const ControlledFilterRadioGroup = ({
  options,
  titleText,
  ...radioGroupProps
}: ControlledFilterRadioGroupProps) => {
  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">{titleText}</h4>
      <ControlledRadioGroup tw="w-full" {...radioGroupProps}>
        {options.map(({ id, name, css, ...props }) => (
          <label
            key={id}
            css={[
              tw`flex items-center px-3 text-sm border-t cursor-pointer border-neutral-200 hover:bg-neutral-100`,
              css,
            ]}
            {...props}
          >
            <Radio size="small" value={id} edge="start" />
            {name}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

export default ControlledFilterRadioGroup;
