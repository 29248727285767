/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import _ from "lodash";
import { SmartTable } from "src/components/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { Type } from "@features/ui";
import { Budget } from "@models/Budget";

import { getExtendedCost } from "../helpers";
import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";

const money = {
  align: "right" as "right",
  render: formatMoneyString,
};

const OrderSetBudgetImpact = () => {
  const { orders, orderSet } = useCurrentOrderSet();

  const orderType = orders?.[0]?.type;

  // When the order-set is any of these statuses,
  // the order-set total gets substracted to the budgey available amount in the backend
  const budgetAppliesOrderSetTotal = [
    "submitted",
    "approved",
    "partial-approval-error",
  ].includes(orderSet.status);

  const budgets: Budget[] = _(orders ?? [])
    .flatMap("orderVariants")
    .flatMap("budgets")
    .uniqBy("id")
    .value();

  const impact = (orders ?? []).reduce<Record<string, number>>((acc, order) => {
    order.orderVariants.forEach((ov) => {
      ov.budgets.forEach((b) => {
        acc[b.id] =
          (acc[b.id] ?? 0) + getExtendedCost(ov, orderType) / ov.budgets.length;
      });
    });
    return acc;
  }, {});

  // On pre-orders and on submitted order-sets, the api substracts the budget impact from the available amount.
  // to standardize the data, we add the impact back to the available amount

  const budgetsWithImpact = budgets.map((b) =>
    orderType === "pre-order" || budgetAppliesOrderSetTotal
      ? {
          ...b,
          availableAmount: +b.availableAmount + (impact[b.id] ?? 0),
          orderTotal: impact[b.id],
          availableAfterOrder: +b.availableAmount,
        }
      : {
          ...b,
          orderTotal: impact[b.id],
          availableAfterOrder: +b.availableAmount - (impact[b.id] ?? 0),
        }
  );

  return (
    <SmartTable
      rows={budgetsWithImpact}
      columns={[
        { id: "externalId", label: "Budget ID" },
        {
          id: "availableAmount",
          label: "Available",
          ...money,
        },
        {
          id: "orderTotal",
          label: "Est. Order Total",
          ...money,
        },
        {
          id: "availableAfterOrder",
          label: "Remaining After Order",
          ...money,
          render: (v) => (
            <span css={[v < 0 && tw`text-red-700`]}>
              {formatMoneyString(v)}
            </span>
          ),
        },
      ]}
      noResultsText="No Budgets on this Order Set"
      error={null}
    />
  );
};

export default OrderSetBudgetImpact;

export const OrderSetBudgetImpactAccordion = () => {
  const { orders } = useCurrentOrderSet();
  const hasBudgets = orders.some((o) =>
    o.orderVariants.some((ov) => ov.budgets.length > 0)
  );
  if (!hasBudgets) return null;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Type.H2>Budget Impact</Type.H2>
      </AccordionSummary>
      <AccordionDetails tw="p-0">
        <OrderSetBudgetImpact />
      </AccordionDetails>
    </Accordion>
  );
};
