/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { Close } from "@mui/icons-material";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import _ from "lodash";
import { formatDateString } from "src/utility/utilityFunctions";

import { SmartTable } from "@components/SmartTable";

import { useUpcomingOcmsQuery } from "./data";

const OrderingCalendar = () => {
  const { data: ocms = [], ...tableProps } = useUpcomingOcmsQuery();

  return (
    <Card>
      <SmartTable
        columns={[
          {
            id: "orderWindowName",
            label: "Name",
            render: (name, ocm) => (
              <div tw="font-medium">
                {name}
                <div
                  css={[
                    tw`text-xs font-normal text-neutral-500`,
                    ocm.orderWindowType === "secondary" && tw`text-blue-500`,
                  ]}
                >
                  {_.capitalize(ocm.orderWindowType)}
                </div>
              </div>
            ),
          },
          {
            id: "orderWindowOpenDate",
            label: "Order Window Open",
            render: formatDateString,
          },
          {
            id: "orderWindowCloseDate",
            label: "Order Window Close",
            render: formatDateString,
          },
          {
            id: "purchasingCloseDate",
            label: "Leadership Close",
            render: formatDateString,
          },
          {
            id: "inMarketStartDate",
            label: "In Market Start Date",
            render: formatDateString,
          },
        ]}
        rows={ocms}
        {...tableProps}
      />
    </Card>
  );
};

const OrderingCalendarModal = ({ onClose }) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle tw="flex justify-between items-start">
        <div>
          Order Calendar Month Schedule
          <p tw="text-neutral-500 text-sm font-normal">
            Showing all Order Calendar Months with a future In Market Start Date
          </p>
        </div>
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="overflow-hidden flex">
        <OrderingCalendar />
      </DialogContent>
    </Dialog>
  );
};

export default OrderingCalendarModal;
