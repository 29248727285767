import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/InputHooks";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OverrideNoteModal = ({ open, handleSubmit, handleClose }) => {
  const classes = useStyles();

  const { value, bind } = useInput("");

  const handleOverride = () => {
    handleSubmit(value);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent>
        <IconButton
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CancelIcon fontSize="large" color="secondary" />
        </IconButton>
        <div className={classes.flexColumnModal}>
          <br />
          <br />
          <Typography className={classes.bodyText}>
            Please provide a reason for this override:
          </Typography>
          <br />
          <TextField
            multiline
            minRows={3}
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth
            type="text"
            label="Override Reason"
            {...bind}
          />
          <br />
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleOverride()}
            disabled={value.length === 0}
          >
            OVERRIDE
          </Button>
          <br />
        </div>
      </DialogContent>
    </Dialog>
  );
};

OverrideNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OverrideNoteModal;
