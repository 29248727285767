/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "@mui/material";

import _ from "lodash";
import { setSelection } from "src/redux/slices/compliance/triggeredRulesSlice";

import {
  PaginatedResourceProps,
  SmartTable,
  showFirst,
} from "@components/SmartTable";
import { ItemPreviewImageButton } from "@features/items";
import displayDate from "@features/reports/utils/displayDate";
import { TriggeredRule } from "@models/TriggeredRule";

import { itemApprovalTypes, triggeredRuleStatusMap } from "../constants";

type ComplianceItemsTableProps = PaginatedResourceProps & {
  data: TriggeredRule[];
};

const ruleTypesWithEmail = [
  "coupon-prior-approval",
  "prior-approval",
  "internal-approval",
];

export const triggeredRulesTableSchema = [
  {
    id: "itemNumber",
    label: "Sequence #",
    sort: "item-number",
    render: (v) => <span tw="font-medium tracking-wide">{v}</span>,
    renderText: (v) => v,
  },
  { id: "mostRecentProgramName", label: "Program" },
  {
    id: "brandNames",
    label: "Brand",
    render: showFirst,
    renderText: (v) => v.join(", "),
  },
  { id: "itemTypeDescription", label: "Item Type" },
  { id: "state.code", label: "State" },
  { id: "rule.type", label: "Rule Type", render: _.startCase },
  { id: "rule.description", label: "Rule Description" },
  {
    id: "status",
    label: "Status",
    render: (status) => triggeredRuleStatusMap[status],
  },
  {
    id: "_email",
    label: "Email",
    render: (_v, tr) =>
      tr.status === "prior-approval-pending"
        ? ruleTypesWithEmail.includes(tr.rule.type) &&
          (tr.approvalEmailSent && tr.emailSentAt
            ? `Email sent on ${displayDate(tr.emailSentAt)}`
            : tr.approvalEmailSent
            ? "Email Sent"
            : "Email not sent")
        : null,
  },
  {
    id: "actionDate",
    label: "Action Date",
    sort: "approved-at",
    render: displayDate,
  },
];

const ComplianceItemsTable = ({
  data,
  ...props
}: ComplianceItemsTableProps) => {
  const dispatch = useDispatch();
  const { selection } = useSelector((state: any) => state.triggeredRules);

  const isRowDisabled = (row: TriggeredRule) =>
    !itemApprovalTypes.includes(row.rule.type);

  const toggleSelection = (id: string) =>
    dispatch(setSelection(_.xor(selection, [id])));
  const selectableRows = data.filter((row) => !isRowDisabled(row));

  const onClickAll = () => {
    if (selection.length === selectableRows.length) {
      dispatch(setSelection([]));
    } else {
      dispatch(setSelection(selectableRows.map((row) => row.id)));
    }
  };

  return (
    <SmartTable
      rows={data}
      {...props}
      columns={[
        {
          id: "checkbox",
          label: (
            <Checkbox
              tw="-my-4"
              edge="start"
              indeterminate={
                selection.length > 0 && selection.length < selectableRows.length
              }
              checked={
                selectableRows.length > 0 &&
                selection.length === selectableRows.length
              }
              onChange={onClickAll}
              inputProps={{ "aria-label": "select all items" }}
              disabled={selectableRows.length === 0}
            />
          ),
          render: (_, row) => (
            <Checkbox
              tw="-my-3"
              edge="start"
              checked={selection.includes(row.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => toggleSelection(row.id)}
              disabled={isRowDisabled(row)}
            />
          ),
        },
        {
          id: "_item",
          label: "Item",
          render: (_v, rule) => (
            <ItemPreviewImageButton
              partialItem={{
                id: rule.itemId,
                images: rule.triggeredItemImages,
              }}
            />
          ),
        },
        ...triggeredRulesTableSchema,
      ]}
    />
  );
};

export default ComplianceItemsTable;
