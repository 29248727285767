/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";
import { Controller } from "react-hook-form";

import { Close, SearchRounded } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";

import { SelectInput } from "@components/Forms/ControlledInputs";

import { useFilterContext } from "../filterContext";
import { Bordered } from "./StyledComponents";

const searchByOptionTexts = {
  id: "Order #",
  itemNumber: "Item #",
  anaplanCode: "Anaplan Code",
  budgetId: "Budget ID",
  programId: "Program ID",
  searchTerm: "Search",
  purchaseOrderId: "PO #",
  invoiceNumber: "Invoice #",
  externalSapId: "SAP PO #",
  supplierReference: "Supplier Ref #",
  programIdOrAnaplanCode: "Program #",
  programName: "Program Name",
  workfrontId: "CPR/EPR ID",
  couponBarcodeId: "Coupon Barcode ID",
};

const Search = ({ searchTitle = "Search", submit, searchBy }) => {
  const { control, setFocus } = useFilterContext();
  const searchByOptions = (searchBy ?? []).map((option) => ({
    id: option,
    name: searchByOptionTexts[option] ?? option,
  }));
  const hasOptions = searchByOptions.length > 1;
  const onSelectOptionsChange = () => {
    setTimeout(() => setFocus("q"), 10);
    submit();
  };

  return (
    <Bordered tw="relative focus-within:border-primary-500 focus-within:bg-white">
      {hasOptions && (
        <SelectInput
          control={control}
          name={"searchBy"}
          options={searchByOptions}
          label=""
          css={{
            "&": tw`h-full rounded-none rounded-l-full appearance-none bg-neutral-200`,
            ".MuiOutlinedInput-root fieldset": tw`border-0 border-r `,
            ".MuiInputBase-root": tw`text-sm text-neutral-600`,
          }}
          onChange={onSelectOptionsChange}
        />
      )}
      <Controller
        control={control}
        name={"q"}
        render={({ field: { ref, ...field } }) => (
          <>
            <InputBase
              autoFocus
              css={[
                tw`max-w-full py-1 pl-3 pr-8 text-sm`,
                hasOptions ? tw`w-40` : tw`w-56`,
              ]}
              placeholder={searchTitle}
              {...field}
              inputRef={ref}
              value={field.value ?? ""}
              inputProps={{
                sx: { "&::placeholder": tw`opacity-100 text-neutral-500` },
              }}
              onBlur={() => submit()}
            />
            <IconButton
              type="submit"
              size="small"
              tw="absolute right-0"
              onClick={() => field.onChange(field.value)}
            >
              <SearchRounded tw="text-xl" />
            </IconButton>
            {field.value && (
              <IconButton
                type="submit"
                tw="absolute right-8 opacity-50 hover:opacity-100"
                onClick={() => {
                  field.onChange("");
                  submit();
                }}
              >
                <Close tw="text-base" />
              </IconButton>
            )}
          </>
        )}
      />
    </Bordered>
  );
};

export default Search;
