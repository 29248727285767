import { Button } from "@features/ui";

import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";

const SetIsWorkInProgressButton = ({
  toggleWorkInProgress,
  isUpdateLoading,
}) => {
  const { orderSet } = useCurrentOrderSet();
  return (
    <Button
      variant="contained"
      color={orderSet.isWorkComplete ? "secondary" : "primary"}
      fullWidth
      onClick={toggleWorkInProgress}
      loading={isUpdateLoading}
    >
      {orderSet.isWorkComplete ? "Mark In-Progress" : "Mark as Complete"}
    </Button>
  );
};

export default SetIsWorkInProgressButton;
