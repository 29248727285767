/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import GetAppIcon from "@mui/icons-material/GetApp";
import { FormControlLabel, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { useTerritories } from "@features/territories";
import { PageTitle } from "@features/ui";
import { CSVLink } from "@utils/csv";

import TerritoryModal from "../components/Settings/TerritoryModal";
import TerritoryTable from "../components/Settings/TerritoryTable";

const csvHeaders = [
  { label: "Region / Key Acct. Code", key: "externalCode" },
  { label: "Anaplan ID", key: "externalAnaplanId" },
  { label: "External Billing ID", key: "externalBillingId" },
  { label: "Name", key: "name" },
  { label: "Type", key: "type" },
  { label: "States", key: "states" },
  { label: "EXEMPT", key: "exemptFromBudgeting" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminTerritories = () => {
  const classes = useStyles();

  const [modal, handleModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [currentTerritoryId, setCurrentTerritoryId] = useState(null);
  const [showAwaitingCode, setShowAwaitingCode] = useState(false);

  const {
    data: territoryList,
    refetch,
    ...tableProps
  } = useTerritories({
    ...(showAwaitingCode && { isAwaitingCode: true }),
  });

  const handleModalClose = () => {
    handleModal(false);
    setCurrentTerritoryId(null);
    refetch();
  };

  const handleModalOpen = (id, type) => {
    setCurrentTerritoryId(id);
    setModalType(type);
    handleModal(true);
  };

  return (
    <FixedHeightScrollLastChild>
      {modal && (
        <TerritoryModal
          open={modal}
          handleClose={handleModalClose}
          type={modalType}
          id={currentTerritoryId}
          territoryList={territoryList ?? []}
        />
      )}
      <div className={classes.titleBar}>
        <PageTitle title="Edit Territories" />

        <div tw="flex flex-row items-center">
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            tw="w-48 ml-1"
            control={
              <Switch
                checked={showAwaitingCode}
                onChange={() => {
                  setShowAwaitingCode(!showAwaitingCode);
                }}
                name="active-switch"
              />
            }
            label={showAwaitingCode === true ? "Awaiting Code" : "All"}
          />
        </div>
        <div className={classes.innerConfigDiv}>
          {territoryList && territoryList.length > 0 && (
            <CSVLink
              data={territoryList.map((territory) => ({
                ...territory,
                states: territory.states.map((state) => state.code).join(", "),
                exemptFromBudgeting: territory.exemptFromBudgeting
                  ? "Yes"
                  : "No",
              }))}
              headers={csvHeaders}
              filename="rta_territories.csv"
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                startIcon={<GetAppIcon />}
              >
                EXPORT TERRITORY REPORT
              </Button>
            </CSVLink>
          )}
          <Button
            className={classes.largeButton}
            variant="contained"
            onClick={() => handleModalOpen(null, "new")}
          >
            NEW TERRITORY
          </Button>
        </div>
      </div>
      <TerritoryTable
        handleTerritoryClick={(terr) => handleModalOpen(terr.id, "edit")}
        territories={territoryList ?? []}
        {...tableProps}
      />
    </FixedHeightScrollLastChild>
  );
};

export default AdminTerritories;
