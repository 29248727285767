/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Chip, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { subMonths } from "date-fns";
import format from "date-fns/format";
import ReduxReportGenerator from "src/components/Reporting/ReduxReportGenerator";

import { DatePicker } from "../components/Forms/DefaultInputs";
import {
  DateRangeSelector,
  OrderMonthYearSelector,
  ReportOrderTypeSelector,
} from "../components/Reporting/ReportSelectors";
import BUAutoComplete from "../components/Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../components/Utility/AutoCompleteFields/BrandAutoComplete";
import { updateSingleFilter } from "../redux/slices/filterSlice";
import { getOrderDetailReport } from "../redux/slices/reports/reportSlice";
import { separateByComma, upCase } from "../utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportOrderDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [orderType, setOrderType] = useState("all");
  const [brands, setBrands] = useState([]);
  const [bus, setBus] = useState([]);
  const [inMarketMonths, setInMarketMonths] = useState([]);
  const [dateRange, setDateRange] = useState("actual");
  const [fromDate, setFromDate] = useState(
    format(subMonths(new Date(), 3), "MM/dd/yyyy")
  );
  const [orderVariantIdsOnly, setOrderVariantIdsOnly] = useState(false);
  const [orderVariantIds, setOrderVariantIds] = useState("");
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));
  const [reset, setReset] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);

  const { bu: currentBuFilter, brand: currentBrandFilter } = useSelector(
    (state) => state.filters
  );

  const handleAddInMarketMonth = (month) => {
    let currentMonth = inMarketMonths.find(
      (m) => m.month === month.month && m.year === month.year
    );
    if (!currentMonth) {
      setInMarketMonths(inMarketMonths.concat(month));
    }
  };

  const handleBrand = (value, _type, _filter) => {
    setBrands(value);
    dispatch(updateSingleFilter({ filter: "brand", value: value }));
  };

  const handleBu = (value, _type, _filter) => {
    setBus(value);
    dispatch(updateSingleFilter({ filter: "bu", value: value }));
  };

  const handleChipClick = (type, value) => {
    if (type === "inMarketMonth") {
      setInMarketMonths(
        inMarketMonths.filter(
          (m) => `${m.month}/${m.year}` !== `${value.month}/${value.year}`
        )
      );
    }
    if (type === "orderType") {
      setOrderType("all");
    }
    if (type === "brand") {
      let currentBrands = currentBrandFilter.filter((b) => b.id !== value);
      setBrands(currentBrands);
      dispatch(updateSingleFilter({ filter: "brand", value: currentBrands }));
    }
    if (type === "bu") {
      let currentBus = currentBuFilter.filter((b) => b.id !== value);
      setBus(currentBus);
      dispatch(updateSingleFilter({ filter: "bu", value: currentBus }));
    }
  };

  const handleReset = () => {
    setInMarketMonths([]);
    setBrands([]);
    setBus([]);
    setFromDate(format(new Date("02/01/2021"), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
    setOrderType("all");
    setReset(true);
  };

  const handleGetReport = () => {
    const dateRangeString = `${fromDate} - ${toDate}`;

    const filterObject = {
      filter: {
        ...(orderType !== "all" && { orderType: orderType }),
        ...(brands.length > 0 && { brandIds: separateByComma(brands, "id") }),
        ...(bus.length > 0 && {
          businessUnitNames: separateByComma(bus, "name"),
        }),
        ...(inMarketMonths.length > 0 && {
          inMarketMonths: separateByComma(
            inMarketMonths.map(
              (m) => `${String(m.month).padStart(2, "0")}/${m.year}`
            )
          ),
        }),
        ...(dateRange === "actual" && { actualShipDateRange: dateRangeString }),
        ...(dateRange === "estimated" && {
          estimatedShipDateRange: dateRangeString,
        }),
        ...(dateRange === "order" && { orderDateRange: dateRangeString }),
      },
    };

    const orderVariantIdsFilter = {
      orderVariantIds: orderVariantIds.split(/\D+/).filter((o) => o !== ""),
    };

    dispatch(
      getOrderDetailReport(
        orderVariantIdsOnly ? orderVariantIdsFilter : filterObject
      )
    );
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle={"Order Detail Report"}
        fileName={"order_detail.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
        reportType={"order-detail"}
      >
        <>
          <p tw="space-x-3">
            <span>Filter by </span>
            <button
              css={
                orderVariantIdsOnly
                  ? tw`text-neutral-500 hover:text-neutral-800`
                  : tw`underline text-neutral-800`
              }
              onClick={() => setOrderVariantIdsOnly(false)}
            >
              Regular Filters
            </button>{" "}
            <button
              css={
                !orderVariantIdsOnly
                  ? tw`text-neutral-500 hover:text-neutral-800`
                  : tw`underline text-neutral-800`
              }
              onClick={() => setOrderVariantIdsOnly(true)}
            >
              Order Variant Ids
            </button>
          </p>
          <br />
          {!orderVariantIdsOnly && (
            <>
              <div
                className={classes.searchComponents}
                style={{ marginBottom: "15px" }}
              >
                <DateRangeSelector
                  currentDateRange={dateRange}
                  setDateRange={setDateRange}
                  classes={classes}
                />
              </div>
              <div
                className={classes.searchComponents}
                style={{ marginBottom: 10 }}
              >
                <ReportOrderTypeSelector
                  currentOrderType={orderType}
                  setCurrentOrderType={setOrderType}
                  classes={classes}
                />
                <BrandAutoComplete
                  classes={classes}
                  handleChange={handleBrand}
                  reset={reset}
                  setReset={setReset}
                  filterType={"brand"}
                />
                <BUAutoComplete
                  classes={classes}
                  handleChange={handleBu}
                  reset={reset}
                  setReset={setReset}
                  filterType={"bu"}
                />
              </div>
              <div className={classes.searchComponents}>
                <OrderMonthYearSelector
                  handleAddOrderMonthYear={handleAddInMarketMonth}
                  label="In Market Month"
                  classes={classes}
                />

                <DatePicker
                  fullWidth
                  color="secondary"
                  className={classes.queryField}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="fromDate"
                  label={
                    dateRange === "order" ? "Order from Date" : "Ship from Date"
                  }
                  value={fromDate}
                  onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
                  disabled={isLoading}
                />

                <DatePicker
                  fullWidth
                  color="secondary"
                  className={classes.queryField}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="toDate"
                  label={
                    dateRange === "order" ? "Order to Date" : "Ship to Date"
                  }
                  value={toDate}
                  onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
                  disabled={isLoading}
                />
              </div>
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <Typography
                  className={classes.headerText}
                  style={{ marginRight: "10px" }}
                >
                  Filters:
                </Typography>
              </div>
              <div className={classes.chipRow}>
                {orderType !== "all" && (
                  <Chip
                    style={{ margin: "auto 2.5px" }}
                    color="primary"
                    label={upCase(orderType, "-")}
                    onDelete={() => handleChipClick("orderType", null)}
                  />
                )}
                {brands.length > 0 &&
                  brands.map((b) => (
                    <Chip
                      style={{ margin: "auto 2.5px" }}
                      color="primary"
                      key={b.id}
                      label={b.name}
                      onDelete={() => handleChipClick("brand", b.id)}
                    />
                  ))}
                {bus.length > 0 &&
                  bus.map((b) => (
                    <Chip
                      style={{ margin: "auto 2.5px" }}
                      color="primary"
                      key={b.id}
                      label={b.name}
                      onDelete={() => handleChipClick("bu", b.id)}
                    />
                  ))}
                {inMarketMonths.length > 0 &&
                  inMarketMonths.map((m, i) => (
                    <Chip
                      style={{ margin: "auto 2.5px" }}
                      color="primary"
                      key={i}
                      label={`${m.month}/${m.year}`}
                      onDelete={() => handleChipClick("inMarketMonth", m)}
                    />
                  ))}
              </div>
            </>
          )}
          {orderVariantIdsOnly && (
            <TextField
              fullWidth
              tw="mb-6"
              size="small"
              value={orderVariantIds}
              onChange={(e) => setOrderVariantIds(e.target.value)}
              label="Order Ids (separate with commas)"
            />
          )}
        </>
      </ReduxReportGenerator>
    </>
  );
};

ReportOrderDetail.propTypes = {};

export default ReportOrderDetail;
