/** @jsxImportSource @emotion/react */
import "twin.macro";

import { format } from "date-fns";
import _ from "lodash";
import { utcDate } from "src/utility/utilityFunctions";

import {
  PaginatedResourceProps,
  SmartTable,
  TableLink,
  showFirst,
} from "@components/SmartTable";
import { Program } from "@models/Program";
import { CldImage } from "@services/cloudinary";

import { getProgramBrandImageSrc } from "../helpers";

type PlanningProgramsTableProps = PaginatedResourceProps & {
  data: Program[];
};
const PlanningProgramsTable = ({
  data,
  ...props
}: PlanningProgramsTableProps) => {
  return (
    <SmartTable
      rows={data}
      {...props}
      columns={[
        {
          id: "_brandImg",
          label: "",
          render: (_, program) => (
            <CldImage
              src={getProgramBrandImageSrc(program)}
              tw="w-12 -m-2 ml-0 max-w-none aspect-square rounded object-cover"
            />
          ),
        },
        {
          id: "id",
          label: "Program ID",
          render: (id) => (
            <TableLink to={`/planning/programs/${id}`}>{id}</TableLink>
          ),
        },
        { id: "status", label: "Status", render: _.startCase },
        { id: "externalWorkfrontId", label: "Workfront ID" },
        {
          id: "brands",
          label: "Brands",
          render: (brands, program) =>
            program.brandAssignmentLevel === "item"
              ? "Multi-brand"
              : showFirst(brands.map((b) => b.name)),
        },
        { id: "name", label: "Program Name" },
        { id: "type", label: "Type" },
        {
          id: "startDate",
          label: "In Market",
          render: (d) => (
            <span tw="whitespace-nowrap">{format(utcDate(d), "PP")}</span>
          ),
        },
        { id: "itemCount", label: "Item Count" },
      ]}
    />
  );
};

export default PlanningProgramsTable;
