import { brandLogoMap } from "src/utility/constants";

const GALLO_LOGO =
  "https://res.cloudinary.com/brandhub/image/upload/v1612303566/prod/BrandLogos/Logo_EJG_Crest_uatj0x.png";

export function getProgramBrandImageSrc(program: {
  brands: { name: string }[];
}) {
  const cleanedFirstBrand = program.brands[0]?.name
    .replace(/[^a-z\-&\s]/gi, "")
    .replace(/\s+/g, " ");
  return brandLogoMap[cleanedFirstBrand] ?? GALLO_LOGO;
}
