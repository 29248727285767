/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { keepPreviousData } from "@tanstack/react-query";

import { SmartTable } from "@components/SmartTable";
import { usePaginatedAnaplanProgramsQuery } from "@features/programs";
import { displayDate } from "@features/reports";
import useDebouncedValue from "@utils/useDebouncedValue";

const start = new Date().getFullYear() - 1;
const end = start + 4;

const yearRangeArray = Array.from(
  { length: end - start },
  (value, index) => start + index
);

const AnaplanProgramSearch = ({
  program,
  selectedAnaplanProgram,
  setSelectedAnaplanProgram,
}) => {
  const [programNameIdSearch, setProgramNameIdSearch] = useState("");

  const [year, setYear] = useState("");

  const filterObject = useDebouncedValue(
    {
      programTypeId: program.programTypeId,
      channel: program.channel,
      isActivated: false,
      inMarketYear: year || null,
      codeOrName: programNameIdSearch || null,
    },
    300
  );

  const { data, ...tableProps } = usePaginatedAnaplanProgramsQuery(
    { filter: filterObject },
    { placeholderData: keepPreviousData }
  );

  return (
    <>
      <Typography variant="h6" tw="mb-4">
        Select Anaplan Program
      </Typography>
      <Typography>Anaplan Program Search Filters:</Typography>
      <div tw="flex gap-4 mt-4">
        <TextField
          color="secondary"
          fullWidth
          name="codeOrName"
          type="text"
          label="Anaplan Id/Name"
          variant="outlined"
          size="small"
          onChange={(e) => setProgramNameIdSearch(e.target.value)}
        />
        <FormControl fullWidth tw="mb-4 w-4/12">
          <InputLabel size="small">Year</InputLabel>
          <Select
            value={year}
            label="Year"
            size="small"
            onChange={(e) => {
              setYear(e.target.value);
            }}
          >
            {yearRangeArray.map((year) => {
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <RadioGroup value={selectedAnaplanProgram?.id ?? ""}>
        <SmartTable
          rows={data}
          {...tableProps}
          maxHeight={400}
          columns={[
            {
              id: "_select",
              label: "",
              render: (_, pa) => <Radio value={pa.id} tw="-m-4" />,
            },
            { id: "code", label: "Code" },
            { id: "name", label: "Name" },
            { id: "startDate", label: "In Market", render: displayDate },
          ]}
          onRowClick={(pa) => setSelectedAnaplanProgram(pa)}
        />
      </RadioGroup>
    </>
  );
};

export default AnaplanProgramSearch;
