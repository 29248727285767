import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { __ } from "lodash/fp";

import { OrderCalendarMonth } from "@models/OrderCalendarMonth";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

export const orderCalendarMonthsKeyFactory = createQueryKeys(
  "order-calendar-months",
  {
    list: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client
          .get<OrderCalendarMonth[]>("order-calendar-months", {
            params: { ...params, skipPagination: true },
          })
          .then((res) => res.data),
    }),
  }
);

export const useOrderCalendarMonthsQuery = (
  params: RequestParams,
  queryOptions?: QueryOptions<OrderCalendarMonth[]>
) => {
  return useQuery({
    ...orderCalendarMonthsKeyFactory.list(params),
    staleTime: Infinity,
    structuralSharing: true,
    ...queryOptions,
  });
};

export const useUpcomingOcmsQuery = () => {
  return useOrderCalendarMonthsQuery({
    filter: {
      isAdHoc: false,
      hasFutureInMarketStartDate: true,
    },
    sort: "order-window-open-date",
  });
};

export const useAllOcmsQuery = () => {
  return useOrderCalendarMonthsQuery({
    filter: {
      isAdHoc: false,
    },
    sort: "-order-window-open-date",
  });
};

type CreateOcmPayload = {
  visibleDate: string;
  reviewCloseDate: string;
  reportDate: string;
  purchasingCloseDate: string;
  orderWindowType: "national" | "secondary";
  orderWindowOpenDate: string;
  orderWindowName: string;
  orderWindowCloseDate: string;
  inMarketStartDate: string;
};
type UpdateOcmPayload = CreateOcmPayload & { id: string };

export const useCreateOcmMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateOcmPayload) =>
      client
        .post<OrderCalendarMonth>("order-calendar-months", {
          __type: "order-calendar-month",
          ...data,
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: orderCalendarMonthsKeyFactory.list._def,
      });
    },
  });
};

export const useUpdateOcmMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: UpdateOcmPayload) => {
      return client
        .put<OrderCalendarMonth>(`order-calendar-months/${id}`, {
          __type: "order-calendar-month",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (updatedOcm) => {
      queryClient.setQueryData<OrderCalendarMonth[]>(
        orderCalendarMonthsKeyFactory.list._def,
        (ocms) =>
          ocms &&
          ocms.map((ocm) => (ocm.id === updatedOcm.id ? updatedOcm : ocm))
      );
      queryClient.invalidateQueries({
        queryKey: orderCalendarMonthsKeyFactory.list._def,
      });
    },
  });
};
