import { displayDate } from "@features/reports";
import { ItemRollup } from "@models/ItemRollup";
import { CsvSchema } from "@utils/csv";

const rfpRollupSchema: CsvSchema<ItemRollup> = [
  { id: "variant.variantSku", label: "Sequence #" },
  { id: "userName", label: "Ordered By" },
  { id: "territoryNames", label: "Territory", f: (names) => names.join(", ") },
  { id: "orderProgramName", label: "Program" },
  { id: "brandNames", label: "Brand", f: (names) => names.join(", ") },
  { id: "externalWorkfrontId", label: "Project #" },
  { id: "itemType", label: "Item Type" },
  { id: "itemTypeDescription", label: "Item Desc." },
  { id: "totalOrdered", label: "Total Ordered" },
  { id: "estimatedCost", label: "Est. Cost" },
  { id: "estimatedTotal", label: "Est. Total" },
  { id: "orderApprovedAt", label: "Order Approval Date", f: displayDate },
  { id: "inMarketDate", label: "In-Market Date", f: displayDate },
];

export default rfpRollupSchema;
